<template>

    <div>
      <p><u><strong>Product Catalogue Report</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
            <button  type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button>
          <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col" @click="sort('ProductNumber')" >Product Number</th>
                <th class="th-lg" scope="col" @click="sort('ProductName')" >Product Name</th>
                <th class="th-sm" scope="col" @click="sort('ProductType')">Product Type</th>
                <th scope="col"  @click="sort('Category')">Category</th>
                <th scope="col"  @click="sort('Carrier')">Carrier</th>
                <th scope="col" @click="sort('PartNumber')">Part Number</th>
                <th scope="col"  @click="sort('UnitCost')" >Unit Cost</th>             
              </tr>
            </thead>
            <tbody>
              <tr v-for="(chargecategory, index) in SortedapiCLIs" :key="index">
                <td>{{ chargecategory.ProductNumber }}</td>
                <td>{{ chargecategory.ProductName }}</td>
                <td>{{ chargecategory.ProductType }}</td>
                <td>{{ chargecategory.Category }}</td>
                <td>{{ chargecategory.Carrier }}</td>
                <td>{{ chargecategory.PartNumber }}</td>
                <td>{{ chargecategory.UnitCost }}</td>
               
                
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredCLIs.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'


import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        apiCLIs: null,
        executed: false,
        message: 'initial alert',
        currentSort:'ProductName',
        currentSortDir:'asc',
        pageSize:30,
        showPaging:true,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selected_account: null,
        ShowBundle:false,
        selected_mobilebundle: null,
        CostCentres: null
      };
    },

    computed:{
    filteredCLIs() {
      //  console.log('filtered cli');
        return this.apiCLIs.filter(c => {
          if(this.filter == '') return true;
          return (c.ProductName && c.ProductName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                  c.ProductNumber && c.ProductNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) ||
                 c.PartNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.ProductType.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 (c.Carrier && c.Carrier.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
        })
      },

    SortedapiCLIs:function() {
      //  console.log('sort cli');
      if (this.apiCLIs != null){
        return this.filteredCLIs.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if (this.currentSort === 'UnitCost') {
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          }
          else{
            if (a[this.currentSort] === null) return 1;
            if (b[this.currentSort] === null) return -1;
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
          else{ return null}
    },
    },
    methods: {
      download : function() {
         // customised headers
         const Heading = [
                            ['ProductNumber','ProductName','ProductType','Category','Carrier','PartNumber','UnitCost']
                        ];

        // original object headers
        const objHeader = ['ProductNumber','ProductName','ProductType','Category','Carrier','PartNumber','UnitCost'];


        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.filteredCLIs, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename = 'Product Catalogue -' + datestr + '.xlsx';
        console.log(filename);
        XLSX.writeFile(wb,filename)


        // const data = XLSX.utils.json_to_sheet(this.filteredCLIs)
        // const wb = XLSX.utils.book_new()
        // XLSX.utils.book_append_sheet(wb, data, 'data')
        // var moment = require('moment');
        // var datestr = moment(new Date()).format('DD-MM-YYYY');
        // var filename = 'Product Catalogue -' + datestr + '.xlsx';
        // console.log(filename);
        // XLSX.writeFile(wb,filename)
        },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredCLIs.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List outbounds
      // *******************************************
      async callReportsApi(lablex) {

         console.log(lablex)
        var url;

        url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/productcatologue`

        document.body.style.cursor = 'wait';

        try {
          const { data } = await this.$http.get(url);

          this.apiCLIs = data;
          console.log(this.apiCLIs)
          if (this.apiCLIs.length < this.pageSize){
            this.showPaging = false
          }

          this.executed = true;
        } catch (e) {
          this.apiCLIs = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },


    },

    created() {
      //  console.log('created')
      this.callReportsApi();


    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
