<template>

  <div>
    <p><u>
        <strong>Dial Plan - Domestic</strong>
      </u> </p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.chargeband-modal>Insert domestic
          prefix</button>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('chargeband.Name')">Charge Band</th>
              <th class="th-sm" scope="col" @click="sort('Code')">Prefix</th>
              <th scope="col" @click="sort('ModifiedOn')">Modified On</th>
              <th scope="col" @click="sort('ModifiedBy')">User</th>
              <th> <button v-if="isAdmin" type="button" class="btn btn-success btn-sm"
                  v-b-modal.chargeband-load-modal>Load domestic dial plan </button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(chargeband, index) in SortedapiChargeBandCodes" :key="index">
              <td>{{ chargeband.chargeband.Name }}</td>
              <td>{{ chargeband.Code }}</td>
              <td>{{ chargeband.ModifiedOn }}</td>
              <td>{{ chargeband.ModifiedBy }}</td>
              <td align="center">
                <div class="btn-group" role="group">
                  <button v-if="isAdmin" type="button" class="btn btn-warning btn-sm" v-b-modal.chargeband-update-modal
                    @click="editChargeBandCode(chargeband)">
                    {{editButtonText}}
                  </button>
                  <p> </p>
                  <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm"
                    @click="onDeleteChargeBandCode(chargeband)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredChargeBandCodes.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
    <!-- LOAD modal -->
    <b-modal ref="loadChargeBandCodeModal" id="chargeband-load-modal" title="Load domestic dial plan" hide-footer
      size="xs">
      <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
        <b-form-row>
          <b-col>
            <p><strong>Select a comma seperated file (.txt).</strong> </p>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadChargeBandCodeForm.FileName"
              required placeholder="Select domestic dial plan file">
            </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- add modal -->
    <b-modal ref="addChargeBandCodeModal" id="chargeband-modal" title="Insert domestic prefix" hide-footer size="sm">
      <b-form @submit="onSubmit" @reset="onReset" class="w-600">
        <b-form-row>
          <b-col>
            <div class="dropdown">
              <select style="width: 100%" v-model="addChargeBandCodeForm.CBId" @change="onChangeChargeBand($event)">
                <option value="">Select chargeband</option>
                <option v-for="(chargeband, index) in ChargeBands" :value="chargeband.CBId" :key="index">
                  {{ chargeband.Name }}
                </option>
              </select>
            </div>
            <br>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Code-group" label="Prefix" label-for="form-Code-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Code-input" type="text" size="sm" v-model="addChargeBandCodeForm.Code"
                placeholder="Enter prefix">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal ref="editChargeBandCodeModal" id="chargeband-update-modal" title="Update domestic prefix" hide-footer
      size="sm">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
        <b-form-row>
          <b-col>
            <div class="dropdown">
              <select style="width: 100%" v-model="editChargeBandCodeForm.CBId" @change="onChangeChargeBand($event)">
                <option value="">Select chargeband</option>
                <option v-for="(chargeband, index) in ChargeBands" :value="chargeband.CBId" :key="index">
                  {{ chargeband.Name }}
                </option>
              </select>
            </div>
            <br>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Code-group" label="Prefix" label-for="form-Code-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Code-input" type="text" size="sm" v-model="editChargeBandCodeForm.Code"
                placeholder="Enter prefix">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row" v-if="isAdmin">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import ChargeBandDropDown from "../components/ChargeBandDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
      SelectedPhoneType: '',
      loadChargeBandCodeForm: {
        FileName: ''
      },
      addChargeBandCodeForm: {
        Name: '' ,
        Description: '' ,
        SellDayRate: '',
        SellEveRate: '',
        SellWendRate: '',
        SellConFee: '',
        CostDayRate: '',
        CostEveRate: '',
        CostWendRate: '',
        CostConFee: '',
        Carrier: ''
      },
      editChargeBandCodeForm: {
        Id: null,
        Code: null ,
        CBId: null
      },
      apiChargeBandCodes: null,
      executed: false,
      message: 'initial alert',
      currentSort:'Code',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:true,
      currentPage:1,
      filter:'',
      ChargeBands: null,
      ShowAccount:true,
      selectedChargeband: null
    };
  },

  computed:{
  filteredChargeBandCodes() {
    // console.log('filtered chargeband');
      return this.apiChargeBandCodes.filter(c => {
        if(this.filter == '') return true;
        if (c.Code == null) c.Code = '';
        if (c.chargeband.Name == null) c.chargeband.Name = '';
        return c.Code.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.chargeband.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiChargeBandCodes:function() {
    // console.log('sort chargeband');
    // console.log('sort account');
    if (this.apiChargeBandCodes == null) return null;
    // console.log ('in sort account....');
    // console.log(this.currentSort)
    return this.filteredChargeBandCodes.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;

      if(this.currentSort === 'chargeband.Name'){
          var prop = this.currentSort.split('.');
          var len = prop.length;
            var i = 0;
            while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
            if (a < b) {
                return -1 * modifier;
            } else if (a > b) {
                return 1 * modifier;
            } else {
                return 0;
            }
        }
      else{
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      }
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    download: function() {
        console.log("in download")
        var data = "ChargeBand,Prefix"

        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "UploadDialPlan-Domestic.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
    onChangeChargeBand(event) {
      this.selectedChargeband= event.target.value

      console.log(event.target.value)


      // this.$emit('account-changed',this.SelectedAccount)
    },

    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredChargeBandCodes.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List chargebands
    // *******************************************
    async callChargebandApi(lablex) {

      console.log(lablex)
    //   console.log('getting the costcentre list')
    //   console.log(this.$route.query.account_id)
    //   console.log('getting the costcentre list')
      var url;
      url = process.env.VUE_APP_API_BASE_URL + '/api/v1/chargebands?q={"filters":[{"name":"AccountId","op":"is_null","val":""}]}&only=Name,CBId';

      try {
        const { data } = await this.$http.get(url);

        this.ChargeBands = data;
        if (this.apiCostCentres.length < this.pageSize){
          this.showPaging = false
        }
        console.log(this.ChargeBands)

        this.executed = true;
      } catch (e) {
        this.apiCostCentres = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // List chargebandcodes
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
    //   console.log('getting the chargeband list')
    //   console.log(this.$route.query.account_id)
    //   console.log('getting the chargeband list')
      var url;
      if (typeof(this.$route.query.CBId) != "undefined"){
          console.log('selected ID in ChargeBandCode is:');
          console.log(this.$route.query.CBId)
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/chargebandcodes?q={"filters":[{"name":"CBId","op":"eq","val":"' + this.$route.query.CBId + '"}]}';
        //   console.log(url)
          this.ShowAccount = false
          this.selected_chargeband = this.$route.query.CBId
        //   console.log('selected chargeband in ChargeBandCode is:');
        //   console.log(this.selected_chargeband);
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebandcodes`
      }
      try {
        document.body.style.cursor = 'wait';
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiChargeBandCodes = data;
        if (this.apiChargeBandCodes.length < this.pageSize){
          this.showPaging = false
        }
        // console.log(this.apiChargeBandCodes )
        // console.log(this.apiChargeBandCodes.length)
        this.executed = true;
      } catch (e) {
        this.apiChargeBandCodes = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // Delete chargeband
    // *******************************************
    async removeChargeBandCode(chargebandcodeid) {

    //   console.log("in removeChargeBandCode before")
      console.log(chargebandcodeid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebandcodes/${chargebandcodeid}`).then(() => {
          this.message = 'chargeband removed!';
          this.showMessage = true;
          console.log("in removeChargeBandCode after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeChargeBandCode error")
          console.error(err);
          this.callApi();

        });

        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiChargeBandCodes = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteChargeBandCode(chargebandcode) {
      console.log(chargebandcode.Id)
      if(confirm("Are you sure you want to delete this chargeband?")){
        this.removeChargeBandCode(chargebandcode.Id);
      }
    },

    // Add chargeband method to post to API
    async addChargeBandCode(payload) {

    //   const id_token = claims.__raw;
      console.log('in add chargeband');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebandcodes`, payload).then(() => {
          this.message = 'chargeband added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated chargeband. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add chargeband. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiChargeBandCodes = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addChargeBandCodeForm.Name =  null;
        this.addChargeBandCodeForm.Notes = null;
    },
    // *******************************************
    // Add cdrload on submit
    // *******************************************
    onLoadSubmit(evt) {
      console.log('on load submit');
      evt.preventDefault();
      this.$refs.loadChargeBandCodeModal.hide();
      const payload = {
        FileName: this.loadChargeBandCodeForm.FileName
      };
      this.loadchargebandcodes(payload);
      //this.initForm();
    },
    onLoadReset(evt) {
      evt.preventDefault();
      this.$refs.loadChargeBandCodeModal.hide();
      this.initForm();
    },
    // Add cdrload method to post to API
    async loadchargebandcodes(payload) {

      let formData = new FormData();
      console.log( 'in load chargebandcodes');
      console.log( 'account is');

      console.log( payload.file);
      formData.append('file', payload.FileName);
      var url;
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebandcodes/load`

      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'charband codes loaded!';
          this.showMessage = true;
          //this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          document.body.style.cursor = 'auto';
          this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
  //
    // *******************************************
    // Add chargeband on submit
    // *******************************************
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addChargeBandCodeModal.hide();
      const payload = {
        Code: this.addChargeBandCodeForm.Code,
        CBId: this.selectedChargeband
      };
      this.addChargeBandCode(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addChargeBandCodeModal.hide();
      this.initForm();
    },
  //
    editChargeBandCode(chargeband) {
      console.log('editChargeBandCode');
      console.log(chargeband);
      console.log(chargeband.CBId);
      this.callChargebandApi();
      this.editChargeBandCodeForm = chargeband;
      this.selectedChargeband = chargeband.CBId
      this.callApi()
    },

    // *******************************************
    // Update chargeband on submit
    // *******************************************
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('CBId');
      evt.preventDefault();
      this.$refs.editChargeBandCodeModal.hide();
      const payload = {
        Code: this.editChargeBandCodeForm.Code,
        CBId: this.selectedChargeband
      };
      this.updateChargeBandCode(payload, this.editChargeBandCodeForm.Id);
      this.callApi()
    },

    async updateChargeBandCode(payload, chargebandcodeid) {

      console.log('in update chargebandcode');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebandcodes/${chargebandcodeid}`, payload).then(() => {
          this.message = 'chargeband updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated chargeband. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add chargeband. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiChargeBandCodes = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiChargeBandCodes = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editChargeBandCodeModal.hide();
      this.initForm();
      this.getChargeBandCodes(); // why?
    },
  },
  created() {
    console.log('created')
    this.callChargebandApi();
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    ChargeBandDropDown: ChargeBandDropDown,
  },
};
</script>
