<template>

    <div>
      <p><u><strong>Account SMS Contract List</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.accountsmscontract-modal>Insert  Account SMS Contract</button>
          <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col" @click="sort('account.Name')" >Account</th>
                <th class="th-lg" scope="col" @click="sort('smscontract.Name')" >SMS Contract</th>
                <th class="th-lg" scope="col" @click="sort('OutboundId')" >CLI</th>
                <th scope="col" @click="sort('ModifiedOn')">Date modified</th>
                <th scope="col" @click="sort('ModifiedBy')">Modified by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(accountsmscontract, index) in SortedapiAccountSMSContract" :key="index">
                <td>{{ accountsmscontract.account.Name }}</td>
                <td>{{ accountsmscontract.smscontract.Name }}</td>
                <td>{{ accountsmscontract.OutboundId}}</td>
                <td>{{ accountsmscontract.ModifiedOn }}</td>
                <td>{{ accountsmscontract.ModifiedBy }}</td>
                <td>
                  <div class="btn-group"  role="group">
                    <p>  </p>
                    <button v-if="isAdmin" v-on:click="onDeleteAccountSMSContract(accountsmscontract)"  type="button"
                            class="btn btn-danger btn-sm">
                        Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div><p align="center">
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div>
        </div>
      </div>


      <!-- ADD modal -->
      <b-modal ref="addAccountSMSContractModal"
              id="accountsmscontract-modal"
              title="Insert a new account smscontract"
              hide-footer size="m" >
        <b-form @submit="onSubmit" @reset="onReset" class="w-200">
        <b-form-row>
            <b-col>
             <b-form-group id="form-Account-group"
              label="Account"
                        label-for="form-Account-input" label-cols="4" label-cols-lg="3" label-size="sm" >
              <div >
                  <AccountDropdownVue  @account-changed="accountChange"></AccountDropdownVue>
          </div>
          </b-form-group>
          </b-col>
        </b-form-row>
         <b-form-row>
            <b-col>
             <b-form-group id="form-Bundle-group"
              label="SMS contract"
                        label-for="form-Bundle-input" label-cols="4" label-cols-lg="3" label-size="sm" >
              <div >
                  <SMSContractDropDown @callsmscontract-changed="callsmscontractChange"></SMSContractDropDown>
          </div>
          </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
           <b-col>
             <b-form-group id="form-name-group"
                        label="CLI"
                        label-for="form-CLI-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-CLI-input"
                        type="text"   size="sm"
                        v-model="addAccountSMSContractForm.OutboundId"
                        placeholder="Enter CLI">
              </b-form-input>
              </b-form-group>
          </b-col>

        </b-form-row>

        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import AccountDropdownVue from "../components/AccountDropdown.vue";
  import SMSContractDropDown from "../components/SMSContractDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        editcomponentKey:0,
        selected_smscontract:"",
        addAccountSMSContractForm: {
          OutboundId:''
        },

        apiAccountSMSContract: null,
        executed: false,
        message: 'initial alert',
        currentSort:'ASMSCId',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
      };
    },

    computed:{
    filteredAccountSMSContracts() {
      console.log('filtered accountsmscontract');
        return this.apiAccountSMSContract.filter(c => {
          if(this.filter == '') return true;
          if (c.smscontract.Name == null) c.smscontract.Name = '';
          if (c.account.Name == null) c.account.Name = '';
          return c.smscontract.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 (c.OutboundId && c.OutboundId.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0
        })
      },

    SortedapiAccountSMSContract:function() {
      // console.log('sort chargeband');
      // console.log('sort account');
      if (this.apiAccountSMSContract == null) return null;
      // console.log ('in sort account....');
      // console.log(this.currentSort)
      return this.filteredAccountSMSContracts.slice().sort((a,b) => {
        let modifier = 1;
        var subfields = ['account.Name','smscontract.Name','smscontract.BundleType']
        if(this.currentSortDir === 'desc') modifier = -1;

        if (subfields.includes(this.currentSort)) {
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
        else{
          if (a[this.currentSort] === null) return 1;
          if (b[this.currentSort] === null) return -1;
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        }
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      accountChange: function (e) {
          this.selectedAccount = e;
      },
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      callsmscontractChange: function (e) {
          console.log('@@@@@@@@@@@@@@@@@@@@@@@@@ in contractchanged @@@@@@@@@@@@@@@@@@@@@@@@@');
          console.log(e);
          this.selectedSMSContract = e;
      },
      forceRerender:function() {
        this.editcomponentKey += 1
      },
      accountsmscontractChange: function (e) {
          this.selectedAccount = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredAccountSMSContracts.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      //////////////////////////////////////////////
      // LIST ACCOUNT BUNDLE METHODS
      //////////////////////////////////////////////
      async callApi(lablex) {

        console.log(lablex)
        console.log('getting the data')
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountsmscontracts`);
          console.log('got account smscontract data')
           console.log(data)
          this.apiAccountSMSContract = data;
          this.executed = true;
        } catch (e) {
          this.apiAccountSMSContract = `Error: the server responded with '${e.response}'`;
        }
      },

      //////////////////////////////////////////////
      // DELETE CALL BUNDLE METHODS
      //////////////////////////////////////////////
      async removeAccountSMSContract(accountsmscontractid) {

        console.log("in removeAccountSMSContract before")
        console.log(accountsmscontractid)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountsmscontracts/${accountsmscontractid}`).then(() => {
            this.message = 'accountsmscontract removed!';
            this.showMessage = true;
            console.log("in removeAccountSMSContract after")
            this.callApi("coming from delete");

          }).catch((err) => {
            console.log("in removeAccountSMSContract error")
            console.error(err);
            this.callApi();

          });

          // this.apiAccountSMSContract = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiAccountSMSContract = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteAccountSMSContract(accountsmscontract) {
        console.log(accountsmscontract.ASMSCId)
        if(confirm("Are you sure you want to delete the account smscontract?")){
          this.removeAccountSMSContract(accountsmscontract.ASMSCId);
        }
      },

      //////////////////////////////////////////////
      // ADD CALL BUNDLE METHODS
      //////////////////////////////////////////////

      // Add accountsmscontract method to post to API
      async addAccountSMSContract(payload) {

      //   const id_token = claims.__raw;
        console.log('in add accountsmscontract');
        console.log('payload is');
        console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountsmscontracts`, payload).then(() => {
            this.message = 'accountsmscontract added!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated accountsmscontract. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add accountsmscontract. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });

          // this.apiAccountSMSContract = data;
          // this.executed = true;
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiAccountSMSContract = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addAccountSMSContractForm.GroupBundle =  false;
      },
      // Add account smscontract on submit
      onSubmit(evt) {
        console.log('on submit');
        console.log(this.selectedSMSContract);
        evt.preventDefault();
        this.$refs.addAccountSMSContractModal.hide();
        const payload = {
          AccountId: this.selectedAccount,
          SMSContractId: this.selectedSMSContract,
          OutboundId: this.addAccountSMSContractForm.OutboundId
        };
        this.addAccountSMSContract(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addAccountSMSContractModal.hide();
        this.initForm();
      },


    },
    created() {
      console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
      SMSContractDropDown: SMSContractDropDown,
    },
  };
  </script>
  <style>
  .table .btn-group {
      white-space: nowrap;
  }
  .table .btn-group .btn {
      display: inline-block;
      float: none;
  }
  .table .btn-group .btn + .btn {
      margin-left: -5px;
  }
  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
  }
  td {
    font-size: 12px;
  }
  .btn-custom {
      width: 50px !important;
      height: 17px;
      font-size: 9px;
      font-weight: normal;
      text-align: center;
      background-color: red;
      text-emphasis-color: white;
      color: white;
  }

  </style>
