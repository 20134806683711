<template>

    <div>
      <p><u><strong>Deleted Group Accounts</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
            <b-form @submit="onSubmit" >

                <b-form-group
                id="fieldset-horizontal"
                label-cols-sm="2"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="5"
                label="From Date"
                label-for="input-horizontal"
                >
                <b-form-input id="form-fromdate-input"
                        type="date"   size="sm"
                        v-model="FromDate"
                        placeholder="Enter from date">
                </b-form-input>
                </b-form-group>
                <b-form-group
                id="fieldset-horizontal"
                label-cols-sm="2"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="5"
                label="To Date"
                label-for="input-horizontal"
                >
                <b-form-input id="form-todate-input"
                        type="date"   size="sm"
                        v-model="ToDate"
                        placeholder="Enter to date">
                </b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary">Find</b-button>
          </b-form>
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col"  >Account No</th>
                <th class="th-lg" scope="col" >Account Name</th>
                <th class="th-lg" scope="col"  >Accounts</th>
                <th scope="col" >Modified on</th>
                <th scope="col">User</th>
                <th scope="col">Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(accountgrouphistory, index) in SortedapiAccountGroups" :key="index">
                <td>{{ accountgrouphistory.accountgroup.GroupNo }}</td>
                <td>{{ accountgrouphistory.accountgroup.Name }}</td>
                <td>{{ accountgrouphistory.accountgroup.accounts.length }}</td>
                <td>{{ accountgrouphistory.modifiedon }}</td>
                <td>{{ accountgrouphistory.modifiedby }}</td>
                <td>{{ accountgrouphistory.method }}</td>
                <td align="center"><a :href="'/accountgrouphistory?accountgroup_id=' + accountgrouphistory.accountgroup.accountgroup_id + '&groupno=' + accountgrouphistory.accountgroup.GroupNo + '&groupname='+ accountgrouphistory.accountgroup.Name" target="_blank">History</a></td>
                <td>
                  <div class="btn-group"  role="group">
                    <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-b-modal.accountgroup-update-modal
                            @click="editAccountGroup(accountgrouphistory)">
                            View
                    </button>
  
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="filteredAccounts.length"
            :per-page="pageSize"
            aria-controls="apirecords"
            ></b-pagination>
            </p></div>
        </div>
      </div>
  
  
    <!-- ADD member modal -->
      <b-modal ref="addAccountMemberModal"
              id="member-modal"
              title="Insert account to group"
              hide-footer size="sm" >
        <b-form    class="w-1700">
        <b-form-row>
          <b-col>
              <div > <center>
                <AccountDropdownVue group_exclude @account-changed="accountChange"></AccountDropdownVue>
              </center>
              <br>
          </div>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
      <!-- EDIT modal -->
      <b-modal :key="editcomponentKey" ref="editAccountGroupModal"
              id="accountgroup-update-modal"
              title="Account Group History"
              hide-footer size="xl" >
        <b-form  @reset="onResetUpdate"  class="w-200">
          <b-form-row>
            <b-col>
              <b-form-group id="form-groupno-group"
                      label="Account Number"
                      label-for="form-groupno-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-groupno-input"
                              type="text"   size="sm"
                              v-model="editAccountGroupForm.GroupNo"
                              readonly
                              placeholder="Enter account no." w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-SageAccount-group"
                        label="Sage Account"
                        label-for="form-SageAccount-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-SageAccount-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.SageAccount"
                        readonly
                        placeholder="Enter Sage Account">
              </b-form-input>
              </b-form-group>
          </b-col>
  
          </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Account Name"
                      label-for="form-name-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editAccountGroupForm.Name"
                              readonly
                               w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-ContactName-group"
                        label="Contact"
                        label-for="form-ContactName-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-ContactName-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.ContactName"
                        readonly>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Address1-group"
                        label="Address1"
                        label-for="form-Address1-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Address1-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.Address1"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Email-group"
                        label="Report Email"
                        label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Email-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.Email"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
  
        </b-form-row>
  
        <b-form-row>
          <b-col>
              <b-form-group id="form-Address2-group"
                        label="Address2"
                        label-for="form-Address2-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-Address2-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.Address2"
                        readonly>
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Notes-group"
                        label="Invoice Email"
                        label-for="form-Notes-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Notes-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.InvoiceEmail"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
         <b-form-row>
          <b-col>
              <b-form-group id="form-City-group"
                        label="City"
                        label-for="form-City-input" label-cols="6" label-cols-sm="3"  label-size="sm">
              <b-form-input id="form-City-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.City"
                        readonly
  
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Notes-group"
                        label="Notes"
                        label-for="form-Notes-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Notes-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.Notes"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
  
        <b-form-row>
           <b-col>
               <b-form-group id="form-County-group"
                        label="County"
                        label-for="form-County-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-County-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.County"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
  
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
               <b-form-group id="form-Country-group"
                        label="Country"
                        label-for="form-Country-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-Country-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.Country"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
  
          <b-col>
              <b-form-group id="form-PaymentTerm-group"
  
                        label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm">
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
               <b-form-group id="form-PostCode-group"
                        label="PostCode"
                        label-for="form-PostCode-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PostCode-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.PostCode"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col ></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
               <b-form-group id="form-BTN-group"
                        label="PO Number"
                        label-for="form-BTN-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-BTN-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.BTN"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-PaymentTerm-group"
                        label="Payment Term"
                        label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-Email-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.PaymentTerm"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col ></b-col>
          <b-col>
             <b-form-checkbox
              id="DirectDebit"
              v-model="editAccountGroupForm.DirectDebit"
              name="DirectDebit"
              disabled='true'
              >
              Direct debit
          </b-form-checkbox>
          </b-col>
          <b-col>
             <b-form-checkbox
              id="ExportCalls"
              v-model="editAccountGroupForm.ExportCalls"
              name="ExportCalls"
              disabled='true'
              >
              Export Calls
          </b-form-checkbox>
          </b-col>
          </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
  
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
  
         <b-form-row>
          <b-col>
              <b-form-group id="form-LastBillDate-group"
                        label="Date Last Transferred"
                        label-for="form-LastBillDate-input" label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input readonly id="form-LastBillDate-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.LastBillDate">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-ModifiedOn-group"
                        label="Modified on"
                        label-for="form-ModifiedOn-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input readonly id="form-ModifiedOn-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.modifiedon">
              </b-form-input>
              </b-form-group>
          </b-col>
  
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-ModifiedBy-group"
                        label="User"
                        label-for="form-ModifiedBy-input" label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input readonly id="form-ModifiedBy-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.modifiedby">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-ModifiedOn-group"
                        label="Action"
                        label-for="form-ModifiedOn-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input readonly id="form-ModifiedOn-input"
                        type="text"   size="sm"
                        v-model="editAccountGroupForm.action">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <p>Group Members</p>
        </b-form-row>
        <b-form-row>
            <b-col cols sm="2"  lg="auto">
            <table class="table table-small w-auto" >
            <thead>
              <tr>
                <th class="th-xs"  scope="col" >Name</th>
                 <th class="th-xs"   scope="col" >AccountNumber</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in editAccountGroupForm.accounts" :key="index">
                <td>{{ account.Name }}</td>
                <td>{{ account.AccountNumber }}</td>
              </tr>
            </tbody>
          </table>
          </b-col>
        </b-form-row>
  
  
          <div class="container">
          <div class="row" v-if="isAdmin" >
              <div class="col text-center">
              <b-button-group>
            <b-button type="reset" variant="danger">Close</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>
  
  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import AccountDropdownVue from "../components/AccountDropdown.vue";
  
  import {isAdmin, editButtonText} from '@/auth/AuthService'
  export default {
    Name: "Api",
    data() {
      return {
        isAdmin: isAdmin(),
        editButtonText: editButtonText(),
        FromDate : null,
        ToDate : null,
        editcomponentKey:0,
        selectedAccount:"",
        editAccountGroupForm: {
          accountgroup_id : '',
          Name: '' ,
          GroupNo: '' ,
          Address1: '' ,
          Address2: '' ,
          PostCode: '' ,
          City: '' ,
          County: '' ,
          Country: '' ,
          SaleAgent: '' ,
          ContactName: '',
          Email: '' ,
          InvoiceEmail:'',
          CreatedOn:'',
          CreatedBy:'',
          ModifiedOn:'',
          ModifiedBy:'',
          DirectDebit:'',
          ExportCalls:'',
          PaymentTerm: '',
          Accounts:[],
          accounts:[],
          Notes:'',
          modifiedby :null,
          modifiedon : null,
          action : null
        },
        apiAccountGroups: [],
        apiHistoryAccountGroup: null,
        executed: false,
        message: 'initial alert',
        currentSort:'modifiedon',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        showPaging:false,
        filter:'',
      };
    },
  
    computed:{
    filteredAccounts() {
      console.log('filtered account');
      return this.apiAccountGroups;
      // console.log(this.filter);
      //   return this.apiAccountGroups.filter(c => {
      //     if(this.filter == '') return true;
      //     return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
      //            c.GroupNo.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
      //            c.SageAccount.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      //   })
      },
    SortedapiAccountGroups:function() {
      console.log('sort account group');
      if (this.apiAccountGroups != null){
      return this.filteredAccounts.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
      }
      else{ return null}
    },
    },
    methods: {
      forceRerender:function() {
        this.editcomponentKey += 1
      },
      accountChange: function (e) {
          this.selectedAccount = e;
      },
      onSubmit(evt) {
        console.log('on submit');
        this.apiHistories = null;
        this.showresult = false;
        var todate = null

        if (this.ToDate != null){
        todate = this.ToDate + ' 23:59:59';
        }

        if (this.ToDate != null & this.FromDate > this.ToDate){
            evt.preventDefault();
            alert("To date can not be before the from date")
        }
        else{
            this.callApi( this.FromDate, todate);
            evt.preventDefault();
        }  
    },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredAccounts.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
  
      // *******************************************
      // get account group details
      // *******************************************
      async getAccountGroupApi(lablex) {
  
        console.log(lablex)
        console.log('getting the account data')
        try {
          document.body.style.cursor = "wait";
          var url;
          if (typeof(this.$route.query.accountgroup_id) != "undefined"){
            url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/accountgroups/' + this.$route.query.accountgroup_id
          }
          // const { data } = await this.$http.get("https://dev03.btlcloud.net/api/v1/accounts", {
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiHistoryAccountGroup = data;
          console.log('got the account group data from api')
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apiAccounts = `Error: the server responded with '${e.response}'`;
        }
      },
      //////////////////////////////////////////////
      // LIST ACCOUNT GROUP HISTORY
      //////////////////////////////////////////////
      async callApi(fromdate, todate) {

        console.log('getting the data')
        // build the filters for the url
        var filters =[];
        var fromfilter = {"name":"modifiedon","op":">=","val":fromdate}
        var tofilter = {"name":"modifiedon","op":"<","val":todate}

        if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
            filters.push(fromfilter)
        }
        if (typeof(todate) != "undefined" && todate != null && todate != "") {
            filters.push(tofilter)
        }


        let queryfilters = JSON.stringify(filters);
        try {
          document.body.style.cursor = "wait";
          var url;
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/accountgroup/deleted?q={"filters":' + queryfilters + '}' ;
          console.log(url);
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          console.log('got account group data')
          this.apiAccountGroups = data;
          console.log(this.apiAccountGroups.lengt)
          if (this.apiAccountGroups.length > this.pageSize){
            this.showPaging = true
          }
          this.executed = true;
        } catch (e) {
          this.apiAccountGroups = `Error: the server responded with '${e.response}'`;
        }
      },
  
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addAccountModal.hide();
        this.initForm();
      },
  
      //////////////////////////////////////////////
      // VIEW ACCOUNT GROUP HISTORY
      //////////////////////////////////////////////
      editAccountGroup(history) {
        console.log('editAccountGroup');
        console.log(history.accountgroup);
        console.log(history.accountgroup.accountgroup_id);
        this.editAccountGroupForm = history.accountgroup;
        this.editAccountGroupForm.modifiedby = history.modifiedby
        this.editAccountGroupForm.modifiedon = history.modifiedon
        this.editAccountGroupForm.action = history.method
      },
  
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editAccountGroupModal.hide();
        this.initForm();
        this.getAccounts(); // why?
      },
    },
    created() {
      console.log('created')
      this.executed = true;
    //   this.getAccountGroupApi();
    //   this.callApi();
  
    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
    },
  };
  </script>
  <style>
  .table .btn-group {
      white-space: nowrap;
  }
  .table .btn-group .btn {
      display: inline-block;
      float: none;
  }
  .table .btn-group .btn + .btn {
      margin-left: -5px;
  }
  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
  }
  td {
    font-size: 12px;
  }
  .btn-custom {
      width: 50px !important;
      height: 17px;
      font-size: 9px;
      font-weight: normal;
      text-align: center;
      background-color: red;
      text-emphasis-color: white;
      color: white;
  }
  
  </style>
  