<template>

    <div>
      <p ><u><strong>Carrier Calls Report</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >

        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="4"
            label="Carrier"
            label-for="input-horizontal"
            >
            <div>
                        <CarrierNameDropDownVue @carrier-changed="carrierChange"></CarrierNameDropDownVue>
                    </div>
            </b-form-group>
        <b-button type="submit" variant="primary">Find</b-button>
  </b-form>

         <br><br>
         <table v-show="showresult" >
            <!-- <tr>
                <td width="900"> <p style="color:blue;font-size:14px;" v-if="showresult"> Number of records found for {{ Account.Name }}  {{ Account.AccountNumber}}: {{ apiPricedCalls.length }}</p></td>
            </tr> -->
            <tr v-if="apiPricedCalls.length > 0">
                <td><button  type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>
        <br><br>

         <!-- <p v-if="showresult"> Number of records found: {{ apiPricedCalls.length }}</p> -->
          <!-- <input type="search" v-model="filter"> -->
          <table class="table table-striped w-auto" v-show="showresult" id="apirecords">
            <thead>
              <tr>
                <th scope="col" @click="sort('AccountName')">Account Name</th>
                <th scope="col" @click="sort('AccountNumber')">Account Number</th>
                <th scope="col" @click="sort('Source')">Source</th>
                <th scope="col" @click="sort('Dialled')">Dialled</th>
                <th scope="col" @click="sort('Destination')">Destination</th>
                <th scope="col" @click="sort('CallType')">Call Type</th>
                <th scope="col" @click="sort('CallDate')">Call Date</th>
                <th scope="col" @click="sort('CallTime')">Call Time</th>
                <th scope="col" @click="sort('Duration')">Duration(m)</th>
                <th scope="col" @click="sort('Data_mb')">Data(MB)</th>
                <th scope="col" @click="sort('Price')">Price</th>
                <th scope="col" @click="sort('Carrier')">Carrier</th>
                <th scope="col" @click="sort('Cost')">Cost</th>
                <th scope="col" @click="sort('Direction')">Direction</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(suspendedcall, index) in SortedapiPricedCalls" :key="index">
                <td>{{ suspendedcall.AccountName }}</td>
                <td>{{ suspendedcall.AccountNumber }}</td>
                <td>{{ suspendedcall.Source }}</td>
                <td>{{ suspendedcall.Dialled }}</td>
                <td>{{ suspendedcall.Destination }}</td>
                <td>{{ suspendedcall.CallType }}</td>
                <td>{{ suspendedcall.CallDate }}</td>
                <td>{{ suspendedcall.CallTime }}</td>
                <td>{{ suspendedcall.Duration }}</td>
                <td>{{ suspendedcall.Data_mb }}</td>
                <td>{{ suspendedcall.Price }}</td>
                <td>{{ suspendedcall.Carrier }}</td>
                <td>{{ suspendedcall.Cost }}</td>
                <td>{{ suspendedcall.Direction }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="apiPricedCalls.length > 0"><p align="center">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredPricedCalls.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'
  import CarrierNameDropDownVue from "../components/CarrierNameDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),

        apiPricedCalls: [],
        executed: false,
        message: 'initial alert',
        currentSort:'CallDate',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        Account: null
      };
    },

    computed:{
    filteredPricedCalls() {
      console.log('filtered suspendedcall');
        return this.apiPricedCalls
      },
    SortedapiPricedCalls:function() {
      console.log('sort suspendedcall');
      return this.filteredPricedCalls.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
    onChangeCallType(event) {
            this.SelectedCallType= event.target.value
            },

    onChangeDirection(event) {
        this.SelectedDirection= event.target.value
        },
    accountChange: function (e) {
        this.apiPricedCalls = null;
        this.showresult = false;
        console.log('£££££££££££ in change account £££££££')
        console.log(this.showresult)
        this.selectedAccount = e;
        },

    carrierChange: function (e) {
        this.selectedCarrier = e;
        },

    onSubmit(evt) {
        console.log('on submit');
        this.apiPricedCalls = null;
        this.showresult = false;
        // this.Account = null
        console.log(this.selectedAccount)
        if (!this.selectedCarrier){ // is null or zero
            alert("Please select a carrier");
            evt.preventDefault();
                    return false;
            }
        this.callApi( this.selectedCarrier);
        // this.showresult = true;
        evt.preventDefault();
        },

     download : function() {
        // customised headers
        const Heading = [
                            ['Account Name','Account Number','Source','Dialled','Destination','Call Type','Call Date','Call Time','Duration(m)','Data(MB)','Price','Carrier','Cost','Direction' ]
                        ];

        // original object headers
        const objHeader =  ['AccountName','AccountNumber','Source','Dialled','Destination','CallType','CallDate','CallTime','Duration','Data_mb','Price','Carrier','Cost','Direction'];


        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.apiPricedCalls, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename = this.selectedCarrier+ ' calls - ' + datestr + '.xlsx';
        XLSX.writeFile(wb,filename)
        },

    // async getAccount(accountid) {
    //   console.log('getting the account data')
    //   try {
    //     const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/${accountid}`);

    //     this.Account = data;
    //     console.log('got the selected account data from api')
    //     console.log(this.Account.Name)
    //     // this.executed = true;
    //   } catch (e) {
    //     this.apiAccounts = `Error: the server responded with '${e.response}'`;
    //   }
    // },
    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredPricedCalls.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List suspendedcalls
      // *******************************************
    async callApi( carrier) {

        console.log('getting the data')
        // build the filters for the url
        var url;
        document.body.style.cursor = 'wait';

        try {
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/carriercalls/carrier/' + carrier ;
          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apiPricedCalls = data;
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiPricedCalls = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      CarrierNameDropDownVue: CarrierNameDropDownVue,
      listfilter:Filter
    },
  };
  </script>
