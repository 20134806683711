<template>

  <div>
    <p v-if="deleted"><u><strong>Deleted Outbound Billing Reference History ({{ this.outboundphonenumber }})</strong></u></p>
    <p v-else><u><strong>Outbound Billing Reference History ({{ this.outboundphonenumber }})</strong></u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-sm" scope="col">Account No</th>
              <th class="th-sm" scope="col">Account Name</th>
              <th class="th-sm" scope="col">Cost Centre No</th>
              <th class="th-sm" scope="col">Cost Centre Name</th>
              <th class="th-lg" scope="col">Outbound</th>
              <th class="th-sm" scope="col">Label</th>
              <th scope="col">Phone Type</th>
              <th scope="col">Modified on</th>
              <th scope="col">User</th>
              <th scope="col">Action</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(outboundhistory, index) in SortedapiCLIs" :key="index">
              <td>{{ outboundhistory.outbound.account.AccountNumber }}</td>
              <td>{{ outboundhistory.outbound.account.Name }}</td>
              <td v-if="outboundhistory.outbound.costcentre">{{ outboundhistory.outbound.costcentre.CostCentreNumber }}</td>
              <td v-else></td>
              <td v-if="outboundhistory.outbound.costcentre">{{ outboundhistory.outbound.costcentre.Name }}</td>
              <td v-else></td>
              <td>{{ outboundhistory.outbound.PhoneNumber }}</td>
              <td>{{ outboundhistory.outbound.Label }}</td>
              <td>{{ outboundhistory.outbound.PhoneType }}</td>
              <td>{{ outboundhistory.modifiedon }}</td>
              <td>{{ outboundhistory.modifiedby }}</td>
              <td>{{ outboundhistory.method }}</td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" label="view" v-b-modal.outbound-update-modal
                    @click="editCLI(outboundhistory)">
                    View
                    <!-- {{editButtonText}} -->
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredCLIs.length" :per-page="pageSize"
              aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
    <!-- EDIT modal -->
    <b-modal ref="editCLIModal" id="outbound-update-modal" title="Outbound billing reference history" hide-footer size="m">
      <b-form @reset="onResetUpdate" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-account-group" label="Account Number" label-for="form-account-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-accountr-input" type="text" size="sm" v-model="editCLIForm.account.AccountNumber"
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-account-group" label="Account Name" label-for="form-account-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-accountr-input" type="text" size="sm" v-model="editCLIForm.account.Name" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Cost Centre" label-for="form-PhoneNumber-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="sm" v-model="costcentre" readonly>
              </b-form-input>
            </b-form-group>
            <!-- <b-form-group id="form-CostCentre-group" label="Cost Centre" label-for="form-CostCentre-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select disabled='true' style="width: 100%" v-model="editCLIForm.CostCentreId"
                  @change="onChangeCostCentre($event)">
                  <option value="">Select cost centre</option>
                  <option v-for="(costcentre, index) in CostCentres" :value="costcentre.costcentre_id" :key="index">
                    {{ costcentre.CostCentreNumber + ' - ' + costcentre.Name }}
                  </option>
                </select>
              </div>
            </b-form-group> -->
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Outbound" label-for="form-PhoneNumber-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="sm" v-model="editCLIForm.PhoneNumber"
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Label-group" label="Label" label-for="form-Label-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Label-input" type="text" size="sm" v-model="editCLIForm.Label" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>


        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneType-group" label="Phone Type" readonly label-for="form-PhoneType-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown" readonly>
                <select disabled='true' v-model="editCLIForm.PhoneType">
                  <option value="">Select phone type</option>
                  <option v-for="(type, index) in phonetypes" :value="type.id" :key="index">
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row v-show="ShowBundle">
          <b-col>
            <b-form-group id="form-Bundle-group" label="Bundle" label-for="form-Bundle-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <div align="left">
                <MobileBundleDropdownVue readonly v-bind:selected_mobilebundle="selected_mobilebundle"
                  @mobilebundle-changed="mobilebundleChange"></MobileBundleDropdownVue>
              </div>
              <!-- <AccountDropdownVue v-bind:selected_account="selected_account" @account-changed="accountChange"></AccountDropdownVue></div> -->
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Note-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-textarea id="textarea" readonly v-model="editCLIForm.Notes" rows="3"
                max-rows="6"></b-form-textarea>

            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Modified on" label-for="form-Note-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Label-input" type="text" size="sm" v-model="editCLIForm.modifiedon" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="User" label-for="form-Note-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Label-input" type="text" size="sm" v-model="editCLIForm.modifiedby" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Action" label-for="form-Note-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Label-input" type="text" size="sm" v-model="editCLIForm.action" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="reset" variant="danger">Close</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";
import MobileBundleDropdownVue from "../components/MobileBundleDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
      SelectedPhoneType: '',
      SelectedCostCentre: null,
      apiCLIs: [],
      apiHistoryCLI:null,
      executed: false,
      message: 'initial alert',
      currentSort:'modifiedon',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:true,
      currentPage:1,
      filter:'',
      ShowAccount:true,
      selected_account: null,
      ShowBundle:false,
      selected_mobilebundle: null,
      CostCentres: null,
      deleted: false,
      outboundphonenumber: null,
      costcentre: null,
      editCLIForm: {
          AccountId: null,
          OutboundId: null,
          PhoneNumber: null ,
          Label:null,
          CostCentreId: null,
          PhoneType: null,
          Notes: null,
          account:{},
         
          modifiedby :null,
          modifiedon : null,
        action: null
        }
    };
  },

  computed:{
  filteredCLIs() {
    return this.apiCLIs
    },
  SortedapiCLIs:function() {
    //  console.log('sort outbound');
    if (this.apiCLIs != null){
      return this.filteredCLIs.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(this.currentSort === 'account.Name'){
          var prop = this.currentSort.split('.');
          var len = prop.length;
            var i = 0;
            while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
            if (a < b) {
                return -1 * modifier;
            } else if (a > b) {
                return 1 * modifier;
            } else {
                return 0;
            }
        }
        else{
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
          return 0;
        }
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    }
        else{ return null}
  },
  },
  methods: {
     mobilebundleChange: function (e) {
          this.selectedMobileBundle = e;
      },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredCLIs.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },
    // *******************************************
      // List costcentres
      // *******************************************
      async callCostCentreApi(outboundaccount) {

         console.log(outboundaccount)
        //  console.log('getting the costcentre list')
        //  console.log(this.$route.query.account_id)
        //  console.log('getting the costcentre list')
        var url;
        if (typeof(outboundaccount) != "undefined"){
            //  console.log('selected account in CostCentre is:');
            //  console.log(this.$route.query.account_id)
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres?q={"filters":[{"name":"AccountId","op":"eq","val":"' + outboundaccount+ '"}]}';
            console.log(url)
        }
        else if (typeof(this.$route.query.account_id) != "undefined"){
            //  console.log('selected account in CostCentre is:');
            //  console.log(this.$route.query.account_id)
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.$route.query.account_id + '"}]}';
            console.log(url)
        }
        else{
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.selectedAccount+ '"}]}';
          console.log(url)
        }
        try {
          const { data } = await this.$http.get(url);

          this.CostCentres = data;
          console.log(this.CostCentres)
          if (this.apiCostCentres.length < this.pageSize){
            this.showPaging = false
          }
          //  console.log(this.CostCentres)

          this.executed = true;
        } catch (e) {
          this.apiCostCentres = `Error: the server responded with '${e.response}'`;
        }
      },

    // *******************************************
    // get CLI details
    // *******************************************
    async getCLIApi(outboundid) {

      console.log(outboundid)
      console.log('getting the account data')
      try {
        document.body.style.cursor = "wait";
        var url;
        url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/outbounds/' + outboundid
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiHistoryCLI = data;
        this.outboundphonenumber = this.apiHistoryCLI.PhoneNumber
        console.log('got the outbound data from api')
        console.log("GET CLI DETAILS")
        console.log(data)
        this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // List outbound history
    // *******************************************
    async callApi(lablex) {

       console.log(lablex)
      //  console.log('getting the outbound list')
      //  console.log(this.$route.query.account_id)
      //  console.log('getting the outbound list')
      var url;
      if (typeof(this.$route.query.outbound_id) != "undefined"){
        url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/outbound/' + this.$route.query.outbound_id
        console.log("CLI HISTORY")
        console.log(url)
        this.ShowAccount = false
        this.selected_account = this.$route.query.account_id
        // if deleted 
        if (typeof(this.$route.query.outbound_phonenumber) != "undefined"){
            this.outboundphonenumber = this.$route.query.outbound_phonenumber;
            this.deleted = true;
          }
          else{
            this.getCLIApi(this.$route.query.outbound_id)
          }
       
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/outbounds`
      }
      try {
        document.body.style.cursor = 'wait';
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiCLIs = data;
        console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")
        console.log(data)
        if (this.apiCLIs.length < this.pageSize){
          this.showPaging = false
        }
        //  console.log(this.apiCLIs )
        //  console.log(this.apiCLIs.length)
        this.executed = true;
      } catch (e) {
        this.apiCLIs = `Error: the server responded with '${e.response}'`;
      }
    },
    editCLI(history) {
        //  console.log('editCLI');
        //  console.log(outbound);
        //  console.log(outbound.outbound_id);
        this.callCostCentreApi(history.outbound.AccountId);

        // console.log(outbound)
      this.editCLIForm = history.outbound;
      this.costcentre = null;
      if (history.outbound.costcentre) {
        if (history.outbound.costcentre.CostCentreNumber) {
          this.costcentre = history.outbound.costcentre.CostCentreNumber + ' - ' + history.outbound.costcentre.Name;
        }
        else {
          this.costcentre = history.outbound.costcentre.Name;
        }
      }
        
        this.editCLIForm.modifiedby = history.modifiedby
        this.editCLIForm.modifiedon = history.modifiedon
        this.editCLIForm.action = history.method
        console.log(" EDIT outbound xxxxxxxxxxxx")
        console.log(history)
        console.log(this.editCLIForm.history)
        this.SelectedPhoneType = history.outbound.PhoneType
        this.selected_mobilebundle = history.outbound.MobileBundleId
        // show bundle for mobile outbounds
        if (history.outbound.PhoneType == "M"){
          this.ShowBundle = true;
        }
        else{
          this.ShowBundle = false;
        }
        // this.callApi()
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editCLIModal.hide();
        // this.initForm();
        // this.getCLIs(); // why?
      },

  },

  created() {
    this.callApi();


  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
    MobileBundleDropdownVue: MobileBundleDropdownVue,
  },
};
</script>

