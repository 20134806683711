<template>
  <div class="cascading-dropdown">
    <div class="dropdown">
      <select style="width: 100%" @change="onChangeProduct($event)" v-model="selected_product">
        <option value="">Select a product</option>
        <option v-for="(product, index) in listProduct" :value="product.ProductId" :key="index">
          {{ product.ProductNumber + ' - ' + product.Name }}
        </option>
      </select>
    </div>
    <!-- <p v-if="SelectedProduct">Selected Product - {{ this.SelectedProduct }}</p> -->
  </div>
</template>

  <script>
  export default {
    name: "App",
    props:{
      group_exclude:{
        type: Boolean,
        default: false},
        selected_product:{
        type: String,
        default: ""},
      },
    data() {
      return {
        listProduct: [],
        SelectedProduct: "",
        authToken: "",
      };
    },
    methods: {
      onChangeProduct(event) {
        this.SelectedProduct = event.target.value
        console.log(event)
        this.$emit('product-changed',this.SelectedProduct)
      },

      async callApi() {
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/products?only=Name,ProductNumber,ProductId&q={"filters":[{"name":"ChargeType","op":"eq","val":"Recurring"}]}`);

        //   console.log('API dropdown XXXXXXXXXXXXXXXXXXXXXXXXXXXX')
        //   console.log(data)
          this.listProduct = data;
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.listProduct = `Error: the server responded with '${e.response}'`;
        }
      },
    },
    created() {
      this.callApi();
      console.log('the selected product in dorpdown is:');
      console.log(this.selected_product);
    }
  };
  </script>
