<template>

    <div>
      <p><u><strong>Single Accounts</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
            <button  type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button>
          <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-sm" scope="col" @click="sort('GroupNumber')">Group Number</th>
                <th class="th-sm" scope="col" @click="sort('GroupName')">Group Name </th>
                <th class="th-sm" scope="col" @click="sort('AccountNumber')">Account Number</th>
                <th class="th-sm" scope="col" @click="sort('AccountName')">Account Name </th>
                <th class="th-sm" scope="col" @click="sort('Address1')">Address1 </th>
                <th class="th-sm" scope="col" @click="sort('Address2')">Address2 </th>
                <th class="th-sm" scope="col" @click="sort('City')">City </th>
                <th class="th-sm" scope="col" @click="sort('County')">County</th>
                <th class="th-sm" scope="col" @click="sort('Country')">Country</th>
                <th class="th-sm" scope="col" @click="sort('PostCode')">Post Code</th>
                <th class="th-sm" scope="col" @click="sort('PONumber')">PO Number</th>
                <th class="th-sm" scope="col" @click="sort('PaymentTerms')">Payment Terms</th>
                <th class="th-sm" scope="col" @click="sort('SageAccount')">Sage Account </th>
                <th class="th-sm" scope="col" @click="sort('NetXAccountID')">NetXAccountID </th>
                <th class="th-sm" scope="col" @click="sort('Industry')">Industry </th>
                <th class="th-sm" scope="col" @click="sort('AccountManager')">Account Manager </th>
                <th class="th-sm" scope="col" @click="sort('Contact')">Contact </th>
                <th class="th-sm" scope="col" @click="sort('ReportEmail')">Report Email </th>
                <th class="th-sm" scope="col" @click="sort('InvoiceEmail')">Invoice Email </th>
                <!-- <th class="th-sm" scope="col" @click="sort('Notes')">Notes </th> -->
                <th class="th-sm" scope="col" @click="sort('NationalMin')">National Min </th>
                <th class="th-sm" scope="col" @click="sort('NGNMin')">NGN Min </th>
                <th class="th-sm" scope="col" @click="sort('InternationalMin')">International Min </th>
                <th class="th-sm" scope="col" @click="sort('MaintenanceOnly')">Maintenance Only </th>
                <th class="th-sm" scope="col" @click="sort('DirectDebit')">Direct Debit </th>
                <th class="th-sm" scope="col" @click="sort('Taxable')">Taxable </th>
                <th class="th-sm" scope="col" @click="sort('ExportCalls')">Export Calls </th>
                <th class="th-sm" scope="col" @click="sort('RoundUp')">Round Up </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cli, index) in SortedapiCLIs" :key="index">
                <td>{{ cli.GroupNumber }}</td>
                <td>{{ cli.GroupName }}</td> 
                <td>{{ cli.AccountNumber }}</td>
                <td>{{ cli.AccountName }}</td>    
                <td>{{ cli.Address1 }}</td>
                <td>{{ cli.Address2 }}</td>
                <td>{{ cli.City }}</td>
                <td>{{ cli.County }}</td>
                <td>{{ cli.Country }}</td>
                <td>{{ cli.PostCode }}</td>
                <td>{{ cli.PONumber }}</td>
                <td>{{ cli.PaymentTerms }}</td>
                <td>{{ cli.SageAccount }}</td>
                <td>{{ cli.NetXAccountID }}</td>
                <td>{{ cli.Industry }}</td>
                <td>{{ cli.AccountManager }}</td>
                <td>{{ cli.Contact }}</td>
                <td>{{ cli.ReportEmail }}</td>
                <td>{{ cli.InvoiceEmail }}</td>
                <!-- <td>{{ cli.Notes }}</td> -->
                <td>{{ cli.NationalMin }}</td>
                <td>{{ cli.NGNMin }}</td>
                <td>{{ cli.InternationalMin }}</td>
                <td>{{ cli.MaintenanceOnly }}</td>
                <td>{{ cli.DirectDebit }}</td>
                <td>{{ cli.Taxable }}</td>
                <td>{{ cli.ExportCalls }}</td>
                <td>{{ cli.RoundUp }}</td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredCLIs.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'


import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        apiCLIs: null,
        executed: false,
        message: 'initial alert',
        currentSort:'AccountNumber',
        currentSortDir:'asc',
        pageSize:30,
        showPaging:true,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selected_account: null,
        ShowBundle:false,
        selected_mobilebundle: null,
        CostCentres: null
      };
    },

    computed:{
    filteredCLIs() {
      //  console.log('filtered cli');
        return this.apiCLIs.filter(c => {
          if(this.filter == '') return true;
          return (c.SageAccount && c.SageAccount.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) ||
                 c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 (c.Contact && c.Contact.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) ||
                 (c.ReportEmail && c.ReportEmail.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
        })
      },

    SortedapiCLIs:function() {
      //  console.log('sort cli');
      if (this.apiCLIs != null){
        return this.filteredCLIs.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(this.currentSort === 'account.Name'){
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
          else{
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
          else{ return null}
    },
    },
    methods: {
      download : function() {
        // customised headers
        const Heading = [
                            ['GroupNumber','GroupName','AccountNumber','AccountName','Address1','Address2','City','County','Country','PostCode','PONumber','PaymentTerms','SageAccount','NetXAccountID','Industry','AccountManager','Contact','ReportEmail','InvoiceEmail','NationalMin','NGNMin','InternationalMin','MaintenanceOnly','DirectDebit','Taxable','ExportCalls','RoundUp' ]
                        ];

        // original object headers
        const objHeader = ['GroupNumber','GroupName','AccountNumber','AccountName','Address1','Address2','City','County','Country','PostCode','PONumber','PaymentTerms','SageAccount','NetXAccountID','Industry','AccountManager','Contact','ReportEmail','InvoiceEmail','NationalMin','NGNMin','InternationalMin','MaintenanceOnly','DirectDebit','Taxable','ExportCalls','RoundUp'];


        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.filteredCLIs, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename = 'SingleAccounts -' + datestr + '.xlsx';
        console.log(filename);
        XLSX.writeFile(wb,filename)


        },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredCLIs.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List outbounds
      // *******************************************
      async callReportsApi(lablex) {

         console.log(lablex)
        var url;

        url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/singleaccounts`

        document.body.style.cursor = 'wait';
        try {
          const { data } = await this.$http.get(url);

          this.apiCLIs = data;
          if (this.apiCLIs.length < this.pageSize){
            this.showPaging = false
          }
          document.body.style.cursor = 'auto';
          this.executed = true;
        } catch (e) {
          this.apiCLIs = `Error: the server responded with '${e.response}'`;
        }
      },


    },

    created() {
      //  console.log('created')
      this.callReportsApi();


    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
