<template>

  <div>
    <p><u><strong>Product Catalogue</strong> </u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.product-modal>Insert
          Product</button>&nbsp;&nbsp;&nbsp;
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.product-load-modal>Load
          products</button>&nbsp;&nbsp;&nbsp;
        <a :href="'deletedproducts'" target="_blank">
          <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm">Deleted</button>
        </a>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th scope="col" @click="sort('ProductNumber')">Product Number</th>
              <th class="th-lg" scope="col" @click="sort('Name')">Product Name</th>
              <th class="th-sm" scope="col" @click="sort('ChargeType')">Product Type</th>
              <th scope="col">Category</th>
              <th scope="col">Carrier</th>
              <th scope="col" @click="sort('PartNumber')">Part Number</th>
              <th scope="col" @click="sort('CostUnitPrice')">Unit Cost</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in SortedapiProductCatalogues" :key="index">
              <td>{{ product.ProductNumber }}</td>
              <td>{{ product.Name }}</td>
              <td>{{ product.ChargeType }}</td>
              <!-- <td>{{ product.category.Name }}</td> -->
              <td v-if="product.category">{{ product.category.Name }}</td>
              <td v-else></td>
              <td v-if="product.carrier">{{ product.carrier.Name }}</td>
              <td v-else></td>

              <!-- <td>{{ product.carrier.Name }}</td> -->
              <td>{{ product.PartNumber }}</td>
              <td>{{ product.CostUnitPrice }}</td>
              <td align="center"><a :href="'/producthistory?product_id=' + product.ProductId"
                  target="_blank">History</a></td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" v-b-modal.product-update-modal
                    @click="editProductCatalogue(product)">
                    {{editButtonText}}
                  </button>
                  <p> </p>
                  <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm"
                    @click="onDeleteProductCatalogue(product)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredProductCatalogues.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
    <!-- LOAD modal -->
    <b-modal ref="loadProductModal" id="product-load-modal" title="Load products" hide-footer size="xs">
      <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
        <b-form-row>
          <b-col>
            <p>Select a comma seperated file (.txt).</p>
            <p><strong>Delete will fail if recurring charges are using entry</strong></p>
     
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadProductForm.FileName" required
              placeholder="Select product file">
            </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- add modal -->
    <b-modal ref="addProductCatalogueModal" id="product-modal" title="Insert a new product" hide-footer size="m">
      <b-form @submit="onSubmit" @reset="onReset" class="w-200">
        <b-form-row>
          <b-col>
            <b-form-group id="form-name-group" label="Product Name" label-for="form-name-input" label-cols="4"
              label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input" type="text" size="sm" v-model="addProductCatalogueForm.Name" required
                placeholder="Enter Name" w-20>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ChargeType-group" label="Product Type" label-for="form-ChargeType-input"
              label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select @change="onChangeChargeType($event)">
                  <option value="">Select charge type</option>
                  <option v-for="(type, index) in chargetypes" :value="type.id" :key="index">
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedChargeType">Selected type - {{ this.SelectedChargeType }}</p> -->
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Description-group" label="Category" label-for="form-Description-input" label-cols="6"
              label-cols-sm="3" label-size="sm">
              <div>
                <CategoryDropDown @category-changed="categoryChange"></CategoryDropDown>
              </div>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Carrier-group" label="Carrier" label-for="form-InclusiveText-input" label-cols="4"
              label-cols-lg="3" label-size="sm" size="xs">
              <div>
                <CarrierDropDownVue @carrier-changed="carrierChange"></CarrierDropDownVue>
                <!-- <br> -->
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PartNumber-group" label="Part Number" label-for="form-PartNumber-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PartNumber-input" type="text" size="sm"
                v-model="addProductCatalogueForm.PartNumber" placeholder="Enter Part number">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-costprice-group" label="Unit Cost" label-for="form-costprice-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-costprice-input" type="text" size="sm"
                v-model="addProductCatalogueForm.CostUnitPrice" placeholder="Enter unit cost">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Description-group" label="Description" label-for="form-Description-input"
              label-cols="6" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-Description-input" type="text" size="sm"
                v-model="addProductCatalogueForm.Description" placeholder="Enter Description">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal ref="editProductCatalogueModal" id="product-update-modal" title="Update product" hide-footer size="m">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
        <b-form-row>
          <b-col>
            <b-form-group id="form-productnumbergroup" label="Product Number" label-for="form-name-input" label-cols="4"
              label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input" type="text" size="sm" v-model="editProductCatalogueForm.ProductNumber"
                required readonly w-20>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-name-group" label="Product Name" label-for="form-name-input" label-cols="4"
              label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input" type="text" size="sm" v-model="editProductCatalogueForm.Name" required
                placeholder="Enter Name" w-20>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group id="form-ChargeType-group" label="Product Type" label-for="form-ChargeType-input"
              label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select v-model="editProductCatalogueForm.ChargeType" @change="onChangeChargeType($event)">
                  <option value="">Select charge type</option>
                  <option v-for="(type, index) in chargetypes" :value="type.id" :key="index">
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedPhoneType">Selected type - {{ this.SelectedPhoneType }}</p> -->
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Description-group" label="Category" label-for="form-Description-input" label-cols="6"
              label-cols-sm="3" label-size="sm">
              <div>
                <CategoryDropDown v-bind:selected_category="editProductCatalogueForm.CategoryId"
                  @category-changed="categoryChange"></CategoryDropDown>
              </div>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Carrier-group" label="Carrier" label-for="form-InclusiveText-input" label-cols="4"
              label-cols-lg="3" label-size="sm" size="xs">
              <div>
                <CarrierDropDownVue v-bind:selected_carrier="editProductCatalogueForm.CarrierId"
                  @carrier-changed="carrierChange"></CarrierDropDownVue>
                <!-- <br> -->
              </div>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PartNumber-group" label="Part Number" label-for="form-PartNumber-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PartNumber-input" type="text" size="sm"
                v-model="editProductCatalogueForm.PartNumber" placeholder="Enter Part number">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-costprice-group" label="Unit Cost" label-for="form-costprice-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-costprice-input" type="text" size="sm"
                v-model="editProductCatalogueForm.CostUnitPrice" placeholder="Enter unit cost">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Description-group" label="Description" label-for="form-Description-input"
              label-cols="6" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-Description-input" type="text" size="sm"
                v-model="editProductCatalogueForm.Description" placeholder="Enter Description">
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>


        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row" v-if="isAdmin">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import CarrierDropDownVue from "../components/CarrierDropDown.vue";
  import CategoryDropDown from '../components/CategoryDropDown.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        chargetypes: [{ "id":"Inbound Calls", "value":'Inbound Calls   '}, {"id":"Mobile Calls","value":'Mobile Calls'},  {"id":"Mobile Calls Non EU","value":'Mobile Calls Non EU'},  {"id":"Mobile Recurring","value":'Mobile Recurring'},  {"id":"Outbound Calls","value":'Outbound Calls'}, {"id":"Recurring","value":'Recurring'}],
        SelectedChargeType: '',
        loadProductForm: {
        FileName: ''
        },
        addProductCatalogueForm: {
          Name: '' ,
          ChargeType: '' ,
          PartNumber: null ,
          Description: null ,
          CarrierId: null ,
          CategoryId: null ,
          CostUnitPrice: null
        },
        editProductCatalogueForm: {
          ProductCatalogueId: null,
          Name: null ,
          ChargeType: null ,
          ProductNumber: null ,
          PartNumber: null ,
          Description: null ,
          CarrierId: null ,
          CategoryId: null ,
          CostUnitPrice: null
        },
        apiProductCatalogues: null,
        executed: false,
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        selected_product: null
      };
    },

    computed:{
    filteredProductCatalogues() {
      console.log('filtered product');
        return this.apiProductCatalogues.filter(c => {
          if (this.filter == '') return true;
          if (c.category == null) c.category = '';
          if (c.carrier == null) c.carrier = '';
          if (c.Name == null) c.Name = '';
          if (c.ProductNumber == null) c.ProductNumber = '';
          return (c.Name && c.Name.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0 ||
            c.ChargeType.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
             c.PartNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
            c.ProductNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
            (c.category.Name && c.category.Name.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0 ||
            (c.carrier.Name && c.carrier.Name.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0 
        })
      },
    SortedapiProductCatalogues:function() {
      console.log('sort product');
      return this.filteredProductCatalogues.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        
        if (this.currentSort === 'CostUnitPrice') {
          if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          }
        else{
          if (a[this.currentSort] === null) return 1;
          if (b[this.currentSort] === null) return -1;
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
          return 0;
        }
       
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      download: function() {
        console.log("in download")
        var data = "ProductNumber,ProductName,ProductType,Category,Carrier,PartNumber,UnitCost,Description,Action"

        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "UploadProducts.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
       productChange: function (e) {
        this.selectedCarrier = e;
        if(e == ''){
            this.selectedCarrier = null;
            this.selectedCategory = null;
        }
       },
       carrierChange: function (e) {
        this.selectedCarrier = e;
      },
      categoryChange: function (e) {
        this.selectedCategory = e;
      },
       onChangeChargeType(event) {
        this.SelectedChargeType= event.target.value
        },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredProductCatalogues.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List products
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        console.log('getting the data')
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/products`);

          this.apiProductCatalogues = data;
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apiProductCatalogues = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // Delete product
      // *******************************************
      async removeProductCatalogue(productid) {

        console.log("in removeProductCatalogue before")
        console.log(productid)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/products/${productid}`).then(() => {
            this.message = 'product removed!';
            this.showMessage = true;
            console.log(data)
            console.log("in removeProductCatalogue after")
            this.callApi("coming from delete");

          }).catch((err) => {
            console.log("in removeCostCentre error")
            console.error(err);
            if (err.response) {
              console.log(err.response)
              console.log(err.response.data.message)
              alert("Problem deleting product. " + err.response.data.message)
            }

          });

        } catch (e) {
          this.apiProductCatalogues = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteProductCatalogue(product) {
        console.log(product.ProductId)
        if(confirm("Are you sure you want to delete this product?")){
          this.removeProductCatalogue(product.ProductId);
        }
      },

      // Add product method to post to API
      async addProductCatalogue(payload) {

      //   const id_token = claims.__raw;
        console.log('in add product');
        console.log('payload is');
        console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/products`, payload).then(() => {
            this.message = 'product added!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated product. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add product. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiProductCatalogues = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addProductCatalogueForm.Name =  null;
          this.addProductCatalogueForm.PartNumber = null;
          this.addProductCatalogueForm.Description = null;

      },
      // *******************************************
      // Load on submit
      // *******************************************
      onLoadSubmit(evt) {
        console.log('on load submit');
        evt.preventDefault();
        this.$refs.loadProductModal.hide();
        const payload = {
          FileName: this.loadProductForm.FileName
        };
        this.loadproducts(payload);
        //this.initForm();
      },
      onLoadReset(evt) {
      evt.preventDefault();
      this.$refs.loadChargeBandModal.hide();
      this.initForm();
    },
    // Add cdrload method to post to API
    async loadproducts(payload) {

      let formData = new FormData();
      console.log( 'in load products');
      console.log( payload.file);
      formData.append('file', payload.FileName);
      var url;
      
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/products/load`
      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'products loaded!';
          this.showMessage = true;
          document.body.style.cursor = 'auto';
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          document.body.style.cursor = 'auto';
          this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
      // *******************************************
      // Add product on submit
      // *******************************************
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addProductCatalogueModal.hide();
        console.log('MaintenaceOnly');
        const payload = {
          Name: this.addProductCatalogueForm.Name,
          ChargeType: this.SelectedChargeType,
          PartNumber: this.addProductCatalogueForm.PartNumber,
          Description: this.addProductCatalogueForm.Description,
          CarrierId: this.selectedCarrier,
          CategoryId: this.selectedCategory,
          CostUnitPrice: this.addProductCatalogueForm.CostUnitPrice
        };
        this.addProductCatalogue(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addProductCatalogueModal.hide();
        this.initForm();
      },
    //
      editProductCatalogue(product) {
        console.log('editProductCatalogue');
        console.log(product);
        console.log(product.product_id);
        this.editProductCatalogueForm = product;
        this.SelectedChargeType = product.ChargeType
        this.callApi()
      },

      // *******************************************
      // Update product on submit
      // *******************************************
      onSubmitUpdate(evt) {
        console.log('in onSubmitUpdate');
        console.log('product_id');
        evt.preventDefault();
        this.$refs.editProductCatalogueModal.hide();
        const payload = {
          Name: this.editProductCatalogueForm.Name,
          ChargeType: this.SelectedChargeType,
          PartNumber: this.editProductCatalogueForm.PartNumber,
          Description: this.editProductCatalogueForm.Description,
          CarrierId: this.selectedCarrier,
          CategoryId: this.selectedCategory,
          CostUnitPrice: this.editProductCatalogueForm.CostUnitPrice
        };
        this.updateProductCatalogue(payload, this.editProductCatalogueForm.ProductId);
        this.callApi()
      },

      async updateProductCatalogue(payload, productid) {

        console.log('in update product');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/products/${productid}`, payload).then(() => {
            this.message = 'product updated!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated product. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add product. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi();

          });

          // this.apiProductCatalogues = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiProductCatalogues = `Error: the server responded with '${e.response}'`;
        }
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editProductCatalogueModal.hide();
        this.initForm();
        this.getProductCatalogues(); // why?
      },
    },
    created() {
      console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      CarrierDropDownVue: CarrierDropDownVue,
      CategoryDropDown: CategoryDropDown,
    },
  };
  </script>

