<template>

  <div>
    <p ><u><strong>DWS Mobile Bundle List</strong> </u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.mobilebundle-modal>Insert Mobile Bundle</button>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('BundleName')" >Name</th>
              <th class="th-lg" scope="col" @click="sort('carrier')" >Carrier</th>
              <th class="th-lg" scope="col" @click="sort('AccountCount')" >Voice</th>
              <th class="th-lg" scope="col" @click="sort('AccountCount')" >Data</th>
              <th class="th-lg" scope="col" @click="sort('AccountCount')" >SMS</th>
              <th scope="col" @click="sort('CreatedOn')">Date created</th>
              <!-- <th scope="col" @click="sort('CreatedBy')">Created by</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(mobilebundle, index) in SortedapiMobileBundles" :key="index">
              <td>{{ mobilebundle.BundleName }}</td>
              <td>{{ mobilebundle.carrier.Name }}</td>
              <td>{{ mobilebundle.InclusiveMinutes }}</td>
              <td>{{ mobilebundle.InclusiveData }}</td>
              <td>{{ mobilebundle.InclusiveText }}</td>
              <td>{{ mobilebundle.ModifiedOn }}</td>
              <!-- <td>{{ mobilebundle.ModifiedBy }}</td> -->
              <td>
                <div class="btn-group"  role="group">
                  <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-b-modal.mobilebundle-update-modal
                          @click="editMobileBundle(mobilebundle)">
                          {{editButtonText}}
                  </button>
                  <p>  </p>
                  <button v-if="isAdmin" v-on:click="onDeleteMobileBundle(mobilebundle)"  type="button"
                          class="btn btn-danger btn-sm">
                          <!-- @click="onDeleteMobileBundle(mobilebundle)"> -->
                      Delete
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div><p align="center">
          <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button>
          </p></div>
      </div>
    </div>



      <!-- add modal -->
    <b-modal ref="addMobileBundleModal"
            id="mobilebundle-modal"
            title="Insert a mobile bundle"
            hide-footer size="m" >
      <b-form @submit="onSubmit" @reset="onReset" class="w-600">
      <b-form-row>
         <b-col>
           <b-form-group id="form-Bundlename-group"
                      label="Bundle name"
                      label-for="form-Bundlename-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-Bundlename-input"
                      type="text"   size="sm"
                      v-model="addMobileBundleForm.BundleName"
                      placeholder="Enter bundle name">
            </b-form-input>
            </b-form-group>
        </b-col>

      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-InclusiveMinutes-group"
                      label="inclusive minutes"
                      label-for="form-InclusiveMinutes-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-InclusiveMinutes-input"
                      type="text"   size="sm"
                      v-model="addMobileBundleForm.InclusiveMinutes"
                      placeholder="Enter inclusive minutes">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-InclusiveData-group"
                      label="inclusive data"
                      label-for="form-InclusiveData-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-InclusiveData-input"
                      type="text"   size="sm"
                      v-model="addMobileBundleForm.InclusiveData"
                      placeholder="Enter inclusive data">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-InclusiveText-group"
                      label="inclusive text"
                      label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-InclusiveText-input"
                      type="text"   size="sm"
                      v-model="addMobileBundleForm.InclusiveText"
                      placeholder="Enter inclusive text">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-BundleCost-group"
                      label="Bundle cost"
                      label-for="form-BundleCost-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-BundleCost-input"
                      type="text"   size="sm"
                      v-model="addMobileBundleForm.BundleCost"
                      placeholder="Enter bundle cost">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
          <b-col>
           <b-form-group id="form-Carrier-group"
            label="inclusive text"
                      label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <div >
                <CarrierDropDownVue @carrier-changed="carrierChange"></CarrierDropDownVue>
            <br>
        </div>
        </b-form-group>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal :key="editcomponentKey" ref="editMobileBundleModal"
            id="mobilebundle-update-modal"
            title="Update mobile bundle"
            hide-footer size="m" >
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
      <b-form-row>
         <b-col>
           <b-form-group id="form-Bundlename-group"
                      label="Bundle name"
                      label-for="form-Bundlename-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-Bundlename-input"
                      type="text"   size="sm"
                      v-model="editMobileBundleForm.BundleName"
                      placeholder="Enter bundle name">
            </b-form-input>
            </b-form-group>
        </b-col>

      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-InclusiveMinutes-group"
                      label="inclusive minutes"
                      label-for="form-InclusiveMinutes-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-InclusiveMinutes-input"
                      type="text"   size="sm"
                      v-model="editMobileBundleForm.InclusiveMinutes"
                      placeholder="Enter inclusive minutes">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-InclusiveData-group"
                      label="inclusive data"
                      label-for="form-InclusiveData-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-InclusiveData-input"
                      type="text"   size="sm"
                      v-model="editMobileBundleForm.InclusiveData"
                      placeholder="Enter inclusive data">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-InclusiveText-group"
                      label="inclusive text"
                      label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-InclusiveText-input"
                      type="text"   size="sm"
                      v-model="editMobileBundleForm.InclusiveText"
                      placeholder="Enter inclusive text">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-BundleCost-group"
                      label="Bundle cost"
                      label-for="form-BundleCost-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-BundleCost-input"
                      type="text"   size="sm"
                      v-model="editMobileBundleForm.BundleCost"
                      placeholder="Enter bundle cost">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
          <b-col>
           <b-form-group id="form-Carrier-group"
            label="inclusive text"
                      label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <div >
                <CarrierDropDownVue v-bind:selected_carrier="editMobileBundleForm.CarrierId" @carrier-changed="carrierChange"></CarrierDropDownVue>
            <br>
        </div>
        </b-form-group>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row" v-if="isAdmin">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import CarrierDropDownVue from "../components/CarrierDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      editcomponentKey:0,
      selectedAccount:"",
      addMobileBundleForm: {
        BundleName: '' ,
        InclusiveMinutes: '' ,
        InclusiveData: '' ,
        InclusiveText: '' ,
        BundleCost: ''
      },
      editMobileBundleForm: {
        BundleName: '' ,
        InclusiveMinutes: '' ,
        InclusiveData: '' ,
        InclusiveText: '' ,
        BundleCost: '',
        CarrierId: ''
      },
      apiMobileBundles: null,
      executed: false,
      message: 'initial alert',
      currentSort:'BundleName',
      currentSortDir:'asc',
      pageSize:60,
      currentPage:1,
      filter:'',
      selected_carrier: null
    };
  },

  computed:{

  filteredMobileBundles() {
    // console.log('filtered gammamarkup');
      return this.apiMobileBundles.filter(c => {
        if(this.filter == '') return true;
        if (c.BundleName == null) c.BundleName = '';
        if (c.CarrierId == null) c.CarrierId = '';
        // if (c.Carrier == null) c.Carrier = '';
        // if (c.Description == null) c.Description = '';
        return c.BundleName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0  ||
               c.CarrierId.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        //        c.Description.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
        //        c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiMobileBundles:function() {
    console.log('sort mobile bundle');
    if (this.apiMobileBundles != null){
    return this.filteredMobileBundles.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
    }
    else{ return null}
  },
  },
  methods: {
    carrierChange: function (e) {
        this.selectedCarrier = e;
    },
    forceRerender:function() {
      this.editcomponentKey += 1
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredMobileBundles.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    //////////////////////////////////////////////
    // LIST MOBILE BUNDLE METHODS
    //////////////////////////////////////////////
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the data')
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/mobilebundles`);
        console.log('got mobile bundle data')
        console.log(data)
        this.apiMobileBundles = data;
        this.executed = true;
      } catch (e) {
        this.apiMobileBundles = `Error: the server responded with '${e.response}'`;
      }
    },

    //////////////////////////////////////////////
    // DELETE MOBILE BUNDLE METHODS
    //////////////////////////////////////////////
    async removeMobileBundle(bundleid) {

      console.log("in removeAccount before")
      console.log(bundleid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/mobilebundles/${bundleid}`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          console.log("in removeAccount after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          this.callApi();

        });

        // this.apiMobileBundles = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiMobileBundles = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteMobileBundle(mobilebundle) {
      console.log(mobilebundle.accountgroup_id)
      if(confirm("Are you sure you want to delete the mobile bundle?")){
        this.removeMobileBundle(mobilebundle.BundleId);
      }
    },


    //////////////////////////////////////////////
    // ADD MOBILE BUNDLE METHODS
    //////////////////////////////////////////////

    // Add account method to post to API
    async addMobileBundle(payload) {

    //   const id_token = claims.__raw;
      console.log('in add account');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/mobilebundles`, payload).then(() => {
          this.message = 'account added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated mobilebundle. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add mobilebundle. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apiMobileBundles = data;
        // this.executed = true;
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiMobileBundles = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addMobileBundleForm.BundleName =  '';
        this.addMobileBundleForm.InclusiveMinutes =  '';
        this.addMobileBundleForm.InclusiveData =  '';
        this.addMobileBundleForm.InclusiveText =  '';
        this.addMobileBundleForm.BundleCost =  '';
    },
    // Add mobile bundle on submit
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addMobileBundleModal.hide();

      const payload = {
        BundleName: this.addMobileBundleForm.BundleName,
        InclusiveMinutes: this.addMobileBundleForm.InclusiveMinutes,
        InclusiveData: this.addMobileBundleForm.InclusiveData,
        InclusiveText: this.addMobileBundleForm.InclusiveText,
        BundleCost: this.addMobileBundleForm.BundleCost,
        CarrierId: this.selectedCarrier,
      };
      this.addMobileBundle(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addMobileBundleModal.hide();
      this.initForm();
    },


    //////////////////////////////////////////////
    // UPDATE MOBILE BUNDLE METHODS
    //////////////////////////////////////////////
    editMobileBundle(mobilebundle) {
      console.log('editMobileBundle');
      // console.log(mobilebundle);
      console.log(mobilebundle.CarrierId)
      this.editMobileBundleForm = mobilebundle;
      this.callApi()
    },

    // Update account on submit
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log(this.selectedCarrier);
      evt.preventDefault();
      this.$refs.editMobileBundleModal.hide();
      const payload = {
        BundleName: this.editMobileBundleForm.BundleName,
        InclusiveMinutes: this.editMobileBundleForm.InclusiveMinutes,
        InclusiveData: this.editMobileBundleForm.InclusiveData,
        InclusiveText: this.editMobileBundleForm.InclusiveText,
        BundleCost: this.editMobileBundleForm.BundleCost,
        CarrierId: this.selectedCarrier,
      };
      this.updateMobileBundle(payload, this.editMobileBundleForm.BundleId);
      this.callApi()
    },

    async updateMobileBundle(payload, bundleid) {

      // console.log('in update account');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/mobilebundles/${bundleid}`, payload).then(() => {
          // this.message = 'account updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated mobilebundle. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add mobilebundle. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiMobileBundles = data;
        // this.executed = true;
        // console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiMobileBundles = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editMobileBundleModal.hide();
      this.initForm();
      this.getAccounts(); // why?
    },
  },
  created() {
    // console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    CarrierDropDownVue: CarrierDropDownVue,
  },
};
</script>
<style>
.table .btn-group {
	white-space: nowrap;
}
.table .btn-group .btn {
	display: inline-block;
	float: none;
}
.table .btn-group .btn + .btn {
	margin-left: -5px;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: normal;
  text-align: center;
  font-size: 14px;
}
td {
  font-size: 12px;
}
.btn-custom {
    width: 50px !important;
    height: 17px;
    font-size: 9px;
    font-weight: normal;
    text-align: center;
    background-color: red;
    text-emphasis-color: white;
    color: white;
}

</style>
