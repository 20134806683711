<template>
  <div class="cascading-dropdown">
    <div class="dropdown">
      <select style="width: 100%" @change="onChangeCarrier($event)" v-model="selected_carrier">
        <option value="">Select carrier</option>
        <option
          v-for="(carrier, index) in listCarrier"
          :value="carrier.CarrierId"
          :key="index"
        >
          {{ carrier.Name }}
        </option>
      </select>
    </div>
    <!-- <p v-if="SelectedCarrier">Selected Carrier - {{ this.SelectedCarrier }}</p> -->
  </div>
</template>

<script>
export default {
  name: "App",
  props:{
    group_exclude:{
      type: Boolean,
      default: false},
    selected_carrier:{
      type: String,
      default: ""},
    },
  data() {
    return {
      listCarrier: [],
      SelectedCarrier: "",
      authToken: "",
    };
  },
  methods: {
    onChangeCarrier(event) {
      this.SelectedCarrier = event.target.value
      console.log(event)
      this.$emit('carrier-changed',this.SelectedCarrier)
    },

    async callApi() {
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/carriers`);

        // console.log('API DATA XXXXXXXXXXXXXXXXXXXXXXXXXXXX')
        // console.log(data)
        this.listCarrier = data;
        this.executed = true;
      } catch (e) {
        this.listCarrier = `Error: the server responded with '${e.response}'`;
      }
    },
  },
  created() {
    this.callApi();
    console.log('the selected carrier in dorpdown is:');
    console.log(this.selected_carrier);
  }
};
</script>
