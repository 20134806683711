<template>
    <div class="cascading-dropdown">
      <div class="dropdown">
        <select style="width: 100%;" @change="onChangeServiceType($event)" v-model="selected_servicetype">
          <option value="">Select a product</option>
        <option
            v-for="(servicetype, index) in listServiceType"
            :value="servicetype.STId"
            :key="index"
          >
            {{ servicetype.Name }}
          </option>

        </select>
      </div>
      <!-- <p v-if="SelectedServiceType">Selected ServiceType - {{ this.SelectedServiceType }}</p> -->
    </div>
  </template>

  <script>
  export default {
    name: "App",
    props:{
      group_exclude:{
        type: Boolean,
        default: false},
        selected_servicetype:{
        type: String,
        default: ""},
      },
    data() {
      return {
        listServiceType: [],
        SelectedServiceType: "",
        authToken: "",
      };
    },
    methods: {
      onChangeServiceType(event) {
        this.SelectedServiceType = event.target.value
        console.log(event)
        this.$emit('servicetype-changed',this.SelectedServiceType)
      },

      async callApi() {
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/servicetypes?only=STId,Name`);

          console.log('API DATA XXXXXXXXXXXXXXXXXXXXXXXXXXXX')
          console.log(data)
          this.listServiceType = data;
          this.executed = true;
        } catch (e) {
          this.listServiceType = `Error: the server responded with '${e.response}'`;
        }
      },
    },
    created() {
      this.callApi();
      console.log('the selected servicetype in dorpdown is:');
      console.log(this.selected_servicetype);
    }
  };
  </script>
