<template>

    <div>
      <p><u><strong>Duplicated Loaded Calls Report</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
      <b-form @submit="onFind" >
        <b-form-row  >
          <b-col>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="1"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="1"
            label="Report"
            label-for="input-horizontal"
            >
            <div class="dropdown">
                <select @change="onChangeReportType($event)">
                  <option value="">Select report</option>
                  <option
                    v-for="(type, index) in reporttypes"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </b-form-group>

  </b-col>
  <b-col> <b-button type="submit" variant="primary">Find</b-button></b-col>
  </b-form-row>
 </b-form>
 <br><br>
 <button v-if="showdownload" type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button>

 <br><br>
          <table  v-show="showresult" v-if="this.SelectedReportType === 'samecarrier'" class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th class="th-sm" scope="col" @click="sort('Carrier')">Carrier </th>
                <th class="th-lg" scope="col" @click="sort('Source')" >Source</th>
                <th class="th-sm" scope="col" @click="sort('Destination')">Destination</th>
                <th class="th-sm" scope="col" @click="sort('CallDate')">Call Date</th>
                <th class="th-sm" scope="col" @click="sort('CallCount')">Repeated</th>
                <th class="th-sm" scope="col" @click="sort('Duration')">Duration(s)</th>
                <th class="th-sm" scope="col" @click="sort('CarrierCost')">Carrier Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cli, index) in SortedapiCLIs" :key="index">
                <td>{{ cli.Carrier }}</td>
                <td>{{ cli.Source }}</td>
                <td>{{ cli.Destination }}</td>
                <td>{{ cli.CallDate }}</td>
                <td>{{ cli.CallCount }}</td>
                <td>{{ cli.Duration }}</td>
                <td>{{ cli.CarrierCost }}</td>
              </tr>
            </tbody>
          </table>
          <table  v-show="showresult" v-if="this.SelectedReportType === 'allcarriers'" class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th class="th-lg" scope="col" @click="sort('Source')" >Source</th>
                <th class="th-sm" scope="col" @click="sort('Destination')">Destination</th>
                <th class="th-sm" scope="col" @click="sort('CallDate')">Call Date</th>
                <th class="th-sm" scope="col" @click="sort('Duration')">Duration(s)</th>
                <th class="th-sm" scope="col" @click="sort('CallCount')">Repeated</th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(cli, index) in SortedapiCLIs" :key="index">
                <td>{{ cli.Source }}</td>
                <td>{{ cli.Destination }}</td>
                <td>{{ cli.CallDate }}</td>
                <td>{{ cli.Duration }}</td>
                <td>{{ cli.CallCount }}</td>

              </tr>
            </tbody>
          </table>
          <div  v-show="showPaging"><p align="center" >
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredCLIs.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        reporttypes: [{ "id":"samecarrier", "value":'Same carrier      '}, {"id":"allcarriers","value":'Across all carriers'}],
        SearchMonthNumber: null,
        apiCLIs: null,
        executed: false,
        message: 'initial alert',
        currentSort:'CallCount',
        currentSortDir:'desc',
        pageSize:20,
        showPaging:false,
        currentPage:1,
        filter:'',
        showdownload: false,
        SelectedReportType: null,
        showresult: false
      };
    },

    computed:{
    filteredCLIs() {
      //  console.log('filtered cli');
        return this.apiCLIs.filter(c => {
          if(this.filter == '') return true;
          return c.PhoneNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    SortedapiCLIs:function() {
      //  console.log('sort cli');
      if (this.apiCLIs != null){
        return this.filteredCLIs.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if (typeof a[this.currentSort]  === 'number') {
            if (a[this.currentSort] === null) return 1;
            if (b[this.currentSort] === null) return -1;
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;

          }
          else{
            if (a[this.currentSort] === null) return 1;
            if (b[this.currentSort] === null) return -1;
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
          else{ return null}
    },
    },
    methods: {
      onChangeReportType(event) {
            this.filter = '';
            this.SelectedReportType= event.target.value;
            },
      download : function() {

        var heading =  ['Source','Destination', 'Call Date', 'Duration(s)','Repeated'];
        var objheading = ['Source','Destination', 'CallDate', 'Duration','CallCount'];

        if (this.SelectedReportType === 'samecarrier'){
          heading = ['Carrier','Source','Destination', 'Call Date','Repeated', 'Duration(s)', 'Carrier Cost']
          objheading = ['Carrier','Source','Destination', 'CallDate','CallCount', 'Duration', 'CarrierCost']
        }

        // customised headers
        const Heading = [
                       heading
                          ];

        // original object headers
        const objHeader = objheading;

        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.filteredCLIs, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var subreport = "across all carriers";

        if (this.SelectedReportType === 'samecarrier'){
          subreport = "same carrier"
        }
        var filename = 'Duplicated loaded calls(' + subreport + ')-' + datestr + '.xlsx';
        console.log(filename);
        XLSX.writeFile(wb,filename)
        },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredCLIs.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List outbounds
      // *******************************************
      async callApi(searchparam) {

        console.log(searchparam)
        console.log('getting the cli list')
        var url;
        // var searchlike = '%25' + searchparam + '%25';
        if(typeof(searchparam) != "undefined"){
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/duplicatedloadedcalls?q=` + searchparam
        }
        else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/duplicatedloadedcalls`
        }
        try {
          document.body.style.cursor = 'wait';
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiCLIs = data;
          console.log("API DATA")
          console.log(data)
          this.showresult = true
          console.log(this.apiCLIs.length)
          this.executed = true;
          if (this.apiCLIs.length > 0){
            this.showdownload = true
          }
          if (this.apiCLIs.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
          this.executed = true;
        } catch (e) {
          this.apiCLIs = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // search month
      // *******************************************
      onFind(evt) {
        console.log('on Find');
        this.apiCLIs = null;
        this.showdownload  = false;
        this.showresult = false;
        this.showPaging = false;
        if (!this.SelectedReportType){ // is null or zero
            alert("Please select a report");
            evt.preventDefault();
                    return false;
            }
        this.callApi(this.SelectedReportType);
        evt.preventDefault();
        },


    },

    created() {
      //  console.log('created')
      // this.callCostCentreApi();
      this.executed = true;
    //   this.callApi();


    },
    components: {
      alert: Alert,
      listfilter:Filter,
    },
  };
  </script>
