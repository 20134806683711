<template>
    <div>
      <p><strong>Final Post</strong> </p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <div v-if="isAdmin">
           <div v-if="apifinalpost.OrdersTransferred">
            <button type="button" class="btn btn-success btn-sm" @click="onStartFinalPost()">Start Final Post</button>
            </div>
          </div>
          <!-- <button type="button" class="btn btn-success btn-sm" v-b-modal.finalpost-modal>Start Pricing</button> -->
          <br><br>
          <!-- <input type="search" v-model="filter"> -->
          <table class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th scope="col">Final post date</th>
                <th scope="col">Bill Run Number</th>
                <th scope="col">Started on</th>
                <th scope="col" >Finished</th>
                <th scope="col" >Finished on</th>
                <th scope="col">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(finalpost, index) in apipricing" :key="index">
                <td>{{ finalpost.FinalPostDate }}</td>
                <td>{{ finalpost.BillRunNumber }}</td>
                <td>{{ finalpost.StartedOn }}</td>
                <td>{{ finalpost.Finished }}</td>
                <td>{{ finalpost.FinishedOn }}</td>
                <td>{{ finalpost.Message }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <!-- <div>
            <button type="button" class="btn btn-danger btn-sm" @click="onResetLoad()">Reset Pricing</button>
            </div> -->
        </div>
      </div>


    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import CarrierDropDownVue from "../components/CarrierDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        timer1: "",
        timer2: "",
        selectedCarrier:"",
        addfinalpostForm: {
          Carrier: '' ,
          CusBillingName: '' ,
          FileName: ''

        },
        apipricing: null,
        apifinalpost:null,
        executed: false,
        message: 'initial alert',
        currentSort:'BillRunNumber',
        currentSortDir:'desc',
        pageSize:20,
        currentPage:1,
        filter:'',
      };
    },

    computed:{
    filteredpricing() {
      return this.apipricing;
      // console.log('filtered finalpost');
      //   return this.apipricing.filter(c => {
      //     if(this.filter == '') return true;
      //     return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      //   })
      },
    SortedapiCDRLoads:function() {
      console.log('sort finalpost');
      return this.filteredpricing.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredpricing.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List finalposts
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        console.log('getting the data')
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/finalposts`);

          this.apipricing = data;
          console.log('the api data is....')
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apipricing = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // check orders transfer
      // *******************************************
      async callCheckTransferApi(lablex) {
        
        console.log(lablex)
        console.log('getting the data')
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/finalposts/checkordertransfer`);

          this.apifinalpost = data;
          console.log("finalpost get transfer @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
          console.log('the check transfer data is....')
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apifinalpost = `Error: the server responded with '${e.response}'`;
        }
      },

      // Add finalpost method to post to API
      async addfinalpost() {

      //   let formData = new FormData();
        console.log( 'START PRICING ');
        console.log(`${process.env.VUE_APP_API_BASE_URL}api/v1/finalposts`);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/finalposts`, null).then(() => {
            this.message = 'pricingl started!';
            this.showMessage = true;
            this.callApi();
            console.log( 'IN PRICING ');

          }).catch((err) => {
            console.log( err);
            if(err.response.status == 409) {
              alert("Duplicated finalpost. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add finalpost. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });

          // this.apipricing = data;
          // this.executed = true;
          console.log(data)
          //this.callApi()
        } catch (e) {
          this.apipricing = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addfinalpostForm.Carrier =  '';
          this.addfinalpostForm.BillingName =  '';
          this.addfinalpostForm.FileName =  '';

      },
      // *******************************************
      // Add finalpost on submit
      // *******************************************
      onStartFinalPost() {
        console.log('on start pricing');
        this.addfinalpost();
        // this.callCheckTransferApi()
        // if (!this.apifinalpost.OrdersTransferred){
        //     alert("Please transfer orders before finalpost");
        //     // evt.preventDefault();
        //             return false;
        //     }
        // else{
        //   this.addfinalpost();
        // }

      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addfinalpostModal.hide();
        this.initForm();
      },
    //
      onResetLoad() {
        console.log('onresetload')
        if(confirm("Are you sure you want to reset all CDR loads?")){
          this.resetLoad();
        }

      },
      // reset load
      // *******************************************
      async resetLoad() {

        console.log("in reset load before")
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing/reset`).then(() => {
            this.message = 'account removed!';
            this.showMessage = true;
            console.log("in removeAccount after")
            this.callApi("coming from delete");

          }).catch((err) => {
            console.log("in removeAccount error")
            console.error(err);
            this.callApi();

          });

          // this.apiAccounts = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiAccounts = `Error: the server responded with '${e.response}'`;
        }
      },
      cancelAutoUpdate() {
        clearInterval(this.timer1);
        clearInterval(this.timer2);
      },
    },
    created() {
      console.log('created')
      this.timer1 = setInterval(this.callCheckTransferApi, 20000);
      this.timer2 = setInterval(this.callApi, 20000);
      this.callCheckTransferApi();
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      CarrierDropDownVue: CarrierDropDownVue,
    },
    beforeDestroy() {
    this.cancelAutoUpdate();
  },
  };
  </script>
