<template>

  <div>
    <p><u><strong>Group Accounts</strong></u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin"  type="button" class="btn btn-success btn-sm" v-b-modal.account-modal>Insert Account Group</button>&nbsp;&nbsp;&nbsp; 
        <a :href="'deletedaccountgroups'" target="_blank">
          <button v-if="isAdmin" type="button"  class="btn btn-danger btn-sm">Deleted</button>
        </a>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('GroupNo')" >Account No</th>
              <th class="th-lg" scope="col" @click="sort('Name')" >Account Name</th>
              <th class="th-lg" scope="col" @click="sort('AccountCount')" >Accounts</th>
              <th scope="col" @click="sort('CreatedBy')">Date Last Transferred</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(accountgroup, index) in SortedapiAccountGroups" :key="index">
              <td>{{ accountgroup.GroupNo }}</td>
              <td>{{ accountgroup.Name }}</td>
              <td>{{ accountgroup.accounts.length }}</td>
              <td>{{ accountgroup.LastBillDate }}</td>
              <td align="center"><a :href="'/accountgrouphistory?accountgroup_id=' + accountgroup.accountgroup_id" target="_blank">History</a></td>
              <td>
                <div class="btn-group"  role="group">
                  <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-b-modal.accountgroup-update-modal
                          @click="editAccountGroup(accountgroup)">
                          {{editButtonText}}
                  </button>
                  <p>  </p>
                  <button  v-if="isAdmin" v-on:click="onDeleteAccount(accountgroup)"  type="button"
                          class="btn btn-danger btn-sm">
                      Delete
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div><p align="center">
          <b-pagination
          v-model="currentPage"
          align="center"
          :total-rows="filteredAccounts.length"
          :per-page="pageSize"
          aria-controls="apirecords"
          ></b-pagination>
          <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p></div>
      </div>
    </div>



  <!-- ADD member modal -->
    <b-modal ref="addAccountMemberModal"
            id="member-modal"
            title="Insert account to group"
            hide-footer size="m" >
      <b-form @submit="onMemberSubmit" @reset="onResetMember"  class="w-1700">
      <b-form-row>
        <b-col>
            <div > <center>
              <AccountDropdownVue group_exclude @account-changed="accountChange"></AccountDropdownVue>
            </center>
            <br>
        </div>
        </b-col>
      </b-form-row>
      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
    <!-- ADD modal -->
    <b-modal ref="addAccountModal"
            id="account-modal"
            title="Insert a new account group"
            hide-footer size="xl" >
      <b-form @submit="onSubmit" @reset="onReset" class="w-200">
        <b-form-row>
          <b-col>
            <b-form-group id="form-groupno-group"
                    label="Account Number"
                    label-for="form-groupno-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
            <b-form-input id="form-groupno-input"
                            type="text"   size="sm"
                            v-model="addAccountGroupForm.GroupNo"
                            required
                            placeholder="Enter account no." w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group id="form-SageAccount-group"
                      label="Sage Account"
                      label-for="form-SageAccount-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-SageAccount-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.SageAccount"
                      required
                      placeholder="Enter Sage Account">
            </b-form-input>
            </b-form-group>
        </b-col>

        </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-name-group"
                    label="Account Name"
                    label-for="form-name-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
            <b-form-input id="form-tname-input"
                            type="text"   size="sm"
                            v-model="addAccountGroupForm.Name"
                            required
                            placeholder="Enter account name" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group id="form-ContactName-group"
                      label="Contact"
                      label-for="form-ContactName-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-ContactName-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.ContactName"
                      placeholder="Enter ContactName">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-Address1-group"
                      label="Address1"
                      label-for="form-Address1-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
            <b-form-input id="form-Address1-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.Address1"
                      required
                      placeholder="Enter Address1">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="form-Email-group"
                      label="Report Email"
                      label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-Email-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.Email"
                      required
                      placeholder="Enter Report Email">
            </b-form-input>
            </b-form-group>
        </b-col>

      </b-form-row>

      <b-form-row>
        <b-col>
            <b-form-group id="form-Address2-group"
                      label="Address2"
                      label-for="form-Address2-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-Address2-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.Address2"
                      placeholder="Enter Address2">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="form-InvoiceEmail-group"
                      label="Invoice Email"
                      label-for="form-InvoiceEmail-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-InvoiceEmail-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.InvoiceEmail"
                      placeholder="Enter Invoice Email">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
       <b-form-row>
        <b-col>
            <b-form-group id="form-City-group"
                      label="City"
                      label-for="form-City-input" label-cols="6" label-cols-sm="3"  label-size="sm">
            <b-form-input id="form-City-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.City"
                      required
                      placeholder="Enter City"
                      >
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col ></b-col>
      </b-form-row>

      <b-form-row>
         <b-col>
             <b-form-group id="form-County-group"
                      label="County"
                      label-for="form-County-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-County-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.County"
                      placeholder="Enter County">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>

        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
             <b-form-group id="form-Country-group"
                      label="Country"
                      label-for="form-Country-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-Country-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.Country"
                      placeholder="Enter Country">
            </b-form-input>
            </b-form-group>
        </b-col>

        <b-col>
            <b-form-group id="form-PaymentTerm-group"

                      label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm">
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
             <b-form-group id="form-PostCode-group"
                      label="PostCode"
                      label-for="form-PostCode-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-PostCode-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.PostCode"
                      required
                      placeholder="Enter PostCode">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col ></b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group id="form-BTN-group"
                      label="PO Number"
                      label-for="form-BTN-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-BTN-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.BTN"
                      required
                      placeholder="Enter po number">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col ></b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group id="form-PaymentTerm-group"
                      label="Payment Term"
                      label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-Email-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.PaymentTerm"
                      required
                      placeholder="Enter PaymentTerm">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col ></b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group id="form-Notes-group"
                      label="Notes"
                      label-for="form-Notes-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-Notes-input"
                      type="text"   size="sm"
                      v-model="addAccountGroupForm.Notes"
                      placeholder="Enter Notes">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col ></b-col>
      </b-form-row>
      <b-form-row>
        <b-col ></b-col>
        <b-col>
           <b-form-checkbox
            id="DirectDebit"
            v-model="addAccountGroupForm.DirectDebit"
            name="DirectDebit"
            >
            Direct debit
        </b-form-checkbox>
        </b-col>
        <b-col>
           <b-form-checkbox
            id="Taxable"
            v-model="addAccountGroupForm.Taxable"
            name="Taxable"
            >
            Taxable
        </b-form-checkbox>
        </b-col>
        <b-col>
           <b-form-checkbox
            id="ExportCalls"
            v-model="addAccountGroupForm.ExportCalls"
            name="ExportCalls"
            >
            Export Calls
        </b-form-checkbox>
        </b-col>
        </b-form-row>
      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>

      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal :key="editcomponentKey" ref="editAccountGroupModal"
            id="accountgroup-update-modal"
            title="Update Account Group"
            hide-footer size="xl">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
        <b-form-row>
          <b-col>
            <b-form-group id="form-groupno-group"
                    label="Account Number"
                    label-for="form-groupno-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
            <b-form-input id="form-groupno-input"
                            type="text"   size="sm"
                            v-model="editAccountGroupForm.GroupNo"
                            required
                            readonly
                            placeholder="Enter account no." w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group id="form-SageAccount-group"
                      label="Sage Account"
                      label-for="form-SageAccount-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-SageAccount-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.SageAccount"
                      required
                      placeholder="Enter Sage Account">
            </b-form-input>
            </b-form-group>
        </b-col>

        </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-name-group"
                    label="Account Name"
                    label-for="form-name-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
            <b-form-input id="form-tname-input"
                            type="text"   size="sm"
                            v-model="editAccountGroupForm.Name"
                            required
                            placeholder="Enter account name" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group id="form-ContactName-group"
                      label="Contact"
                      label-for="form-ContactName-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-ContactName-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.ContactName"
                      placeholder="Enter ContactName">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-Address1-group"
                      label="Address1"
                      label-for="form-Address1-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
            <b-form-input id="form-Address1-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.Address1"
                      required
                      placeholder="Enter Address1">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="form-Email-group"
                      label="Report Email"
                      label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-Email-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.Email"
                      required
                      placeholder="Enter Report Email">
            </b-form-input>
            </b-form-group>
        </b-col>

      </b-form-row>

      <b-form-row>
        <b-col>
            <b-form-group id="form-Address2-group"
                      label="Address2"
                      label-for="form-Address2-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-Address2-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.Address2"
                      placeholder="Enter Address2">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="form-InvoiceEmail-group"
                      label="Invoice Email"
                      label-for="form-InvoiceEmail-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-InvoiceEmail-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.InvoiceEmail"
                      placeholder="Enter Invoice Email">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
       <b-form-row>
        <b-col>
            <b-form-group id="form-City-group"
                      label="City"
                      label-for="form-City-input" label-cols="6" label-cols-sm="3"  label-size="sm">
            <b-form-input id="form-City-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.City"
                      required
                      placeholder="Enter City"
                      >
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col></b-col>
      </b-form-row>

      <b-form-row>
         <b-col>
             <b-form-group id="form-County-group"
                      label="County"
                      label-for="form-County-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-County-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.County"
                      placeholder="Enter County">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>

        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
             <b-form-group id="form-Country-group"
                      label="Country"
                      label-for="form-Country-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-Country-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.Country"
                      placeholder="Enter Country">
            </b-form-input>
            </b-form-group>
        </b-col>

        <b-col>
            <b-form-group id="form-PaymentTerm-group"

                      label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm">
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
             <b-form-group id="form-PostCode-group"
                      label="PostCode"
                      label-for="form-PostCode-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-PostCode-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.PostCode"
                      required
                      placeholder="Enter PostCode">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col ></b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group id="form-BTN-group"
                      label="PO Number"
                      label-for="form-BTN-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-BTN-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.BTN"
                      required
                      placeholder="Enter po number">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col></b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group id="form-PaymentTerm-group"
                      label="Payment Term"
                      label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-Email-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.PaymentTerm"
                      required
                      placeholder="Enter PaymentTerm">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col></b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group id="form-Notes-group"
                      label="Notes"
                      label-for="form-Notes-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-Notes-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.Notes"
                      placeholder="Enter Notes">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col></b-col>
      </b-form-row>
      <b-form-row>
        <b-col ></b-col>
        <b-col>
           <b-form-checkbox
            id="DirectDebit"
            v-model="editAccountGroupForm.DirectDebit"
            name="DirectDebit"
            >
            Direct debit
        </b-form-checkbox>
        </b-col>
        <b-col>
           <b-form-checkbox
            id="Taxable"
            v-model="editAccountGroupForm.Taxable"
            name="Taxable"
            >
            Taxable
        </b-form-checkbox>
        </b-col>
        <b-col>
           <b-form-checkbox
            id="ExportCalls"
            v-model="editAccountGroupForm.ExportCalls"
            name="ExportCalls"
            >
            Export Calls
        </b-form-checkbox>
        </b-col>
        </b-form-row>
      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>

      <b-form-row>
        <b-col>
            <b-form-group id="form-LastBillDate-group"
                      label="Date Last Transferred"
                      label-for="form-LastBillDate-input" label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input readonly id="form-LastBillDate-input"
                      type="text"   size="sm"
                      v-model="editAccountGroupForm.LastBillDate">
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col>

        </b-col>
      </b-form-row>
      <b-form-row>
          <p>Group Members</p>
      </b-form-row>
      <b-form-row>
          <b-col cols sm="2"  lg="auto">
          <table class="table table-small w-auto" >
          <thead>
            <tr>
              <th class="th-xs"  scope="col" >Name</th>
               <th class="th-xs"   scope="col" >AccountNumber</th>
               <!-- <th class="th-xs"   scope="col" >Added on</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in editAccountGroupForm.accounts" :key="index">
              <td>{{ account.Name }}</td>
              <td>{{ account.AccountNumber }}</td>
              <!-- <td>{{ account.AddedOn }}</td> -->
              <td>
                  <button  v-if="isAdmin"
                          type="button"
                          class="btn-custom"
                          title="Remove member"
                          @click="onDeleteAccountMember(account.account_id)">
                      Remove
                  </button>
              </td>
            </tr>
          </tbody>
        </table>
        </b-col>
        <b-col>
            <button  v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.member-modal>Insert member</button>
        </b-col>
      </b-form-row>


        <div class="container">
        <div class="row" v-if="isAdmin" >
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      editcomponentKey:0,
      selectedAccount:"",
      addAccountGroupForm: {
        Name: '' ,
        Address1: '' ,
        Address2: '' ,
        PostCode: '' ,
        City: '' ,
        County: '' ,
        Country: '' ,
        ContactName: '',
        Email: '' ,
        InvoiceEmail: '' ,
        PaymentTerm: 7,
        DirectDebit:'',
        Taxable:'',
        ExportCalls:'',
        MaintenaceOnly: '',
        Notes:''
      },
      editAccountGroupForm: {
        accountgroup_id : '',
        Name: '' ,
        GroupNo: '' ,
        Address1: '' ,
        Address2: '' ,
        PostCode: '' ,
        City: '' ,
        County: '' ,
        Country: '' ,
        SaleAgent: '' ,
        ContactName: '',
        Email: '' ,
        InvoiceEmail: '' ,
        CreatedOn:'',
        CreatedBy:'',
        ModifiedOn:'',
        ModifiedBy:'',
        DirectDebit:'',
        Taxable:'',
        ExportCalls:'',
        PaymentTerm: '',
        Accounts:[],
        accounts:[],
        Notes:''
      },
      apiAccountGroups: null,
      executed: false,
      message: 'initial alert',
      currentSort:'Name',
      currentSortDir:'asc',
      pageSize:20,
      currentPage:1,
      filter:'',
    };
  },

  computed:{
  filteredAccounts() {
    console.log('filtered account');
    console.log(this.filter);
      return this.apiAccountGroups.filter(c => {
        if(this.filter == '') return true;
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.GroupNo.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.SageAccount.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiAccountGroups:function() {
    console.log('sort account group');
    if (this.apiAccountGroups != null){
    return this.filteredAccounts.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
    }
    else{ return null}
  },
  },
  methods: {
    forceRerender:function() {
      this.editcomponentKey += 1
    },
    accountChange: function (e) {
        this.selectedAccount = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredAccounts.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    //////////////////////////////////////////////
    // LIST ACCOUNT GROUP METHODS
    //////////////////////////////////////////////
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the data')
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountgroups`);
        console.log('got account group data')
        this.apiAccountGroups = data;
        this.executed = true;
      } catch (e) {
        this.apiAccountGroups = `Error: the server responded with '${e.response}'`;
      }
    },

    //////////////////////////////////////////////
    // DELETE ACCOUNT GROUP METHODS
    //////////////////////////////////////////////
    async removeAccount(accountid) {

      console.log("in removeAccount before")
      console.log(accountid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountgroups/${accountid}`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          console.log("in removeAccount after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          this.callApi();

        });

        // this.apiAccountGroups = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccountGroups = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteAccount(accountgroup) {
      console.log(accountgroup.accountgroup_id)
      if(confirm("Are you sure you want to delete the account group?")){
        this.removeAccount(accountgroup.accountgroup_id);
      }
    },

    //////////////////////////////////////////////
    // DELETE MEMBER FROM ACCOUNT GROUP METHODS
    //////////////////////////////////////////////
    async removeAccountGroupMember(accountgroupid,memberid) {

      console.log("in remove Accountgroup member before")
      console.log(memberid)
    //   const  payload = {};
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountgroups/${accountgroupid}/accounts/${memberid}`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          console.log("in removeAccount after")
        //   this.forceRerender();
          this.$refs.editAccountGroupModal.hide();
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          this.callApi();

        });

        // this.apiAccountGroups = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccountGroups = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteAccountMember(memberid) {
      console.log(memberid)
      this.removeAccountGroupMember(this.editAccountGroupForm.accountgroup_id, memberid);
    },


    //////////////////////////////////////////////
    // ADD ACCOUNT GROUP METHODS
    //////////////////////////////////////////////

    // Add account method to post to API
    async addAccount(payload) {

    //   const id_token = claims.__raw;
      console.log('in add account');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountgroups`, payload).then(() => {
          this.message = 'account added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated accountgroup. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add accountgroup. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apiAccountGroups = data;
        // this.executed = true;
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccountGroups = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addAccountGroupForm.Name =  '';
        this.addAccountGroupForm.GroupNo =  '';
        this.addAccountGroupForm.Address1 =  '';
        this.addAccountGroupForm.Address2 =  '';
        this.addAccountGroupForm.PostCode =  '';
        this.addAccountGroupForm.City =  '';
        this.addAccountGroupForm.County = '';
        this.addAccountGroupForm.Country = '';
        // this.addAccountGroupForm.SaleAgent = '';
        this.addAccountGroupForm.PaymentTerm = 7;
        this.addAccountGroupForm.ContactName = '';
        this.addAccountGroupForm.SageAccount = '';
        this.addAccountGroupForm.NetXaccountgroup_id = '';
        this.addAccountGroupForm.Email = '';
        this.addAccountGroupForm.InvoiceEmail = '';
        this.addAccountGroupForm.MaintenaceOnly =  false;
        this.addAccountGroupForm.BTN = '';
        this.addAccountGroupForm.PhoneUsers =  10;
        this.addAccountGroupForm.Taxable =  false;
        this.addAccountGroupForm.Roundup =  false;
        this.addAccountGroupForm.DirectDebit =  false;
        this.addAccountGroupForm.BonusEligible =  false;
        this.addAccountGroupForm.FaxToEmailCost =  0.05;
        this.addAccountGroupForm.AudioConfPct =  15;
        this.addAccountGroupForm.VideoConfPct =  25;
        this.addAccountGroupForm.DirectDebit =  false;
        this.addAccountGroupForm.Notes = '';
        this.addAccountGroupForm.BTN = '';

    },
    // Add account on submit
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addAccountModal.hide();
      console.log('MaintenaceOnly');
      // ***********this need to be looked at again as the check**************
      // box value is not presented when not selected
      if(this.addAccountGroupForm.DirectDebit== "") this.addAccountGroupForm.DirectDebit= false;
      if(this.addAccountGroupForm.DirectDebit!= "") this.addAccountGroupForm.DirectDebit= true;
      if(this.addAccountGroupForm.ExportCalls== "") this.addAccountGroupForm.ExportCalls= false;
      if(this.addAccountGroupForm.ExportCalls!= "") this.addAccountGroupForm.ExportCalls= true;
      if(this.addAccountGroupForm.Taxable== "") this.addAccountGroupForm.Taxable= false;
      if(this.addAccountGroupForm.Taxable!= "") this.addAccountGroupForm.Taxable= true;

      const payload = {
        Name: this.addAccountGroupForm.Name,
        GroupNo: this.addAccountGroupForm.GroupNo,
        Address1: this.addAccountGroupForm.Address1,
        Address2: this.addAccountGroupForm.Address2,
        PostCode: this.addAccountGroupForm.PostCode,
        City: this.addAccountGroupForm.City,
        County:this.addAccountGroupForm.County,
        Country:this.addAccountGroupForm.Country,
        // SaleAgent:this.addAccountGroupForm.SaleAgent,
        ContactName:this.addAccountGroupForm.ContactName,
        SageAccount:this.addAccountGroupForm.SageAccount,
        Email:this.addAccountGroupForm.Email,
        InvoiceEmail:this.addAccountGroupForm.InvoiceEmail ,
        Taxable: this.addAccountGroupForm.Taxable,
        DirectDebit:this.addAccountGroupForm.DirectDebit,
        ExportCalls: this.addAccountGroupForm.ExportCalls,
        PaymentTerm:this.addAccountGroupForm.PaymentTerm,
        Notes: this.addAccountGroupForm.Notes,
        BTN: this.addAccountGroupForm.BTN
      };
      this.addAccount(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addAccountModal.hide();
      this.initForm();
    },

    //////////////////////////////////////////////
    // ADD MEMBER METHODS
    //////////////////////////////////////////////
    onMemberSubmit(evt) {
      console.log('on member submit');
      evt.preventDefault();
      this.$refs.addAccountMemberModal.hide();

      const memberid = this.selectedAccount;
      console.log('memberid is');
      console.log(memberid);
      console.log('groupid is');
      console.log(this.editAccountGroupForm.accountgroup_id);

      this.addMemberAccount(this.editAccountGroupForm.accountgroup_id,memberid);
      this.initForm();
    },
    onResetMember(evt) {
      evt.preventDefault();
      this.$refs.addAccountMemberModal.hide();
      this.initForm();
    },
    // Add account member method to post to API
    async addMemberAccount(accountgroupid,memberid) {

    //   const id_token = claims.__raw;
      console.log('in add account');
      console.log('payload is');
      console.log(memberid);
      const  payload = {};
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountgroups/${accountgroupid}/accounts/${memberid}`, payload).then(() => {
          this.message = 'account added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated accountgroup. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add member to accountgroup. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apiAccountGroups = data;
        // this.executed = true;
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccountGroups = `Error: the server responded with '${e.response}'`;
      }
    },
    //////////////////////////////////////////////
    // UPDATE ACCOUNT GROUP METHODS
    //////////////////////////////////////////////
    editAccountGroup(accountgroup) {
      console.log('editAccountGroup');
      console.log(accountgroup);
      console.log(accountgroup.accountgroup_id);
      this.editAccountGroupForm = accountgroup;
      this.callApi()
    },

    // Update account on submit
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('accountgroup_id');
      evt.preventDefault();
      this.$refs.editAccountGroupModal.hide();
      // ***********this need to be looked at again as the check**************
      // box value is not presented when not selected
      if(this.editAccountGroupForm.DirectDebit== "") this.editAccountGroupForm.DirectDebit= false;
      if(this.editAccountGroupForm.DirectDebit!= "") this.editAccountGroupForm.DirectDebit= true;
      if(this.editAccountGroupForm.ExportCalls== "") this.editAccountGroupForm.ExportCalls= false;
      if(this.editAccountGroupForm.ExportCalls!= "") this.editAccountGroupForm.ExportCalls= true;
      if(this.editAccountGroupForm.Taxable== "") this.editAccountGroupForm.Taxable= false;
      if(this.editAccountGroupForm.Taxable!= "") this.editAccountGroupForm.Taxable= true;

      const payload = {
        Name: this.editAccountGroupForm.Name,
        GroupNo: this.editAccountGroupForm.GroupNo,
        Address1: this.editAccountGroupForm.Address1,
        Address2: this.editAccountGroupForm.Address2,
        PostCode: this.editAccountGroupForm.PostCode,
        City: this.editAccountGroupForm.City,
        County:this.editAccountGroupForm.County,
        Country:this.editAccountGroupForm.Country,
        // SaleAgent:this.editAccountGroupForm.SaleAgent,
        ContactName:this.editAccountGroupForm.ContactName,
        SageAccount:this.editAccountGroupForm.SageAccount,
        Email:this.editAccountGroupForm.Email,
        InvoiceEmail:this.editAccountGroupForm.InvoiceEmail ,
        DirectDebit:this.editAccountGroupForm.DirectDebit,
        Taxable: this.editAccountGroupForm.Taxable,
        ExportCalls: this.editAccountGroupForm.ExportCalls,
        PaymentTerm:this.editAccountGroupForm.PaymentTerm,
        Notes: this.editAccountGroupForm.Notes,
        BTN: this.editAccountGroupForm.BTN
      };
      this.updateAccount(payload, this.editAccountGroupForm.accountgroup_id);
      this.callApi()
    },

    async updateAccount(payload, accountid) {

      console.log('in update account');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountgroups/${accountid}`, payload).then(() => {
          this.message = 'account updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated accountgroup. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add accountgroup. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiAccountGroups = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccountGroups = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editAccountGroupModal.hide();
      this.initForm();
      this.getAccounts(); // why?
    },
  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
  },
};
</script>
<style>
.table .btn-group {
	white-space: nowrap;
}
.table .btn-group .btn {
	display: inline-block;
	float: none;
}
.table .btn-group .btn + .btn {
	margin-left: -5px;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: normal;
  text-align: center;
  font-size: 14px;
}
td {
  font-size: 12px;
}
.btn-custom {
    width: 50px !important;
    height: 17px;
    font-size: 9px;
    font-weight: normal;
    text-align: center;
    background-color: red;
    text-emphasis-color: white;
    color: white;
}

</style>
