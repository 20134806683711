<template>

    <div>
      <p ><u><strong>NetX Breakdown Report</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >
        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="4"
            label="Report"
            label-for="input-horizontal"
            >
            <div class="dropdown">
                <select @change="onChangeReportType($event)">
                  <option value="">Select report</option>
                  <option
                    v-for="(type, index) in reporttypes"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedPhoneType">Selected type - {{ this.SelectedPhoneType }}</p> -->
              </div>
            </b-form-group>

        <b-button type="submit" variant="primary">Find</b-button>
  </b-form>

         <br><br>
         <table v-show="showresult" >
            <tr v-if="apiBaseRates.length > 0">
                <td><button type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>
        <br><br>
        <!-- <div v-show="showresult"><input type="search" v-model="filter"></div> -->
          <table class="table table-striped w-auto" v-if="SelectedReportType == 'ABD'" v-show="showresult" id="apirecords" >
            <thead >
            <tr>
              <th class="th-lg" scope="col" @click="sort('Name')" >Account</th>
              <th class="th-sm" scope="col" @click="sort('AccountNumber')">Customer no.</th>
              <th class="th-sm" scope="col" @click="sort('CallCount')">Count</th>
              <th class="th-sm" scope="col" @click="sort('Duration')">Duration(s) </th>
              <th class="th-sm" scope="col" @click="sort('Sales')">Sales </th>
              <th class="th-sm" scope="col" @click="sort('Carrier')">Carrier </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(chargeband, index) in SortedapiBaseRates" :key="index">
              <td>{{ chargeband.Name }}</td>
              <td>{{ chargeband.AccountNumber }}</td>
              <td>{{ chargeband.CallCount }}</td>
              <td>{{ chargeband.Duration }}</td>
              <td>{{ chargeband.Sales }}</td>
              <td>{{ chargeband.Carrier }}</td>
            </tr>
          </tbody>
          </table>
          <table class="table table-striped w-auto" v-if="SelectedReportType == 'DBD'"  v-show="showresult" id="apirecords" >
            <thead >
            <tr>
              <th class="th-lg" scope="col" @click="sort('Name')" >Destination</th>
              <th class="th-sm" scope="col" @click="sort('CallCount')">Count</th>
              <th class="th-sm" scope="col" @click="sort('Duration')">Duration(s) </th>
              <th class="th-sm" scope="col" @click="sort('Sales')">Sales </th>
              <th class="th-sm" scope="col" @click="sort('Carrier')">Carrier </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(chargeband, index) in SortedapiBaseRates" :key="index">
              <td>{{ chargeband.Name }}</td>
              <td>{{ chargeband.CallCount }}</td>
              <td>{{ chargeband.Duration }}</td>
              <td>{{ chargeband.Sales }}</td>
              <td>{{ chargeband.Carrier }}</td>
            </tr>
          </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredBaseRates.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        reporttypes: [{ "id":"ABD", "value":'Account breakdown     '}, {"id":"DBD","value":'Destination breakdown'}],
        executed: false,
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        showPaging:false,
        Account: null,
        apiBaseRates: [],
        SelectedReportType: ''
      };
    },

    computed:{
    filteredBaseRates() {
      console.log('filtered suspendedcall');
        return this.apiBaseRates
        // return this.apiBaseRates.filter(c => {
        //     // console.log('in filter')
        //   if(this.filter == '') return true;
        //   return  c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
        //         (c.AccountNumber && c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0
        // })
      },
    SortedapiBaseRates:function() {
        console.log('sort suspendedcall');
      return this.filteredBaseRates.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
    onChangeReportType(event) {
            this.showresult = false;
            this.SelectedReportType= event.target.value;
            },


    onSubmit(evt) {
        console.log('on submit');
        this.apiBaseRates = null;
        this.showresult = false;
        if (!this.SelectedReportType){ // is null or zero
            alert("Please select a report");
            evt.preventDefault();
                    return false;
            }
        this.callApi(this.SelectedReportType);
        // this.showresult = true;
        evt.preventDefault();
        },

    download : function() {
        const data = XLSX.utils.json_to_sheet(this.filteredBaseRates)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename;
        if (this.SelectedReportType== 'ABD'){
            filename = 'netX account breakdown -' + datestr + '.xlsx';
        }
        if (this.SelectedReportType== 'DBD'){
            filename = 'netX destination breakdown -' + datestr + '.xlsx';
        }

        console.log(filename)
        XLSX.writeFile(wb,filename)
        },

    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredBaseRates.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List details
      // *******************************************
    async callApi(report) {

        console.log('getting the data')
        // build the filters for the url
        var url;
        if (report== 'ABD'){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/netxaccountbreakdown'
        }
        if (report== 'DBD'){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/netxdestinationbreakdown'
            this.currentSort = 'Carrier'
        }

        document.body.style.cursor = 'wait';

        try {

          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apiBaseRates = data;
          this.showPaging = true;
          if (this.apiBaseRates.length < this.pageSize){
            this.showPaging = false
          }
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiBaseRates = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
