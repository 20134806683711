<template>

    <div>
      <p ><strong>Users List</strong> </p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <button type="button" class="btn btn-success btn-sm" v-b-modal.user-modal>Insert User</button>
          <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col" @click="sort('name')" >Name</th>
                <th class="th-sm" scope="col" @click="sort('email')">Email</th>
                <th class="th-sm" scope="col" @click="sort('isAdminStr')">Admin</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in SortedapiUsers" :key="index">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.isAdminStr }}</td>
                <td>
                  <div class="btn-group"  role="group">
                    <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-b-modal.user-update-modal
                            @click="editUser(user)">
                        Update
                    </button>
                    <p>  </p>
                    <button
                            type="button"
                            class="btn btn-danger btn-sm"
                            @click="onDeleteUser(user)">
                        Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- add modal -->
      <b-modal ref="addUserModal"
              id="user-modal"
              title="Insert a new user"
              hide-footer size="sm" >
        <b-form @submit="onSubmit" @reset="onReset" class="w-200">
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Name"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="addUserForm.name"
                              required
                              placeholder="Enter Name" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-email-group"
                        label="Email"
                        label-for="form-email-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-email-input"
                        type="text"   size="sm"
                        v-model="addUserForm.email"
                        placeholder="Enter email">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
            <b-form-group id="form-IsAdmin-group"
                      label="Admin"
                      label-for="form-IsAdmin-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
            <div class="dropdown">
                <select @change="onChangeIsAdmin($event)">
                  <!-- <option value="">Select phone type</option> -->
                  <option
                    v-for="(type, index) in isadmin"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedPhoneType">Selected type - {{ this.SelectedPhoneType }}</p> -->
              </div>
            </b-form-group>
        </b-col>

        </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>

      <!-- EDIT modal -->
      <b-modal ref="editUserModal"
              id="user-update-modal"
              title="Insert a new user"
              hide-footer size="sm" >
        <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Name"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editUserForm.name"
                              required
                              placeholder="Enter Name" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-email-group"
                        label="Email"
                        label-for="form-email-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input readonly id="form-email-input"
                        type="text"   size="sm"
                        v-model="editUserForm.email"
                        placeholder="Enter email" >
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
            <b-form-group id="form-IsAdmin-group"
                      label="Admin"
                      label-for="form-IsAdmin-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
            <div class="dropdown">
                <select v-model="editUserForm.isAdmin" @change="onChangeIsAdmin($event)">
                  <!-- <option value="">Select phone type</option> -->
                  <option
                    v-for="(type, index) in isadmin"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </b-form-group>
        </b-col>

        </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>

  <script>
//   import { SingleEntryPlugin } from 'webpack';
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        isadmin: [{ "id":false, "value":"No"}, {"id":true,"value":"Yes"}],
        SelectedIsAdmin: false,
        addUserForm: {
          name: '' ,
          email: ''
        },
        editUserForm: {
            name: '' ,
          email: ''
        },
        apiUsers: null,
        executed: false,
        message: 'initial alert',
        currentSort:'name',
        currentSortDir:'asc',
        pageSize:200,
        currentPage:1,
        filter:'',
      };
    },

    computed:{
    filteredUsers() {
      console.log('filtered user');
        return this.apiUsers.filter(c => {
          if(this.filter == '') return true;
          return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.BillingName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
                 c.ContactName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    SortedapiUsers:function() {
      console.log('sort user');
      return this.filteredUsers.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      onChangeIsAdmin(event) {
      this.SelectedIsAdmin = JSON.parse(event.target.value)
      console.log("selected is admin ==========")
      console.log(typeof this.SelectedIsAdmin )
      console.log(this.SelectedIsAdmin )


      // this.$emit('account-changed',this.SelectedAccount)
    },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredUsers.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List users
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        console.log('getting the data')
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/users`);

          this.apiUsers = data;
          this.executed = true;
        } catch (e) {
          this.apiUsers = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // Delete user
      // *******************************************
      async removeUser(useremail) {

        console.log("in removeUser before")
        console.log(useremail)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/users/${useremail}`).then(() => {
            this.message = 'user removed!';
            this.showMessage = true;
            console.log("in removeUser after")
            this.callApi("coming from delete");

          }).catch((err) => {
            console.log("in removeUser error")
            console.error(err);
            this.callApi();

          });

          console.log('delete data is:')
          console.log(data)
        //   this.callApi()
        } catch (e) {
          this.apiUsers = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteUser(user) {
        console.log(user.email)
        if(confirm("Are you sure you want to delete this user?")){
          this.removeUser(user.email);
        }
      },

      // Add user method to post to API
      async addUser(payload) {

      //   const id_token = claims.__raw;
        console.log('in add user');
        console.log('payload is');
        console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/users`, payload).then(() => {
            this.message = 'user added!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated user. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add user. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });
          console.log(data)
        //   this.callApi()
        } catch (e) {
          this.apiUsers = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addUserForm.name =  null;
          this.addUserForm.email =  null;
      },
      // *******************************************
      // Add user on submit
      // *******************************************
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addUserModal.hide();
        console.log('MaintenaceOnly');
        const payload = {
          name: this.addUserForm.name,
          email: this.addUserForm.email,
          isAdmin : this.SelectedIsAdmin
        };
        this.addUser(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addUserModal.hide();
        this.initForm();
      },
    //
      editUser(user) {
        console.log('editUser');
        console.log(user);
        console.log(user.name);
        this.editUserForm = user;
        this.callApi()
      },

      // *******************************************
      // Update user on submit
      // *******************************************
      onSubmitUpdate(evt) {
        console.log('in onSubmitUpdate');
        evt.preventDefault();
        this.$refs.editUserModal.hide();
        const payload = {
          name: this.editUserForm.name,
          email: this.editUserForm.email,
          isAdmin : this.SelectedIsAdmin
        };
        this.updateUser(payload, this.editUserForm.email);
        // this.callApi()
      },

      async updateUser(payload, useremail) {

        console.log('in update user');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/users/${useremail}`, payload).then(() => {
            this.message = 'user updated!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated user. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add user. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi();

          });

          console.log(data)
        //   this.callApi()
        } catch (e) {
          this.apiUsers = `Error: the server responded with '${e.response}'`;
        }
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editUserModal.hide();
        this.initForm();
        this.getUsers(); // why?
      },
    },
    created() {
      console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
