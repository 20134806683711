import Vue from "vue";
import App from "./App.vue";
import Axios from "./plugins/axios";
import router from "./router";


import hljs from "highlight.js/lib/core";
import "highlight.js/styles/github.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';

// import { XLSX } from 'xlsx';
// import * as XLSX from 'xlsx'


Vue.use(BootstrapVue);
// Vue.use(XLSX);

Vue.config.productionTip = false;

Vue.use(hljs.vuePlugin);


Vue.use(Axios);

library.add(faLink, faUser, faPowerOff);

Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
