<template>
    <div class="cascading-dropdown">
      <div class="dropdown">
        <select style="width: 100%" @change="onChangeIndustryType($event)" v-model="selected_industrytype" :disabled="disableOption">
          <option value=""></option>
          <option
            v-for="(industrytype, index) in listIndustryType"
            :value="industrytype.IndustryTypeId"
            :key="index"
          >
            {{ industrytype.Name }}
          </option>
        </select>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "App",
    props:{
      group_exclude:{
        type: Boolean,
        default: false},
        selected_industrytype:{
        type: String,
        default: ""},
        disableOption: {
          type: Boolean,
          required: false,
          default: true
          },
      },
    data() {
      return {
        listIndustryType: [],
        SelectedIndustryType: "",
        authToken: "",
      };
    },
    methods: {
      onChangeIndustryType(event) {
        this.SelectedIndustryType = event.target.value
        console.log(event)
        this.$emit('industrytype-changed',this.SelectedIndustryType)
      },

      async callApi() {
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/industrytypes`);
          this.listIndustryType = data;
          this.executed = true;
        } catch (e) {
          this.listIndustryType = `Error: the server responded with '${e.response}'`;
        }
      },
    },
    created() {
      this.callApi();
      console.log("disable btn is")
      console.log(this.disableOption)
      console.log('the selected industrytype in dorpdown is:');
      console.log(this.selected_industrytype);
    }
  };
  </script>
