<template>
  <div id="app" class="d-sm-inline-flex  flex-column h-100" style="padding-left: 1px;">
    <nav-bar />
    <div class="container flex-grow-3" style="padding-left: 0px;">
      <error />
      <div class="mt-5">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import NavBar from "./components/NavBar";
import Error from "./components/Error";
import { isAuthenticated, logout } from '@/auth/AuthService';
export default {
  components: {
    NavBar,
    Error
  },
  watch: {
    created() {
      console.log('App.vue created')
      this.loadAuthentication();
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      'searchQuery',
      'authentication',
    ]),
  },
  methods: {
    isAuthenticated,
    ...mapMutations(['setSearchQuery',]),
    ...mapActions(['loadAuthentication',
    ]),
    logoff() {
      logout();
    },
  },
};



</script>
./auth/AuthService