<template>

    <div>

      <p v-if="deleted"><u><strong>Deleted Cost Centre History ({{ this.costcentrenumber }})</strong> </u></p>
      <p v-else><u><strong>Cost Centre History ({{ this.costcentrenumber }})</strong> </u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
                <tr>
              <th class="th-sm" scope="col" >Account No</th>
              <th class="th-sm" scope="col" >Account Name</th>
              <th class="th-sm" scope="col" >Cost Centre No</th>             
              <th class="th-sm" scope="col" >Cost Centre Name</th>
              <th scope="col" >Modified on</th>
              <th scope="col">User</th>
              <th scope="col">Action</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(costcentrehistory, index) in SortedapiCostCentres" :key="index">
              <td>{{ costcentrehistory.costcentre.account.AccountNumber }}</td>
              <td>{{ costcentrehistory.costcentre.account.Name }}</td>
              <td>{{ costcentrehistory.costcentre.CostCentreNumber }}</td>
              <td>{{ costcentrehistory.costcentre.Name }}</td>
              <td>{{ costcentrehistory.modifiedon }}</td>
              <td>{{ costcentrehistory.modifiedby }}</td>
              <td>{{ costcentrehistory.method }}</td>
              <td>
                  <div class="btn-group"  role="group">
                    <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            label="view"
                            v-b-modal.costcentre-update-modal
                            @click="editCostCentre(costcentrehistory)">
                            View
                            <!-- {{editButtonText}} -->
                    </button>

                  </div>
                </td>
            </tr>
          </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
          v-model="currentPage"
          align="center"
          :total-rows="filteredCostCentres.length"
          :per-page="pageSize"
          aria-controls="apirecords"
          ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>

      <!-- EDIT modal -->
       <b-modal ref="editCostCentreModal"
              id="costcentre-update-modal"
              title="Cost centre history"
              hide-footer size="m" >
        <b-form  @reset="onResetUpdate" class="w-600">
          <b-form-row>
        <b-col>
            <b-form-group id="form-CCN-group"
                      label="Cost Centre No"
                      label-for="form-Name-input" label-cols="2" label-cols-lg="4" label-size="sm" size="sm">
            <b-form-input id="form-CCN-input"
                      type="text"   size="sm"
                      v-model="editCostCentreForm.CostCentreNumber"
                      readonly>
            </b-form-input>
            </b-form-group>
        </b-col>

      </b-form-row>
          <b-form-row>
          <b-col>
              <b-form-group id="form-account-group"
                        label="Account No"
                        label-for="form-account-input" label-cols="2" label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-accountr-input"
                        type="text"   size="sm"
                        v-model="editCostCentreForm.account.AccountNumber"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>

        </b-form-row>
          <b-form-row>
          <b-col>
              <b-form-group id="form-account-group"
                        label="Account Name"
                        label-for="form-account-input" label-cols="2" label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-accountr-input"
                        type="text"   size="sm"
                        v-model="editCostCentreForm.account.Name"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>

        </b-form-row>

        <b-form-row>
          <b-col>
              <b-form-group id="form-Name-group"
                        label="Cost Centre Name"
                        label-for="form-Name-input" label-cols="2" label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-Name-input"
                        type="text"   size="sm"
                        v-model="editCostCentreForm.Name"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Notes-group"
                        label="Notes"
                        label-for="form-Notes-input" label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Notes-input"
                        type="text"   size="sm"
                        v-model="editCostCentreForm.Notes"
                        readonly>
              </b-form-input>
              </b-form-group>
          </b-col>


        </b-form-row>
        <b-form-row>
        <b-col>
            <b-form-group id="form-Notes-group"
                      label="Modified on"
                      label-for="form-Note-input" label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
                      <b-form-input id="form-Label-input"
                        type="text"   size="sm"
                        v-model="editCostCentreForm.modifiedon"
                        readonly
                        >
              </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-Notes-group"
                      label="User"
                      label-for="form-Note-input" label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
                      <b-form-input id="form-Label-input"
                        type="text"   size="sm"
                        v-model="editCostCentreForm.modifiedby"
                        readonly
                        >
              </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-Notes-group"
                      label="Action"
                      label-for="form-Note-input" label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
                      <b-form-input id="form-Label-input"
                        type="text"   size="sm"
                        v-model="editCostCentreForm.action"
                        readonly
                        >
              </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>

        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row" v-if="isAdmin" >
              <div class="col text-center">
              <b-button-group>
            <b-button type="reset" variant="danger">Close</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
        SelectedPhoneType: '',
        editCostCentreForm: {
          CostCentreId: null,
          Name: null ,
          Notes: null ,
          AccountId: null,
          account:{},
        },
        apiCostCentres: null,
        apiHistoryCostCentre:null,
        executed: false,
        message: 'initial alert',
        currentSort:'modifiedon',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:true,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selected_account: null,
        account:{},
        modifiedby :null,
        modifiedon : null,
        action : null,
        costcentrenumber: null,
        deleted:false
      };
    },

    computed:{
    filteredCostCentres() {
    return this.apiCostCentres
    },
    SortedapiCostCentres:function() {
    if (this.apiCostCentres != null){
        return this.filteredCostCentres.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(this.currentSort === 'account.Name'){
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
          else{
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
          else{ return null}
    },

    },
    methods: {
      onChangePhoneType(event) {
        this.SelectedPhoneType= event.target.value

        console.log(event.target.value)


        // this.$emit('account-changed',this.SelectedAccount)
      },
      accountChange: function (e) {
          this.selectedAccount = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredCostCentres.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
      // *******************************************
    // get CLI details
    // *******************************************
    async getCostCentreApi(cliid) {

      console.log(cliid)
      try {
        document.body.style.cursor = "wait";
        var url;
        url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres/' + cliid
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiHistoryCostCentre = data;
        this.costcentrenumber = this.apiHistoryCostCentre.CostCentreNumber
        console.log('got the cost centre data from api')
        console.log(this.apiHistoryCostCentre )
        this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
      // *******************************************
      // List costcentres
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        console.log('getting the costcentre list')

        var url;
        if (typeof(this.$route.query.costcentre_id) != "undefined"){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/costcentre/' + this.$route.query.costcentre_id;
            console.log(url)
            this.ShowAccount = false
            console.log('selected  CostCentre is:');
            console.log(this.$route.query.costcentre_id);
        }
        else{
            url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/costcentres`
        }
        try {
          const { data } = await this.$http.get(url);
          console.log(data)
          this.apiCostCentres = data;
          // if deleted costcentre
          if (typeof(this.$route.query.costcentrenumber) != "undefined"){
            this.costcentrenumber = this.$route.query.costcentrenumber;
            this.deleted = true;
          }
          else{
            this.getCostCentreApi(this.$route.query.costcentre_id)
          }
          if (this.apiCostCentres.length < this.pageSize){
            this.showPaging = false
          }
          console.log(this.apiCostCentres )
          console.log(this.apiCostCentres.length)
          this.executed = true;
        } catch (e) {
          this.apiCostCentres = `Error: the server responded with '${e.response}'`;
        }
      },

      // initForm() {
      //   console.log('init form');
      //     this.addCostCentreForm.Name =  null;
      //     this.addCostCentreForm.Notes = null;
      // },

      onReset(evt) {
        evt.preventDefault();
        this.$refs.addCostCentreModal.hide();
        this.initForm();
      },
    //

      editCostCentre(history) {
        console.log('editCostCentre');
        // this.callCostCentreApi(history.costcentre.AccountId);

        // console.log(cli)
        this.editCostCentreForm = history.costcentre;
        this.editCostCentreForm.modifiedby = history.modifiedby
        this.editCostCentreForm.modifiedon = history.modifiedon
        this.editCostCentreForm.action = history.method
        console.log(" EDIT cli xxxxxxxxxxxx")
        console.log(history)
        console.log(this.editCostCentreForm.history)
        // console.log(costcentre);
        // console.log(costcentre.costcentre_id);
        // this.editCostCentreForm = costcentre;
        // this.callApi()
      },


      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editCostCentreModal.hide();
        this.initForm();
        this.getCostCentres(); // why?
      },
    },
    created() {
      console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
    },
  };
  </script>
