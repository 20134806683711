import auth0 from 'auth0-js';
import axios from 'axios';
import store from '@/store/';
const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENTID;
const audience = process.env.VUE_APP_AUTH0_AUDIENCE;
const base_URL = process.env.VUE_APP_LOGOUT_URL;
const scope = "openid profile email READ ADMIN"
const britannicApis = [new URL(base_URL).hostname, 'localhost'];

const TEN_MINUTES = 10 * 60 * 1000;
let renewTokenTimeout;

const webAuth = new auth0.WebAuth({
  clientID: clientId,
  domain: domain,
  redirectUri: base_URL + "/callback",
  responseType: 'token id_token',
  audience: audience,
  scope: scope,
});

const login = (appState = {}) => {
  webAuth.authorize({ appState });
};

const logout = () => {
  localStorage.clear();
  webAuth.logout();
};

const setSession = (authResult) => {
  const { accessToken } = authResult;
  const access_token_full = Buffer.from(accessToken.split('.')[1], 'base64').toString()
  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('access_token_full', access_token_full);
  localStorage.setItem('expires_at', (JSON.parse(access_token_full)['exp'] * 1000));
};

// eslint-disable-next-line consistent-return
const handleAuthentication = async () => {
  try {
    const authResult = await extractAuthToken();
    setSession(authResult);
    // eslint-disable-next-line no-use-before-define
    // const authenticationContext = await retrieveAuthenticationContext();
    store.dispatch('handleLogin', authResult);
    // eslint-disable-next-line no-use-before-define
    startRenewTokenTimer();
    return {
      appState: authResult.appState || {},
    };
  } catch (e) {
    console.error(e);
    login();
  }
};

const extractAuthToken = () => new Promise((resolve, reject) => {
  webAuth.parseHash((err, authResult) => {
    if (err) {
      reject(err);
    } else {
      resolve(authResult);
    }
  });
});

const isAuthenticated = () => {
  // const access_token_content = JSON.parse(localStorage.getItem('access_token_full'));
  const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
  // if (new Date().getTime() > (access_token_content['exp'] * 1000)){
  //   return false
  // }
  return new Date().getTime() < expiresAt;
  };



const userFullName = () => {
    if (isAuthenticated()){
      const access_token_content = localStorage.getItem('access_token_full');
      return JSON.parse(access_token_content)['https://btlcloud.net/name'];
    }
    return ''
  };

  
const isAdmin = () => {
  if (isAuthenticated()){
    const access_token_content = localStorage.getItem('access_token_full');
    return JSON.parse(access_token_content)['permissions'].indexOf('ADMIN') !== -1;
  }
  return false
};

const editButtonText = () => {
    return 'View'
};

const startRenewTokenTimer = () => {
  if (!isAuthenticated()) {
    return;
  }

  if (renewTokenTimeout) {
    clearTimeout(renewTokenTimeout);
  }

  const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
  const timeout = expiresAt - new Date().getTime() - TEN_MINUTES;

  // eslint-disable-next-line no-use-before-define
  renewTokenTimeout = setTimeout(() => renewToken(), timeout);
};

const renewToken = () => new Promise((resolve, reject) => {
  console.log('renew');
  if (!isAuthenticated()) {
    reject(new Error('Not logged in'));
  }
  logout();
  // webAuth.checkSession({}, (err, authResult) => {
  //   if (err) {
  //     console.error('Cant renew token. Sending user to login', err);
  //     login();
  //     reject();
  //   } else {
  //     setSession(authResult);
  //     startRenewTokenTimer();
  //     resolve(authResult);
  //   }
  // });
});


const getToken = () => localStorage.getItem('access_token');

const getAuthenticationContext = () => JSON.parse(localStorage.getItem('auth_context'));

axios.defaults.headers.common['Content-Type'] = 'application/json ';
axios.defaults.headers.common.Accept = 'application/json ';

axios.interceptors.request.use((config) => {
  if (britannicApis.includes(new URL(config.url).hostname)) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (britannicApis.includes(new URL(error.config.url).hostname)) {
      if (error.response && error.response.status === 401) {
        login();
      } else if (error.response && error.response.status === 403) {
        console.log(`You don't have the permissions to access this resource: ${error.response.config.method.toUpperCase()} ${error.response.config.url}`);
      }
    }
    return Promise.reject(error);
  },
);

startRenewTokenTimer();

export {
  login,
  logout,
  renewToken,
  handleAuthentication,
  getToken,
  getAuthenticationContext,
  isAuthenticated,
  isAdmin,
  userFullName,
  editButtonText,
};
