<template>
    <div>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <p><strong>Accounts Cost Summary</strong> </p>
         <table v-show="showresult" >
            <tr v-if="apicarrier.length > 0">
                <td><button  type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>
        <br><br>
         <table class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th scope="col" @click="sort('Direction')">Name</th>
                <th scope="col" @click="sort('CallType')">Call count</th>
                <th scope="col" @click="sort('CallCount')">Duration (hh:mm:ss)</th>
                <th scope="col" @click="sort('CallCount')">Data(kb)</th>
                <th scope="col" @click="sort('Sales')">Sales</th>
                <th scope="col" @click="sort('CallCount')">Cost</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(carrierummary, index) in apicarrier" :key="index">
                <td >{{ carrierummary.Name }}</td>
                <td align="center">{{ carrierummary.CallCount }}</td>
                <td align="center">{{ carrierummary.Duration }}</td>
                <td align="center">{{ carrierummary.Data_kb }}</td>
                <td align="center">{{ carrierummary.Sales }}</td>
                <td align="center">{{ carrierummary.Cost }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'
//   import moment from 'moment';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        selectedCarrier:"",
        apipricing: null,
        apicarrier: null,
        executed: false,
        message: 'initial alert',
        currentSort:'Direction',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        loading : false,
        showresult: false
      };
    },

    computed:{
    filteredpricing() {
      console.log('filtered pricinglog');
        return this.apipricing.filter(c => {
          if(this.filter == '') return true;
          return c.Direction.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    SortedapiCDRLoads:function() {
      console.log('sort pricinglog');
      return this.filteredpricing.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredpricing.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      download : function() {
        const data = XLSX.utils.json_to_sheet(this.apicarrier)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename = 'Accounts cost summary-' + datestr + '.xlsx';
        console.log(filename);
        XLSX.writeFile(wb,filename)
        },


      // *******************************************
      // List pricing summary
      // *******************************************
      async callApi(lablex) {

        this.loading = true
        console.log(lablex)
        console.log("calling for summary ############################")
        document.body.style.cursor = 'wait';
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/accountsummary`);
          // window.EventBus.$emit('finance-calculator-display');
          document.body.style.cursor = 'auto';
          this.apicarrier = data;
          this.loading = false;
          this.showresult = true;
          console.log("got the summary ############################")
          this.executed = true;
          console.log("the carrierapi data is..........")
          console.log(this.apicarrier)
        } catch (e) {
          this.apipricing = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addpricinglogForm.Carrier =  '';
          this.addpricinglogForm.BillingName =  '';
          this.addpricinglogForm.FileName =  '';

      },
    },
    created() {
      console.log('created')
      //  // promises array
      // let promises = [];
      // // this context
      // const self = this;
      // this.$Progress.start();
      // // push into the array
      // promises.push(this.callApi());
      // // then hide the progress bar
      // Promise.all(promises).then(()=>{
      //   self.$Progress.finish();
      // })
      // this.$Progress.start();
      this.callApi();
      // this.$Progress.finish();

    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
