<template>

    <div>
      <p ><u><strong>SMS Contract List</strong> </u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.smscontract-modal>Insert SMS Contract</button>
          <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col" @click="sort('Name')" >Name</th>
                <th class="th-lg" scope="col" @click="sort('BaseCharge')" >Base Charge</th>
                <th class="th-lg" scope="col" @click="sort('InclusiveOut')" >Inclusive Out</th>
                <th class="th-lg" scope="col" @click="sort('InclusiveIn')" >Inclusive In</th>
                <th class="th-lg" scope="col" @click="sort('ModifiedBy')" >Modified by</th>
                <th scope="col" @click="sort('ModifiedOn')">Date modified</th>
                <!-- <th scope="col" @click="sort('CreatedBy')">Created by</th> -->
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(smscontract, index) in SortedapiSMSContracts" :key="index">
                <td>{{ smscontract.Name }}</td>
                <td>{{ smscontract.BaseCharge}}</td>
                <td>{{ smscontract.InclusiveOut }}</td>
                <td>{{ smscontract.InclusiveIn }}</td>
                <td>{{ smscontract.ModifiedBy }}</td>
                <td>{{ smscontract.ModifiedOn }}</td>
                <!-- <td>{{ smscontract.ModifiedBy }}</td> -->
                <td>
                  <div class="btn-group"  role="group">
                    <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-b-modal.smscontract-edit-modal
                            @click="editSMSContract(smscontract)">
                            {{editButtonText}}
                    </button>
                    <p>  </p>
                    <button v-if="isAdmin" v-on:click="onDeleteSMSContract(smscontract)"  type="button"
                            class="btn btn-danger btn-sm">
                            <!-- @click="onDeleteSMSContract(smscontract)"> -->
                        Delete
                    </button>

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="apiSMSContracts.length > pageSize"><p align="center">
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div>
        </div>
      </div>



        <!-- add modal -->
      <b-modal ref="addSMSContractModal"
              id="smscontract-modal"
              title="Insert a SMS contract"
              hide-footer size="m" >
        <b-form @submit="onSubmit" @reset="onReset" class="w-600">
        <b-form-row>
           <b-col>
             <b-form-group id="form-name-group"
                        label="Name"
                        label-for="form-Bundlename-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Bundlename-input"
                        type="text"   size="sm"
                        v-model="addSMSContractForm.Name"
                        placeholder="Enter contract name">
              </b-form-input>
              </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Description-group"
                        label="Description"
                        label-for="form-Description-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Description-input"
                        type="text"   size="sm"
                        v-model="addSMSContractForm.Description"
                        placeholder="Enter description">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-BaseCharge-group"
                        label="Base charge"
                        label-for="form-BaseCharge-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-BaseCharge-input"
                        type="text"   size="sm"
                        v-model="addSMSContractForm.BaseCharge"
                        placeholder="Enter base charge">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-ContractTerm-group"
                        label="Contract term"
                        label-for="form-ContractTerm-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-ContractTerm-input"
                        type="text"   size="sm"
                        v-model="addSMSContractForm.ContractTerm"
                        placeholder="Enter contract term">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-InclusiveOut-group"
                        label="Inclusice out"
                        label-for="form-InclusiveOut-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-InclusiveOut-input"
                        type="text"   size="sm"
                        v-model="addSMSContractForm.InclusiveOut"
                        placeholder="Enter Inclusive out">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-InclusiveIn-group"
                        label="Inclusive in"
                        label-for="form-InclusiveIn-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-InclusiveIn-input"
                        type="text"   size="sm"
                        v-model="addSMSContractForm.InclusiveIn"
                        placeholder="Enter Inclusive in">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-STDChargeOut-group"
                        label="STD charge out"
                        label-for="form-STDChargeOut-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-STDChargeOut-input"
                        type="text"   size="sm"
                        v-model="addSMSContractForm.STDChargeOut"
                        placeholder="Enter standard charge out">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-STDChargeIn-group"
                        label="STD charge in"
                        label-for="form-STDChargeIn-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-STDChargeIn-input"
                        type="text"   size="sm"
                        v-model="addSMSContractForm.STDChargeIn"
                        placeholder="Enter standard charge in">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>

      <!-- EDIT modal -->
      <b-modal ref="editSMSContractModal"
              id="smscontract-edit-modal"
              title="Update a SMS contract"
              hide-footer size="m" >
        <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
        <b-form-row>
           <b-col>
             <b-form-group id="form-name-group"
                        label="Name"
                        label-for="form-Bundlename-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Bundlename-input"
                        type="text"   size="sm"
                        v-model="editSMSContractForm.Name"
                        placeholder="Enter contract name">
              </b-form-input>
              </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Description-group"
                        label="Description"
                        label-for="form-Description-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Description-input"
                        type="text"   size="sm"
                        v-model="editSMSContractForm.Description"
                        placeholder="Enter description">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-BaseCharge-group"
                        label="Base charge"
                        label-for="form-BaseCharge-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-BaseCharge-input"
                        type="text"   size="sm"
                        v-model="editSMSContractForm.BaseCharge"
                        placeholder="Enter base charge">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-ContractTerm-group"
                        label="Contract term"
                        label-for="form-ContractTerm-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-ContractTerm-input"
                        type="text"   size="sm"
                        v-model="editSMSContractForm.ContractTerm"
                        placeholder="Enter contract term">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-InclusiveOut-group"
                        label="Inclusice out"
                        label-for="form-InclusiveOut-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-InclusiveOut-input"
                        type="text"   size="sm"
                        v-model="editSMSContractForm.InclusiveOut"
                        placeholder="Enter Inclusive out">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-InclusiveIn-group"
                        label="Inclusive in"
                        label-for="form-InclusiveIn-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-InclusiveIn-input"
                        type="text"   size="sm"
                        v-model="editSMSContractForm.InclusiveIn"
                        placeholder="Enter Inclusive in">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-STDChargeOut-group"
                        label="STD charge out"
                        label-for="form-STDChargeOut-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-STDChargeOut-input"
                        type="text"   size="sm"
                        v-model="editSMSContractForm.STDChargeOut"
                        placeholder="Enter standard charge out">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-STDChargeIn-group"
                        label="STD charge in"
                        label-for="form-STDChargeIn-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-STDChargeIn-input"
                        type="text"   size="sm"
                        v-model="editSMSContractForm.STDChargeIn"
                        placeholder="Enter standard charge in">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import CarrierDropDownVue from "../components/CarrierDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        editcomponentKey:0,
        selectedAccount:"",
        addSMSContractForm: {
          Name: '' ,
          InclusiveIn: '' ,
          BaseCharge: '' ,
          ContractTerm: '' ,
          InclusiveOut: '',
          Description:'',
          STDChargeIn:'',
          STDChargeOut:''
        },
        editSMSContractForm: {
          Name: '' ,
          InclusiveIn: '' ,
          BaseCharge: '' ,
          ContractTerm: '' ,
          InclusiveOut: '',
          Description:'',
          STDChargeIn:'',
          STDChargeOut:''
        },
        apiSMSContracts: null,
        executed: false,
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        selected_carrier: null
      };
    },

    computed:{

    filteredSMSContracts() {
      // console.log('filtered gammamarkup');
        return this.apiSMSContracts.filter(c => {
          if(this.filter == '') return true;
          if (c.BundleName == null) c.BundleName = '';
          if (c.CarrierId == null) c.CarrierId = '';
          // if (c.Carrier == null) c.Carrier = '';
          // if (c.Description == null) c.Description = '';
          return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        //  ||
        //          c.SMSCId.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
          //        c.Description.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
          //        c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    SortedapiSMSContracts:function() {
      console.log('sort mobile bundle');
      if (this.apiSMSContracts != null){
      return this.filteredSMSContracts.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toString().toLowerCase() < b[this.currentSort].toString().toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toString().toLowerCase() > b[this.currentSort].toString().toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
      }
      else{ return null}
    },
    },
    methods: {
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      forceRerender:function() {
        this.editcomponentKey += 1
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredSMSContracts.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      //////////////////////////////////////////////
      // LIST MOBILE BUNDLE METHODS
      //////////////////////////////////////////////
      async callApi(lablex) {

        console.log(lablex)
        console.log('getting the data')
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/smscontracts`);
          console.log('got sms data')
          console.log(data)
          this.apiSMSContracts = data;
          this.executed = true;
        } catch (e) {
          this.apiSMSContracts = `Error: the server responded with '${e.response}'`;
        }
      },

      //////////////////////////////////////////////
      // DELETE MOBILE BUNDLE METHODS
      //////////////////////////////////////////////
      async removeSMSContract(smscontractid) {

        console.log(smscontractid)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/smscontracts/${smscontractid}`).then(() => {
            this.message = 'account removed!';
            this.showMessage = true;
            console.log("in removeAccount after")
            this.callApi("coming from delete");

          }).catch((err) => {
            console.log("in removeAccount error")
            console.error(err);
            this.callApi();

          });

          // this.apiSMSContracts = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiSMSContracts = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteSMSContract(smscontract) {
        console.log(smscontract.SMSContractId)
        if(confirm("Are you sure you want to delete the sms contract?")){
          this.removeSMSContract(smscontract.SMSContractId);
        }
      },


      //////////////////////////////////////////////
      // ADD MOBILE BUNDLE METHODS
      //////////////////////////////////////////////

      // Add account method to post to API
      async addSMSContract(payload) {

      //   const id_token = claims.__raw;
        console.log('in add sms contract');
        console.log('payload is');
        console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/smscontracts`, payload).then(() => {
            this.message = 'account added!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated smscontract. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add smscontract. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });

          // this.apiSMSContracts = data;
          // this.executed = true;
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiSMSContracts = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addSMSContractForm.BundleName =  '';
          this.addSMSContractForm.InclusiveMinutes =  '';
          this.addSMSContractForm.BaseCharge =  '';
          this.addSMSContractForm.ContractTerm =  '';
          this.addSMSContractForm.InclusiveOut =  '';
      },
      // Add mobile bundle on submit
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addSMSContractModal.hide();

        const payload = {
          Name: this.addSMSContractForm.Name,
          InclusiveIn: this.addSMSContractForm.InclusiveIn,
          InclusiveOut: this.addSMSContractForm.InclusiveOut,
          BaseCharge: this.addSMSContractForm.BaseCharge,
          ContractTerm: this.addSMSContractForm.ContractTerm,
          Description: this.addSMSContractForm.Description,
          STDChargeIn: this.addSMSContractForm.STDChargeIn,
          STDChargeOut: this.addSMSContractForm.STDChargeOut,
        };
        this.addSMSContract(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addSMSContractModal.hide();
        this.initForm();
      },


      //////////////////////////////////////////////
      // UPDATE MOBILE BUNDLE METHODS
      //////////////////////////////////////////////
      editSMSContract(smscontract) {
        console.log('editSMSContract');
        // console.log(smscontract);
        console.log(smscontract.CarrierId)
        this.editSMSContractForm = smscontract;
        this.callApi()
      },

      // Update account on submit
      onSubmitUpdate(evt) {
        console.log('in onSubmitUpdate');
        console.log(this.selectedCarrier);
        evt.preventDefault();
        this.$refs.editSMSContractModal.hide();
        const payload = {
          Name: this.editSMSContractForm.Name,
          InclusiveIn: this.editSMSContractForm.InclusiveIn,
          InclusiveOut: this.editSMSContractForm.InclusiveOut,
          BaseCharge: this.editSMSContractForm.BaseCharge,
          ContractTerm: this.editSMSContractForm.ContractTerm,
          Description: this.editSMSContractForm.Description,
          STDChargeIn: this.editSMSContractForm.STDChargeIn,
          STDChargeOut: this.editSMSContractForm.STDChargeOut,
        };
        this.updateSMSContract(payload, this.editSMSContractForm.SMSContractId);
        this.callApi()
      },

      async updateSMSContract(payload, smscontractid) {

        // console.log('in update account');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/smscontracts/${smscontractid}`, payload).then(() => {
            // this.message = 'account updated!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated smscontracts. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add smscontracts. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi();

          });

          // this.apiSMSContracts = data;
          // this.executed = true;
          // console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiSMSContracts = `Error: the server responded with '${e.response}'`;
        }
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editSMSContractModal.hide();
        this.initForm();
        this.getAccounts(); // why?
      },
    },
    created() {
      // console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      CarrierDropDownVue: CarrierDropDownVue,
    },
  };
  </script>
  <style>
  .table .btn-group {
      white-space: nowrap;
  }
  .table .btn-group .btn {
      display: inline-block;
      float: none;
  }
  .table .btn-group .btn + .btn {
      margin-left: -5px;
  }
  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
  }
  td {
    font-size: 12px;
  }
  .btn-custom {
      width: 50px !important;
      height: 17px;
      font-size: 9px;
      font-weight: normal;
      text-align: center;
      background-color: red;
      text-emphasis-color: white;
      color: white;
  }

  </style>
