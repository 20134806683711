<template>

    <div>
      <p ><u><strong>Carriers Report</strong></u> </p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
        <button  type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button>
            <br><br>
          <!-- <input type="search" v-model="filter"> -->
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col" @click="sort('Name')" >Name</th>
                <th scope="col" @click="sort('ContactName')">Contact</th>
                <th scope="col" @click="sort('ContactEmail')">Contact Email</th>
                <th scope="col" @click="sort('URL')">URL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(carrier, index) in SortedapiCarriers" :key="index">
                <td>{{ carrier.Name }}</td>
                <td>{{ carrier.ContactName }}</td>
                <td>{{ carrier.ContactEmail }}</td>
                <td>{{ carrier.URL }}</td>
              </tr>
            </tbody>
          </table>
          <div><p align="center">
            <b-pagination
                v-model="currentPage"
                align="center"
                :total-rows="filteredCarriers.length"
                :per-page="pageSize"
                aria-controls="apirecords"
                ></b-pagination>
            </p></div>
        </div>
      </div>

    </div>
  </template>
  
  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'
  
  import {isAdmin, editButtonText} from '@/auth/AuthService'
  export default {
    Name: "Api",
    data() {
      return {
        isAdmin: isAdmin(),
        editButtonText: editButtonText(),
        // env_url: process.env.ENV_API_URL,
        addCarrierForm: {
          Name: '' ,
          BillingName: '' ,
          ContactName: null,
          ContactEmail: null,
          URL: null
        },
        editCarrierForm: {
          CarrierId: null,
          Name: '' ,
          BillingName: '' ,
          ContactName: null,
          ContactEmail: null,
          URL: null
        },
        apiCarriers: null,
        executed: false,
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
      };
    },
  
    computed:{
    filteredCarriers() {
      console.log('filtered carrier');
        return this.apiCarriers.filter(c => {
          if(this.filter == '') return true;
          // if (c.Name == null) c.Name = '';
          // if (c.ContactName == null) c.ContactName = '';
          return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 (c.ContactName && c.ContactName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) ||
                 (c.ContactEmail && c.ContactEmail.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)||
                 (c.URL && c.URL.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
        })
      },
    
  
    SortedapiCarriers:function() {
      console.log('sort carrier');
      return this.filteredCarriers.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
        download : function() {
         // customised headers
         const Heading = [
                            ['Name','Contact','Contact Email','URL']
                        ];

        // original object headers
        const objHeader = ['Name','ContactName','ContactEmail','URL'];


        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.filteredCarriers, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename = 'Carriers -' + datestr + '.xlsx';
        console.log(filename);
        XLSX.writeFile(wb,filename)
        },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredCarriers.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
  
      // *******************************************
      // List carriers
      // *******************************************
      async callApi(lablex) {
        console.log(lablex)
        try {
          console.log(process.env.VUE_APP_API_BASE_URL)
          console.log(process.env.VUE_APP_TEST)
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/carriers`);
  
          this.apiCarriers = data;
          this.executed = true;
        } catch (e) {
          this.apiCarriers = `Error: the server responded with '${e.response}'`;
        }
      },
  
    },
    created() {
      console.log('created')
      this.callApi();
  
    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
  