<template>
  <div class="text-center hero">

    <table  align="center">
      <tr class="blank_row">
        <td colspan="1"></td>
      </tr>
      <tr >
        <td></td>
        <td align="center"><img  src="/logo.bmp" alt="Vue.js logo" width="200" /> </td>
        <td></td>
      </tr>
      <tr class="blank_row">
        <td colspan="1"></td>
      </tr>
      <tr >
        <td></td>
        <td align="center"><h3 >BriBILL V2</h3> </td>
        <td></td>
      </tr>
      <br>
      
      <br>
      <tr >
        <td></td>
        <td align="center"><h4 >Current Bill Run: {{ this.apiCallBillRuns.BillRunNumber}}</h4> </td>
        <td></td>
      </tr>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>

    </table>






  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import IndustryDropDown from "../components/IndustryDropDown.vue"
import {isAdmin} from '@/auth/AuthService'

export default {
 
  name: "Hero",
  data() {
    return {
      apiCallBillRuns: {},
      isAdmin: isAdmin()
    };
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    async callApi() {
      console.log("########################GET THE BILL RUN ##############")


      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/bills/billruns`);

        console.log(data)
        // this.listCallBundle = data;
        console.log("########################GOT THE BILL RUN DATA ##############")
        this.apiCallBillRuns = data;
        this.executed = true;
        // this.reloadPage()
      } catch (e) {
        this.apiCallBillRuns = `Error: the server responded with '${e.response}'`;
      }
    },
  },
  created() {
    this.callApi();
  },
  components: {
    alert: Alert,
    listfilter:Filter,
    IndustryDropDown: IndustryDropDown
  },
};



</script>
<style>
.blank_row
{
    height: 100px ;
    background-color: #FFFFFF;
}
</style>
