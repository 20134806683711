<template>

  <div>
    <p ><u><strong>Call Bundles List</strong></u> </p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.callbundle-modal>Insert  Call Bundle</button>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('BundleName')" >Name</th>
              <th class="th-lg" scope="col" @click="sort('BundleType')" >Type</th>
              <th class="th-lg" scope="col" @click="sort('InclusiveMinutes')">Inclusive Minutes</th>
              <th scope="col" @click="sort('ModifiedOn')">Date modified</th>
              <th scope="col" @click="sort('ModifiedBy')">Modified by</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(callbundle, index) in SortedapiCallBundles" :key="index">
              <td>{{ callbundle.BundleName }}</td>
              <td>{{ callbundle.BundleType }}</td>
              <td>{{ callbundle.InclusiveMinutes }}</td>
              <td>{{ callbundle.ModifiedOn }}</td>
              <td>{{ callbundle.ModifiedBy }}</td>
              <td>
                <div class="btn-group"  role="group">
                  <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-b-modal.callbundle-update-modal
                          @click="editCallBundle(callbundle)">
                          {{editButtonText}}
                  </button>
                  <p>  </p>
                  <button v-if="isAdmin" v-on:click="onDeleteCallBundle(callbundle)"  type="button"
                          class="btn btn-danger btn-sm">
                      Delete
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div><p align="center">
          <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button>
          </p></div>
      </div>
    </div>


  <!-- ADD chargeband modal -->
    <b-modal ref="addBundleChargebandModal"
            id="chargeband-modal"
            title="Insert chargeband to bundle"
            hide-footer size="sm" >
      <b-form @submit="onChargeBandSubmit" @reset="onResetMember"  class="w-1700">
      <b-form-row>
        <b-col>
            <div > <center>
              <ChargeBandDropDown  @chargeband-changed="chargebandChange"></ChargeBandDropDown>
            </center>
            <br>
        </div>
        </b-col>
      </b-form-row>
      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
    <!-- ADD modal -->
    <b-modal ref="addCallBundleModal"
            id="callbundle-modal"
            title="Insert a new call bundle"
            hide-footer size="m" >
      <b-form @submit="onSubmit" @reset="onReset" class="w-200">
      <b-form-row>
        <b-col>
            <b-form-group id="form-Bundlename-group"
                    label="Name"
                    label-for="form-Bundlename-input" label-cols="4" label-cols-sm="3" label-size="sm">
            <b-form-input id="form-Bundlename-input"
                            type="text"   size="sm"
                            v-model="addCallBundleForm.BundleName"
                            required
                            placeholder="Enter Name" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>


      <b-form-row>
        <b-col>
            <b-form-group id="form-BundleType-group"
                      label="Bundle type"
                      label-for="form-BundleType-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div class="dropdown" align="left">
                <select @change="onChangeBundleType($event)">
                  <option value="">Select bundle type</option>
                  <option
                    v-for="(type, index) in bundletypes"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedBundleType">Selected type - {{ this.SelectedBundleType }}</p> -->
              </div>
            </b-form-group>
        </b-col>
        </b-form-row>
        <b-form-row>
        <b-col>
             <b-form-group id="form-BundleCost-group"
                      label="BundleCost"
                      label-for="form-BundleCost-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-BundleCost-input"
                      type="text"   size="sm"
                      v-model="addCallBundleForm.BundleCost"
                      required
                      placeholder="Enter BundleCost">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
            <b-form-group id="form-InclusiveMinutes-group"
                      label="InclusiveMinutes"
                      label-for="form-InclusiveMinutes-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-InclusiveMinutes-input"
                      type="text"   size="sm"
                      v-model="addCallBundleForm.InclusiveMinutes"
                      required
                      placeholder="Enter InclusiveMinutes">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
       <b-form-row>
          <b-col>
           <b-form-group id="form-Carrier-group"
            label="Carrier"
                      label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div >
                <CarrierDropDownVue @carrier-changed="carrierChange"></CarrierDropDownVue>
        </div>
        </b-form-group>
        </b-col>
      </b-form-row>

       <b-form-row>
        <b-col>
            <b-form-group id="form-Direction-group"
                      label="Direction"
                      label-for="form-Direction-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div class="dropdown" align="left">
                <select @change="onChangeDirection($event)">
                  <option value="">Select call direction</option>
                  <option
                    v-for="(type, index) in bundledirection"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </b-form-group>
        </b-col>
        </b-form-row>
        <b-form-row>
           <b-col>
            <b-form-group id="form-GroupBundle-group"
                      label="Group bundle"
                      label-for="form-GroupBundle-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div class="dropdown" align="left">
              <b-form-checkbox id="GroupBundle"
                  v-model="addCallBundleForm.GroupBundle" name="GroupBundle">
            </b-form-checkbox>
            </div>
            </b-form-group>
        </b-col>
        </b-form-row>
      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal :key="editcomponentKey" ref="editCallBundleModal"
            id="callbundle-update-modal"
            title="Update call bundle"
            hide-footer size="m" >
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
          <b-form-row>
        <b-col>
            <b-form-group id="form-Bundlename-group"
                    label="Name"
                    label-for="form-Bundlename-input" label-cols="4" label-cols-sm="3" label-size="sm">
            <b-form-input id="form-Bundlename-input"
                            type="text"   size="sm"
                            v-model="editCallBundleForm.BundleName"
                            required
                            placeholder="Enter Name" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>


      <b-form-row>
        <b-col>
            <b-form-group id="form-BundleType-group"
                      label="Bundle type"
                      label-for="form-BundleType-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div class="dropdown" align="left">
                <select v-model="editCallBundleForm.BundleType" @change="onChangeBundleType($event)">
                  <option value="">Select bundle type</option>
                  <option
                    v-for="(type, index) in bundletypes"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </b-form-group>
        </b-col>
        </b-form-row>
        <b-form-row>
        <b-col>
             <b-form-group id="form-BundleCost-group"
                      label="BundleCost"
                      label-for="form-BundleCost-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-BundleCost-input"
                      type="text"   size="sm"
                      v-model="editCallBundleForm.BundleCost"
                      required
                      placeholder="Enter BundleCost">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
            <b-form-group id="form-InclusiveMinutes-group"
                      label="InclusiveMinutes"
                      label-for="form-InclusiveMinutes-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-InclusiveMinutes-input"
                      type="text"   size="sm"
                      v-model="editCallBundleForm.InclusiveMinutes"
                      required
                      placeholder="Enter InclusiveMinutes">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
       <b-form-row>
          <b-col>
           <b-form-group id="form-Carrier-group"
            label="Carrier"
                      label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div >
                <CarrierDropDownVue v-bind:selected_carrier="editCallBundleForm.CarrierId" @carrier-changed="carrierChange"></CarrierDropDownVue>
        </div>
        </b-form-group>
        </b-col>
      </b-form-row>

       <b-form-row>
        <b-col>
            <b-form-group id="form-Direction-group"
                      label="Direction"
                      label-for="form-Direction-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div class="dropdown" align="left">
                <select v-model="editCallBundleForm.Direction" @change="onChangeDirection($event)">
                  <option value="">Select call direction</option>
                  <option
                    v-for="(type, index) in bundledirection"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </b-form-group>
        </b-col>
        </b-form-row>
        <b-form-row>
           <b-col>
            <b-form-group id="form-GroupBundle-group"
                      label="Group bundle"
                      label-for="form-GroupBundle-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div class="dropdown" align="left">
              <b-form-checkbox id="GroupBundle"
                  v-model="editCallBundleForm.GroupBundle" name="GroupBundle">
            </b-form-checkbox>
            </div>
            </b-form-group>
        </b-col>
        </b-form-row>
      <b-form-row>
          <p>Bundle Chargebands</p>
      </b-form-row>
      <b-form-row>
          <b-col cols sm="2"  lg="auto">
          <table class="table table-small w-auto" >
          <thead>
            <tr>
              <th class="th-xs"  scope="col" >Chargeband</th>
               <th class="th-xs"   scope="col" >Added on</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(bundlechargeband, index) in editCallBundleForm.chargebands" :key="index">
              <td>{{ bundlechargeband.ChargeBand }}</td>
              <td>{{ bundlechargeband.ModifiedOn }}</td>
              <td>
                  <button v-if="isAdmin"
                          type="button"
                          class="btn-custom"
                          title="Remove"
                          @click="onDeleteChargeband(bundlechargeband.BCBId)">
                      Remove
                  </button>
              </td>
            </tr>
          </tbody>
        </table>
        </b-col>
        <b-col>
            <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.chargeband-modal>Insert chargeband</button>
        </b-col>
      </b-form-row>
        <div class="container">
        <div class="row" v-if="isAdmin">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";
import CarrierDropDownVue from "../components/CarrierDropDown.vue";
import ChargeBandDropDown from "../components/ChargeBandDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      bundletypes: [{ "id":"NATIONAL", "value":'National      '}, {"id":"INTERNATIONAL","value":'International'}, {"id":"NONGEO","value":'NonGeo'}],
      SelectedBundleType: '',
      bundledirection: [{ "id":"O", "value":'Outgoing'}, {"id":"I","value":'Incoming'}],
      SelectedDirection: '',
      editcomponentKey:0,
      selectedChargeBand:"",
      addCallBundleForm: {
        BundleName: '' ,
        BundleType: '' ,
        BundleCost: '' ,
        InclusiveMinutes: '' ,
        Direction: '' ,
        CarrierId: '' ,
        GroupBundle: ''
      },
      editCallBundleForm: {
        BundleId : '',
        BundleName: '' ,
        BundleType: '' ,
        BundleCost: '' ,
        InclusiveMinutes: '' ,
        Direction: '' ,
        CarrierId: '' ,
        GroupBundle: ''
      },
      apiCallBundles: null,
      executed: false,
      message: 'initial alert',
      currentSort:'BundleName',
      currentSortDir:'asc',
      pageSize:60,
      currentPage:1,
      filter:'',
    };
  },

  computed:{
  filteredAccounts() {
    console.log('filtered callbundle');
      return this.apiCallBundles.filter(c => {
        if(this.filter == '') return true;
        return c.BundleName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.BundleType.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiCallBundles:function() {
    console.log('sort call bundle');
    if (this.apiCallBundles != null){
    return this.filteredAccounts.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if(a[this.currentSort].toString().toLowerCase() < b[this.currentSort].toString().toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toString().toLowerCase() > b[this.currentSort].toString().toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
    }
    else{ return null}
  },
  },
  methods: {
    onChangeBundleType(event) {
      this.SelectedBundleType= event.target.value
    },
    onChangeDirection(event) {
      this.SelectedDirection= event.target.value
    },
    carrierChange: function (e) {
        this.selectedCarrier = e;
    },
    chargebandChange: function (e) {
        this.selectedChargeBand = e;
    },
    forceRerender:function() {
      this.editcomponentKey += 1
    },
    callbundleChange: function (e) {
        this.selectedAccount = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredAccounts.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    //////////////////////////////////////////////
    // LIST CALL BUNDLE METHODS
    //////////////////////////////////////////////
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the data')
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/callbundles`);
        console.log('got call bundle data')
         console.log(data)
        this.apiCallBundles = data;
        this.executed = true;
      } catch (e) {
        this.apiCallBundles = `Error: the server responded with '${e.response}'`;
      }
    },

    //////////////////////////////////////////////
    // DELETE CALL BUNDLE METHODS
    //////////////////////////////////////////////
    async removeCallBundle(callbundleid) {

      console.log("in removeCallBundle before")
      console.log(callbundleid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/callbundles/${callbundleid}`).then(() => {
          this.message = 'callbundle removed!';
          this.showMessage = true;
          console.log("in removeCallBundle after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeCallBundle error")
          console.error(err);
          this.callApi();

        });

        // this.apiCallBundles = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCallBundles = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteCallBundle(callbundle) {
      console.log(callbundle.BundleId)
      if(confirm("Are you sure you want to delete the call bundle?")){
        this.removeCallBundle(callbundle.BundleId);
      }
    },

    //////////////////////////////////////////////
    // DELETE MEMBER FROM CALL BUNDLE METHODS
    //////////////////////////////////////////////
    async removeCallBundleChargeband(callbundleid,bundlechargebandid) {

      console.log("in remove Accountgroup member before")
      console.log(bundlechargebandid)
    //   const  payload = {};
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/callbundles/${callbundleid}/chargebands/${bundlechargebandid}`).then(() => {
          this.message = 'callbundle removed!';
          this.showMessage = true;
          console.log("in removeCallBundle after")
        //   this.forceRerender();
          this.callApi("coming from delete");
          this.$refs.editCallBundleModal.hide()

        }).catch((err) => {
          console.log("in removeCallBundle error")
          console.error(err);
          this.callApi();

        });

        // this.apiCallBundles = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCallBundles = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteChargeband(bundlechargebandid) {
      console.log(bundlechargebandid)
      this.removeCallBundleChargeband(this.editCallBundleForm.BundleId, bundlechargebandid);
    },

    //////////////////////////////////////////////
    // ADD CALL BUNDLE METHODS
    //////////////////////////////////////////////

    // Add callbundle method to post to API
    async addCallBundle(payload) {

    //   const id_token = claims.__raw;
      console.log('in add callbundle');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/callbundles`, payload).then(() => {
          this.message = 'callbundle added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated callbundle. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add callbundle. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apiCallBundles = data;
        // this.executed = true;
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCallBundles = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addCallBundleForm.GroupBundle =  false;
        this.addCallBundleForm.Name =  '';
        this.addCallBundleForm.AccountNumber =  '';
        this.addCallBundleForm.Address1 =  '';
        this.addCallBundleForm.Address2 =  '';
        this.addCallBundleForm.PostCode =  '';
        this.addCallBundleForm.City =  '';
        this.addCallBundleForm.County = '';
        this.addCallBundleForm.Country = '';
        this.addCallBundleForm.SaleAgent = '';
        this.addCallBundleForm.PaymentTerm = 7;
        this.addCallBundleForm.ContactName = '';
        this.addCallBundleForm.SageAccount = '';
        this.addCallBundleForm.NetXBundleId = '';
        this.addCallBundleForm.Email = '';
        this.addCallBundleForm.MaintenaceOnly =  false;
        this.addCallBundleForm.BTN = '';
        this.addCallBundleForm.PhoneUsers =  10;
        this.addCallBundleForm.Taxable =  false;
        this.addCallBundleForm.Roundup =  false;
        this.addCallBundleForm.DirectDebit =  false;
        this.addCallBundleForm.BonusEligible =  false;
        this.addCallBundleForm.FaxToEmailCost =  0.05;
        this.addCallBundleForm.AudioConfPct =  15;
        this.addCallBundleForm.VideoConfPct =  25;

    },
    // Add call bundle on submit
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addCallBundleModal.hide();
      // ***********this need to be looked at again as the check**************
      // box value is not presented when not selected
      if(this.addCallBundleForm.BundleType== "") this.addCallBundleForm.BundleType= false;
      const payload = {
        BundleName: this.addCallBundleForm.BundleName ,
        BundleType: this.SelectedBundleType ,
        BundleCost: this.addCallBundleForm.BundleCost ,
        InclusiveMinutes: this.addCallBundleForm.InclusiveMinutes ,
        Direction: this.SelectedDirection ,
        CarrierId: this.selectedCarrier,
        GroupBundle: this.addCallBundleForm.GroupBundle
      };
      this.addCallBundle(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addCallBundleModal.hide();
      this.initForm();
    },

    //////////////////////////////////////////////
    // ADD CHARGEBAND METHODS
    //////////////////////////////////////////////
    onChargeBandSubmit(evt) {
      console.log('on chargeband submit');
      evt.preventDefault();
      this.$refs.addBundleChargebandModal.hide();

      const chargebandid = this.selectedChargeBand;
      console.log('chargeband is');
      console.log(chargebandid);
      console.log('callbundle is');
      console.log(this.editCallBundleForm.BundleId);

      this.addBundleChargeband(this.editCallBundleForm.BundleId,chargebandid);
      this.initForm();
    },
    onResetMember(evt) {
      evt.preventDefault();
      this.$refs.addBundleChargebandModal.hide();
      this.initForm();
    },
    // Add callbundle member method to post to API
    async addBundleChargeband(callbundleid,chargebandid) {

    //   const id_token = claims.__raw;
      console.log('in add callbundle');
      console.log('payload is');
      console.log(chargebandid);
      const payload = {
        ChargeBand:chargebandid
      };
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/callbundles/${callbundleid}/chargebands`, payload).then(() => {
          this.message = 'callbundle added!';
          this.showMessage = true;
          this.callApi();
          this.$refs.editCallBundleModal.hide()

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated callbundle. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add member to callbundle. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apiCallBundles = data;
        // this.executed = true;
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCallBundles = `Error: the server responded with '${e.response}'`;
      }
    },
    //////////////////////////////////////////////
    // UPDATE CALL BUNDLE METHODS
    //////////////////////////////////////////////
    editCallBundle(callbundle) {
      console.log('editCallBundle');
      console.log(callbundle);
      console.log(callbundle.bundle_id);
      this.editCallBundleForm = callbundle;
      this.SelectedBundleType = callbundle.BundleType
      this.SelectedDirection = callbundle.Direction
      this.callApi()
    },

    // Update callbundle on submit
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('BundleId');
      evt.preventDefault();
      this.$refs.editCallBundleModal.hide();
      // ***********this need to be looked at again as the check**************
      // box value is not presented when not selected
      if(this.editCallBundleForm.BundleType== "") this.editCallBundleForm.BundleType= false;
      const payload = {
        BundleName: this.editCallBundleForm.BundleName ,
        BundleType: this.SelectedBundleType ,
        BundleCost: this.editCallBundleForm.BundleCost ,
        InclusiveMinutes: this.editCallBundleForm.InclusiveMinutes ,
        Direction: this.SelectedDirection ,
        CarrierId: this.selectedCarrier,
        GroupBundle: this.editCallBundleForm.GroupBundle
      };
      this.updateCallBundle(payload, this.editCallBundleForm.BundleId);
      this.callApi()
    },

    async updateCallBundle(payload, callbundleid) {

      console.log('in update callbundle');
      console.log(callbundleid);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/callbundles/${callbundleid}`, payload).then(() => {
          this.message = 'callbundle updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated callbundle. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add callbundle. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiCallBundles = data;
        // this.executed = true;
        // console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCallBundles = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editCallBundleModal.hide();
      this.initForm();
      this.getAccounts(); // why?
    },
  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
    CarrierDropDownVue: CarrierDropDownVue,
    ChargeBandDropDown: ChargeBandDropDown,
  },
};
</script>
<style>
.table .btn-group {
	white-space: nowrap;
}
.table .btn-group .btn {
	display: inline-block;
	float: none;
}
.table .btn-group .btn + .btn {
	margin-left: -5px;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: normal;
  text-align: center;
  font-size: 14px;
}
td {
  font-size: 12px;
}
.btn-custom {
    width: 50px !important;
    height: 17px;
    font-size: 9px;
    font-weight: normal;
    text-align: center;
    background-color: red;
    text-emphasis-color: white;
    color: white;
}

</style>
