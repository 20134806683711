<template>
  <div class="cascading-dropdown">
    <div class="dropdown">
      <select @change="onChangeChargeBand($event)" v-model="selected_chargeband">
        <option value="">Select a chargeband</option>
        <option
          v-for="(chargeband, index) in apiChargeBands"
          :value="chargeband.Name"
          :key="index"
        >
          {{ chargeband.Name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  props:{
    group_exclude:{
      type: Boolean,
      default: false},
    selected_chargeband:{
      type: String,
      default: ""},
    },
  data() {
    return {
      listChargeBand: [],
      selectedChargeBand: "",
      authToken: "",
      currentSort:'Name',
      currentSortDir:'asc',
      apiChargeBands: null,
    };
  },
  methods: {
    onChangeChargeBand(event) {
      this.selectedChargeBand = event.target.value
      console.log('in dropdown')
      console.log(this.selectedChargeBand)
      this.$emit('chargeband-changed',this.selectedChargeBand)
    },

    async callApi() {
      try {
        const { data } = await this.$http.get(process.env.VUE_APP_API_BASE_URL + '/api/v1/chargebands?q={"filters":[{"name":"AccountId","op":"is_null","val":""}]}');

        // console.log(data)
        // this.listChargeBand = data;
        this.apiChargeBands = data;
        this.executed = true;
      } catch (e) {
        this.listChargeBand = `Error: the server responded with '${e.response}'`;
      }
    },
  },
  created() {
    this.callApi();
  }
};
</script>
