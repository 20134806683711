<template>
    <div class="cascading-dropdown">
      <div class="dropdown">
        <select style="width: 100%" @change="onChangeCategory($event)" v-model="selected_category" :disabled="disableOption">
          <option value="">Select category</option>
          <option
            v-for="(category, index) in listCategory"
            :value="category.CategoryId"
            :key="index"
          >
            {{ category.Name }}
          </option>
        </select>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "App",
    props:{
      group_exclude:{
        type: Boolean,
        default: false},
        selected_category:{
        type: String,
        default: ""},
        disableOption: {
          type: Boolean,
          required: false,
          default: false
          },
      },
    data() {
      return {
        listCategory: [],
        SelectedCategory: "",
        authToken: "",
      };
    },
    methods: {
      onChangeCategory(event) {
        this.SelectedCategory = event.target.value
        console.log(event)
        this.$emit('category-changed',this.SelectedCategory)
      },

      async callApi() {
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/categories`);
          this.listCategory = data;
          this.executed = true;
        } catch (e) {
          this.listCategory = `Error: the server responded with '${e.response}'`;
        }
      },
    },
    created() {
      this.callApi();
      console.log("disable btn is")
      console.log(this.disableOption)
      console.log('the selected category in dorpdown is:');
      console.log(this.selected_category);
    }
  };
  </script>
