<template>
    <div>
      <p><u><strong>Invoices</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
          <div v-if="isAdmin">
            <div v-if="apifinalpost.OrdersTransferred">
            
            <div v-if="this.apiInvoiceLog.Name==='INVOICE GENERATION COMPLETED'" >
              <button type="button" class="btn btn-success btn-sm" @click="onStartBills()">Generate Invoices</button>          
              <button type="button"  style="margin-left :50px" class="btn btn-success btn-sm" v-b-modal.invoicedetails-modal @click="editInvoiceDetails()">Invoice Email Details</button>
              <button style="margin-left :400px" type="button" class="btn btn-success btn-sm" v-on:click="onStartInvoiceEmail()" >Email Invoices</button>
            </div>
            <div v-else>
              <button type="button" class="btn btn-success btn-sm" @click="onStartBills()">Generate Invoices</button>
              <button type="button"  style="margin-left :50px" class="btn btn-success btn-sm" v-b-modal.invoicedetails-modal @click="editInvoiceDetails()">Invoice Email Details</button>
            </div>
            </div>
            <div v-else>
              <button type="button"   class="btn btn-success btn-sm" v-b-modal.invoicedetails-modal @click="editInvoiceDetails()">Invoice Email Details</button>
            </div>
          </div>
            <br>
          <p  v-if="this.apiInvoiceLog.Name==='INVOICE GENERATION COMPLETED'" style="color:blue;font-size:16px;text-align:left;"> {{ this.apiInvoiceLog.Name }}  --   Finished on: {{ this.apiInvoiceLog.FinishedOn }} </p>
          <p  v-else-if="this.apiInvoiceLog.Name" style="color:blue;font-size:16px;text-align:left;"> {{ this.apiInvoiceLog.Name }} -- Started on: {{ this.apiInvoiceLog.StartedOn }}  </p>
          <p  v-else style="color:blue;font-size:16px;text-align:left;"> </p>
            <br>
          <input type="search" v-model="filter">

          <div v-if="this.apiInvoiceLog.Name==='INVOICE GENERATION COMPLETED'" align="right" >
            <button  v-on:click="onDownloadAll()">Download All</button>
          </div>

          <table class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th scope="col" @click="sort('AccountNumber')">Account No</th>
                <th scope="col" @click="sort('AccountName')">Account Name</th>
                <th class="th-sm" scope="col" @click="sort('SageAccount')">Sage Ref</th>
                <th scope="col" >Value</th>
                <th class="th-lg" scope="col" @click="sort('BillNumber')" >Bill no.</th>
                <th class="th-lg" scope="col" @click="sort('InvoiceNo')" >Invoice no.</th>
                <th scope="col" >File Name</th>
                <th scope="col"  @click="sort('Status')">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(invoice, index) in SortedapiBillLogs" :key="index">
                <td v-if="invoice.AccountName">{{ invoice.AccountNumber }}</td>
                <td v-else>{{ invoice.GroupNo }}</td>
                <td v-if="invoice.AccountName">{{ invoice.AccountName }}</td>
                <td v-else>{{ invoice.GroupName }}</td>
                <td >{{ invoice.SageAccount }}</td>
                <td >{{ invoice.InvoiceValue }}</td>
                <td >{{ invoice.BillNumber }}</td>
                <td >{{ invoice.InvoiceNo }}</td>
                <td >{{ invoice.FileName }}</td>
                <td>{{ invoice.Status }}</td>
                <td><button v-on:click="onDownloadInvoice(invoice.FileName)">Download</button></td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredbilllogs.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
          <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
      </div>
        </div>
      </div>

      <b-modal ref="editInvoiceDetails"
              id="invoicedetails-modal"
              title="Invoice Email Details"
              hide-footer size="m" >
              <b-form @submit="onSubmit" @reset="onReset" style="width: 200">
                <b-form-row>
                  <b-col>
                    <b-form-group id="form-title-group"
                                label="DD Collection Date"
                                label-for="form-title-input">
                      <b-form-input id="form-title-input"
                                    type="text"
                                    v-model="editInvoiceDetailsForm.DDCDate"
                                    required
                                    placeholder="Insert DD collection date">
                      </b-form-input>
                    </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
          <b-form-group id="form-title-group"
                      label="Advert"
                      label-for="form-title-input">
            <b-form-textarea id="form-title-input"
                          rows="3"
                          max-rows="6"
                          v-model="editInvoiceDetailsForm.AddText"
                          required
                          placeholder="Insert advert">
            </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-form-row>
          <b-form-row>
            <b-col>
        <b-form-group id="form-title-group"
                      label="Subject"
                      label-for="form-title-input">
                      <b-form-textarea id="form-title-input"
                          rows="3"
                          max-rows="6"
                          v-model="editInvoiceDetailsForm.EmailSubject"
                          required
                          placeholder="Insert subject">
                        </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-form-row>
          <b-form-row>
            <b-col>
          <b-form-group id="form-author-group"
                        label="Body"
                        label-for="form-author-input">
              <b-form-textarea id="form-author-input"
                            rows="3"
                            max-rows="6"
                            v-model="editInvoiceDetailsForm.EmailBody"
                            required
                            placeholder="Insert body">
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
          <!-- <b-button-group>
            <b-button v-if="isAdmin" type="submit" variant="primary" align="centre">Submit</b-button>
          </b-button-group> -->
          <div class="container">
          <div class="row">
              <div class="col text-center" v-if="isAdmin">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import CarrierDropDownVue from "../components/CarrierDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        timer1: "",
        timer2: "",
        timer3: "",
        selectedCarrier:"",
        addbilllogForm: {
          Carrier: '' ,
          CusBillingName: '' ,
          FileName: ''

        },
        editInvoiceDetailsForm: {
          DetailId: '',
          DDCDate: '',
          AddText: '',
          EmailBody: '',
          EmailSubject: ''
        },
        apiInvoices: [],
        apiInvoiceLog:{},
        executed: false,
        message: 'initial alert',
        currentSort:'CreatedOn',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:false,
        currentPage:1,
        apifinalpost:null,
        filter:'',
      };
    },

    computed:{
    filteredbilllogs() {
      console.log('filtered billlog');
        return this.apiInvoices.filter(c => {
          if(this.filter == '') return true;
        if (c.AccountNumber == null) c.AccountNumber = '';
        if (c.GroupNo == null) c.GroupNo = '';
        if (c.AccountName == null) c.AccountName = '';
        if (c.GroupName == null) c.GroupName = '';
        if (c.SageAccount == null) c.SageAccount = '';
        if (c.BillNumber == null) c.BillNumber = '';
        return c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.GroupNo.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.GroupName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.SageAccount.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.BillNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
      },
    SortedapiBillLogs:function() {
      console.log('sort billlog');
      return this.filteredbilllogs.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      // on download all method
      onDownloadAll() {
      console.log()
      this.downloadInvoice('all');
      },
      // on download method
      onDownloadInvoice(invoicename) {
      console.log(invoicename)
      this.downloadInvoice(invoicename);
      },
      // api get download invoice method
      async downloadInvoice(invoicename) {

        console.log('***********************download*****************************')

        try {
          document.body.style.cursor = "wait";
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoices/download/` + invoicename, {responseType: 'blob'});
          document.body.style.cursor = 'auto';

          this.executed = true;
          var fileURL = window.URL.createObjectURL(new Blob([data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          if (invoicename == 'all'){
            invoicename = 'invoices.zip';
          }
          fileLink.setAttribute('download', invoicename);
          // fileLink.setAttribute('download', 'UploadRecurringCharges_12.txt');
          document.body.appendChild(fileLink);

          fileLink.click();

        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
      },
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredbilllogs.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
            // *******************************************
      // Start email csv
      // *******************************************
      onStartInvoiceEmail() {
        console.log('on start email');
        this.StartInvoiceEmail();
      },
       // *******************************************
      // start csv email on submit
      // *******************************************
      // start csv email method to post to API
      async StartInvoiceEmail() {

        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoices/emailinvoices`, null).then(() => {
            this.message = 'email started!';
            this.showMessage = true;

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated billlog. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add billlog. status code:  " + err.response.status)
              }
            console.error(err);

          });

          // this.apiexportcsv = data;
          // this.executed = true;
          console.log(data)
          //this.callApi()
        } catch (e) {
          this.apiexportcsv = `Error: the server responded with '${e.response}'`;
        }
      },
      // *******************************************
      // check orders transfer
      // *******************************************
      async callCheckTransferApi() {
        
        // console.log(lablex)
        console.log('invoice getting the data')
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/finalposts/checkordertransfer`);

          this.apifinalpost = data;
          console.log("invoice get transfer @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
          console.log('the check transfer data is....')
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apifinalpost = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // Get invoice log
      // *******************************************
      async callInvoiceLogApi() {

        // console.log(lablex)
        console.log('***********************getting the data')

        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoices/logs`);

          this.apiInvoiceLog = data;
          console.log("invoice log returned................");
          console.log(data);
          this.executed = true;
          // stop the autorefresh
          // if (this.apiInvoiceLog.Name==='INVOICE GENERATION COMPLETED'){
          //   console.log("inside stop auto refresh")
          //   this.cancelAutoUpdate();
          // }
        } catch (e) {
          this.apiInvoiceLog = `Error: the server responded with '${e.response}'`;
        }
      },
      // *******************************************
      // get invoice details
      // *******************************************
      async callDetailsApi(lablex) {

        console.log(lablex)
        var url;
        url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoicedetails?q=INVOICE`;

        try {
          const { data } = await this.$http.get(url);

          this.apiInvoiceDetail = data;
          console.log('this.apiInvoiceDetail' )
          console.log(this.apiInvoiceDetail )
          this.editInvoiceDetailsForm= this.apiInvoiceDetail ;
          this.executed = true;
        } catch (e) {
          this.apiInvoiceDetail = `Error: the server responded with '${e.response}'`;
        }
        },
        editInvoiceDetails() {
        console.log('editIndustryType');
        this.editInvoiceDetailsForm = this.apiInvoiceDetail;
      },
      // *******************************************
      // update invoice details on submit
      // *******************************************
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.editInvoiceDetails.hide();
        const payload = {
          DDCDate: this.editInvoiceDetailsForm.DDCDate,
          AddText: this.editInvoiceDetailsForm.AddText,
          EmailSubject: this.editInvoiceDetailsForm.EmailSubject,
          EmailBody: this.editInvoiceDetailsForm.EmailBody,
        };
        this.updateInvoiceDetail(payload, this.editInvoiceDetailsForm.DetailId);
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.editInvoiceDetails.hide();
      },
      // *******************************************
      // Update invoice detailson submit
      // *******************************************
      async updateInvoiceDetail(payload, detailid) {

      console.log('in update invoiceadd');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoicedetails/${detailid}`, payload).then(() => {
          this.message = 'invoiceadetails updated!';
          this.showMessage = true;

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated invoiceadd. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add invoiceadd. status code:  " + err.response.status)
            }

          console.error(err);
        });
        console.log('delete data is:')
        console.log(data)
        this.callDetailsApi();
        // this.callApi()
      } catch (e) {
        this.apiInvoiceAdds = `Error: the server responded with '${e.response}'`;
      }
      },
      // *******************************************
      // List invoice logs
      // *******************************************
      async callApi() {

        // console.log(lablex)
        console.log('***********************getting the data')

        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoices`);

          this.apiInvoices = data;
          console.log(data);
          console.log("invoice returned................");
          this.executed = true;
          if (this.apiInvoices.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
      },
      // Add invoicelog method to post to API
      async addinvoicelog() {

        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoices`, null).then(() => {
            this.message = 'invoices started!';
            this.showMessage = true;
            this.callApi();
            this.callInvoiceLogApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated invoicelog. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add invoicelog. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });

          // this.apiInvoices = data;
          // this.executed = true;
          console.log(data)
          //this.callApi()
        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addbilllogForm.Carrier =  '';
          this.addbilllogForm.BillingName =  '';
          this.addbilllogForm.FileName =  '';

      },
      // *******************************************
      // Add billlog on submit
      // *******************************************
      onStartBills() {
        console.log('on start invoices');
        this.addinvoicelog();
        //this.initForm();
      },
      // onReset(evt) {
      //   evt.preventDefault();
      //   this.$refs.addbilllogModal.hide();
      //   this.initForm();
      // },
    //
      onResetLoad() {
        console.log('onresetload')
        if(confirm("Are you sure you want to reset all CDR loads?")){
          this.resetLoad();
        }

      },
      // reset load
      // *******************************************
      async resetLoad() {

        console.log("in reset load before")
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing/reset`).then(() => {
            this.message = 'account removed!';
            this.showMessage = true;
            console.log("in removeAccount after")
            this.callApi("coming from delete");

          }).catch((err) => {
            console.log("in removeAccount error")
            console.error(err);
            this.callApi();

          });

          // this.apiAccounts = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiAccounts = `Error: the server responded with '${e.response}'`;
        }
      },
      cancelAutoUpdate() {
        console.log("invoice clear interval @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
        clearInterval(this.timer1);
        clearInterval(this.timer2);
        clearInterval(this.timer3);
      },
    },
    created() {
      console.log('created')
      this.timer1 = setInterval(this.callApi, 20000);
      this.timer2 = setInterval(this.callInvoiceLogApi, 20000);
      this.timer3 = setInterval(this.callCheckTransferApi, 20000);
      this.callCheckTransferApi();
      this.callDetailsApi();
      this.callApi();
      this.callInvoiceLogApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      CarrierDropDownVue: CarrierDropDownVue,
    },
    beforeDestroy() {
    this.cancelAutoUpdate();
  },
  };
  </script>
