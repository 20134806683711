<template>

  <div>
    <p><u><strong>Base Rates - Domestic</strong></u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.chargeband-modal>Insert domestic base
          rate</button>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('Name')">Charge Band</th>
              <th class="th-sm" scope="col" @click="sort('Description')">Description</th>
              <th class="th-sm" scope="col" @click="sort('SellDayRate')">Sell Day Rate </th>
              <th class="th-sm" scope="col" @click="sort('SellEveRate')">Sell Eve Rate </th>
              <th class="th-sm" scope="col" @click="sort('SellWendRate')">Sell W/E Rate </th>
              <th class="th-sm" scope="col" @click="sort('SellConFee')">Sell Con Fee </th>
              <th class="th-sm" scope="col" @click="sort('CodeCount')">Prefixes </th>
              <th scope="col" @click="sort('ModifiedOn')">Modified On</th>
              <th scope="col" @click="sort('ModifiedBy')">User</th>
              <th> <button v-if="isAdmin" type="button" class="btn btn-success btn-sm"
                  v-b-modal.chargeband-load-modal>Load domestic base rates</button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(chargeband, index) in SortedapiChargeBands" :key="index">
              <td>{{ chargeband.Name }}</td>
              <td>{{ chargeband.Description }}</td>
              <td>{{ chargeband.SellDayRate }}</td>
              <td>{{ chargeband.SellEveRate }}</td>
              <td>{{ chargeband.SellWendRate }}</td>
              <td>{{ chargeband.SellConFee }}</td>
              <td align="center"><a :href="'/chargebandcodes?CBId=' + chargeband.CBId"
                  target="_blank">{{chargeband.CodeCount}}</a></td>
              <td>{{ chargeband.ModifiedOn }}</td>
              <td>{{ chargeband.ModifiedBy }}</td>
              <td align="center">
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" v-b-modal.chargeband-update-modal
                    @click="editChargeBand(chargeband)">
                    {{editButtonText}}
                  </button>
                  <p> </p>
                  <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm"
                    @click="onDeleteChargeBand(chargeband)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredChargeBands.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
    <!-- LOAD modal -->
    <b-modal ref="loadChargeBandModal" id="chargeband-load-modal" title="Load domestic base rates" hide-footer
      size="xs">
      <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
        <b-form-row>
          <b-col>
            <p>Select a comma seperated file (.txt).</p>
            <p><strong>Do not select an account for base domestic rates</strong></p>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <div align="left">
              <AccountDropdownVue v-bind:selected_account="loadChargeBandForm.AccountId"
                @account-changed="accountChange"></AccountDropdownVue>
            </div>
            <br>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadChargeBandForm.FileName" required
              placeholder="Select domestic base rate file">
            </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- add modal -->
    <b-modal ref="addChargeBandModal" id="chargeband-modal" title="Insert domestic base rate" hide-footer size="lg">
      <b-form @submit="onSubmit" @reset="onReset" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-Name-group" label="Charge Band" label-for="form-Name-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-Name-input" type="text" size="sm" v-model="loadChargeBandForm.Name"
                placeholder="Enter chargeband">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col readonly>
            <!-- <div readonly align="left">
              <AccountDropdownVue  readonly v-bind:selected_account="addChargeBandForm.AccountId" @account-changed="accountChange"></AccountDropdownVue></div>
            <br> -->
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Description-group" label="Description" label-for="form-Description-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Description-input" type="text" size="sm" v-model="addChargeBandForm.Description"
                placeholder="Enter description">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Carrier-group" label="Carrier" label-for="form-Carrier-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Carrier-input" type="text" size="sm" v-model="addChargeBandForm.Carrier"
                placeholder="Enter carrier">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellDayRate-group" label="Sell Day Rate" label-for="form-SellDayRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellDayRate-input" type="text" size="sm" v-model="addChargeBandForm.SellDayRate"
                placeholder="Enter sell day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostDayRate-group" label="Cost Day Rate" label-for="form-CostDayRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostDayRate-input" type="text" size="sm" v-model="addChargeBandForm.CostDayRate"
                placeholder="Enter cost day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellEveRate-group" label="Sell Eve Rate" label-for="form-SellEveRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellEveRate-input" type="text" size="sm" v-model="addChargeBandForm.SellEveRate"
                placeholder="Enter sell evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostEveRate-group" label="Cost Eve Rate" label-for="form-CostEveRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostEveRate-input" type="text" size="sm" v-model="addChargeBandForm.CostEveRate"
                placeholder="Enter cost evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellWendRate-group" label="Sell W/E Rate" label-for="form-SellWendRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellWendRate-input" type="text" size="sm" v-model="addChargeBandForm.SellWendRate"
                placeholder="Enter sell weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostWendRate-group" label="Cost W/E Rate" label-for="form-CostWendRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostWendRate-input" type="text" size="sm" v-model="addChargeBandForm.CostWendRate"
                placeholder="Enter cost weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellConfee-group" label="Sell Con Fee" label-for="form-SellConfee-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellConfee-input" type="text" size="sm" v-model="addChargeBandForm.SellConFee"
                placeholder="Enter sell connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostConfee-group" label="Cost Con Fee" label-for="form-CostConfee-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostConfee-input" type="text" size="sm" v-model="addChargeBandForm.CostConFee"
                placeholder="Enter cost connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal ref="editChargeBandModal" id="chargeband-update-modal" title="Update domestic base rate" hide-footer
      size="lg">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-Name-group" label="Charge Band" label-for="form-Name-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-Name-input" type="text" size="sm" v-model="editChargeBandForm.Name"
                placeholder="Enter charge band">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <!-- <div align="left">
              <AccountDropdownVue v-bind:selected_account="editChargeBandForm.AccountId" @account-changed="accountChange"></AccountDropdownVue></div>
            <br> -->
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Description-group" label="Description" label-for="form-Description-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Description-input" type="text" size="sm" v-model="editChargeBandForm.Description"
                placeholder="Enter description">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Carrier-group" label="Carrier" label-for="form-Carrier-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Carrier-input" type="text" size="sm" v-model="editChargeBandForm.Carrier"
                placeholder="Enter carrier">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellDayRate-group" label="Sell Day Rate" label-for="form-SellDayRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellDayRate-input" type="text" size="sm" v-model="editChargeBandForm.SellDayRate"
                placeholder="Enter sell day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostDayRate-group" label="Cost Day Rate" label-for="form-CostDayRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostDayRate-input" type="text" size="sm" v-model="editChargeBandForm.CostDayRate"
                placeholder="Enter cost day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellEveRate-group" label="Sell Eve Rate" label-for="form-SellEveRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellEveRate-input" type="text" size="sm" v-model="editChargeBandForm.SellEveRate"
                placeholder="Enter sell evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostEveRate-group" label="Cost Eve Rate" label-for="form-CostEveRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostEveRate-input" type="text" size="sm" v-model="editChargeBandForm.CostEveRate"
                placeholder="Enter cost evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellWendRate-group" label="Sell W/E Rate" label-for="form-SellWendRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellWendRate-input" type="text" size="sm" v-model="editChargeBandForm.SellWendRate"
                placeholder="Enter sell weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostWendRate-group" label="Cost W/E Rate" label-for="form-CostWendRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostWendRate-input" type="text" size="sm" v-model="editChargeBandForm.CostWendRate"
                placeholder="Enter cost weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellConfee-group" label="Sell Con Fee" label-for="form-SellConfee-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellConfee-input" type="text" size="sm" v-model="editChargeBandForm.SellConFee"
                placeholder="Enter sell connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostConfee-group" label="Cost Con Fee" label-for="form-CostConfee-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostConfee-input" type="text" size="sm" v-model="editChargeBandForm.CostConFee"
                placeholder="Enter cost connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row" v-if="isAdmin">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
      SelectedPhoneType: '',
      loadChargeBandForm: {
        FileName: ''
      },
      addChargeBandForm: {
        Name: '' ,
        Description: '' ,
        SellDayRate: '',
        SellEveRate: '',
        SellWendRate: '',
        SellConFee: '',
        CostDayRate: '',
        CostEveRate: '',
        CostWendRate: '',
        CostConFee: '',
        Carrier: ''
      },
      editChargeBandForm: {
        ChargeBandId: null,
        Name: null ,
        Description: null,
        SellDayRate: null,
        SellEveRate: null,
        SellWendRate: null,
        SellConFee: null,
        CostDayRate: null,
        CostEveRate: null,
        CostWendRate: null,
        CostConFee: null,
        Carrier: null,
        AccountId: null
      },
      apiChargeBands: null,
      executed: false,
      message: 'initial alert',
      currentSort:'Name',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:true,
      currentPage:1,
      filter:'',
      ShowAccount:true,
      selected_account: null
    };
  },

  computed:{
  filteredChargeBands() {
    console.log('filtered chargeband');
      return this.apiChargeBands.filter(c => {
        if(this.filter == '') return true;
        if (c.Name == null) c.Name = '';
        if (c.AccountName == null) c.AccountName = '';
        if (c.Carrier == null) c.Carrier = '';
        if (c.Description == null) c.Description = '';
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
               c.Description.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiChargeBands:function() {
    console.log('sort chargeband');
    console.log('sort account');
    if (this.apiChargeBands == null) return null;
    console.log ('in sort account....');
    console.log(this.currentSort)
    return this.filteredChargeBands.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;

      if (this.currentSort === 'SellDayRate' || 'SellEveRate' ||'SellWendRate' || 'SellConFee' || 'CodeCount') {
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
      }
      else{
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      }
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    download: function() {
        console.log("in download")
        var data = "ChargeBand,Description,Carrier,SellDayRate,SellEveRate,SellWERate,SellConFee,CostDayRate,CostEveRate,CostWERate,CostConFee"

        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "UploadRates-Domestic.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
    onChangePhoneType(event) {
      this.SelectedPhoneType= event.target.value

      console.log(event.target.value)


      // this.$emit('account-changed',this.SelectedAccount)
    },
    accountChange: function (e) {
        this.selectedAccount = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredChargeBands.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List chargebands
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the chargeband list')
      console.log(this.$route.query.account_id)
      console.log('getting the chargeband list')
      var url;
      if (typeof(this.$route.query.account_id) != "undefined"){
          console.log('selected account in ChargeBand is:');
          console.log(this.$route.query.account_id)
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/chargebands?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.$route.query.account_id + '"}]}';
          // url = process.env.VUE_APP_API_BASE_URL + '/api/v1/chargebands?q={"filters":[{"name":"AccountId","op":"is_null","val":""}]}';
          console.log(url)
          this.ShowAccount = false
          this.selected_account = this.$route.query.account_id
          console.log('selected account in ChargeBand is:');
          console.log(this.selected_account);
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebands?q={"filters":[{"name":"AccountId","op":"is_null","val":""}]}`;
      }
      try {
        document.body.style.cursor = 'wait';
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiChargeBands = data;
        if (this.apiChargeBands.length < this.pageSize){
          this.showPaging = false
        }
        console.log(this.apiChargeBands )
        console.log(this.apiChargeBands.length)
        this.executed = true;
      } catch (e) {
        this.apiChargeBands = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // Delete chargeband
    // *******************************************
    async removeChargeBand(chargebandid) {

      console.log("in removeChargeBand before")
      console.log(chargebandid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebands/${chargebandid}`).then(() => {
          this.message = 'chargeband removed!';
          this.showMessage = true;
          console.log("in removeChargeBand after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeChargeBand error")
          console.error(err);
          this.callApi();

        });

        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiChargeBands = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteChargeBand(chargeband) {
      console.log(chargeband.ChargeBandId)
      if(confirm("Are you sure you want to delete this chargeband?")){
        this.removeChargeBand(chargeband.CBId);
      }
    },

    // Add chargeband method to post to API
    async addChargeBand(payload) {

    //   const id_token = claims.__raw;
      console.log('in add chargeband');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebands`, payload).then(() => {
          this.message = 'chargeband added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated chargeband. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add chargeband. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiChargeBands = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addChargeBandForm.Name =  null;
        this.addChargeBandForm.Notes = null;
    },
    // *******************************************
    // Add cdrload on submit
    // *******************************************
    onLoadSubmit(evt) {
      console.log('on load submit');
      evt.preventDefault();
      this.$refs.loadChargeBandModal.hide();
      var accountid;
      if ((this.selected_account) != null){
        accountid = this.selected_account
      }
      else{
        accountid = this.selectedAccount
      }
      const payload = {
        FileName: this.loadChargeBandForm.FileName
      };
      this.loadchargebands(payload, accountid);
      //this.initForm();
    },
    onLoadReset(evt) {
      evt.preventDefault();
      this.$refs.loadChargeBandModal.hide();
      this.initForm();
    },
    // Add cdrload method to post to API
    async loadchargebands(payload, accountid) {

      let formData = new FormData();
      console.log( 'in load chargebands');
      console.log( 'account is');

      console.log( accountid);
      console.log( payload.file);
      formData.append('file', payload.FileName);
      var url;
      if (typeof(accountid) != "undefined" || accountid != null){
          console.log('selected account in ChargeBand is:');
          console.log(this.$route.query.account_id)
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/chargebands/accounts/' + accountid+ '/load';
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebands/load`
      }
      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'charbands loaded!';
          this.showMessage = true;
          //this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          document.body.style.cursor = 'auto';
          this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
  //
    // *******************************************
    // Add chargeband on submit
    // *******************************************
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      console.log(this.selectedAccount);
      console.log(this.selected_account);
      this.$refs.addChargeBandModal.hide();
      var accountid;
      if ((this.selected_account) != null){
        accountid = this.selected_account
      }
      else{
        accountid = this.selectedAccount
      }
      const payload = {
        Name: this.addChargeBandForm.Name,
        Description: this.addChargeBandForm.Description,
        SellDayRate: this.addChargeBandForm.SellDayRate,
        SellEveRate: this.addChargeBandForm.SellEveRate,
        SellWendRate: this.addChargeBandForm.SellWendRate,
        SellConFee: this.addChargeBandForm.SellConFee,
        CostDayRate: this.addChargeBandForm.CostDayRate,
        CostEveRate: this.addChargeBandForm.CostEveRate,
        CostWendRate: this.addChargeBandForm.CostWendRate,
        CostConFee: this.addChargeBandForm.CostConFee,
        Carrier: this.addChargeBandForm.Carrier,
        AccountId: accountid
      };
      this.addChargeBand(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addChargeBandModal.hide();
      this.initForm();
    },
  //
    editChargeBand(chargeband) {
      console.log('editChargeBand');
      console.log(chargeband);
      console.log(chargeband.CBId);
      this.editChargeBandForm = chargeband;
      this.callApi()
    },

    // *******************************************
    // Update chargeband on submit
    // *******************************************
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('CBId');
      evt.preventDefault();
      this.$refs.editChargeBandModal.hide();
      const payload = {
        Name: this.editChargeBandForm.Name,
        Description: this.editChargeBandForm.Description,
        SellDayRate: this.editChargeBandForm.SellDayRate,
        SellEveRate: this.editChargeBandForm.SellEveRate,
        SellWendRate: this.editChargeBandForm.SellWendRate,
        SellConFee: this.editChargeBandForm.SellConFee,
        CostDayRate: this.editChargeBandForm.CostDayRate,
        CostEveRate: this.editChargeBandForm.CostEveRate,
        CostWendRate: this.editChargeBandForm.CostWendRate,
        CostConFee: this.editChargeBandForm.CostConFee,
        Carrier: this.editChargeBandForm.Carrier,
        AccountId: this.selectedAccount
      };
      this.updateChargeBand(payload, this.editChargeBandForm.CBId);
      this.callApi()
    },

    async updateChargeBand(payload, chargebandid) {

      console.log('in update chargeband');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/chargebands/${chargebandid}`, payload).then(() => {
          this.message = 'chargeband updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated chargeband. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add chargeband. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiChargeBands = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiChargeBands = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editChargeBandModal.hide();
      this.initForm();
      this.getChargeBands(); // why?
    },
  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
  },
};
</script>
