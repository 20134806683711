<template>

    <div>

      <p ><u><strong>Dialled Number Usage Report</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >


          <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="1"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="5"
            label="Dialled starts with"
            label-for="input-horizontal"
            >
            <b-form-input id="form-fromdate-input"
                      type="text"   size="sm"
                      v-model="DilalledNumber"
                      placeholder="Dialled number starts with i.e. 0906"
                      required >
            </b-form-input>
            </b-form-group>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="1"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="5"
            label="From date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-fromdate-input"
                      type="date"   size="sm"
                      v-model="FromDate"
                      placeholder="Enter from date"
                      required>
            </b-form-input>
            </b-form-group>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="1"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="5"
            label="To date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-todate-input"
                      type="date"   size="sm"
                      v-model="ToDate"
                      placeholder="Enter to date"
                      required>
            </b-form-input>
            </b-form-group>


        <b-button type="submit" variant="primary">Find</b-button>
  </b-form>

        <br><br>

        <button  v-if="showdownload" type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button>
          <br><br>
          <table class="table table-striped w-auto" v-show="showresult" id="apirecords" >
            <thead>
              <tr>
                <th scope="col" @click="sort('AccountNumber')">Account Number</th>
                <th scope="col" @click="sort('Source')">Source</th>
                <th scope="col" @click="sort('Dialled')">Dialled</th>
                <th scope="col" @click="sort('CallDate')">Call Date</th>
                <th scope="col" @click="sort('DurationStr')">Duration</th>
                <th scope="col" @click="sort('CallCost')">Call Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(history, index) in SortedapiHistories" :key="index">
                <td>{{ history.AccountNumber }}</td>
                <td>{{ history.Source }}</td>
                <td>{{ history.Dialled }}</td>
                <td>{{ history.CallDate }}</td>
                <td>{{ history.DurationStr }}</td>
                <td>{{ history.CallCost }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="apiHistories.length > pageSize"><p align="center">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredHistories.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import datepicker from 'vue-date-picker'
  import * as XLSX from 'xlsx'

  export default {

    Name: "Api",
    data() {
      return {
        DilalledNumber: null,
        FromDate : null,
        ToDate : null,
        apiHistories: [],
        executed: false,
        message: 'initial alert',
        currentSort:'AccountNumber',
        currentSortDir:'desc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        Account: null
      };
    },

    computed:{
    filteredHistories() {
      console.log('filtered history');
      return this.apiHistories
    },
    SortedapiHistories:function() {
      console.log('sort history');
      console.log(this.currentSort);
      return this.filteredHistories.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] === null) return 1;
        if (a[this.currentSort] === "undefined") return 1;
        if (b[this.currentSort] === null) return -1;
        if (b[this.currentSort] === "undefined") return -1;
        if (typeof a[this.currentSort]  === 'number') {
            if (a[this.currentSort] === null) return 1;
            if (b[this.currentSort] === null) return -1;
            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;

          }
          else{
            if (a[this.currentSort] === null) return 1;
            if (b[this.currentSort] === null) return -1;
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      download : function() {
        const Heading = [
                            ['Account Numbe','Source','Dialled', 'Call Date','Duration', 'Call Cost']
                        ];

        // original object headers
        const objHeader = ["AccountNumber","Source","Dialled","CallDate","DurationStr","CallCost"];


        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.apiHistories, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename = 'Dialled Number Usage(where dialled number is ' + this.DilalledNumber +  '%) -' + datestr + '.xlsx';
        console.log(filename);
        XLSX.writeFile(wb,filename)
        },
    onChangeCallType(event) {
            this.SelectedCallType= event.target.value
            },

    onSubmit(evt) {
        console.log('on submit');
        this.apiHistories = null;
        this.showresult = false;
        this.showdownload  = false;
        this.callApi(this.DilalledNumber, this.FromDate, this.ToDate);
        this.showresult = true;
        evt.preventDefault();
        },


    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredHistories.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List historys
      // *******************************************
    async callApi(dialled, fromdate, todate) {

        // console.log(entity)
        console.log('getting the data')
        // build the filters for the url
        var url;
        var filters =[];
        var dialledfilter = {"name":"dialled","op":"eq","val":dialled}
        var fromfilter = {"name":"fromdate","op":">","val":fromdate}
        var tofilter = {"name":"todate","op":"<","val":todate}


        if (typeof(dialled) != "undefined" && dialled != null && dialled != "") {
            filters.push(dialledfilter)
        }
        if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
            filters.push(fromfilter)
        }
        if (typeof(todate) != "undefined" && todate != null && todate != "") {
            filters.push(tofilter)
        }


        let queryfilters = JSON.stringify(filters);

        console.log(queryfilters)

        document.body.style.cursor = 'wait';

        try {
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/diallednumberusage?q={"filters":' + queryfilters + '}';
          console.log(url)
          const { data } = await this.$http.get(url);
          console.log('DATA IS :');
          console.log(data);

          this.apiHistories = data;
          if (this.apiHistories.length > 0){
            this.showdownload = true
          }
          console.log(this.apiHistories);
          this.showresult = true;
          console.log(this.showresult)
          this.executed = true;
        } catch (e) {
          this.apiHistories = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      datepicker
    },
  };
  </script>
