<template>

    <div align="center">
        <p align="left"><u><strong>Pangea Margin</strong> </u></p>
        <br>
      <div class="result-block-container" >

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit"  style="width: 30%" >
           <b-form-row>
          <b-col>
              <b-form-group id="form-MarkupPercentage-group"
                        label="Margin %"
                        label-for="form-MarkupPercentage-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-MarkupPercentage-input"
                        type="text"   size="sm"
                        v-model="editPangeaMarkupsForm.PriceMarkupPercentage"
                        placeholder="Enter margin percentage">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row"  v-if="isAdmin">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),

        editPangeaMarkupsForm: {
          PriceMarkupPercentage: null ,
        },
        apiPangeaMarkups: null,
        executed: false,
        message: 'initial alert',
        currentSort:'MarkupPercentage',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:true,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selected_account: null
      };
    },

    computed:{
    },
    methods: {
      onChangePhoneType(event) {
        this.SelectedPhoneType= event.target.value

      //   console.log(event.target.value)


        // this.$emit('account-changed',this.SelectedAccount)
      },
      accountChange: function (e) {
          this.selectedAccount = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredPangeaMarkupss.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List gammamarkups
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        var url;
        url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/pricemarkups/pangea`

        try {
          const { data } = await this.$http.get(url);

          this.apiPangeaMarkups = data;
          this.editPangeaMarkupsForm = this.apiPangeaMarkups ;
          console.log('this.apiPangeaMarkups' )
          console.log(this.apiPangeaMarkups )
          this.executed = true;
        } catch (e) {
          this.apiPangeaMarkups = `Error: the server responded with '${e.response}'`;
        }
      },

      initForm() {
      //   console.log('init form');
          this.addPangeaMarkupsForm.MarkupPercentage =  null;
          // this.selectedAccount = null;
      },

      onReset(evt) {
        evt.preventDefault();
        this.$refs.addPangeaMarkupsModal.hide();
        this.initForm();
      },
      // *******************************************
      // Update gammamarkup on submit
      // *******************************************
      onSubmit(evt) {
      //   console.log('in onSubmitUpdate');
      //   console.log('CBId');
        evt.preventDefault();
        // this.$refs.editPangeaMarkupsModal.hide();
      //   var accountid;
        console.log("in onSubmitUpdate");
        // console.log(this.selectedAccount);
        const payload = {
          PriceMarkupPercentage: this.editPangeaMarkupsForm.PriceMarkupPercentage,
        };
        console.log(payload);
        this.updatePangeaMarkups(payload);
        this.callApi()
      },

      async updatePangeaMarkups(payload) {

        console.log('in update gammamarkup');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricemarkups/pangea`, payload).then(() => {
            this.message = 'gammamarkup updated!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated gammamarkup. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add gammamarkup. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi();

          });

          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiPangeaMarkups = `Error: the server responded with '${e.response}'`;
        }
      },

    },
    created() {
      // console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
    },
  };
  </script>
