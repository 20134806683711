<template>
    <div>
      <div class="result-block-container">
        <p ><u><strong>Invoice Details</strong></u> </p>
      <br>
        <div >
        <b-form @submit="onSubmit" style="width: 50%">
          <b-form-group id="form-title-group"
                      label="Invoice DD Collection Date"
                      label-for="form-title-input">
            <b-form-input id="form-title-input"
                          type="text"
                          v-model="editInvoiceDetailsForm.DDCDate"
                          required
                          placeholder="Insert invoice DD collection date">
            </b-form-input>
          </b-form-group>
          <b-form-group id="form-title-group"
                      label="Invoice Advert"
                      label-for="form-title-input">
            <b-form-textarea id="form-title-input"
                          rows="3"
                          max-rows="6"
                          v-model="editInvoiceDetailsForm.AddText"
                          required
                          placeholder="Insert invoice advert">
            </b-form-textarea>
          </b-form-group>
        <b-form-group id="form-title-group"
                      label="Invoice Email Subject"
                      label-for="form-title-input">
                      <b-form-textarea id="form-title-input"
                          rows="3"
                          max-rows="6"
                          v-model="editInvoiceDetailsForm.EmailSubject"
                          required
                          placeholder="Insert invoice email subject">
                        </b-form-textarea>
          </b-form-group>
          <b-form-group id="form-author-group"
                        label="Invoice Email Body"
                        label-for="form-author-input">
              <b-form-textarea id="form-author-input"
                            rows="3"
                            max-rows="6"
                            v-model="editInvoiceDetailsForm.EmailBody"
                            required
                            placeholder="Insert invoice email body">
              </b-form-textarea>
            </b-form-group>

          <b-button-group>
            <b-button v-if="isAdmin" type="submit" variant="primary">Submit</b-button>
          </b-button-group>
        </b-form>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        editInvoiceDetailsForm: {
          DetailId: '',
          DDCDate: '',
          AddText: '',
          EmailBody: '',
          EmailSubject: ''
        },
        executed: false,
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:3,
        currentPage:1,
        filter:'',
        selectedAccount:"",
      };
    },
    watch: {
      filter() {
        console.log('reset to p1 due to filter');
        this.currentPage = 1;
      },
    },
    computed:{
    },
    methods: {
      // *******************************************
      // get invoice details
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        var url;
        url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoicedetails`;

        try {
          const { data } = await this.$http.get(url);

          this.apiInvoiceDetail = data;
          console.log('this.apiInvoiceDetail' )
          console.log(this.apiInvoiceDetail )
          this.editInvoiceDetailsForm= this.apiInvoiceDetail ;
          this.executed = true;
        } catch (e) {
          this.apiInvoiceDetail = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // Update invoiceadd on submit
      // *******************************************
      async updateInvoiceDetail(payload, detailid) {

        console.log('in update invoiceadd');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoicedetails/${detailid}`, payload).then(() => {
            this.message = 'invoiceadetails updated!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated invoiceadd. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add invoiceadd. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi();

          });
          console.log('delete data is:')
          console.log(data)
          // this.callApi()
        } catch (e) {
          this.apiInvoiceAdds = `Error: the server responded with '${e.response}'`;
        }
      },
      onSubmit(evt) {
        console.log('on submit national' );
        evt.preventDefault();
        const payload = {
          DDCDate: this.editInvoiceDetailsForm.DDCDate,
          AddText: this.editInvoiceDetailsForm.AddText,
          EmailSubject: this.editInvoiceDetailsForm.EmailSubject,
          EmailBody: this.editInvoiceDetailsForm.EmailBody,
        };
        this.updateInvoiceDetail(payload, this.editInvoiceDetailsForm.DetailId);
        this.$router.push('/invoices')
        // router.push({ path: '/invoices'})
      },
      // onReset(evt) {
      //   console.log('in reset');
      //   evt.preventDefault();
      //   this.$router.push('/invoices')
      // },

    },
    created() {
      console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
    },
  };
  </script>
