<template>
    <div>
      <p><strong>Pricing Summary</strong> </p>
      <br>
      <div class="result-block-container">
        <!-- <img class="mb-3 app-logo" src="/loading icon.gif" alt="Vue.js logo" width="4000" />
        <div v-show="apicarrier.length == 0">
          <img src="./../assets/loading icon.gif"> -->
          <!-- <img class="mb-3 app-logo" src="/loading icon.gif" alt="Vue.js logo" width="4000" />
        </div> -->
        <div :class="['result-block', executed ? 'show' : '']">
          <table class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th scope="col" @click="sort('Direction')">Direction</th>
                <th scope="col" @click="sort('CallType')">Call type</th>
                <th scope="col" @click="sort('CallCount')">Call count</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pricesummary, index) in apipricing" :key="index">
                <td>{{ pricesummary.Direction }}</td>
                <td>{{ pricesummary.CallType }}</td>
                <td align="center">{{ pricesummary.CallCount }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br>
          <p><strong>Carriers Pricing Summary</strong> </p>
         <br>
         <table class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th scope="col" @click="sort('Direction')">Carrier</th>
                <th scope="col" @click="sort('CallType')">Total calls</th>
                <th scope="col" @click="sort('CallCount')">Priced calls</th>
                <th scope="col" @click="sort('CallCount')">Suspended calls</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(carrierummary, index) in apicarrier" :key="index">
                <td >{{ carrierummary.Name }}</td>
                <td align="center">{{ carrierummary.Total }}</td>
                <td align="center">{{ carrierummary.Priced }}</td>
                <td align="center">{{ carrierummary.Suspended }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import CarrierDropDownVue from "../components/CarrierDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        selectedCarrier:"",
        apipricing: null,
        apicarrier: null,
        executed: false,
        message: 'initial alert',
        currentSort:'Direction',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        loading : false
      };
    },

    computed:{
    filteredpricing() {
      console.log('filtered pricinglog');
        return this.apipricing.filter(c => {
          if(this.filter == '') return true;
          return c.Direction.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    SortedapiCDRLoads:function() {
      console.log('sort pricinglog');
      return this.filteredpricing.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredpricing.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List pricing summary
      // *******************************************
      async callApi(lablex) {

        this.loading = true
        console.log(lablex)
        console.log('getting the data')
        // this.$Progress.start();
        document.body.style.cursor = 'wait';
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing/pricesummary`);

          this.apipricing = data;
          this.executed = true;
          console.log("the api data is..........")
          console.log(this.apipricing)
        } catch (e) {
          this.apipricing = `Error: the server responded with '${e.response}'`;
        }
        console.log("calling for summary ############################")
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing/carriersummary`);
          document.body.style.cursor = 'auto';
          // window.EventBus.$emit('finance-calculator-display');
          this.apicarrier = data;
          this.loading = false
          console.log("got the summary ############################")
          this.executed = true;
          console.log("the carrierapi data is..........")
          console.log(this.apicarrier)
        } catch (e) {
          this.apipricing = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addpricinglogForm.Carrier =  '';
          this.addpricinglogForm.BillingName =  '';
          this.addpricinglogForm.FileName =  '';

      },
    },
    created() {
      console.log('created')
      //  // promises array
      // let promises = [];
      // // this context
      // const self = this;
      // this.$Progress.start();
      // // push into the array
      // promises.push(this.callApi());
      // // then hide the progress bar
      // Promise.all(promises).then(()=>{
      //   self.$Progress.finish();
      // })
      // this.$Progress.start();
      this.callApi();
      // this.$Progress.finish();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      CarrierDropDownVue: CarrierDropDownVue,
    },
  };
  </script>
