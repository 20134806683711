<template>

    <div>
      <p ><u><strong>Suspended Usage</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
         <button  type="button" class="btn btn-success btn-sm" v-on:click="download">Download</button>
         <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th scope="col" @click="sort('Source')">Source</th>
                <th scope="col" @click="sort('Destination')">Destination</th>
                <th scope="col" @click="sort('CallDate')">Date</th>
                <th scope="col" @click="sort('Duration')">Duration</th>
                <th scope="col" @click="sort('Carrier')">Carrier</th>
                <th scope="col" @click="sort('Direction')">Direction</th>
                <th scope="col" @click="sort('CarrierCost')">Carrier cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(suspendedcall, index) in SortedapiOrders" :key="index">
                <td>{{ suspendedcall.Source }}</td>
                <td>{{ suspendedcall.Destination }}</td>
                <td>{{ suspendedcall.CallDate }}</td>
                <td>{{ suspendedcall.Duration }}</td>
                <td>{{ suspendedcall.Carrier }}</td>
                <td>{{ suspendedcall.Direction }}</td>
                <td>{{ suspendedcall.CarrierCost }}</td>
              </tr>
            </tbody>
          </table>
          <div><p align="center">
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div>
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        addOrderForm: {
          Name: '' ,
          BillingName: '' ,
          Address1: null ,
          Address2: null ,
          PostCode: null ,
          City: null ,
          Country: null ,
          ContactName: null,
          ContactNumber: null,
          FaxNumber: null
        },
        editOrderForm: {
          OrderId: null,
          Name: null ,
          BillingName: null ,
          Address1: null ,
          Address2: null ,
          PostCode: null ,
          City: null ,
          Country: null ,
          ContactName: null,
          ContactNumber: null,
          FaxNumber: null ,
          suspendedcalldetails: null
        },
        apiOrders: null,
        executed: false,
        message: 'initial alert',
        currentSort:'CallDate',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
      };
    },

    computed:{
    filteredOrders() {
      console.log('filtered suspendedcall');
        return this.apiOrders.filter(c => {
          if(this.filter == '') return true;
          if (c.Carrier == null) c.Carrier = '';
          if (c.Source == null) c.Source = '';
          if (c.Destination == null) c.Destination = '';
          if (c.CallDate == null) c.CallDate = '';
          return c.Carrier.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.Source.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.Destination.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.CallDate.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    SortedapiOrders:function() {
      console.log('sort suspendedcall');
      return this.filteredOrders.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
     download : async function() {
        console.log('start download')
        document.body.style.cursor = 'wait';
        const data = XLSX.utils.json_to_sheet(this.apiOrders)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        XLSX.writeFile(wb,'suspended calls.xlsx')
        console.log('end download')
        document.body.style.cursor = 'auto';
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredOrders.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List suspendedcalls
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        console.log('getting the data')
        try {
          document.body.style.cursor = 'wait';
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing/suspendedcalls`);
          document.body.style.cursor = 'auto';
          console.log(data);
          this.apiOrders = data;
          this.executed = true;
        } catch (e) {
          this.apiOrders = `Error: the server responded with '${e.response}'`;
        }
      },

    },

    created() {
      console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
