<template>
  <div>
    <input type="search" v-model="filter">
    <br>
  </div>
</template>

<script>
export default {
  props: [],
};
</script>