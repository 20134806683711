<template>

  <div>
    <p><u><strong>Recurring Charges Inventory Report</strong></u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onFind">
          <b-form-row>
            <b-col sm="35">
              <b-form-group id="fieldset-horizontal" label-cols-sm="4" label-cols-xs="4" content-cols-sm
                content-cols-xs="1" label="Account Name" label-for="input-horizontal">
                <b-form-input id="form-Account-input" type="text" size="m" v-model="SearchAccount"
                  placeholder="account name">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col> <b-button type="submit" variant="primary">Find</b-button></b-col>
          </b-form-row>

        </b-form>

        <br><br>
        <button v-show="showresult" type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload
          Results</button>
        <br><br>
        <!-- <input v-show="showresult" type="search" v-model="filter"> -->
        <table v-show="showresult" class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th style="white-space:nowrap;" @click="sort('GroupNumber')">Group Number</th>
              <th class="th-sm" scope="col" @click="sort('GroupName')">Group Name </th>
              <th style="white-space:nowrap;" @click="sort('AccountNumber')">Account Number</th>
              <th class="th-sm" scope="col" @click="sort('AccountName')">Account Name </th>
              <th class="th-sm" scope="col">Cost Centre Name</th>
              <th class="th-lg" scope="col" @click="sort('Outbound')">Outbound</th>
              <th class="th-sm" scope="col">Label</th>
              <th class="th-sm" scope="col">Charge Type</th>
              <th class="th-sm" scope="col">Charge Number</th>
              <th class="th-sm" scope="col">Product</th>
              <th class="th-sm" scope="col">Term Start</th>
              <th class="th-sm" scope="col">Term End</th>
              <th scope="col">Frequency</th>
              <th scope="col">Quantity</th>
              <th scope="col">Unit Price</th>
              <th scope="col">Total Price</th>
              <th scope="col">Cost Centre No</th>
              <th scope="col">Product Number</th>
              <th class="th-sm" scope="col" @click="sort('SageAccount')">Sage Account</th>
              <th class="th-sm" scope="col">Customised Name</th>
              <th scope="col">Part Number</th>
              <th scope="col">ISA</th>
              <th scope="col">Unit Cost</th>
              <th scope="col">Total Cost</th>
              <th class="th-sm" scope="col">Carrier</th>
              <th class="th-sm" scope="col">Category</th>
              <th class="th-sm" scope="col">Industry</th>
              <th class="th-sm" scope="col" @click="sort('SaleAgent')">Sales Agent</th>
              <th scope="col" @click="sort('LastBillDate')">Date First Billed</th>
              <th scope="col" @click="sort('LastBillDate')">Bill Run First Billed</th>
              <th scope="col">Date Last Billed</th>
              <th scope="col">Bill Run Last Billed</th>
              <th scope="col">Bill Run Next Billed</th>
              <th class="th-sm" scope="col">Final Bill Run</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cli, index) in SortedapiCLIs" :key="index">
              <td>{{ cli.GroupNumber }}</td>
              <td>{{ cli.GroupName }}</td>
              <td>{{ cli.AccountNumber }}</td>
              <td>{{ cli.AccountName }}</td>

              <td>{{ cli.CostCentre }}</td>
              <td>{{ cli.Outbound }}</td>
              <td>{{ cli.Label }}</td>
              <td>{{ cli.ChargeType }}</td>
              <td>{{ cli.ChargeNumber }}</td>
              <td>{{ cli.Product }}</td>
              <td>{{ cli.TermStartDate }}</td>
              <td>{{ cli.TermEndDate }}</td>
              <td>{{ cli.Frequency }}</td>
              <td>{{ cli.Quantity }}</td>
              <td>{{ cli.SellUnitPrice }}</td>
              <td>{{ cli.SellTotalPrice }}</td>
              <td>{{ cli.CostCentreNumber }}</td>
              <td>{{ cli.ProductNumber }}</td>
              <td>{{ cli.SageAccount }}</td>
              <div v-if=cli.CustomisedName>
                <td>{{ cli.CustomisedName.substring(0,39) }}</td>
              </div>
              <div v-else>
                <td></td>
              </div>
              <td>{{ cli.PartNumber }}</td>
              <td>{{ cli.ISA }}</td>
              <td>{{ cli.CostUnitPrice }}</td>
              <td>{{ cli.CostTotalPrice }}</td>
              <td>{{ cli.Carrier }}</td>
              <td>{{ cli.Category }}</td>
              <td>{{ cli.Industry }}</td>
              <td>{{ cli.SaleAgent }}</td>
              <td>{{ cli.DateFirstBilled }}</td>
              <td>{{ cli.BillRunFirstBilled }}</td>
              <td>{{ cli.DateLastBilled }}</td>
              <td>{{ cli.BillRunBilled }}</td>
              <td>{{ cli.BillRunNextBilled }}</td>
              <td>{{ cli.FinalBillRun }}</td>

            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredCLIs.length" :per-page="pageSize"
              aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'


import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        SearchAccount: null,
        apiCLIs: [],
        executed: false,
        message: 'initial alert',
        currentSort:'AccountName',
        currentSortDir:'asc',
        pageSize:30,
        showPaging:false,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selected_account: null,
        ShowBundle:false,
        selected_mobilebundle: null,
        CostCentres: null,
        showresult:false
      };
    },

    computed:{
    filteredCLIs() {
      //  console.log('filtered cli');
        return this.apiCLIs.filter(c => {
          if(this.filter == '') return true;
          return c.Outbound.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 (c.CostCentre && c.CostCentre.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
        })
      },

    SortedapiCLIs:function() {
      //  console.log('sort cli');
      if (this.apiCLIs != null){
        return this.filteredCLIs.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(this.currentSort === 'account.Name'){
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
          else{
            if (a[this.currentSort] === null) return 1;
            if (b[this.currentSort] === null) return -1;
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
          else{ return null}
    },
    SortedapiCLIsDownload:function() {
      //  console.log('sort cli');
      if (this.apiCLIs != null){
        return this.filteredCLIs.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(this.currentSort === 'account.Name'){
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
          else{
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
        })

      }
          else{ return null}
    },
    },
    methods: {
      download : function() {
        // customised headers
        const Heading = [
                            ['Group Number', 'Group Name', 'Account Number', 'Account Name','Cost Centre Name','Outbound','Label','Charge Type','Charge Number','Product', 'Term Start',
                            'Term End', 'Frequency','Quantity','Unit Price', 'Total Price', 'Cost Center No' ,'Product Number', 'Sage Account', 'Customised Name', 'Part Number', 'ISA', 'Unit Cost', 'Total Cost','Carrier', 'Category','Industry',
                            'Sales Agent', 'Date First Billed', 'Bill Run First Billed','Date Last Billed', 'Bill Run Last Billed','Bill Run Next Billed' , 'Final Bill Run']
                        ];

        // original object headers
        const objHeader = ["GroupNumber","GroupName","AccountNumber","AccountName", "CostCentre","Outbound","Label","ChargeType","ChargeNumber","Product","TermStartDate","TermEndDate","Frequency","Quantity","SellUnitPrice","SellTotalPrice", "CostCentreNumber","ProductNumber", "SageAccount" ,
                          "CustomisedName", "PartNumber", "ISA","CostUnitPrice","CostTotalPrice","Carrier", "Category","Industry","SaleAgent","DateFirstBilled", "BillRunFirstBilled","DateLastBilled", "BillRunBilled","BillRunNextBilled","FinalBillRun"];


        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.SortedapiCLIsDownload, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename = 'Recurring Charges Inventory -' + datestr + '.xlsx';
        console.log(filename);
        XLSX.writeFile(wb,filename)
        },

      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredCLIs.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List outbounds
      // *******************************************
      async callReportsApi(account) {

         console.log(account)
        var url;
        if (account !== null) {
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/accountsrecurringcharges?q=` + account
        }
        else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/accountsrecurringcharges`
        }
        console.log(url)
        document.body.style.cursor = 'wait';

        try {
          const { data } = await this.$http.get(url);

          this.apiCLIs = data;
          console.log("API DATA.....................")
          console.log(data)
          if (this.apiCLIs.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiCLIs = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

      // *******************************************
      // search Recurring by account
      // *******************************************
      onFind(evt) {
        console.log('on Find');
        this.apiCLIs = null;
        this.showresult = false;
        this.showPaging = false
        this.callReportsApi(this.SearchAccount);
        // this.showresult = true;
        evt.preventDefault();
        },

    },

    created() {
      //  console.log('created')
      // this.callReportsApi();
      this.executed = true;


    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
<style type="text/css">
th {
    white-space: nowrap;
}
td {
    white-space: nowrap;
}
</style>