<template>
  <div class="cascading-dropdown">
    <div class="dropdown">
      <select @change="onChangeCallBundle($event)" v-model="selected_bundle">
        <option value="">Select a call bundle</option>
        <option
          v-for="(callbundle, index) in apiCallBundles"
          :value="callbundle.BundleId"
          :key="index"
        >
          {{ callbundle.BundleName }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  props:{
    group_exclude:{
      type: Boolean,
      default: false},
      selected_bundle:{
      type: String,
      default: ""},
    },
  data() {
    return {
      listCallBundle: [],
      selectedCallBundle: "",
      authToken: "",
      currentSort:'BundleName',
      currentSortDir:'asc',
      apiCallBundles: null,
    };
  },
  methods: {
    onChangeCallBundle(event) {
      this.selectedCallBundle = event.target.value
      console.log('in dropdownXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
      console.log(this.selectedCallBundle)
      this.$emit('callbundle-changed',this.selectedCallBundle)
    },

    async callApi() {
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/callbundles`);

        console.log(data)
        // this.listCallBundle = data;
        this.apiCallBundles = data;
        this.executed = true;
      } catch (e) {
        this.listCallBundle = `Error: the server responded with '${e.response}'`;
      }
    },
  },
  created() {
    this.callApi();
  }
};
</script>
