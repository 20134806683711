<template>

  <div>
    <p><u><strong>Base Rates - International</strong></u> </p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.country-modal>Insert international
          base rate</button>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('CountryName')">Charge Band</th>
              <th class="th-sm" scope="col" @click="sort('Description')">Description</th>
              <th class="th-sm" scope="col" @click="sort('SellDayRate')">Sell Day Rate </th>
              <th class="th-sm" scope="col" @click="sort('SellEveRate')">Sell Eve Rate </th>
              <th class="th-sm" scope="col" @click="sort('SellWendRate')">Sell W/E Rate </th>
              <th class="th-sm" scope="col" @click="sort('SellConFee')">Sell Con Fee </th>
              <th class="th-sm" scope="col" @click="sort('CodeCount')">Prefixes </th>
              <th scope="col" @click="sort('ModifiedOn')">Modified On</th>
              <th scope="col" @click="sort('ModifiedBy')">User</th>
              <th> <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.country-load-modal>Load
                  international base rates</button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(country, index) in SortedapiCountrys" :key="index">
              <td>{{ country.CountryName }}</td>
              <td>{{ country.Description }}</td>
              <td>{{ country.SellDayRate }}</td>
              <td>{{ country.SellEveRate }}</td>
              <td>{{ country.SellWendRate }}</td>
              <td>{{ country.SellConFee }}</td>
              <td align="center"><a :href="'/countrycodes?CountryId=' + country.CountryId"
                  target="_blank">{{country.CodeCount}}</a></td>
              <!-- <td>{{ country.CodeCount }}</td> -->
              <td>{{ country.ModifiedOn }}</td>
              <td>{{ country.ModifiedBy }}</td>
              <td align="center">
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" v-b-modal.country-update-modal
                    @click="editCountry(country)">
                    {{editButtonText}}
                  </button>
                  <p> </p>
                  <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm" @click="onDeleteCountry(country)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredCountrys.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
    <!-- LOAD modal -->
    <b-modal ref="loadCountryModal" id="country-load-modal" title="Load international base rates" hide-footer size="xs">
      <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
        <b-form-row>
          <b-col>
            <p>Select a comma seperated file (.txt).</p>
            <p><strong>Do not select an account for international base rates</strong></p>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <div align="left">
              <AccountDropdownVue v-bind:selected_account="loadCountryForm.AccountId" @account-changed="accountChange">
              </AccountDropdownVue>
            </div>
            <br>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadCountryForm.FileName" required
              placeholder="Select international base rates file">
            </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- add modal -->
    <b-modal ref="addCountryModal" id="country-modal" title="Insert international base rate" hide-footer size="lg">
      <b-form @submit="onSubmit" @reset="onReset" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-CountryName-group" label="Charge Band" label-for="form-CountryName-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-CountryName-input" type="text" size="sm" v-model="loadCountryForm.CountryName"
                placeholder="Enter charge band">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>

          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Description-group" label="Description" label-for="form-Description-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Description-input" type="text" size="sm" v-model="addCountryForm.Description"
                placeholder="Enter description">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Carrier-group" label="Carrier" label-for="form-Carrier-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Carrier-input" type="text" size="sm" v-model="addCountryForm.Carrier"
                placeholder="Enter carrier">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellDayRate-group" label="Sell Day Rate" label-for="form-SellDayRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellDayRate-input" type="text" size="sm" v-model="addCountryForm.SellDayRate"
                placeholder="Enter sell day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostDayRate-group" label="Cost Day Rate" label-for="form-CostDayRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostDayRate-input" type="text" size="sm" v-model="addCountryForm.CostDayRate"
                placeholder="Enter cost day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellEveRate-group" label="Sell Eve Rate" label-for="form-SellEveRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellEveRate-input" type="text" size="sm" v-model="addCountryForm.SellEveRate"
                placeholder="Enter sell evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostEveRate-group" label="Cost Eve Rate" label-for="form-CostEveRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostEveRate-input" type="text" size="sm" v-model="addCountryForm.CostEveRate"
                placeholder="Enter sell evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellWendRate-group" label="Sell W/E Rate" label-for="form-SellWendRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellWendRate-input" type="text" size="sm" v-model="addCountryForm.SellWendRate"
                placeholder="Enter sell weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostWendRate-group" label="Cost W/E Rate" label-for="form-CostWendRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostWendRate-input" type="text" size="sm" v-model="addCountryForm.CostWendRate"
                placeholder="Enter cost weekend Rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellConfee-group" label="Sell Con Fee" label-for="form-SellConfee-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellConfee-input" type="text" size="sm" v-model="addCountryForm.SellConFee"
                placeholder="Enter sell connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostConfee-group" label="Cost Con Fee" label-for="form-CostConfee-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostConfee-input" type="text" size="sm" v-model="addCountryForm.CostConFee"
                placeholder="Enter cost connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal ref="editCountryModal" id="country-update-modal" title="Update international base rate" hide-footer
      size="lg">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-CountryName-group" label="Charge Band" label-for="form-CountryName-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-CountryName-input" type="text" size="sm" v-model="editCountryForm.CountryName"
                placeholder="Enter charge band">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Description-group" label="Description" label-for="form-Description-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Description-input" type="text" size="sm" v-model="editCountryForm.Description"
                placeholder="Enter description">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Carrier-group" label="Carrier" label-for="form-Carrier-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Carrier-input" type="text" size="sm" v-model="editCountryForm.Carrier"
                placeholder="Enter carrier">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellDayRate-group" label="Sell Day Rate" label-for="form-SellDayRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellDayRate-input" type="text" size="sm" v-model="editCountryForm.SellDayRate"
                placeholder="Enter sell day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostDayRate-group" label="Cost Day Rate" label-for="form-CostDayRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostDayRate-input" type="text" size="sm" v-model="editCountryForm.CostDayRate"
                placeholder="Enter cost day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellEveRate-group" label="Sell Eve Rate" label-for="form-SellEveRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellEveRate-input" type="text" size="sm" v-model="editCountryForm.SellEveRate"
                placeholder="Enter sell evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostEveRate-group" label="Cost Eve Rate" label-for="form-CostEveRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostEveRate-input" type="text" size="sm" v-model="editCountryForm.CostEveRate"
                placeholder="Enter sell evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellWendRate-group" label="Sell W/E Rate" label-for="form-SellWendRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellWendRate-input" type="text" size="sm" v-model="editCountryForm.SellWendRate"
                placeholder="Enter sell weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostWendRate-group" label="Cost W/E Rate" label-for="form-CostWendRate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostWendRate-input" type="text" size="sm" v-model="editCountryForm.CostWendRate"
                placeholder="Enter cost weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-SellConfee-group" label="Sell Con Fee" label-for="form-SellConfee-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-SellConfee-input" type="text" size="sm" v-model="editCountryForm.SellConFee"
                placeholder="Enter sell connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-CostConfee-group" label="Cost Con Fee" label-for="form-CostConfee-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-CostConfee-input" type="text" size="sm" v-model="editCountryForm.CostConFee"
                placeholder="Enter cost connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row" v-if="isAdmin">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
      SelectedPhoneType: '',
      loadCountryForm: {
        FileName: ''
      },
      addCountryForm: {
        CountryName: '' ,
        Description: '' ,
        SellDayRate: '',
        SellEveRate: '',
        SellWendRate: '',
        SellConFee: '',
        CostDayRate: '',
        CostEveRate: '',
        CostWendRate: '',
        CostConFee: '',
        Carrier: ''
      },
      editCountryForm: {
        CountryId: null,
        CountryName: null ,
        Description: null,
        SellDayRate: null,
        SellEveRate: null,
        SellWendRate: null,
        SellConFee: null,
        CostDayRate: null,
        CostEveRate: null,
        CostWendRate: null,
        CostConFee: null,
        Carrier: null,
        AccountId: null
      },
      apiCountrys: null,
      executed: false,
      message: 'initial alert',
      currentSort:'CountryName',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:true,
      currentPage:1,
      filter:'',
      ShowAccount:true,
      selected_account: null
    };
  },

  computed:{
  filteredCountrys() {
    console.log('filtered country');
      return this.apiCountrys.filter(c => {
        if(this.filter == '') return true;
        if (c.CountryName == null) c.CountryName = '';
        if (c.AccountName == null) c.AccountName = '';
        if (c.Carrier == null) c.Carrier = '';
        if (c.Description == null) c.Description = '';
        return c.CountryName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.CountryName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
               c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiCountrys:function() {
    console.log('sort country');
    console.log('sort account');
    if (this.apiCountrys == null) return null;
    console.log ('in sort account....');
    console.log(this.currentSort)
    return this.filteredCountrys.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;

      if (this.currentSort === 'SellDayRate' || 'SellEveRate' ||'SellWendRate' || 'SellConFee' || 'CodeCount' ) {
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
      }
      else{
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      }
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    download: function() {
        console.log("in download")
        var data = "ChargeBand,Description,Carrier,SellDayRate,SellEveRate,SellWERate,SellConFee,CostDayRate,CostEveRate,CostWERate,CostConFee"

        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        // const jsonData = encodeURIComponent('{"is_valid": true}')
        // this.myUrl = `data:text/plain;charset=utf-8,${jsonData}`
        // this.myFilename = 'example.json'
        fileLink.setAttribute('download', "UploadRates-International.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
    onChangePhoneType(event) {
      this.SelectedPhoneType= event.target.value

      console.log(event.target.value)


      // this.$emit('account-changed',this.SelectedAccount)
    },
    accountChange: function (e) {
        this.selectedAccount = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredCountrys.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List countries
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the country list')
      console.log(this.$route.query.account_id)
      console.log('getting the country list')
      var url;
      if (typeof(this.$route.query.account_id) != "undefined"){
          console.log('selected account in Country is:');
          console.log(this.$route.query.account_id)
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/countries?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.$route.query.account_id + '"}]}';
          console.log(url)
          this.ShowAccount = false
          this.selected_account = this.$route.query.account_id
          console.log('selected account in Country is:');
          console.log(this.selected_account);
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/countries?q={"filters":[{"name":"AccountId","op":"is_null","val":""}]}`;
      }
      try {
        document.body.style.cursor = 'wait';
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiCountrys = data;
        if (this.apiCountrys.length < this.pageSize){
          this.showPaging = false
        }
        console.log(this.apiCountrys )
        console.log(this.apiCountrys.length)
        this.executed = true;
      } catch (e) {
        this.apiCountrys = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // Delete country
    // *******************************************
    async removeCountry(countryid) {

      console.log("in removeCountry before")
      console.log(countryid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/countries/${countryid}`).then(() => {
          this.message = 'country removed!';
          this.showMessage = true;
          console.log("in removeCountry after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeCountry error")
          console.error(err);
          this.callApi();

        });

        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCountrys = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteCountry(country) {
      console.log(country.CountryId)
      if(confirm("Are you sure you want to delete this country?")){
        this.removeCountry(country.CountryId);
      }
    },

    // Add country method to post to API
    async addCountry(payload) {

    //   const id_token = claims.__raw;
      console.log('in add country');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/countries`, payload).then(() => {
          this.message = 'country added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated country. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add country. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCountrys = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addCountryForm.CountryName =  null;
        this.addCountryForm.Notes = null;
    },
    // *******************************************
    // Add cdrload on submit
    // *******************************************
    onLoadSubmit(evt) {
      console.log('on load submit');
      evt.preventDefault();
      this.$refs.loadCountryModal.hide();
      var accountid;
      if ((this.selected_account) != null){
        accountid = this.selected_account
      }
      else{
        accountid = this.selectedAccount
      }
      const payload = {
        FileName: this.loadCountryForm.FileName
      };
      this.loadcountries(payload, accountid);
      //this.initForm();
    },
    onLoadReset(evt) {
      evt.preventDefault();
      this.$refs.loadCountryModal.hide();
      this.initForm();
    },
    // Add cdrload method to post to API
    async loadcountries(payload, accountid) {

      let formData = new FormData();
      console.log( 'in load countries');
      console.log( 'account is');

      console.log( accountid);
      console.log( payload.file);
      formData.append('file', payload.FileName);
      var url;
      if (typeof(accountid) != "undefined" || accountid != null){
          console.log('selected account in Country is:');
          console.log(this.$route.query.account_id)
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/countries/accounts/` + accountid + '/load';
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/countries/load`
      }
      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'charbands loaded!';
          this.showMessage = true;
          //this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          document.body.style.cursor = 'auto';
          this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
  //
    // *******************************************
    // Add country on submit
    // *******************************************
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      console.log(this.selectedAccount);
      console.log(this.selected_account);
      this.$refs.addCountryModal.hide();
      var accountid;
      if ((this.selected_account) != null){
        accountid = this.selected_account
      }
      else{
        accountid = this.selectedAccount
      }
      const payload = {
        CountryName: this.addCountryForm.CountryName,
        Description: this.addCountryForm.Description,
        SellDayRate: this.addCountryForm.SellDayRate,
        SellEveRate: this.addCountryForm.SellEveRate,
        SellWendRate: this.addCountryForm.SellWendRate,
        SellConFee: this.addCountryForm.SellConFee,
        CostDayRate: this.addCountryForm.CostDayRate,
        CostEveRate: this.addCountryForm.CostEveRate,
        CostWendRate: this.addCountryForm.CostWendRate,
        CostConFee: this.addCountryForm.CostConFee,
        Carrier: this.addCountryForm.Carrier,
        AccountId: accountid
      };
      this.addCountry(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addCountryModal.hide();
      this.initForm();
    },
  //
    editCountry(country) {
      console.log('editCountry');
      console.log(country);
      console.log(country.CountryId);
      this.editCountryForm = country;
      this.callApi()
    },

    // *******************************************
    // Update country on submit
    // *******************************************
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('CountryId');
      evt.preventDefault();
      this.$refs.editCountryModal.hide();
      const payload = {
        CountryName: this.editCountryForm.CountryName,
        Description: this.editCountryForm.Description,
        SellDayRate: this.editCountryForm.SellDayRate,
        SellEveRate: this.editCountryForm.SellEveRate,
        SellWendRate: this.editCountryForm.SellWendRate,
        SellConFee: this.editCountryForm.SellConFee,
        CostDayRate: this.editCountryForm.CostDayRate,
        CostEveRate: this.editCountryForm.CostEveRate,
        CostWendRate: this.editCountryForm.CostWendRate,
        CostConFee: this.editCountryForm.CostConFee,
        Carrier: this.editCountryForm.Carrier,
        AccountId: this.selectedAccount
      };
      this.updateCountry(payload, this.editCountryForm.CountryId);
      this.callApi()
    },

    async updateCountry(payload, countryid) {

      console.log('in update country');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/countries/${countryid}`, payload).then(() => {
          this.message = 'country updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated country. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add country. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiCountrys = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCountrys = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editCountryModal.hide();
      this.initForm();
      this.getCountrys(); // why?
    },
  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
  },
};
</script>
