<template>

    <div>
      <p ><u><strong>Invoice Advert</strong></u> </p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col" @click="sort('AdText')" >Advert</th>
                <th scope="col" @click="sort('ModifiedOn')">Date modified</th>
                <th scope="col" @click="sort('ModifiedBy')">Modified by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(invoiceadd, index) in SortedapiInvoiceAdds" :key="index">
                <td>{{ invoiceadd.AddText }}</td>
                <td>{{ invoiceadd.ModifiedOn }}</td>
                <td>{{ invoiceadd.ModifiedBy }}</td>
                <td>
                  <div class="btn-group"  role="group">
                    <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-b-modal.invoiceadd-update-modal
                            @click="editInvoiceAdd(invoiceadd)">
                        {{editButtonText}}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- EDIT modal -->
      <b-modal ref="editInvoiceAddModal"
              id="invoiceadd-update-modal"
              title="Update the invoice addvert"
              hide-footer size="lg" >
        <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Advert"
                      label-for="form-name-input" label-cols="1" label-cols-sm="1" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editInvoiceAddForm.AddText"
                              required
                              placeholder="Enter advert text" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center" v-if="isAdmin">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        // env_url: process.env.ENV_API_URL,
        addInvoiceAddForm: {
          Name: '' ,
          BillingName: '' ,
          Address1: null ,
          Address2: null ,
          PostCode: null ,
          City: null ,
          Country: null ,
          ContactName: null,
          ContactNumber: null,
          FaxNumber: null
        },
        editInvoiceAddForm: {
          AddId: null,
          Name: null ,
          BillingName: null ,
          Address1: null ,
          Address2: null ,
          PostCode: null ,
          City: null ,
          Country: null ,
          ContactName: null,
          ContactNumber: null,
          FaxNumber: null
        },
        apiInvoiceAdds: null,
        executed: false,
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
      };
    },

    computed:{
    filteredInvoiceAdds() {
      console.log('filtered invoiceadd');
        return this.apiInvoiceAdds.filter(c => {
          if(this.filter == '') return true;
          return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.BillingName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
                //  (c.ContactName && c.ContactName.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0
        })
      },
    SortedapiInvoiceAdds:function() {
      console.log('sort invoiceadd');
      return this.filteredInvoiceAdds.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredInvoiceAdds.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List invoiceadds
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        console.log('XXXXXXXXXXXXXXXXXXXXgetting the dataXXXXXXXXXXXXXXXXXX')
        try {
          console.log(process.env.VUE_APP_API_BASE_URL)
          console.log(process.env.VUE_APP_TEST)
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoiceadds`);

          this.apiInvoiceAdds = data;
          this.executed = true;
        } catch (e) {
          this.apiInvoiceAdds = `Error: the server responded with '${e.response}'`;
        }
      },


    //
      editInvoiceAdd(invoiceadd) {
        console.log('editInvoiceAdd');
        this.editInvoiceAddForm = invoiceadd;
        this.callApi()
      },

      // *******************************************
      // Update invoiceadd on submit
      // *******************************************
      onSubmitUpdate(evt) {
        console.log('in onSubmitUpdate');
        console.log('invoiceadd_id');
        evt.preventDefault();
        this.$refs.editInvoiceAddModal.hide();
        const payload = {
          AddText: this.editInvoiceAddForm.AddText,
        };
        this.updateInvoiceAdd(payload, this.editInvoiceAddForm.AddId);
        this.callApi()
      },

      async updateInvoiceAdd(payload, invoiceaddid) {

        console.log('in update invoiceadd');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoiceadds/${invoiceaddid}`, payload).then(() => {
            this.message = 'invoiceadd updated!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated invoiceadd. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add invoiceadd. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi();

          });

          // this.apiInvoiceAdds = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiInvoiceAdds = `Error: the server responded with '${e.response}'`;
        }
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editInvoiceAddModal.hide();
        this.initForm();
        this.getInvoiceAdds(); // why?
      },
    },
    created() {
      console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
