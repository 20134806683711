<template>
    <div>
      <p><u><strong>Call Data</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
           <!-- <div>
            <button type="button" class="btn btn-success btn-sm" @click="onStartBills()">Generate Call CSV</button>
            </div> -->
            <div v-if="this.apiInvoiceLog.Name==='CSV GENERATION COMPLETED'" >
             <button v-show="showGenerate"  type="button" class="btn btn-success btn-sm" @click="onStartBills()">Generate Call Data</button>
             <button v-show="showGenerate" type="button"  style="margin-left :50px" class="btn btn-success btn-sm" v-b-modal.calldataedetails-modal @click="editCallDataDetails()">Call Data Email Details</button>
             <button v-show="showEmail"  style="margin-left :400px" type="button" class="btn btn-success btn-sm" v-on:click="onStartCSVEmail()" >Email Call Data</button>
          </div>
          <div v-else>    
            <!-- <button v-show="showGenerate" type="button" class="btn btn-success btn-sm" @click="onStartBills()">Generate Call Data</button>
            <button v-show="showGenerate" type="button"  style="margin-left :50px" class="btn btn-success btn-sm" v-b-modal.calldataedetails-modal @click="editCallDataDetails()">Call Data Email Details</button> -->
            <div v-if="this.showGenerate==true">
              <button v-show="showGenerate" type="button" class="btn btn-success btn-sm" @click="onStartBills()">Generate Call Data</button>
              <button v-show="showGenerate" type="button"  style="margin-left :50px" class="btn btn-success btn-sm" v-b-modal.calldataedetails-modal @click="editCallDataDetails()">Call Data Email Details</button>
            </div>
            <div v-else>
              <button  type="button"   class="btn btn-success btn-sm" v-b-modal.calldataedetails-modal @click="editCallDataDetails()">Call Data Email Details</button>
            </div>
            
          </div>


            <br>
          <p  v-if="this.apiInvoiceLog.Name==='CSV GENERATION COMPLETED'" style="color:blue;font-size:16px;text-align:left;"> {{ this.apiInvoiceLog.Name }}  --   Finished on: {{ this.apiInvoiceLog.FinishedOn }} </p>
          <p  v-else-if="this.apiInvoiceLog.Name" style="color:blue;font-size:16px;text-align:left;"> {{ this.apiInvoiceLog.Name }} -- Started on: {{ this.apiInvoiceLog.StartedOn }}  </p>
          <p  v-else style="color:blue;font-size:16px;text-align:left;"> </p>
            <br>

          <input type="search" v-model="filter">

          <div v-if="this.apiInvoiceLog.Name==='CSV GENERATION COMPLETED'" align="right" >
            <button  v-on:click="onDownloadAll()">Download All</button>
          </div>


          <table class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th scope="col" @click="sort('AccountNumber')">Account No</th>
                <th scope="col" @click="sort('AccountName')">Account Name</th>
                <th scope="col" >File Name</th>
                <!-- <th scope="col" @click="sort('Finished')">Finished</th>
                <th scope="col" @click="sort('FinishedOn')">Finished on</th> -->
                <th scope="col" @click="sort('Message')">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(billlog, index) in Sortedapiexportcsv" :key="index">
                <td v-if="billlog.AccountName">{{ billlog.AccountNumber }}</td>
                <td v-else>{{ billlog.GroupNo }}</td>
                <td v-if="billlog.AccountName">{{ billlog.AccountName }}</td>
                <td v-else>{{ billlog.GroupName }}</td>
                <td>{{ billlog.Name }}</td>
                <!-- <td>{{ billlog.Finished }}</td>
                <td>{{ billlog.FinishedOn }}</td> -->
                <td>{{ billlog.Message }}</td>
                <div v-if="billlog.Name === 'CSV generation start'">
                    <td><button v-on:click="onDownloadAll()">Download All</button></td>
                </div>
                <div v-else>
                        <td><button v-on:click="onDownloadInvoice(billlog.Name)">Download</button></td>
                </div>

              </tr>
            </tbody>
          </table>
          <div v-show="showPaging">
            <p align="center">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredbilllogs.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
          </p>
      </div>
        </div>
      </div>

      <b-modal ref="editCallDataDetails"
              id="calldataedetails-modal"
              title="Call Data Email Details"
              hide-footer size="m" >
              <b-form @submit="onSubmit" @reset="onReset" style="width: 200">
          <b-form-row>
            <b-col>
        <b-form-group id="form-title-group"
                      label="Subject"
                      label-for="form-title-input">
                      <b-form-textarea id="form-title-input"
                          rows="3"
                          max-rows="6"
                          v-model="editCallDataDetailsForm.EmailSubject"
                          required
                          placeholder="Insert subject">
                        </b-form-textarea>
          </b-form-group>
        </b-col>
      </b-form-row>
          <b-form-row>
            <b-col>
          <b-form-group id="form-author-group"
                        label="Body"
                        label-for="form-author-input">
              <b-form-textarea id="form-author-input"
                            rows="3"
                            max-rows="6"
                            v-model="editCallDataDetailsForm.EmailBody"
                            required
                            placeholder="Insert body">
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center" v-if="isAdmin">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import CarrierDropDownVue from "../components/CarrierDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        timer1: "",
        timer2: "",
        selectedCarrier:"",
        apibills:{},
        addbilllogForm: {
          Carrier: '' ,
          CusBillingName: '' ,
          FileName: ''

        },
        editCallDataDetailsForm: {
          DetailId: '',
          DDCDate: '',
          AddText: '',
          EmailBody: '',
          EmailSubject: ''
        },
        apiexportcsv: null,
        executed: false,
        apiInvoiceLog:{},
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showPaging:false,
        showGenerate:false,
        showEmail:false
      };
    },

    computed:{
    filteredbilllogs() {
      // return this.apiexportcsv;
      console.log('filtered billlog');
        return this.apiexportcsv.filter(c => {
          if(this.filter == '') return true;
          if (c.AccountNumber == null) c.AccountNumber = '';
          if (c.GroupNo == null) c.GroupNo = '';
          if (c.AccountName == null) c.AccountName = '';
          if (c.GroupName == null) c.GroupName = '';
          return c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.GroupNo.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.GroupName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    Sortedapiexportcsv:function() {
      console.log('sort exportcsv');
      if (this.apiexportcsv == null) return null;
      return this.filteredbilllogs.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      // on download all method
      onDownloadAll() {
      console.log()
      this.downloadInvoice('all');
      },
      // on download method
      onDownloadInvoice(invoicename) {
      console.log(invoicename)
      this.downloadInvoice(invoicename);
      },
      // api get download invoice method
      async downloadInvoice(invoicename) {

        console.log('***********************download*****************************')

        document.body.style.cursor = 'wait';
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/exportcsvs/download/` + invoicename,{ // Note the change here
          responseType: 'blob',
        });

          this.executed = true;
          var fileURL = window.URL.createObjectURL(new Blob([data]));
          console.log("download data")
          console.log( data)

          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          if (invoicename == 'all'){
            invoicename = 'callscsv.zip';
          }
          fileLink.setAttribute('download', invoicename);
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.style.cursor = 'auto';

        } catch (e) {
          this.apiexportcsv = `Error: the server responded with '${e.response}'`;
        }
      },
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredbilllogs.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // Start email csv
      // *******************************************
      onStartCSVEmail() {
        console.log('on start email');
        this.StartCSVEmail();
      },
       // *******************************************
      // start csv email on submit
      // *******************************************
      // start csv email method to post to API
      async StartCSVEmail() {

        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/exportcsvs/emailcsv`, null).then(() => {
            this.message = 'email started!';
            this.showMessage = true;

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated billlog. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add billlog. status code:  " + err.response.status)
              }
            console.error(err);

          });

          // this.apiexportcsv = data;
          // this.executed = true;
          console.log(data)
          //this.callApi()
        } catch (e) {
          this.apiexportcsv = `Error: the server responded with '${e.response}'`;
        }
      },
      // *******************************************
      // Get invoice log
      // *******************************************
      async callInvoiceLogApi(lablex) {

        console.log(lablex)
        console.log('***********************getting the data')

        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/exportcsvs/logs`);

          this.apiInvoiceLog = data;
          console.log("invoice log returned................");
          console.log(data);
          this.executed = true;
          // stop the autorefresh
          // if (this.apiInvoiceLog.Name==='INVOICE GENERATION COMPLETED'){
          //   console.log("inside stop auto refresh")
          //   this.cancelAutoUpdate();
          // }
        } catch (e) {
          this.apiInvoiceLog = `Error: the server responded with '${e.response}'`;
        }
      },
      // *******************************************
      // get calldata details
      // *******************************************
      async callDetailsApi(lablex) {

      console.log(lablex)
      var url;
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/invoicedetails?q=CALLDATA`;

      try {
        const { data } = await this.$http.get(url);

        this.apiCallDataDetail = data;
        console.log('this.apiCallDataDetail' )
        console.log(this.apiCallDataDetail )
        this.editCallDataDetailsForm= this.apiCallDataDetail ;
        this.executed = true;
      } catch (e) {
        this.apiCallDataDetail = `Error: the server responded with '${e.response}'`;
      }
      },
      editCallDataDetails() {
      console.log('editIndustryType');
      this.editCallDataDetailsForm = this.apiCallDataDetail;
      },
      // *******************************************
      // update invoice details on submit
      // *******************************************
      onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.editCallDataDetails.hide();
      const payload = {
        DDCDate: this.editCallDataDetailsForm.DDCDate,
        AddText: this.editCallDataDetailsForm.AddText,
        EmailSubject: this.editCallDataDetailsForm.EmailSubject,
        EmailBody: this.editCallDataDetailsForm.EmailBody,
      };
      this.updateCallDataDetail(payload, this.editCallDataDetailsForm.DetailId);
      },
      // *******************************************
      // Update invoice detailson submit
      // *******************************************
      async updateCallDataDetail(payload, detailid) {

      console.log('in update invoiceadd');
      console.log(payload);
      try {
      const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoicedetails/${detailid}`, payload).then(() => {
        this.message = 'invoiceadetails updated!';
        this.showMessage = true;

      }).catch((err) => {
        if(err.response.status == 409) {
          alert("Duplicated invoiceadd. status code:  " + err.response.status)
          }
        if(err.response.status != 409) {
          alert("Problem with add invoiceadd. status code:  " + err.response.status)
          }

        console.error(err);
      });
      console.log('delete data is:')
      console.log(data)
      this.callDetailsApi();
      } catch (e) {
      this.apiInvoiceAdds = `Error: the server responded with '${e.response}'`;
      }
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.editCallDataDetails.hide();
      },
      // *******************************************
      // List invoice logs
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        console.log('***********************getting the data')

        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/exportcsvs`);

          this.apiexportcsv = data;
          console.log(data);
          if (this.apiexportcsv.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
          this.executed = true;
        } catch (e) {
          this.apiexportcsv = `Error: the server responded with '${e.response}'`;
        }
      },
      // Add invoicelog method to post to API
      async addinvoicelog() {

        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/exportcsvs`, null).then(() => {
            this.message = 'invoices started!';
            this.showMessage = true;
            this.callApi();
            this.callInvoiceLogApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated invoicelog. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add invoicelog. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });

          // this.apiexportcsv = data;
          // this.executed = true;
          console.log(data)
          //this.callApi()
        } catch (e) {
          this.apiexportcsv = `Error: the server responded with '${e.response}'`;
        }
      },
      // *******************************************
    // List bill logs
    // *******************************************
    async callBillLogApi(lablex) {
      console.log(lablex)
      console.log('***********************getting the data')

      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/bills/billlogs`);

        this.apibills = data;
        console.log("in bill log.................");
        // console.log(data);
        if (this.apibills.StageNumber > 21){
          this.showGenerate = true;
        }
        if (this.apibills.StageNumber== 5000){
          this.showEmail= true;
        }

        console.log("stage number")

        console.log(this.apibills.StageNumber)
        console.log("show generate")
        console.log(this.showGenerate)
        this.executed = true;
        // stop the autorefresh
        // if (this.apibills.Name==='ORDER GENERATION COMPLETED'){
        //   console.log("inside stop auto refresh")
        //   this.cancelAutoUpdate();
        // }
      } catch (e) {
        this.apibills = `Error: the server responded with '${e.response}'`;
      }},
      initForm() {
        console.log('init form');
          this.addbilllogForm.Carrier =  '';
          this.addbilllogForm.BillingName =  '';
          this.addbilllogForm.FileName =  '';

      },
      // *******************************************
      // Add billlog on submit
      // *******************************************
      onStartBills() {
        console.log('on start invoices');
        this.addinvoicelog();
        //this.initForm();
      },
      // onReset(evt) {
      //   evt.preventDefault();
      //   this.$refs.addbilllogModal.hide();
      //   this.initForm();
      // },
    //
      onResetLoad() {
        console.log('onresetload')
        if(confirm("Are you sure you want to reset all CDR loads?")){
          this.resetLoad();
        }

      },
      // reset load
      // *******************************************
      async resetLoad() {

        console.log("in reset load before")
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing/reset`).then(() => {
            this.message = 'account removed!';
            this.showMessage = true;
            console.log("in removeAccount after")
            this.callApi("coming from delete");

          }).catch((err) => {
            console.log("in removeAccount error")
            console.error(err);
            this.callApi();

          });

          // this.apiAccounts = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiAccounts = `Error: the server responded with '${e.response}'`;
        }
      },
      cancelAutoUpdate() {
        clearInterval(this.timer1);
        clearInterval(this.timer2);
    },
    },
    created() {
      console.log('created')
      this.callBillLogApi();
      this.callDetailsApi();
      this.callApi();
      this.callInvoiceLogApi();
      this.timer1 = setInterval(this.callApi, 20000);
      this.timer2 = setInterval(this.callInvoiceLogApi, 20000);


    },
    components: {
      alert: Alert,
      listfilter:Filter,
      CarrierDropDownVue: CarrierDropDownVue,
    },
    beforeDestroy() {
    this.cancelAutoUpdate();
  },
  };
  </script>
