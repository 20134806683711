<template>

  <div>
    <p><u><strong>Inbound Billing References</strong></u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onFind">
          <b-form-row>
            <b-col>
              <b-form-group id="fieldset-horizontal" label-cols-sm="4" label-cols-lg="3" content-cols-sm
                content-cols-lg="8" label="Inbound" label-for="input-horizontal">
                <b-form-input id="form-PhoneNumber-input" type="text" size="m" v-model="SearchPhoneNumber"
                  placeholder="Enter Phone number">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col> <b-button type="submit" variant="primary">Find</b-button></b-col>
          </b-form-row>

        </b-form>
        <br><br>
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.inbound-modal>Insert Inbound Billing
          Reference</button>&nbsp;&nbsp;&nbsp;
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.inbound-load-modal>Load
          inbounds</button>&nbsp;&nbsp;&nbsp;
        <a :href="'deletedinbounds'" target="_blank">
          <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm">Deleted</button>
        </a>
        <br><br>
        <!-- <input type="search" v-model="filter"> -->
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-sm" scope="col" @click="sort('account.AccountNumber')">Account No</th>
              <th class="th-sm" scope="col" @click="sort('account.Name')">Account Name</th>
              <th class="th-sm" scope="col" @click="sort('costcentre.Name')">Cost Centre No</th>
              <th class="th-sm" scope="col" @click="sort('costcentre.Name')">Cost Centre Name</th>
              <th class="th-lg" scope="col" @click="sort('PhoneNumber')">Inbound</th>
              <th class="th-lg" scope="col" @click="sort('Label')">Label</th>
              <th class="th-sm" scope="col" @click="sort('DayRate')">Day Nat</th>
              <th class="th-sm" scope="col" @click="sort('EveRate')">Eve Nat</th>
              <th class="th-sm" scope="col" @click="sort('WendRate')">W/E Nat</th>
              <th class="th-sm" scope="col" @click="sort('DayRateMobile')">Day Mob</th>
              <th class="th-sm" scope="col" @click="sort('EveRateMobile')">Eve Mob</th>
              <th class="th-sm" scope="col" @click="sort('WendRateMobile')">W/E Mob</th>
              <th class="th-sm" scope="col" @click="sort('ConnFee')">Con Fee</th>
              <th></th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(inbound, index) in SortedapiInbounds" :key="index">
              <td>{{ inbound.account.AccountNumber }}</td>
              <td>{{ inbound.account.Name }}</td>
              <td v-if="inbound.costcentre">{{ inbound.costcentre.CostCentreNumber }}</td>
              <td v-else></td>
              <td v-if="inbound.costcentre">{{ inbound.costcentre.Name }}</td>
              <td v-else></td>
              <td>{{ inbound.PhoneNumber }}</td>
              <td>{{ inbound.Label }}</td>
              <td align="center">{{ inbound.DayRate }}</td>
              <td align="center">{{ inbound.EveRate }}</td>
              <td align="center">{{ inbound.WendRate }}</td>
              <td align="center">{{ inbound.DayRateMobile }}</td>
              <td align="center">{{ inbound.EveRateMobile }}</td>
              <td align="center">{{ inbound.WendRateMobile }}</td>
              <td align="center">{{ inbound.ConnFee }}</td>
              <td align="center"><a :href="'/inboundhistory?inbound_id=' + inbound.inbound_id"
                  target="_blank">History</a></td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" v-b-modal.inbound-update-modal
                    @click="editInbound(inbound)">
                    {{editButtonText}}
                  </button>
                  <p> </p>
                  <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm" @click="onDeleteInbound(inbound)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredInbounds.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
    <!-- LOAD modal -->
    <b-modal ref="loadInboundModal" id="inbound-load-modal" title="Load inbound billing references" hide-footer
      size="xs">
      <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
        <b-form-row>
          <b-col>
            <p>Select a comma seperated file (.txt).</p>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadInboundForm.FileName" required
              placeholder="Select inbound billing reference file">
            </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- add modal -->
    <b-modal ref="addInboundModal" id="inbound-modal" title="Insert a new inbound billing reference" hide-footer size="lg">
      <b-form @submit="onSubmit" @reset="onReset" class="w-600">
        <b-form-row>

          <b-col>
            <b-form-group id="form-Bundle-group" label="Account" label-for="form-Bundle-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <div align="left">
                <AccountDropdownVue @account-changed="accountChange"></AccountDropdownVue>
              </div>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-CostCentre-group" label="Cost Centre" label-for="form-CostCentre-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select style="width: 100%" v-model="addInboundForm.CostCentreId" @change="onChangeCostCentre($event)">
                  <option value="">Select cost center</option>
                  <option v-for="(costcentre, index) in CostCentres" :value="costcentre.costcentre_id" :key="index">
                    {{ costcentre.CostCentreNumber + ' - ' + costcentre.Name }}
                  </option>
                </select>
                <!-- <p v-if="SelectedCostCentre">Selected costcentre - {{ this.SelectedCostCentre }}</p> -->
              </div>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Inbound" label-for="form-PhoneNumber-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="sm" v-model="addInboundForm.PhoneNumber"
                placeholder="Enter inbound">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <!-- <div align="left">
              <AccountDropdownVue v-bind:selected_account="apiInboundAccount.account_id" @account-changed="accountChange"></AccountDropdownVue></div>
            <br> -->
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Label-group" label="Label" label-for="form-Label-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Label-input" type="text" size="sm" v-model="addInboundForm.Label"
                placeholder="Enter label">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-DayRate-group" label="Day Nat" label-for="form-DayRate-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-DayRate-input" type="number" step="0.0001" size="sm"
                v-model="addInboundForm.DayRate" placeholder="Enter day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-EveRate-group" label="Eve Nat" label-for="form-EveRate-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-EveRate-input" type="number" step="0.0001" size="sm"
                v-model="addInboundForm.EveRate" placeholder="Enter evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-WendRate-group" label="W/E Nat" label-for="form-WendRate-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-WendRate-input" type="number" step="0.0001" size="sm"
                v-model="addInboundForm.WendRate" placeholder="Enter weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-DayRateMobile-group" label="Day Mob" label-for="form-DayRateMobile-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-DayRateMobile-input" type="number" step="0.0001" size="sm"
                v-model="addInboundForm.DayRateMobile" placeholder="Enter mobile day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-EveRateMobile-group" label="Eve Mob" label-for="form-EveRateMobile-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-EveRateMobile-input" type="number" step="0.0001" size="sm"
                v-model="addInboundForm.EveRateMobile" placeholder="Enter mobile evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-WendRateMobile-group" label="W/E Mob" label-for="form-WendRateMobile-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-WendRateMobile-input" type="number" step="0.0001" size="sm"
                v-model="addInboundForm.WendRateMobile" placeholder="Enter mobile weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ConnFee-group" label="Con Fee" label-for="form-ConnFee-input" label-cols="5"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-ConnFee-input" type="number" step="0.0001" size="sm"
                v-model="addInboundForm.ConnFee" placeholder="Enter connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Note-input" label-size="sm" size="xs">
              <b-form-textarea id="textarea" v-model="addInboundForm.Notes" placeholder="Enter notes" rows="3"
                max-rows="6"></b-form-textarea>

            </b-form-group>
          </b-col>
          <!-- <b-col>
        </b-col> -->
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- EDIT modal -->
    <b-modal ref="editInboundModal" id="inbound-update-modal" title="Update inbound billing reference" hide-footer
      size="lg">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
        <b-form-row>

          <b-col>
            <b-form-group id="form-Bundle-group" label="Account" label-for="form-Bundle-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <div align="left">
                <AccountDropdownVue v-bind:selected_account="editInboundForm.AccountId"
                  @account-changed="accountChange"></AccountDropdownVue>
              </div>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-CostCentre-group" label="Cost Centre" label-for="form-CostCentre-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select style="width: 100%" v-model="editInboundForm.CostCentreId" @change="onChangeCostCentre($event)">
                  <option value="">Select cost center</option>
                  <option v-for="(costcentre, index) in CostCentres" :value="costcentre.costcentre_id" :key="index">
                    {{ costcentre.CostCentreNumber + ' - ' + costcentre.Name }}
                  </option>
                </select>
                <!-- <p v-if="SelectedCostCentre">Selected costcentre - {{ this.SelectedCostCentre }}</p> -->
              </div>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Inbound" label-for="form-PhoneNumber-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="sm" v-model="editInboundForm.PhoneNumber"
                readonly placeholder="Enter inbound">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>

          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Label-group" label="Label" label-for="form-Label-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Label-input" type="text" size="sm" v-model="editInboundForm.Label"
                placeholder="Enter label">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-DayRate-group" label="Day Nat" label-for="form-DayRate-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-DayRate-input" type="number" step="0.0001" size="sm"
                v-model="editInboundForm.DayRate" placeholder="Enter day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-EveRate-group" label="Eve Nat" label-for="form-EveRate-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-EveRate-input" type="number" step="0.0001" size="sm"
                v-model="editInboundForm.EveRate" placeholder="Enter evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-WendRate-group" label="W/E Nat" label-for="form-WendRate-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-WendRate-input" type="number" step="0.0001" size="sm"
                v-model="editInboundForm.WendRate" placeholder="Enter weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-DayRateMobile-group" label="Day Mob" label-for="form-DayRateMobile-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-DayRateMobile-input" type="number" step="0.0001" size="sm"
                v-model="editInboundForm.DayRateMobile" placeholder="Enter mobile day rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-EveRateMobile-group" label="Eve Mob" label-for="form-EveRateMobile-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-EveRateMobile-input" type="number" step="0.0001" size="sm"
                v-model="editInboundForm.EveRateMobile" placeholder="Enter mobile evening rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-WendRateMobile-group" label="W/E Mob" label-for="form-WendRateMobile-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-WendRateMobile-input" type="number" step="0.0001" size="sm"
                v-model="editInboundForm.WendRateMobile" placeholder="Enter mobile weekend rate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ConnFee-group" label="Con Fee" label-for="form-ConnFee-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-ConnFee-input" type="number" step="0.0001" size="sm"
                v-model="editInboundForm.ConnFee" placeholder="Enter connection fee">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Note-input" label-size="sm" size="xs">
              <b-form-textarea id="textarea" v-model="editInboundForm.Notes" placeholder="Enter notes" rows="3"
                max-rows="6"></b-form-textarea>

            </b-form-group>
          </b-col>
          <!-- <b-col>
        </b-col> -->
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row" v-if="isAdmin">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      loadInboundForm: {
        FileName: ''
        },
      editButtonText: editButtonText(),
        SearchPhoneNumber: null,
        SelectedCostCentre: null,
        addInboundForm: {
          PhoneNumber: null ,
          DayRate: null ,
          EveRate: null ,
          WendRate: null ,
          DayRateMobile: null ,
          EveRateMobile: null,
          WendRateMobile: null,
          CostCentreId: null,
          ConnFee : 0,
          Label: null,
          Notes:null
        },
        editInboundForm: {
          InboundId: null,
          PhoneNumber: null ,
          DayRate: null ,
          EveRate: null ,
          WendRate: null ,
          DayRateMobile: null ,
          EveRateMobile: null,
          WendRateMobile: null,
          CostCentreId: null,
          ConnFee : null,
          Label: null,
          Notes:null
        },
        apiInbounds: [],
        executed: false,
        message: 'initial alert',
        currentSort:'account.Name',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:false,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selected_account: null,
        CostCentres: null,
        showresult: false
      };
    },

    computed:{
    filteredInbounds() {
      // // console.log('filtered inbound');
        return this.apiInbounds.filter(c => {
          if(this.filter == '') return true;
          return c.PhoneNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    SortedapiInbounds:function() {
      // console.log('sort inbound');
      if (this.apiInbounds != null){
        return this.filteredInbounds.slice().sort((a,b) => {
          let modifier = 1;
          var numfields = ['DayRate','EveRate','WendRate','DayRateMobile', 'EveRateMobile', 'WendRateMobile']
          if(this.currentSortDir === 'desc') modifier = -1;
          if (numfields.includes(this.currentSort)) {
          if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        }
        else if(this.currentSort === 'account.Name'){
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
        }
        else{
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
          return 0;
        }
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
          else{ return null}
    },
    },
    methods: {
      download: function() {
        console.log("in download")
        var data = "AccountNumber,CostCentre,Inbound,Label,DayNat,EveNat,WENat,DayMobile,EveMobile,WEMobile,ConFee,Notes,Action"
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;

        fileLink.setAttribute('download', "UploadInboundBillingReferences.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
      onChangePhoneType(event) {
        this.SelectedPhoneType= event.target.value

        // console.log(event.target.value)


        // this.$emit('account-changed',this.SelectedAccount)
      },
      onChangeCostCentre(event) {
        this.SelectedCostCentre= event.target.value

        // console.log(event.target.value)


        // this.$emit('account-changed',this.SelectedAccount)
      },
      accountChange: function (e) {
          this.selectedAccount = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredInbounds.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
      // *******************************************
      // List costcentres
      // *******************************************
      async callCostCentreApi(lablex) {

        console.log(lablex)
      //   // console.log('getting the costcentre list')
      //   // console.log(this.$route.query.account_id)
      //   // console.log('getting the costcentre list')
        var url;
        if (typeof(this.$route.query.account_id) != "undefined"){
          //   // console.log('selected account in CostCentre is AAAAAAAAAA:');
            // console.log(this.$route.query.account_id)
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.$route.query.account_id + '"}]}';
            // console.log(url)
        }
        else{
          //   // console.log('in where the account cc are selected BBBBBBBBBBB')
            // console.log(this.editInbound.AccountId)
            // console.log(this.editInboundForm.AccountId)
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.editInboundForm.AccountId + '"}]}';
        }
        try {
          const { data } = await this.$http.get(url);

          this.CostCentres = data;
          if (this.apiCostCentres.length > this.pageSize){
            this.showPaging = true
          }
          // console.log(this.CostCentres)

          this.executed = true;
        } catch (e) {
          this.apiCostCentres = `Error: the server responded with '${e.response}'`;
        }
      },
        // *******************************************
      // search CLI
      // *******************************************
      onFind(evt) {
        // console.log('on Find');
        this.apiCLIs = null;
        this.showresult = false;
        this.callApi(this.SearchPhoneNumber);
        // this.showresult = true;
        evt.preventDefault();
        },

      // *******************************************
      // List inbounds
      // *******************************************
      async callApi(searchparam) {

        // console.log(searchparam)
        // console.log('getting the inbound list')
        var url;
        var searchlike = '%25' + searchparam + '%25';
        if(typeof(searchparam) != "undefined"){
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/inbounds?q={"filters":[{"name":"PhoneNumber","op":"like","val":"' + searchlike + '"}]}';
          this.ShowAccount = false
          this.selected_account = this.$route.query.account_id
          // console.log('in if the selected account is:')
          // console.log(this.selected_account)
        }
        else{
            url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/inbounds`
        }
        try {
          document.body.style.cursor = 'wait';
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiInbounds = data;
          // console.log("found inbounds")
          // console.log(data)
          this.showresult = true
          this.executed = true;
          if (this.apiInbounds.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
          this.executed = true;
        } catch (e) {
          this.apiInbounds = `Error: the server responded with '${e.response}'`;
        }
      },

    // *******************************************
    // Load on submit
    // *******************************************
    onLoadSubmit(evt) {
      console.log('on load submit');
      evt.preventDefault();
      this.$refs.loadInboundModal.hide();
      const payload = {
        FileName: this.loadInboundForm.FileName
      };
      this.loadintbounds(payload);
      //this.initForm();
    },
    onLoadReset(evt) {
    evt.preventDefault();
    this.$refs.loadInboundModal.hide();
    this.initForm();
    },
    // Add cdrload method to post to API
    async loadintbounds(payload) {

      let formData = new FormData();
      console.log( 'in load outbounds');
      console.log( payload.file);
      formData.append('file', payload.FileName);
      var url;
      
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/inbounds/load`
      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'cost centre loaded!';
          this.showMessage = true;
          document.body.style.cursor = 'auto';
          // this.callApi();
        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          document.body.style.cursor = 'auto';
          // this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
      // *******************************************
      // Delete inbound
      // *******************************************
      async removeInbound(inboundid) {

      //   // console.log("in removeInbound before")
      //   // console.log(inboundid)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/inbounds/${inboundid}`).then(() => {
            this.message = 'inbound removed!';
            this.showMessage = true;
            // console.log("in removeInbound after")
            this.callApi("coming from delete");

          }).catch((err) => {
            // console.log("in removeInbound error")
            console.error(err);
            this.callApi();

          });

          // console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiInbounds = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteInbound(inbound) {
        // console.log(inbound.InboundId)
        if(confirm("Are you sure you want to delete this inbound?")){
          this.removeInbound(inbound.inbound_id);
        }
      },

      // Add inbound method to post to API
      async addInbound(payload) {

      //   const id_token = claims.__raw;
        // console.log('in add inbound');
      //   // console.log('payload is');
        // console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/inbounds`, payload).then(() => {
            this.message = 'inbound added!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated inbound. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add inbound. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiInbounds = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
      //   // console.log('init form');
          this.addInboundForm.PhoneNumber =  null;
          this.addInboundForm.DayRate = null;
          this.addInboundForm.EveRate = null;
          this.addInboundForm.WendRate = null;
          this.addInboundForm.DayRateMobile = null;
          this.addInboundForm.EveRateMobile = null;
          this.addInboundForm.WendRateMobile = null;
          this.addInboundForm.SelectedCostCentre=  null;
          this.addInboundForm.Label=  null;
          this.addInboundForm.Notes = null;

      },
      // *******************************************
      // Add inbound on submit
      // *******************************************
      onSubmit(evt) {
        // console.log('on submitXXXXXXXXXXXXX');
        evt.preventDefault();
        this.$refs.addInboundModal.hide();
        var accountid;
        // console.log('selected account');
        // console.log(this.selected_account);
        if ((this.selected_account) != null){
          accountid = this.selected_account
        }
        else{
          accountid = this.selectedAccount
        }
        const payload = {
          PhoneNumber: this.addInboundForm.PhoneNumber,
          CostCentreId: this.SelectedCostCentre,
          AccountId: accountid,
          DayRate: this.addInboundForm.DayRate,
          EveRate: this.addInboundForm.EveRate,
          WendRate: this.addInboundForm.WendRate,
          DayRateMobile: this.addInboundForm.DayRateMobile,
          EveRateMobile: this.addInboundForm.EveRateMobile,
          WendRateMobile: this.addInboundForm.WendRateMobile,
          ConnFee: this.addInboundForm.ConnFee,
          Label : this.addInboundForm.Label,
          Notes : this.addInboundForm.Notes
        };
      //   // console.log('in add inbound ')
      //   // console.log(payload)
        this.addInbound(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addInboundModal.hide();
        this.initForm();
      },
    //
      editInbound(inbound) {
        // console.log('editInbound');
        // console.log(inbound);
        this.callCostCentreApi();
      //   // console.log(inbound.inbound_id);
        this.SelectedCostCentre =  inbound.CostCentreId;
        this.editInboundForm = inbound;

      //   this.callApi()
      },

      // *******************************************
      // Update inbound on submit
      // *******************************************
      onSubmitUpdate(evt) {
      //   // console.log('in onSubmitUpdatexxxxxxxxxxxxx');
      //   // console.log('selectedAccount is');
      //   // console.log(this.selectedAccount);
      //   // console.log('inbound_id');
        evt.preventDefault();
        this.$refs.editInboundModal.hide();
        const payload = {
          PhoneNumber: this.editInboundForm.PhoneNumber,
          DayRate: this.editInboundForm.DayRate,
          EveRate: this.editInboundForm.EveRate,
          WendRate: this.editInboundForm.WendRate,
          DayRateMobile: this.editInboundForm.DayRateMobile,
          EveRateMobile: this.editInboundForm.EveRateMobile,
          WendRateMobile: this.editInboundForm.WendRateMobile,
          AccountId: this.selectedAccount,
          CostCentreId: this.SelectedCostCentre,
          ConnFee: this.editInboundForm.ConnFee,
          Label : this.editInboundForm.Label,
          Notes : this.editInboundForm.Notes
        };
        // console.log(payload)
        this.updateInbound(payload, this.editInboundForm.inbound_id);
        this.callApi(this.SearchPhoneNumber);
      },

      async updateInbound(payload, inboundid) {

        // console.log('in update inbound');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/inbounds/${inboundid}`, payload).then(() => {
            this.message = 'inbound updated!';
            this.showMessage = true;
            this.callApi(this.SearchPhoneNumber);

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated inbound. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add inbound. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi(this.SearchPhoneNumber);

          });

          // this.apiInbounds = data;
          // this.executed = true;
          // console.log('delete data is:')
          console.log(data)
          this.callApi(this.SearchPhoneNumber);
        } catch (e) {
          this.apiInbounds = `Error: the server responded with '${e.response}'`;
        }
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editInboundModal.hide();
        this.initForm();
        this.getInbounds(); // why?
      },
    },

    created() {
      // console.log('created')
      this.callCostCentreApi();
      this.executed = true;
    //   this.callApi();


    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
    },
  };
  </script>
