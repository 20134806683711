<template>
  <div>
    <p><u><strong>Bills & Orders Generation</strong></u> </p>
    <br>
    <div class="result-block-container">

      <div :class="['result-block', executed ? 'show' : '']">
         <div>
          <button type="button" class="btn btn-success btn-sm" @click="onStartBills()">Generate Orders</button>
          </div>
        <!-- <button type="button" class="btn btn-success btn-sm" v-b-modal.billlog-modal>Start Pricing</button> -->
        <br><br>
        <!-- <input type="search" v-model="filter"> -->
        <table class="table table-striped w-auto" id="apirecords">
          <thead >
            <tr>
              <th scope="col" @click="sort('Name')">Bill stage</th>
              <th scope="col" @click="sort('StartedOn')">Started on</th>
              <th scope="col" @click="sort('Finished')">Finished</th>
              <th scope="col" @click="sort('FinishedOn')">Finished on</th>
              <th scope="col" @click="sort('Message')">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(billlog, index) in SortedapiBillLogs" :key="index">
              <td>{{ billlog.Name }}</td>
              <td>{{ billlog.StartedOn }}</td>
              <td>{{ billlog.Finished }}</td>
              <td>{{ billlog.FinishedOn }}</td>
              <td>{{ billlog.Message }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <!-- <div>
          <button type="button" class="btn btn-danger btn-sm" @click="onResetLoad()">Reset Pricing</button>
          </div> -->
      </div>
    </div>


  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import CarrierDropDownVue from "../components/CarrierDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      timer: "",
      selectedCarrier:"",
      addbilllogForm: {
        Carrier: '' ,
        CusBillingName: '' ,
        FileName: ''

      },
      apibills: null,
      executed: false,
      message: 'initial alert',
      currentSort:'StartedOn',
      currentSortDir:'asc',
      pageSize:20,
      currentPage:1,
      filter:'',
    };
  },

  computed:{
  filteredbilllogs() {
    console.log('filtered billlog');
      return this.apibills.filter(c => {
        if(this.filter == '') return true;
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiBillLogs:function() {
    console.log('sort billlog');
    return this.filteredbilllogs.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    carrierChange: function (e) {
        this.selectedCarrier = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredbilllogs.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List bill logs
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('***********************getting the data')

      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/bills`);

        this.apibills = data;
        console.log(data);
        this.executed = true;
      } catch (e) {
        this.apibills = `Error: the server responded with '${e.response}'`;
      }
    },
    // Add billlog method to post to API
    async addbilllog() {

      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/bills`, null).then(() => {
          this.message = 'bills started!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated billlog. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add billlog. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apibills = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apibills = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addbilllogForm.Carrier =  '';
        this.addbilllogForm.BillingName =  '';
        this.addbilllogForm.FileName =  '';

    },
    // *******************************************
    // Add billlog on submit
    // *******************************************
    onStartBills() {
      console.log('on start bills');
      this.addbilllog();
      //this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addbilllogModal.hide();
      this.initForm();
    },
  //
    onResetLoad() {
      console.log('onresetload')
      if(confirm("Are you sure you want to reset all CDR loads?")){
        this.resetLoad();
      }

    },
    // reset load
    // *******************************************
    async resetLoad() {

      console.log("in reset load before")
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pricing/reset`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          console.log("in removeAccount after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          this.callApi();

        });

        // this.apiAccounts = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  created() {
    console.log('created')
    this.callApi();
    this.timer = setInterval(this.callApi, 20000);

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    CarrierDropDownVue: CarrierDropDownVue,
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
