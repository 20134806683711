<template>
    <div>
      <p><u><strong>Last Uploaded Accounts</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
          <button type="button" class="btn btn-success btn-sm" v-b-modal.ccountload-modal>Upload Accounts</button>
          <br><br>
          <!-- <input type="search" v-model="filter"> -->
          <table class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th class="th-lg" scope="col"  >Account No.</th>
                <th scope="col" >Account Name</th>
                <th scope="col" >Inserted</th>
                <!-- <th scope="col" >Inserted</th> -->
                <th scope="col" >Uploaded on</th>
                <th scope="col" >Uploaded by</th>
                <th scope="col" >Message</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ccountload, index) in apiaccountload" :key="index"
               >
                    <td>{{ ccountload.AccountNumber }}</td>
                    <td>{{ ccountload.AccountName }}</td>
                    <td>{{ ccountload.Inserted }}</td>
                    <td>{{ ccountload.LoadedOn }}</td>
                    <td>{{ ccountload.LoadedBy }}</td>
                    <td>{{ ccountload.Message }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- ADD modal -->
      <b-modal ref="addccountloadModal"
              id="ccountload-modal"
              title="Upload Accounts"
              hide-footer size="m" >
        <b-form @submit="onSubmit" @reset="onReset" >
        <b-form-row>
            <b-col>
              <p> Select a comma seperated text file (.txt)</p>
          </b-col>
          </b-form-row>
          <b-form-row>
          <b-col>
              <b-form-file id="form-filename-input"
                            type="file"   size="sm"
                            v-model="addccountloadForm.FileName"
                            placeholder="Select a upload file">
              </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
            <b-button type="button" variant="info" v-on:click="download()" >Download Template</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        myUrl: '#',
        myFilename: '',
        timer: "",
        selectedCarrier:"",
        addccountloadForm: {
          Carrier: '' ,
          CusBillingName: '' ,
          FileName: ''

        },
        apiaccountload: [],
        executed: false,
        message: 'initial alert',
        currentSort:'AccountNumber',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
      };
    },

    computed:{
    filteredccountloads() {
      console.log('filtered ccountload');
        return this.apiaccountload
      },
    SortedapiCDRLoads:function() {
      console.log('sort ccountload');
      if(Array.isArray(this.apiaccountload)){
        return this.filteredccountloads.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
          return 0;
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
    },
    },
    methods: {
      download: function() {
        console.log("in download")
        var data = "AccountNumber,AccountName,Address1,Address2,City,County,Country,PostCode,PONumber,PaymentTerms,SageAccount,NetXAccountID,Industry,AccountManager,Contact,Email,Notes,NationalMin,NGNMin,InternationalMin,MaintenanceOnly,DirectDebit,Taxable,ExportCalls,RoundUp"
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        // const jsonData = encodeURIComponent('{"is_valid": true}')
        // this.myUrl = `data:text/plain;charset=utf-8,${jsonData}`
        // this.myFilename = 'example.json'
        fileLink.setAttribute('download', "UploadAccount.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
      carrierChange: function (e) {
          this.selectedCarrier = e;
      },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredccountloads.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },


      // *******************************************
      // List ccountloads
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        console.log('getting the data')
        try {
          document.body.style.cursor = 'wait';
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/load`);
          document.body.style.cursor = 'auto';
          this.apiaccountload = data;
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apiaccountload = `Error: the server responded with '${e.response}'`;
        }
      },
      // Add ccountload method to post to API
      async addccountload(payload) {

        let formData = new FormData();
        console.log( payload.FileName);
        formData.append('Carrier', payload.Carrier);
        formData.append('BillingName', payload.BillingName);
        formData.append('file', payload.FileName);
        try {
          document.body.style.cursor = 'wait';
          const { data } =  await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/load`, formData).then(() => {
            this.message = 'accountload added!';
            this.showMessage = true;
            console.log('Here we call update and calApi then');
            document.body.style.cursor = 'auto';
            // this.$forceUpdate();
            // this.$router.go()
            console.log("in add load after")
            this.callApi("coming add  AAA");
            // this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated ccountload. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add ccountload. status code:  " + err.response.status)
              }
            console.error(err);
            console.log("in error")
            this.callApi();

          });

          // this.apiaccountload = data;
          // this.executed = true;
          document.body.style.cursor = 'auto';
          console.log("in add load BBB")
          this.callApi("coming add  BBB");
          console.log(data)
          //this.callApi()
        } catch (e) {
          this.apiaccountload = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addccountloadForm.Carrier =  '';
          this.addccountloadForm.BillingName =  '';
          this.addccountloadForm.FileName =  '';

      },
      // *******************************************
      // Add ccountload on submit
      // *******************************************
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addccountloadModal.hide();
        console.log('MaintenaceOnly');

        const payload = {
          FileName: this.addccountloadForm.FileName ,
        };
        document.body.style.cursor = 'wait';
        this.addccountload(payload);
        //this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addccountloadModal.hide();
        this.initForm();
      },
    //
      onResetLoad() {
        console.log('onresetload')
        if(confirm("Are you sure you want to reset all CDR loads?")){
          this.resetLoad();
        }

      },
      cancelAutoUpdate() {
        clearInterval(this.timer);
      },

    },
    created() {
      console.log('created')
      this.callApi();
    //   this.timer = setInterval(this.callApi, 20000);

    },
    components: {
      alert: Alert,
      listfilter:Filter,
    },
    beforeDestroy() {
      this.cancelAutoUpdate();
    },
  };
  </script>
