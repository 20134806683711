<template>

    <div>
      <p ><u><strong>Base Rates Report</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >

        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="4"
            label="Report"
            label-for="input-horizontal"
            >
            <div class="dropdown">
                <select @change="onChangeReportType($event)">
                  <option value="">Select report</option>
                  <option
                    v-for="(type, index) in reporttypes"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedPhoneType">Selected type - {{ this.SelectedPhoneType }}</p> -->
              </div>
            </b-form-group>
            <table>
              <tr>
                <td><b-button type="submit" variant="primary">Find</b-button></td>
                <td></td>
                <td><button type="button" class="btn btn-success btn-sm" v-on:click="downloadAll">Dowload All</button></td>
            </tr>
            </table>

        <!-- <b-button type="submit" variant="primary">Find</b-button> -->
  </b-form>

         <br><br>
         <table v-show="showresult" >
            <tr v-if="apiBaseRates.length > 0">
                <td><button  type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>
        <br><br>
        <div v-show="showresult"><input type="search" v-model="filter"></div>
          <table class="table table-striped w-auto" v-if="SelectedReportType == 'CBR' || SelectedReportType == 'ICR'" v-show="showresult" >
            <thead >
            <tr>
              <th class="th-lg" scope="col" @click="sort('ChargeBand')" >ChargeBand</th>
              <th class="th-sm" scope="col" @click="sort('Description')">Description</th>
              <th class="th-sm" scope="col" @click="sort('Carrier')">Carrier</th>
              <th class="th-sm" scope="col" @click="sort('SellDayRate')">Sell Day Rate </th>
              <th class="th-sm" scope="col" @click="sort('SellEveRate')">Sell Eve Rate</th>
              <th class="th-sm" scope="col" @click="sort('SellWendRate')">Sell W/E Rate </th>
              <th class="th-sm" scope="col" @click="sort('SellConFee')">Sell Con Fee </th>
              <th class="th-sm" scope="col" @click="sort('CostDayRate')">Cost Day Rate </th>
              <th class="th-sm" scope="col" @click="sort('CostEveRate')">Cost Eve Rate</th>
              <th class="th-sm" scope="col" @click="sort('CostWendRate')">Cost W/E Rate </th>
              <th class="th-sm" scope="col" @click="sort('CostConFee')">Cost Con Fee </th>
              <th scope="col" @click="sort('ModifiedOn')">Modified On</th>
              <th scope="col" @click="sort('ModifiedBy')">User</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(chargeband, index) in SortedapiBaseRates" :key="index">
              <td>{{ chargeband.Name }}</td>
              <td>{{ chargeband.Description }}</td>
              <td>{{ chargeband.Carrier }}</td>
              <td>{{ chargeband.SellDayRate }}</td>
              <td>{{ chargeband.SellEveRate }}</td>
              <td>{{ chargeband.SellWendRate }}</td>
              <td>{{ chargeband.SellConFee }}</td>
              <td>{{ chargeband.CostDayRate }}</td>
              <td>{{ chargeband.CostEveRate }}</td>
              <td>{{ chargeband.CostWendRate }}</td>
              <td>{{ chargeband.CostConFee }}</td>
              <td>{{ chargeband.ModifiedOn }}</td>
              <td>{{ chargeband.ModifiedBy }}</td>
            </tr>
          </tbody>
          </table>
          <table class="table table-striped w-auto" v-if="SelectedReportType == 'CBDP' || SelectedReportType == 'ICDP'" v-show="showresult" >
            <thead >
            <tr>
              <th class="th-lg" scope="col" @click="sort('Name')" >ChargeBand</th>
              <!-- <th class="th-sm" scope="col" @click="sort('Description')">Description</th> -->
              <th class="th-sm" scope="col"  @click="sort('Code')">Prefix </th>
              <th scope="col" @click="sort('ModifiedOn')">Modified On</th>
              <th scope="col" @click="sort('ModifiedBy')">User</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(chargeband, index) in SortedapiBaseRates" :key="index">
              <td>{{ chargeband.Name }}</td>
              <!-- <td>{{ chargeband.Description }}</td> -->
              <td>{{ chargeband.Code }}</td>
              <td>{{ chargeband.ModifiedOn }}</td>
              <td>{{ chargeband.ModifiedBy }}</td>
            </tr>
          </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredBaseRates.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'
  import CarrierNameDropDownVue from "../components/CarrierNameDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        reporttypes: [{ "id":"CBR", "value":'Base Rates - Domestic     '}, {"id":"CBDP","value":'Dial Plan - Domestic'}, {"id":"ICR","value":'Base Rates - International'}, {"id":"ICDP","value":'Dial Plan - International'}],
        executed: false,
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        showPaging:false,
        Account: null,
        apiBaseRates: []
      };
    },

    computed:{
    filteredBaseRates() {
      console.log('filtered suspendedcall');
        // return this.apiBaseRates
        return this.apiBaseRates.filter(c => {
            // console.log('in filter')
          if(this.filter == '') return true;
          return  c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                (c.Description && c.Description.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0 ||
                (c.Code && c.Code.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0
        })
      },
    SortedapiBaseRates:function() {
        console.log('sort suspendedcall');
      return this.filteredBaseRates.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
     // on download method
     onDownloadAll() {
      console.log('download click')
      this.downloadAll();
      },
      // api get download invoice method
      async downloadAll() {

        console.log('***********************download*****************************')

        try {
          document.body.style.cursor = "wait";
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/baseratesall`);
          document.body.style.cursor = 'auto';

          this.executed = true;
          var fileURL = window.URL.createObjectURL(new Blob([data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          // if (invoicename == 'all'){
          //   invoicename = 'invoices.zip';
          // }
          fileLink.setAttribute('download', "BaseRates.xlsx");
          document.body.appendChild(fileLink);

          fileLink.click();

        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
      },
    onChangeReportType(event) {
            this.filter = '';
            this.SelectedReportType= event.target.value;
            },


    onSubmit(evt) {
        console.log('on submit');
        this.apiBaseRates = null;
        this.showresult = false;
        // this.Account = null
        if (!this.SelectedReportType){ // is null or zero
            alert("Please select a report");
            evt.preventDefault();
                    return false;
            }
        this.callApi(this.SelectedReportType);
        // this.showresult = true;
        evt.preventDefault();
        },

    download : function() {
    //     const data = XLSX.utils.json_to_sheet(this.filteredBaseRates)
        // const wb = XLSX.utils.book_new()
        // XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename;
        const Heading = [
                              ['ChargeBand', 'Description', 'Carrier', 'SellDayRate','SellEveRate','SellWERate','SellConFee','CostDayRate','CostEveRate', 'CostWERate', 'CostConFee', 'ModifiedOn','User']
                              ];
        const objHeader = ["Name","Description","Carrier","SellDayRate", "SellEveRate","SellWendRate","SellConFee","CostDayRate","CostEveRate","CostWendRate","CostConFee","ModifiedOn","ModifiedBy"];

        if (this.SelectedReportType== 'CBR'){
            filename = 'Base Rates - Domestic -' + datestr + '.xlsx';
            // build based on original object fields but skip the header row
            const data = XLSX.utils.json_to_sheet(this.filteredBaseRates, { origin: 'A2', header: objHeader, skipHeader: true })
            // const data = XLSX.utils.json_to_sheet(this.SortedapiCLIsDownload, { origin: 'A2', header: objHeader, skipHeader: true })
            // add the customised header
            XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, data, 'data')
            // var moment = require('moment');
            // var datestr = moment(new Date()).format('DD-MM-YYYY');
            // var filename = 'Recurring Charges Inventory -' + datestr + '.xlsx';
            console.log(filename);
            XLSX.writeFile(wb,filename)
        }
        if (this.SelectedReportType== 'ICR'){
            filename = 'Base Rates - International -' + datestr + '.xlsx';
            // build based on original object fields but skip the header row
            const data = XLSX.utils.json_to_sheet(this.filteredBaseRates, { origin: 'A2', header: objHeader, skipHeader: true })
            // const data = XLSX.utils.json_to_sheet(this.SortedapiCLIsDownload, { origin: 'A2', header: objHeader, skipHeader: true })
            // add the customised header
            XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, data, 'data')
            // var moment = require('moment');
            // var datestr = moment(new Date()).format('DD-MM-YYYY');
            // var filename = 'Recurring Charges Inventory -' + datestr + '.xlsx';
            console.log(filename);
            XLSX.writeFile(wb,filename)
          }
        if (this.SelectedReportType== 'CBDP'){
            filename = 'Dial Plan - Domestic -' + datestr + '.xlsx';
            const Heading = [
                              ['ChargeBand', 'Prefix', 'ModifiedOn','User']
                              ];
            const objHeader = ["Name","Code","ModifiedOn","ModifiedBy"];
            // build based on original object fields but skip the header row
            const data = XLSX.utils.json_to_sheet(this.filteredBaseRates, { origin: 'A2', header: objHeader, skipHeader: true })
            // const data = XLSX.utils.json_to_sheet(this.SortedapiCLIsDownload, { origin: 'A2', header: objHeader, skipHeader: true })
            // add the customised header
            XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, data, 'data')
            // var moment = require('moment');
            // var datestr = moment(new Date()).format('DD-MM-YYYY');
            // var filename = 'Recurring Charges Inventory -' + datestr + '.xlsx';
            console.log(filename);
            XLSX.writeFile(wb,filename)
        }
        if (this.SelectedReportType== 'ICDP'){
            filename = 'Dial Plan - International -' + datestr + '.xlsx';
            const Heading = [
                              ['ChargeBand', 'Prefix', 'ModifiedOn','User']
                              ];
            const objHeader = ["Name","Code","ModifiedOn","ModifiedBy"];
            // build based on original object fields but skip the header row
            const data = XLSX.utils.json_to_sheet(this.filteredBaseRates, { origin: 'A2', header: objHeader, skipHeader: true })
            // const data = XLSX.utils.json_to_sheet(this.SortedapiCLIsDownload, { origin: 'A2', header: objHeader, skipHeader: true })
            // add the customised header
            XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, data, 'data')
            // var moment = require('moment');
            // var datestr = moment(new Date()).format('DD-MM-YYYY');
            // var filename = 'Recurring Charges Inventory -' + datestr + '.xlsx';
            console.log(filename);
            XLSX.writeFile(wb,filename)
        }


        },


    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredBaseRates.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List details
      // *******************************************
    async callApi( report) {

        console.log('getting the data')
        // build the filters for the url
        var url;
        if (report== 'CBR'){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/chargebandrates'
        }
        if (report== 'ICR'){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/countryrates'
        }
        if (report== 'CBDP'){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/chargebanddialplans'
        }
        if (report== 'ICDP'){
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/countrydialplans'
        }

        document.body.style.cursor = 'wait';

        try {

        //   url = process.env.VUE_APP_API_BASE_URL + '/api/v1/reports/carrierdetails/' + carrier ;
          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apiBaseRates = data;
          this.showPaging = true;
          if (this.apiBaseRates.length < this.pageSize){
            this.showPaging = false
          }
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiBaseRates = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      CarrierNameDropDownVue: CarrierNameDropDownVue,
      listfilter:Filter
    },
  };
  </script>
