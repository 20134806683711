<template>

  <div>
    <p ><strong>NetX outgoing CDR Load Logs List</strong> </p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th scope="col" @click="sort('accountid')">Account</th>
              <th scope="col" @click="sort('defaultsource')">Default Source</th>
              <th scope="col" @click="sort('source')">Source</th>
              <th scope="col" @click="sort('callcount')">Call count</th>
              <th scope="col" @click="sort('message')">Message</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(netxlog, index) in SortedapinetXLoadLogs" :key="index">
              <td>{{ netxlog.accountid }}</td>
              <td>{{ netxlog.defaultsource }}</td>
              <td>{{ netxlog.source }}</td>
              <td>{{ netxlog.callcount }}</td>
              <td>{{ netxlog.message }}</td>
            </tr>
          </tbody>
        </table>
        <div><p align="center">
          <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filterednetxloadlogs.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
          <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p></div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      selectedCarrier:"",
      addcdrloadForm: {
        Carrier: '' ,
        CusBillingName: '' ,
        FileName: ''

      },
      apinetxloadlogs: null,
      executed: false,
      message: 'initial alert',
      currentSort:'callcount',
      currentSortDir:'asc',
      pageSize:20,
      currentPage:1,
      filter:'',
    };
  },

  computed:{
  filterednetxloadlogs() {
    console.log('filtered netxlog');

      return this.apinetxloadlogs.filter(c => {
        if(this.filter == '') return true;
        return c.defaultsource.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
               c.message.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
               c.accountid.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapinetXLoadLogs:function() {
    console.log('sort cdrload');
    return this.filterednetxloadlogs.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filterednetxloadlogs.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },
    // *******************************************
    // List cdrloads
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the data')
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/cdrloads/netxloadlogs`);

        this.apinetxloadlogs = data;
        this.executed = true;
      } catch (e) {
        this.apinetxloadlogs = `Error: the server responded with '${e.response}'`;
      }
    }

  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter
  },
};
</script>
