<template>

    <div>

      <p ><u><strong>Archived Invoices</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="5"
            label="Bill Run Number"
            label-for="input-horizontal"
            >
            <b-form-input id="form-billrunnumber-input"
                      type="text"   size="sm"
                      v-model="BillRunNumber"
                      placeholder="Enter bill run number">
            </b-form-input>
            </b-form-group>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="5"
            label="From Date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-fromdate-input"
                      type="date"   size="sm"
                      v-model="FromDate"
                      placeholder="Enter from date">
            </b-form-input>
            </b-form-group>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="5"
            label="To Date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-todate-input"
                      type="date"   size="sm"
                      v-model="ToDate"
                      placeholder="Enter to date">
            </b-form-input>
            </b-form-group>


        <b-button type="submit" variant="primary">Find</b-button>
  </b-form>

        <br><br>

         <!-- <p v-if="showresult"> Number of records found: {{ apiInvoices.length }}</p> -->
          <label v-if="showresult" for="filter"></label><input v-if="showresult" type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead >
              <tr>
                <th scope="col" @click="sort('AccountNumber')">Account No</th>
                <th scope="col" @click="sort('AccountName')">Account Name</th>
                <th class="th-sm" scope="col" @click="sort('SageAccount')">Sage Ref</th>
                <th scope="col" >Value</th>
                <th class="th-lg" scope="col" @click="sort('BillNumber')" >Bill no.</th>
                <th class="th-lg" scope="col" @click="sort('InvoiceNo')" >Invoice no.</th>
                <th scope="col" >File Name</th>
                <th scope="col"  @click="sort('BillRunNumber')">Bill Run</th>
                <th scope="col"  @click="sort('CreatedOn')">Date Created</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(invoice, index) in SortedapiBillLogs" :key="index">
                <td v-if="invoice.AccountName">{{ invoice.AccountNumber }}</td>
                <td v-else>{{ invoice.GroupNo }}</td>
                <td v-if="invoice.AccountName">{{ invoice.AccountName }}</td>
                <td v-else>{{ invoice.GroupName }}</td>
                <td >{{ invoice.SageAccount }}</td>
                <td >{{ invoice.InvoiceValue }}</td>
                <td >{{ invoice.BillNumber }}</td>
                <td >{{ invoice.InvoiceNo }}</td>
                <td >{{ invoice.FileName }}</td>
                <td>{{ invoice.BillRunNumber }}</td>
                <td>{{ invoice.CreatedOn }}</td>
                <td><button v-on:click="onDownloadInvoice(invoice.FileName)">Download</button></td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredbilllogs.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
          <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
          <!-- <div v-if="apiInvoices.length > pageSize"><p align="center">
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div> -->
        </div>
      </div>
      </div>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import datepicker from 'vue-date-picker'

  export default {

    Name: "Api",
    data() {
      return {
        BillRunNumber: null,
        FromDate : null,
        ToDate : null,
        editHistoryForm: {
          OrderId: null,
          entityid: null ,
          entityname: null ,
          before: null ,
          after: null ,
          modifiedon: null ,
          modifiedby: null ,
          comment: null ,
          entitytype: null,
          method: null
        },
        apiInvoices: [],
        executed: false,
        showPaging:false,
        message: 'initial alert',
        currentSort:'CreatedOn',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        Account: null
      };
    },

    computed:{
    filteredbilllogs() {
      console.log('filtered billlog');
        return this.apiInvoices.filter(c => {
          if(this.filter == '') return true;
        if (c.AccountNumber == null) c.AccountNumber = '';
        if (c.GroupNo == null) c.GroupNo = '';
        if (c.AccountName == null) c.AccountName = '';
        if (c.GroupName == null) c.GroupName = '';
        if (c.SageAccount == null) c.SageAccount = '';
        if (c.BillNumber == null) c.BillNumber = '';
        return c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.GroupNo.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.GroupName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.SageAccount.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.BillNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
      },
    SortedapiBillLogs:function() {
      console.log('sort billlog');
      return this.filteredbilllogs.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {


        // on download method
      onDownloadInvoice(invoicename) {
      console.log(invoicename)
      this.downloadInvoice(invoicename);
      },
      // api get download invoice method
      async downloadInvoice(invoicename) {

        console.log('***********************download*****************************')

        try {
          document.body.style.cursor = "wait";
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/invoices/archived/download/` + invoicename, {responseType: 'blob'});
          document.body.style.cursor = 'auto';

          this.executed = true;
          var fileURL = window.URL.createObjectURL(new Blob([data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          if (invoicename == 'all'){
            invoicename = 'invoices.zip';
          }
          fileLink.setAttribute('download', invoicename);
          // fileLink.setAttribute('download', 'UploadRecurringCharges_12.txt');
          document.body.appendChild(fileLink);

          fileLink.click();

        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
      },
    //
    editHistory(history) {
      console.log('editCaedithistoryrrier');
      console.log(history);
      this.editHistoryForm = history;
      // this.callApi()
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editHistoryModal.hide();
    },
    onSubmit(evt) {
        console.log('on submit');
        this.apiInvoices = null;
        this.showresult = false;
        var todate = null

        if (this.ToDate != null){
          todate = this.ToDate + ' 23:59:59';
        }
        
        if(this.BillRunNumber=== null & this.FromDate == null &  this.ToDate == null){
          evt.preventDefault();
          alert("Please select a search parameter")
            
        }
        else if (this.ToDate != null & this.FromDate > this.ToDate){
          evt.preventDefault();
          alert("To date can not be before the from date")
        }
        // else if (this.FromDate != null & this.FromDate === this.ToDate){
        //   var date = new Date(this.ToDate);        
        //   /* Add a day to to date*/
        //   date.setDate(date.getDate() + 1);
        //   /* to date =  to date + 1*/
        //   // this.callApi(this.BillRunNumber, this.FromDate, date.toLocaleDateString("en-CA"));
        //   var todate = this.FromDate + ' 23:59:59';
        //   this.callApi(this.BillRunNumber, this.FromDate, todate)
        //   // this.showresult = true;
        //   evt.preventDefault();

        // }
        else{
            this.callApi(this.BillRunNumber, this.FromDate, todate);
            // this.showresult = true;
            evt.preventDefault();
        }
        },


    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredbilllogs.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List historys
      // *******************************************
    async callApi(billrun, fromdate, todate) {

        console.log(billrun)
        console.log('getting the data')
        // build the filters for the url
        var url;
        var filters =[];
        var billrunfilter = {"name":"BillRunNumber","op":"eq","val":billrun}
        var fromfilter = {"name":"CreatedOn","op":">=","val":fromdate}
        var tofilter = {"name":"CreatedOn","op":"<=","val":todate}

        if (typeof(billrun) != "undefined" && billrun != null && billrun != "") {
            filters.push(billrunfilter)
        }
        if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
            filters.push(fromfilter)
        }
        if (typeof(todate) != "undefined" && todate != null && todate != "") {
            filters.push(tofilter)
        }


        let queryfilters = JSON.stringify(filters);

        console.log(queryfilters)

        document.body.style.cursor = 'wait';

        try {
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/invoices/archived?q={"filters":' + queryfilters + '}';
          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apiInvoices = data;
          this.showresult = true
          this.executed = true;
          if (this.apiInvoices.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },
    
     

    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      datepicker
    },
  };
  </script>
