<template>
    <div class="cascading-dropdown">
      <div class="dropdown">
        <select style="width: 100%"  @change="onChangeAccount($event)" v-model="selected_account" >
          <option value="">Select an account</option>
          <option
            v-for="(account, index) in listAccount"
            :value="account.account_id"
            :key="index"
          >
            {{ account.Name + ' - ' + account.AccountNumber }}
          </option>
        </select>
      </div>
      <!-- <p v-if="SelectedAccount">Selected Account - {{ this.SelectedAccount }}</p> -->
    </div>
  </template>

  <script>
  // import axios from "axios";

  export default {
    name: "App",
    props:{
      group_exclude:{
        type: Boolean,
        default: false},
      selected_account:{
        type: String,
        default: ""},
      },
    data() {
      return {
        listAccount: [],
        SelectedAccount: "",
        authToken: "",
      };
    },
    methods: {
      onChangeAccount(event) {
        this.SelectedAccount = event.target.value
        console.log(event)
        this.$emit('account-changed',this.SelectedAccount)
      },

      async callApi() {
        try {
          let url = "";

          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/callaccounts?only=account_id,Name,AccountNumber`;
          const { data } = await this.$http.get(url);

          console.log('API DATA ')
          console.log(data)
          this.listAccount = data;
          this.executed = true;
        } catch (e) {
          this.listAccount = `Error: the server responded with '${e.response}'`;
        }
      },
    },
    created() {
      this.callApi();
      console.log('the selected account in dorpdown is:');
      console.log(this.selected_account);
    }
  };
  </script>
