<template>

    <div>
      <p><u><strong>Account Clear up Report</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
      <b-form @submit="onFind" width="1400" >
        <b-form-row  >
          <b-col>
        <b-form-group
      id="fieldset-1"
      label="Number of months the last billed date was before"
      label-for="input-1"
      content-cols-lg="2"
      :invalid-feedback="invalidFeedback"
      :state="state"
    >
    <b-form-input id="form-MonthNumber-input"
                        type="text"   size="m"
                        v-model="SearchMonthNumber"
                        >
              </b-form-input>
      <!-- <b-form-input id="input-1" v-model="name" :state="state" trim></b-form-input> -->

    </b-form-group>
  </b-col>
  <b-col> <b-button type="submit" variant="primary">Find</b-button></b-col>
  </b-form-row>
 </b-form>
 <br><br>
 <button v-if="showdownload" type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button>

 <br><br>
          <table  v-show="showresult" class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-sm" scope="col" @click="sort('AccountNumber')">Account Number</th>
                <th class="th-sm" scope="col" @click="sort('AccountName')">Account Name </th>
                <th class="th-sm" scope="col" @click="sort('BillNumber')">Bill Number </th>
                <th class="th-lg" scope="col" @click="sort('DateLastBilled')" >Date Last Billed</th>
                <th class="th-sm" scope="col" @click="sort('CallCost')">Call Cost</th>
                <th class="th-sm" scope="col" @click="sort('RecurringCost')">Recurring Cost</th>
                <th class="th-sm" scope="col" @click="sort('TotalCost')">Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cli, index) in SortedapiCLIs" :key="index">
                <td>{{ cli.AccountNumber }}</td>
                <td>{{ cli.AccountName }}</td>
                <td>{{ cli.BillNumber }}</td>
                <td>{{ cli.DateLastBilled }}</td>
                <td>{{ cli.CallCost }}</td>
                <td>{{ cli.RecurringCost }}</td>
                <td>{{ cli.TotalCost }}</td>

              </tr>
            </tbody>
          </table>
          <div  v-show="showPaging"><p align="center" >
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredCLIs.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        SearchMonthNumber: null,
        apiCLIs: null,
        executed: false,
        message: 'initial alert',
        currentSort:'AccountName',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:false,
        currentPage:1,
        filter:'',
        showdownload: false,
      };
    },

    computed:{
    filteredCLIs() {
      //  console.log('filtered cli');
        return this.apiCLIs.filter(c => {
          if(this.filter == '') return true;
          return c.PhoneNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    SortedapiCLIs:function() {
      //  console.log('sort cli');
      if (this.apiCLIs != null){
        return this.filteredCLIs.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(this.currentSort === 'account.Name'){
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
          else{
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
          else{ return null}
    },
    },
    methods: {
      download : function() {
        // customised headers
        const Heading = [
                            ['Account Number','Account Name','Bill Number','Date Last Billed', 'Call Cost', 'Recurring Cost', 'Total Cost']
                        ];

        // original object headers
        const objHeader = ["AccountNumber","AccountName","BillNumber","DateLastBilled","CallCost","RecurringCost","TotalCost"];


        // build based on original object fields but skip the header row
        const data = XLSX.utils.json_to_sheet(this.filteredCLIs, { origin: 'A2', header: objHeader, skipHeader: true })
        // add the customised header
        XLSX.utils.sheet_add_aoa(data, Heading, { origin: 'A1' });


        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        var filename = 'Account clean up - date last billed before (' + datestr + ' minus ' + this.SearchMonthNumber + ' months).xlsx';
        console.log(filename);
        XLSX.writeFile(wb,filename)
        },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredCLIs.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List outbounds
      // *******************************************
      async callApi(searchparam) {

        console.log(searchparam)
        console.log('getting the cli list')
        var url;
        // var searchlike = '%25' + searchparam + '%25';
        if(typeof(searchparam) != "undefined"){
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/accountsclearup?q=` + searchparam
        }
        else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/reports/accountsclearup?q=1`
        }
        try {
          document.body.style.cursor = 'wait';
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiCLIs = data;
          console.log("API DATA")
          console.log(data)
          this.showresult = true
          console.log(this.apiCLIs.length)
          this.executed = true;
          if (this.apiCLIs.length > 0){
            this.showdownload = true
          }
          if (this.apiCLIs.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
          this.executed = true;
        } catch (e) {
          this.apiCLIs = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // search month
      // *******************************************
      onFind(evt) {
        console.log('on Find');
        this.apiCLIs = null;
        this.showdownload  = false;
        this.showresult = false;
        this.callApi(this.SearchMonthNumber);
        evt.preventDefault();
        },


    },

    created() {
      //  console.log('created')
      // this.callCostCentreApi();
      this.executed = true;
    //   this.callApi();


    },
    components: {
      alert: Alert,
      listfilter:Filter,
    },
  };
  </script>
