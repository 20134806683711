<template>

  <div>
    <p><u><strong>Dial Plan - International</strong></u> </p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.country-modal>Insert international
          prefix</button>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('country.CountryName')">Charge Band</th>
              <th class="th-sm" scope="col" @click="sort('Code')">Prefix</th>
              <th scope="col" @click="sort('ModifiedOn')">Modified On</th>
              <th scope="col" @click="sort('ModifiedBy')">User</th>
              <th> <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.country-load-modal>Load
                  international dial plan</button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(country, index) in SortedapiCountryCodes" :key="index">
              <td>{{ country.country.CountryName }}</td>
              <td>{{ country.Code }}</td>
              <td>{{ country.ModifiedOn }}</td>
              <td>{{ country.ModifiedBy }}</td>
              <td align="center">
                <div class="btn-group" role="group">
                  <button v-if="isAdmin" type="button" class="btn btn-warning btn-sm" v-b-modal.country-update-modal
                    @click="editCountryCode(country)">
                    View
                  </button>
                  <p> </p>
                  <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm"
                    @click="onDeleteCountryCode(country)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredCountryCodes.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
    <!-- LOAD modal -->
    <b-modal ref="loadCountryCodeModal" id="country-load-modal" title="Load international dial plan" hide-footer
      size="xs">
      <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
        <b-form-row>
          <b-col>
            <p>Select a comma seperated file (.txt).</p>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadCountryCodeForm.FileCountryName"
              required placeholder="Select international dial plan file">
            </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- add modal -->
    <b-modal ref="addCountryCodeModal" id="country-modal" title="Insert international prefix" hide-footer size="sm">
      <b-form @submit="onSubmit" @reset="onReset" class="w-600">
        <b-form-row>
          <b-col>
            <div class="dropdown">
              <select style="width: 100%" v-model="addCountryCodeForm.CountryId" @change="onChangeCountry($event)">
                <option value="">Select country</option>
                <option v-for="(country, index) in Countrys" :value="country.CountryId" :key="index">
                  {{ country.CountryName }}
                </option>
              </select>
            </div>
            <br>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Code-group" label="Prefix" label-for="form-Code-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Code-input" type="text" size="sm" v-model="addCountryCodeForm.Code"
                placeholder="Enter prefix">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal ref="editCountryCodeModal" id="country-update-modal" title="Update international prefix" hide-footer
      size="sm">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
        <b-form-row>
          <b-col>
            <div class="dropdown">
              <select style="width: 100%" v-model="editCountryCodeForm.CountryId" @change="onChangeCountry($event)">
                <option value="">Select country</option>
                <option v-for="(country, index) in Countrys" :value="country.CountryId" :key="index">
                  {{ country.CountryName }}
                </option>
              </select>
            </div>
            <br>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Code-group" label="Prefix" label-for="form-Code-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Code-input" type="text" size="sm" v-model="editCountryCodeForm.Code"
                placeholder="Enter prefix">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
      SelectedPhoneType: '',
      loadCountryCodeForm: {
        FileCountryName: ''
      },
      addCountryCodeForm: {
        Code: null ,
        CountryId: null
      },
      editCountryCodeForm: {
        Id: null,
        Code: null ,
        CountryId: null
      },
      apiCountryCodes: null,
      executed: false,
      message: 'initial alert',
      currentSort:'country.CountryName',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:true,
      currentPage:1,
      filter:'',
      Countrys: null,
      ShowAccount:true,
      selectedCountry: null
    };
  },

  computed:{
  filteredCountryCodes() {
    // console.log('filtered country');
      return this.apiCountryCodes.filter(c => {
        if(this.filter == '') return true;
        if (c.Code == null) c.Code = '';
        if (c.country.CountryName == null) c.country.CountryName = '';
        return c.Code.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.country.CountryName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiCountryCodes:function() {
    // console.log('sort country');
    // console.log('sort account');
    if (this.apiCountryCodes == null) return null;
    // console.log ('in sort account....');
    // console.log(this.currentSort)
    return this.filteredCountryCodes.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;

      if(this.currentSort === 'country.CountryName'){
          var prop = this.currentSort.split('.');
          var len = prop.length;
            var i = 0;
            while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
            if (a < b) {
                return -1 * modifier;
            } else if (a > b) {
                return 1 * modifier;
            } else {
                return 0;
            }
        }
      else{
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      }
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    download: function() {
        console.log("in download")
        var data = "ChargeBand,Prefix"

        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        // const jsonData = encodeURIComponent('{"is_valid": true}')
        // this.myUrl = `data:text/plain;charset=utf-8,${jsonData}`
        // this.myFilename = 'example.json'
        fileLink.setAttribute('download', "UploadDialPlan-International.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
    onChangeCountry(event) {
      this.selectedCountry= event.target.value

      console.log(event.target.value)


      // this.$emit('account-changed',this.SelectedAccount)
    },

    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredCountryCodes.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List countrys
    // *******************************************
    async callCountryApi(lablex) {

      console.log(lablex)
    //   console.log('getting the costcentre list')
    //   console.log(this.$route.query.account_id)
    //   console.log('getting the costcentre list')
      var url;
      url = process.env.VUE_APP_API_BASE_URL + '/api/v1/countries?q={"filters":[{"name":"AccountId","op":"is_null","val":""}]}&only=CountryName,CountryId';

      try {
        const { data } = await this.$http.get(url);

        this.Countrys = data;
        if (this.apiCostCentres.length < this.pageSize){
          this.showPaging = false
        }
        console.log(this.Countrys)

        this.executed = true;
      } catch (e) {
        this.apiCostCentres = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // List countrycodes
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
    //   console.log('getting the country list')
    //   console.log(this.$route.query.account_id)
    //   console.log('getting the country list')
      var url;
      if (typeof(this.$route.query.CountryId) != "undefined"){
        //   console.log('selected account in CountryCode is:');
        //   console.log(this.$route.query.account_id)
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/countrycodes?q={"filters":[{"name":"CountryId","op":"eq","val":"' + this.$route.query.CountryId + '"}]}';
        //   console.log(url)
          this.ShowAccount = false
          this.selected_country = this.$route.query.CountryId
        //   console.log('selected country in CountryCode is:');
        //   console.log(this.selected_country);
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/countrycodes`
      }
      try {
        document.body.style.cursor = 'wait';
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiCountryCodes = data;
        if (this.apiCountryCodes.length < this.pageSize){
          this.showPaging = false
        }
        // console.log(this.apiCountryCodes )
        // console.log(this.apiCountryCodes.length)
        this.executed = true;
      } catch (e) {
        this.apiCountryCodes = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // Delete country
    // *******************************************
    async removeCountryCode(countrycodeid) {

    //   console.log("in removeCountryCode before")
      console.log(countrycodeid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/countrycodes/${countrycodeid}`).then(() => {
          this.message = 'country removed!';
          this.showMessage = true;
          console.log("in removeCountryCode after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeCountryCode error")
          console.error(err);
          this.callApi();

        });

        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCountryCodes = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteCountryCode(country) {
      console.log(country.Id)
      if(confirm("Are you sure you want to delete this country?")){
        this.removeCountryCode(country.Id);
      }
    },

    // Add country method to post to API
    async addCountryCode(payload) {

    //   const id_token = claims.__raw;
      console.log('in add country');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/countrycodes`, payload).then(() => {
          this.message = 'country added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated country. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add country. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCountryCodes = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addCountryCodeForm.CountryName =  null;
        this.addCountryCodeForm.Notes = null;
        this.addCountryCodeForm.selectedCountry=  null;
    },
    // *******************************************
    // Add cdrload on submit
    // *******************************************
    onLoadSubmit(evt) {
      console.log('on load submit');
      evt.preventDefault();
      this.$refs.loadCountryCodeModal.hide();
      const payload = {
        FileCountryName: this.loadCountryCodeForm.FileCountryName
      };
      this.loadcountrycodes(payload);
      //this.initForm();
    },
    onLoadReset(evt) {
      evt.preventDefault();
      this.$refs.loadCountryCodeModal.hide();
      this.initForm();
    },
    // Add cdrload method to post to API
    async loadcountrycodes(payload) {

      let formData = new FormData();
      console.log( 'in load countrycodes');
      console.log( 'account is');

      console.log( payload.file);
      formData.append('file', payload.FileCountryName);
      var url;
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/countrycodes/load`

      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'charband codes loaded!';
          this.showMessage = true;
          //this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        document.body.style.cursor = 'auto';
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
  //
    // *******************************************
    // Add country on submit
    // *******************************************
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addCountryCodeModal.hide();
      const payload = {
        Code: this.addCountryCodeForm.Code,
        CountryId: this.selectedCountry
      };
      this.addCountryCode(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addCountryCodeModal.hide();
      this.initForm();
    },
  //
    editCountryCode(country) {
      console.log('editCountryCode');
      console.log(country);
      console.log(country.CountryId);
      this.callCountryApi();
      this.editCountryCodeForm = country;
      this.selectedCountry = country.CountryId
      this.callApi()
    },

    // *******************************************
    // Update country on submit
    // *******************************************
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('CountryId');
      evt.preventDefault();
      this.$refs.editCountryCodeModal.hide();
      const payload = {
        Code: this.editCountryCodeForm.Code,
        CountryId: this.selectedCountry
      };
      this.updateCountryCode(payload, this.editCountryCodeForm.Id);
      this.callApi()
    },

    async updateCountryCode(payload, countrycodeid) {

      console.log('in update countrycode');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/countrycodes/${countrycodeid}`, payload).then(() => {
          this.message = 'country updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated country. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add country. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiCountryCodes = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCountryCodes = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editCountryCodeModal.hide();
      this.initForm();
      this.getCountryCodes(); // why?
    },
  },
  created() {
    console.log('created')
    this.callCountryApi();
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter
  },
};
</script>
