<template>

    <div>
      <p ><u><strong>Categories</strong></u> </p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.category-modal>Insert Category</button>
          <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th align= "left" @click="sort('Name')" >Name</th>
                <th align= "left" @click="sort('Description')">Description</th>
                <th align= "left"  @click="sort('ModifiedOn')">Date modified</th>
                <th align= "left"  @click="sort('ModifiedBy')">Modified by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(category, index) in SortedapiCategorys" :key="index">
                <td>{{ category.Name }}</td>
                <td>{{ category.Description }}</td>
                <td>{{ category.ModifiedOn }}</td>
                <td>{{ category.ModifiedBy }}</td>
                <td>
                  <div class="btn-group"  role="group">
                    <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-b-modal.category-update-modal
                            @click="editCategory(category)">
                        {{editButtonText}}
                    </button>
                    <p>  </p>
                    <button v-if="isAdmin"
                            type="button"
                            class="btn btn-danger btn-sm"
                            @click="onDeleteCategory(category)">
                        Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div><p align="center">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredCategorys.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
      <!-- add modal -->
      <b-modal ref="addCategoryModal"
              id="category-modal"
              title="Insert a new category"
              hide-footer size="m" >
        <b-form @submit="onSubmit" @reset="onReset" class="w-200">
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Name"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="addCategoryForm.Name"
                              required
                              placeholder="Enter name" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>

        </b-form-row>

        <b-form-row>
            <b-col>
              <b-form-group id="form-description-group"
                        label="Description"
                        label-for="form-description-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-description-input"
                            type="text"   size="sm"
                            v-model="addCategoryForm.Description"
                            required
                            placeholder="Enter description">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>

      <!-- EDIT modal -->
      <b-modal ref="editCategoryModal"
              id="category-update-modal"
              title="Edit category"
              hide-footer size="m" >
        <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Name"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editCategoryForm.Name"
                              required
                              placeholder="Enter Name" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
              <b-form-group id="form-description-group"
                        label="Description"
                        label-for="form-description-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-description-input"
                            type="text"   size="sm"
                            v-model="editCategoryForm.Description"
                            required
                            placeholder="Enter description">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center" v-if="isAdmin">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        // env_url: process.env.ENV_API_URL,
        addCategoryForm: {
          Name: '' ,
          Description: ''
        },
        editCategoryForm: {
          CategoryId: null,
          Name: null ,
          Description: null
        },
        apiCategorys: null,
        executed: false,
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
      };
    },

    computed:{
    filteredCategorys() {
      console.log('filtered category');
        return this.apiCategorys.filter(c => {
          if(this.filter == '') return true;
          return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.Description.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
                //  (c.ContactName && c.ContactName.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0
        })
      },
    SortedapiCategorys:function() {
      console.log('sort category');
      return this.filteredCategorys.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredCategorys.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List categories
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        try {
          console.log(process.env.VUE_APP_API_BASE_URL)
          console.log(process.env.VUE_APP_TEST)
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/categories`);

          this.apiCategorys = data;
          this.executed = true;
        } catch (e) {
          this.apiCategorys = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // Delete category
      // *******************************************
      async removeCategory(categoryid) {

        console.log("in removeCategory before")
        console.log(categoryid)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/categories/${categoryid}`).then(() => {
            this.message = 'category removed!';
            this.showMessage = true;
            console.log("in removeCategory after")
            this.callApi("coming from delete");

        }).catch((err) => {
            if(err.response.status == 400) {
              alert("Category is assigned to products. status code:  " + err.response.status)
              }
            if(err.response.status != 400) {
              alert("Problem with delete industry type. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });
        //   console.log(data)
        //   this.callApi()

          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiCategorys = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteCategory(category) {
        console.log(category.CategoryId)
        if(confirm("Are you sure you want to delete this industry type?")){
          this.removeCategory(category.CategoryId);
        }
      },

      // Add category method to post to API
      async addCategory(payload) {

      //   const id_token = claims.__raw;
        console.log('in add category');
        console.log('payload is');
        console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/categories`, payload).then(() => {
            this.message = 'category added!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated industry type. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add industry type. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiCategorys = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addCategoryForm.Name =  null;
          this.addCategoryForm.Description =  null;
      },
      // *******************************************
      // Add category on submit
      // *******************************************
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addCategoryModal.hide();
        console.log('MaintenaceOnly');
        const payload = {
          Name: this.addCategoryForm.Name,
          Description: this.addCategoryForm.Description,
          Address1: this.addCategoryForm.Address1,
          Address2: this.addCategoryForm.Address2,
          PostCode: this.addCategoryForm.PostCode,
          City: this.addCategoryForm.City,
          Country:this.addCategoryForm.Country,
          ContactName:this.addCategoryForm.ContactName,
          ContactNumber:this.addCategoryForm.ContactNumber,
          FaxNumber:this.addCategoryForm.FaxNumber,
          Email:this.addCategoryForm.Email
        };
        this.addCategory(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addCategoryModal.hide();
        this.initForm();
      },
    //
      editCategory(category) {
        console.log('editCategory');
        console.log(category);
        console.log(category.category_id);
        this.editCategoryForm = category;
        this.callApi()
      },

      // *******************************************
      // Update category on submit
      // *******************************************
      onSubmitUpdate(evt) {
        console.log('in onSubmitUpdate');
        console.log('category_id');
        evt.preventDefault();
        this.$refs.editCategoryModal.hide();
        const payload = {
          Name: this.editCategoryForm.Name,
          Description: this.editCategoryForm.Description
        };
        this.updateCategory(payload, this.editCategoryForm.CategoryId);
        this.callApi()
      },

      async updateCategory(payload, categoryid) {

        console.log('in update category');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/categories/${categoryid}`, payload).then(() => {
            this.message = 'category updated!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated category. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add category. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi();

          });

          // this.apiCategorys = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiCategorys = `Error: the server responded with '${e.response}'`;
        }
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editCategoryModal.hide();
        this.initForm();
        this.getCategorys(); // why?
      },
    },
    created() {
      console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter
    },
  };
  </script>
