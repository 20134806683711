<template>
    <div class="cascading-dropdown">
      <div class="dropdown">
        <select @change="onChangeSMSContract($event)" v-model="selected_smscontract">
          <option value="">Select a sms contract</option>
          <option
            v-for="(smscontract, index) in apiSMSContracts"
            :value="smscontract.SMSContractId"
            :key="index"
          >
            {{ smscontract.Name }}
          </option>
        </select>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "App",
    props:{
      group_exclude:{
        type: Boolean,
        default: false},
        selected_smscontract:{
        type: String,
        default: ""},
      },
    data() {
      return {
        listSMSContract: [],
        selectedSMSContract: "",
        authToken: "",
        currentSort:'Name',
        currentSortDir:'asc',
        apiSMSContracts: null,
      };
    },
    methods: {
      onChangeSMSContract(event) {
        this.selectedSMSContract = event.target.value
        console.log('in dropdown @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
        console.log(this.selectedSMSContract)
        this.$emit('callsmscontract-changed',this.selectedSMSContract)
      },

      async callApi() {
        try {
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/smscontracts`);

          // console.log(data)
          // this.listSMSContract = data;
          this.apiSMSContracts = data;
          this.executed = true;
        } catch (e) {
          this.listSMSContract = `Error: the server responded with '${e.response}'`;
        }
      },
    },
    created() {
      this.callApi();
    }
  };
  </script>
