<template>

    <div>
      <p ><u><strong>Deleted NetX Groups</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
            <b-form @submit="onSubmit" >
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="From Date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-fromdate-input"
                    type="date"   size="sm"
                    v-model="FromDate"
                    placeholder="Enter from date">
            </b-form-input>
            </b-form-group>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="To Date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-todate-input"
                    type="date"   size="sm"
                    v-model="ToDate"
                    placeholder="Enter to date">
            </b-form-input>
            </b-form-group>


            <b-button type="submit" variant="primary">Find</b-button>
            </b-form>
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-lg" scope="col" >Account Name</th>
                <th class="th-lg" scope="col"  >Accounts</th>
                <th scope="col" >Modified on</th>
                <th scope="col">User</th>
                <th scope="col">Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(netxgrouphistory, index) in SortedapiNetxGroup" :key="index">
                <td>{{ netxgrouphistory.netxgroup.Name }}</td>
                <td>{{ netxgrouphistory.netxgroup.accounts.length  }}</td>
                <td>{{ netxgrouphistory.modifiedon }}</td>
                <td>{{ netxgrouphistory.modifiedby }}</td>
                <td>{{ netxgrouphistory.method }}</td>
                <td align="center"><a :href="'/netxgrouphistory?netxgroup_id=' + netxgrouphistory.netxgroup.netxgroup_id + '&groupname=' + netxgrouphistory.netxgroup.Name" target="_blank">History</a></td>
                <td>
                  <div class="btn-group"  role="group">
                    <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-b-modal.netxgroup-update-modal
                            @click="editNetxGroup(netxgrouphistory)">
                        View
                    </button>

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="apiNetxGroup.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>



      <!-- EDIT modal -->
      <b-modal :key="editcomponentKey" ref="editNetxGroupModal"
              id="netxgroup-update-modal"
              title="NetX Group History"
              hide-footer size="lg" >
        <b-form @reset="onResetUpdate" class="w-200">
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Account Name"
                      label-for="form-name-input" label-cols="1" label-cols-sm="2" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editNetxGroupForm.Name"
                              readonly
                              placeholder="" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-notes-group"
                      label="Notes"
                      label-for="form-notes-input" label-cols="1" label-cols-sm="2" label-size="sm">
              <b-form-input id="form-tnotes-input"
                              type="text"   size="sm"
                              v-model="editNetxGroupForm.Notes"
                              readonly
                              placeholder="" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-modified-group"
                      label="Modified on"
                      label-for="form-notes-input" label-cols="1" label-cols-sm="4" label-size="sm">
              <b-form-input id="form-tnotes-input"
                              type="text"   size="sm"
                              v-model="editNetxGroupForm.modifiedon"
                              readonly
                              placeholder="" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-modified-group"
                      label="User"
                      label-for="form-notes-input" label-cols="1" label-cols-sm="4" label-size="sm">
              <b-form-input id="form-tnotes-input"
                              type="text"   size="sm"
                              v-model="editNetxGroupForm.modifiedby"
                              readonly
                              placeholder="" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-modified-group"
                      label="Action"
                      label-for="form-notes-input" label-cols="1" label-cols-sm="4" label-size="sm">
              <b-form-input id="form-tnotes-input"
                              type="text"   size="sm"
                              v-model="editNetxGroupForm.action"
                              readonly
                              placeholder="" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>

        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>

        <b-form-row>
            <p>Group Members</p>
        </b-form-row>
        <b-form-row>
            <b-col cols sm="2"  lg="auto">
            <table class="table table-small w-auto" >
            <thead>
              <tr>
                <th class="th-xs"  scope="col" >Name</th>
                 <th class="th-xs"   scope="col" >AccountNumber</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in editNetxGroupForm.accounts" :key="index">
                <td>{{ account.Name }}</td>
                <td>{{ account.AccountNumber }}</td>
              </tr>
            </tbody>
          </table>
          </b-col>
        </b-form-row>


          <div class="container">
          <div class="row"   >
              <div class="col text-center">
              <b-button-group>
            <b-button type="reset" variant="danger">Close</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      FromDate : null,
        ToDate : null,
        editcomponentKey:0,
        selectedAccount:"",
        addNetxGroupForm: {
          Name: '' ,
          Notes: ''
        },
        editNetxGroupForm: {
          accountgroup_id : '',
          Name: '' ,
          Address1: '' ,
          Notes: '',
          CreatedOn:'',
          CreatedBy:'',
          ModifiedOn:'',
          ModifiedBy:'',
          Accounts:[],
          accounts:[],
          modifiedby :null,
          modifiedon : null,
          action : null
        },
        apiNetxGroup: [],
        apiHistoryNetXGroup: null,
        executed: false,
        message: 'initial alert',
        currentSort:'modifiedon',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        showPaging:false,
        filter:'',
      };
    },

    computed:{
      filteredNetxGroup() {
      console.log('filtered account');
      return this.apiNetxGroup;
      },

    SortedapiNetxGroup:function() {
      console.log('sort netx group');
      if (this.apiNetxGroup != null){
      return this.filteredNetxGroup.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
      }
      else{ return null}
    },
    },
    methods: {
      forceRerender:function() {
        this.editcomponentKey += 1
      },
      accountChange: function (e) {
          this.selectedAccount = e;
      },
      onSubmit(evt) {
            console.log('on submit');
            this.apiHistories = null;
            this.showresult = false;
            var todate = null

            if (this.ToDate != null){
            todate = this.ToDate + ' 23:59:59';
            }

            if (this.ToDate != null & this.FromDate > this.ToDate){
                evt.preventDefault();
                alert("To date can not be before the from date")
            }
            else{
                this.callApi( this.FromDate, todate);
                evt.preventDefault();
            }  
                    },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredNetxGroup.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

    // *******************************************
    // get netx group details
    // *******************************************
    async getNetXGroupApi(lablex) {

      console.log(lablex)
      console.log('getting the account data')
      try {
        document.body.style.cursor = "wait";
        var url;
        if (typeof(this.$route.query.netxgroup_id) != "undefined"){
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/netxgroups/' + this.$route.query.netxgroup_id
        }
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiHistoryNetXGroup = data;
        console.log('got the account group data from api')
        console.log(data)
        this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
      //////////////////////////////////////////////
      // LIST NETX GROUP HISTORY
      //////////////////////////////////////////////
      async callApi(fromdate, todate) {

        console.log('getting the data')
        // build the filters for the url
        var filters =[];
        var fromfilter = {"name":"modifiedon","op":">=","val":fromdate}
        var tofilter = {"name":"modifiedon","op":"<","val":todate}

        if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
            filters.push(fromfilter)
        }
        if (typeof(todate) != "undefined" && todate != null && todate != "") {
            filters.push(tofilter)
        }
        let queryfilters = JSON.stringify(filters);

        this.showPaging = false

        try {
          document.body.style.cursor = "wait";
          var url;
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/netxgroup/deleted?q={"filters":' + queryfilters + '}' ;
          const { data } = await this.$http.get(url);

          console.log('got netx group history')
          this.apiNetxGroup = data;
          document.body.style.cursor = 'auto';
          console.log(this.apiNetxGroup)
          if (this.apiNetxGroup.length > this.pageSize){
          this.showPaging = true
          }
          this.executed = true;
        } catch (e) {
          console.log(e)
          this.apiNetxGroup = `Error: the server responded with '${e.response}'`;
        }
      },


      initForm() {
        console.log('init form');
          this.addNetxGroupForm.Name =  '';
          this.addNetxGroupForm.Notes =  '';
      },

      onReset(evt) {
        evt.preventDefault();
        this.$refs.addNetXGroupModal.hide();
        this.initForm();
      },
      //////////////////////////////////////////////
      // UPDATE ACCOUNT GROUP METHODS
      //////////////////////////////////////////////
      editNetxGroup(history) {
        console.log('editNetxGroup');
        console.log(history);
        this.editNetxGroupForm = history.netxgroup;
        this.editNetxGroupForm.modifiedby = history.modifiedby
        this.editNetxGroupForm.modifiedon = history.modifiedon
        this.editNetxGroupForm.action = history.method
      },

      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editNetxGroupModal.hide();
        this.initForm();
        this.getAccounts(); // why?
      },
    },
    created() {
      console.log('created')
      this.executed = true;
    //   this.getNetXGroupApi();
    //   this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
    },
  };
  </script>
  <style>
  .table .btn-group {
      white-space: nowrap;
  }
  .table .btn-group .btn {
      display: inline-block;
      float: none;
  }
  .table .btn-group .btn + .btn {
      margin-left: -5px;
  }
  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
  }
  td {
    font-size: 12px;
  }
  .btn-custom {
      width: 50px !important;
      height: 17px;
      font-size: 9px;
      font-weight: normal;
      text-align: center;
      background-color: red;
      text-emphasis-color: white;
      color: white;
  }

  </style>
