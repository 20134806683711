<template>

  <div>
    <div v-if="accountcostcentre===true">
      <p><u><strong>Cost Centres ({{ apiCostCentreAccount.Name }} {{ apiCostCentreAccount.AccountNumber }})</strong> </u>
      </p>
    </div>
    <div v-else>
      <p><u><strong>Cost Centres</strong> </u></p>
    </div>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.costcentre-modal>Insert Cost
          Centre</button>&nbsp;&nbsp;&nbsp;
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.costcentre-load-modal>Load Cost
          Centres</button>&nbsp;&nbsp;&nbsp;
        <a v-if="accountcostcentre===true"
          :href="'deletedcostcentres?account_id=' + this.selected_account +'&accountname=' +  apiCostCentreAccount.Name + '&accountnumber='+ apiCostCentreAccount.AccountNumber"
          target="_blank">
          <button type="button" class="btn btn-danger btn-sm">Deleted</button>
        </a>
        <a v-else :href="'deletedcostcentres'" target="_blank">
          <button type="button" class="btn btn-danger btn-sm">Deleted</button>
        </a>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-sm" scope="col" @click="sort('account.AccountNumber')">Account No </th>
              <th class="th-sm" scope="col" @click="sort('account.Name')">Account Name </th>
              <th class="th-sm" scope="col" @click="sort('CostCentreNumber')">Cost Centre No</th>
              <th class="th-lg" scope="col" @click="sort('Name')">Cost Centre Name</th>
              <!-- <th class="th-sm" scope="col" >Notes</th> -->
              <th class="th-sm" scope="col">Outbounds </th>
              <th class="th-sm" scope="col">Inbounds </th>
              <!-- <th scope="col" @click="sort('ModifiedOn')">Date modified</th>
              <th scope="col" @click="sort('ModifiedBy')">Modified by</th> -->
              <th scope="col"></th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(costcentre, index) in SortedapiCostCentres" :key="index">
              <td>{{ costcentre.account.AccountNumber }}</td>
              <td>{{ costcentre.account.Name }}</td>
              <td>{{ costcentre.CostCentreNumber }}</td>
              <td>{{ costcentre.Name }}</td>
              <!-- <td>{{ costcentre.Notes }}</td> -->
              <!-- <td align="center"><a :href="'/outbounds?costcentre_id=' + costcentre.costcentre_id + '&account_id=' + costcentre.AccountId" target="_blank">{{costcentre.outbounds.length}}</a></td> -->
              <!-- <td align="center"><a :href="'/inbounds?costcentre_id=' + costcentre.costcentre_id + '&account_id=' + costcentre.AccountId" target="_blank">{{costcentre.outbounds.length}}</a></td> -->
              <td align="center"><a
                  :href="'/outbounds?costcentre_id=' + costcentre.costcentre_id + '&account_id=' + costcentre.AccountId"
                  target="_blank">Outbounds</a></td>
              <td align="center"><a
                  :href="'/inbounds?costcentre_id=' + costcentre.costcentre_id + '&account_id=' + costcentre.AccountId"
                  target="_blank">Inbounds</a></td>
              <!-- <td>{{ costcentre.ModifiedOn }}</td>
              <td>{{ costcentre.ModifiedBy }}</td> -->
              <td align="center"><a :href="'/costcentrehistory?costcentre_id=' + costcentre.costcentre_id"
                  target="_blank">History</a></td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" v-b-modal.costcentre-update-modal
                    @click="editCostCentre(costcentre)">
                    {{editButtonText}}
                  </button>
                  <p> </p>
                  <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm"
                    @click="onDeleteCostCentre(costcentre)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredCostCentres.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>
    <!-- LOAD modal -->
    <b-modal ref="loadCostCentreModal" id="costcentre-load-modal" title="Load cost centres" hide-footer size="lg">
      <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
        <b-form-row>
          <b-col>
            <p>Select a comma seperated file (.txt).</p>
            <p><strong>Update will fail if account number doesn’t match existing</strong></p>
            <p><strong>Delete will fail if outbounds or inbounds reside on entry</strong></p>

          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadCostCentreForm.FileName" required
              placeholder="Select cost centre file">
            </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>


    <!-- add modal -->
    <b-modal ref="addCostCentreModal" id="costcentre-modal" title="Insert a new cost centre" hide-footer size="m">
      <b-form @submit="onSubmit" @reset="onReset" class="w-600">
        <!-- <b-form-row v-show="ShowAccount">
        <b-col>
            <div align="left">
              <AccountDropdownVue  @account-changed="accountChange"></AccountDropdownVue></div>
            <br>
        </b-col>
      </b-form-row> -->
        <b-form-row>
          <b-col>
            <b-form-group id="form-account-group" label="Account" label-for="form-account-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <AccountDropdownVue @account-changed="accountChange"></AccountDropdownVue>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Name-group" label="Cost Centre Name" label-for="form-Name-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-Name-input" type="text" size="sm" v-model="addCostCentreForm.Name"
                placeholder="Enter cost centre name">
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Notes-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-Notes-input" type="text" size="sm" v-model="addCostCentreForm.Notes"
                placeholder="Enter Notes">
              </b-form-input>
            </b-form-group>
          </b-col>


        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal ref="editCostCentreModal" id="costcentre-update-modal" title="Update cost centre" hide-footer size="l">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-CCN-group" label="Cost Centre No" label-for="form-Name-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-CCN-input" type="text" size="sm" v-model="editCostCentreForm.CostCentreNumber"
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-account-group" label="Account" label-for="form-account-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <AccountDropdownVue v-bind:selected_account="editCostCentreForm.AccountId"
                @account-changed="accountChange"></AccountDropdownVue>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Name-group" label="Cost Centre Name" label-for="form-Name-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-Name-input" type="text" size="sm" v-model="editCostCentreForm.Name"
                placeholder="Enter cost centre name">
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Notes-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-Notes-input" type="text" size="sm" v-model="editCostCentreForm.Notes"
                placeholder="Enter notes">
              </b-form-input>
            </b-form-group>
          </b-col>


        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row" v-if="isAdmin">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
      SelectedPhoneType: '',
      loadCostCentreForm: {
        FileName: ''
        },
      addCostCentreForm: {
        Name: '' ,
        Notes: ''
      },
      editCostCentreForm: {
        CostCentreId: null,
        Name: null ,
        Notes: null ,
        AccountId: null
      },
      accountcostcentre : false,
      apiCostCentres: null,
      apiCostCentreAccount:{},
      executed: false,
      message: 'initial alert',
      currentSort:'Name',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:true,
      currentPage:1,
      filter:'',
      ShowAccount:true,
      selected_account: null
    };
  },

  computed:{
  filteredCostCentres() {
    console.log('filtered costcentre');
      return this.apiCostCentres.filter(c => {
        if(this.filter == '') return true;
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              //  (c.Notes && c.Notes.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0 ||
               c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.CostCentreNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiCostCentres:function() {
  if (this.apiCostCentres != null){
      return this.filteredCostCentres.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(this.currentSort === 'account.Name'){
          var prop = this.currentSort.split('.');
          var len = prop.length;
            var i = 0;
            while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
            if (a < b) {
                return -1 * modifier;
            } else if (a > b) {
                return 1 * modifier;
            } else {
                return 0;
            }
        }
        else{
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
          return 0;
        }
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    }
        else{ return null}
  },

  },
  methods: {
    download: function() {
        console.log("in download")
        var data = "CostCentreNumber,CostCentreName,AccountNumber,Notes,Action"
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "UploadCostCentres.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
    onChangePhoneType(event) {
      this.SelectedPhoneType= event.target.value

      console.log(event.target.value)


      // this.$emit('account-changed',this.SelectedAccount)
    },
    accountChange: function (e) {
        this.selectedAccount = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredCostCentres.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // get account details
    // *******************************************
    async getAccountApi(accountid) {

      console.log(accountid)
      console.log('getting the account data')
      try {
        document.body.style.cursor = "wait";
        var url;
        url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/accounts/' + accountid
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiCostCentreAccount = data;
        console.log('got the account data from api')
        console.log(data)
        this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // List costcentres
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the costcentre list')
      console.log(this.$route.query.account_id)
      console.log('getting the costcentre list')
      var url;
      if (typeof(this.$route.query.account_id) != "undefined"){
          console.log('selected account in CostCentre is:');
          console.log(this.$route.query.account_id)
          this.accountcostcentre = true;
          console.log('accountcostcentre is:');
          console.log(this.accountcostcentre);
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.$route.query.account_id + '"}]}';
          this.currentSort = 'CostCentreNumber'
          this.ShowAccount = false
          this.selected_account = this.$route.query.account_id
          this.getAccountApi(this.selected_account);
          console.log('selected account in CostCentre is:');
          console.log(this.selected_account);
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/costcentres`
      }
      try {
        const { data } = await this.$http.get(url);

        this.apiCostCentres = data;
        if (this.apiCostCentres.length < this.pageSize){
          this.showPaging = false
        }
        console.log(this.apiCostCentres )
        console.log(this.apiCostCentres.length)
        this.executed = true;
      } catch (e) {
        this.apiCostCentres = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // Delete costcentre
    // *******************************************
    async removeCostCentre(costcentreid) {

      console.log("in removeCostCentre before")
      console.log(costcentreid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/costcentres/${costcentreid}`).then(() => {
          this.message = 'costcentre removed!';
          this.showMessage = true;
          console.log("in removeCostCentre after")
          console.log(data)
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeCostCentre error")
          console.error(err);
          if (err.response.status == 400) {
            console.log(err.response)
            console.log(err.response.data.message)
            alert("Problem deleting cost centre. " + err.response.data.message)
          }
          // this.callApi();

        });

        // console.log('delete data is:')
        // console.log(data)
        // this.callApi()
      } catch (e) {
        this.apiCostCentres = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteCostCentre(costcentre) {
      console.log(costcentre.CostCentreId)
      if(confirm("Are you sure you want to delete this costcentre?")){
        this.removeCostCentre(costcentre.costcentre_id);
      }
    },
    // *******************************************
    // Load on submit
    // *******************************************
    onLoadSubmit(evt) {
      console.log('on load submit');
      evt.preventDefault();
      this.$refs.loadCostCentreModal.hide();
      const payload = {
        FileName: this.loadCostCentreForm.FileName
      };
      this.loadcostcentres(payload);
      //this.initForm();
    },
    onLoadReset(evt) {
    evt.preventDefault();
    this.$refs.loadCostCentreModal.hide();
    this.initForm();
    },
    // Add cdrload method to post to API
    async loadcostcentres(payload) {

      let formData = new FormData();
      console.log( 'in load products');
      console.log( payload.file);
      formData.append('file', payload.FileName);
      var url;
      
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/costcentres/load`
      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'cost centre loaded!';
          this.showMessage = true;
          document.body.style.cursor = 'auto';
          this.callApi();
        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          document.body.style.cursor = 'auto';
          this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },

    // Add costcentre method to post to API
    async addCostCentre(payload) {

    //   const id_token = claims.__raw;
      console.log('in add costcentre');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/costcentres`, payload).then(() => {
          this.message = 'costcentre added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated costcentre. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add costcentre. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCostCentres = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addCostCentreForm.Name =  null;
        this.addCostCentreForm.Notes = null;
    },
    // *******************************************
    // Add costcentre on submit
    // *******************************************
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      console.log(this.selectedAccount);
      console.log(this.selected_account);
      this.$refs.addCostCentreModal.hide();
      var accountid;
      if ((this.selected_account) != null){
        accountid = this.selected_account
      }
      else{
        accountid = this.selectedAccount
      }
      const payload = {
        Name: this.addCostCentreForm.Name,
        Notes: this.addCostCentreForm.Notes,
        AccountId: accountid
      };
      this.addCostCentre(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addCostCentreModal.hide();
      this.initForm();
    },
  //
    editCostCentre(costcentre) {
      console.log('editCostCentre');
      console.log(costcentre);
      console.log(costcentre.costcentre_id);
      this.editCostCentreForm = costcentre;
      this.callApi()
    },

    // *******************************************
    // Update costcentre on submit
    // *******************************************
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('costcentre_id');
      evt.preventDefault();
      this.$refs.editCostCentreModal.hide();
      const payload = {
        Name: this.editCostCentreForm.Name,
        Notes: this.editCostCentreForm.Notes,
        AccountId: this.selectedAccount
      };
      this.updateCostCentre(payload, this.editCostCentreForm.costcentre_id);
      this.callApi()
    },

    async updateCostCentre(payload, costcentreid) {

      console.log('in update costcentre');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/costcentres/${costcentreid}`, payload).then(() => {
          this.message = 'costcentre updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated costcentre. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add costcentre. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiCostCentres = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCostCentres = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editCostCentreModal.hide();
      this.initForm();
      this.getCostCentres(); // why?
    },
  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
  },
};
</script>
