<template>

    <div>
      <p ><strong>Recurring Charge Catalogue</strong> </p>
      <br>
      <div class="result-block-container" width="1200">
        <div :class="['result-block', executed ? 'show' : '']">
          <button  v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.servicetype-modal>Insert Service Type</button>
          <br><br>
          <input type="search" v-model="filter">
          <table class="table table-striped" >
            <thead>
              <tr>
                <th class="th-sm" scope="col" @click="sort('Name')">Name</th>
                <th class="th-lg" scope="col" @click="sort('chargecategory.Name')" >Category</th>
                <th scope="col" @click="sort('Cost')">Cost</th>
                <th scope="col" @click="sort('chargecount')">Charges</th>
                <!-- <th scope="col" @click="sort('ModifiedOn')">Date modified</th> -->
                <!-- <th scope="col" @click="sort('ModifiedBy')">Modified by</th> -->
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(servicetype, index) in SortedapiServiceTypes" :key="index">
                <td>{{ servicetype.Name }}</td>
                <td>{{ servicetype.chargecategory.Name }}</td>
                <td>{{ servicetype.Cost }}</td>
                <td>{{ servicetype.chargecount }}</td>
                <!-- <td>{{ servicetype.ModifiedOn }}</td> -->
                <!-- <td>{{ servicetype.ModifiedBy }}</td> -->
                <td  >
                  <div class="btn-group"  role="group" >
                    <button
                            type="button"
                            class="btn btn-warning btn-sm"
                            v-b-modal.servicetype-update-modal
                            @click="editServiceType(servicetype)">
                            {{editButtonText}}
                    </button>
                    <p> </p>
                    <div v-if="servicetype.chargecount < 1">
                    <button  v-if="isAdmin"
                            type="button"
                            class="btn btn-danger btn-sm"
                            @click="onDeleteServiceType(servicetype)">
                        Delete
                    </button>
                  </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div>
        </div>
      </div>
      <!-- add modal -->
        <b-modal ref="addServiceTypeModal"
              id="servicetype-modal"
              title="Insert a service type"
              hide-footer size="m" >
        <b-form @submit="onSubmit" @reset="onReset" class="w-600">
        <b-form-row>
          <b-col>
              <b-form-group id="form-Name-group"
                        label="Name"
                        label-for="form-Name-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Name-input"
                        type="text"   size="sm"
                        v-model="addServiceTypeForm.Name"
                        placeholder="Enter name">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Description-group"
                        label="Description"
                        label-for="form-Description-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Description-input"
                        type="text"   size="sm"
                        v-model="addServiceTypeForm.Description"
                        placeholder="Enter description">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Cost-group"
                        label="Carrier cost"
                        label-for="form-Cost-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Cost-input"
                        type="text"   size="sm"
                        v-model="addServiceTypeForm.Cost"
                        placeholder="Enter carrier cost">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
           <b-form-group id="form-Carrier-group"
            label="Charge category"
                      label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
            <div >
                <ChargeCategoryDropDown @chargecategory-changed="chargecategoryChange"></ChargeCategoryDropDown>
            <br>
        </div>
        </b-form-group>
        </b-col>

        </b-form-row>
        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row">
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>

    <!-- EDIT modal -->
       <b-modal ref="editServiceTypeModal"
              id="servicetype-update-modal"
              title="Update  service type"
              hide-footer size="m" >
        <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
            <b-form-row>
          <b-col>
              <b-form-group id="form-Name-group"
                        label="Name"
                        label-for="form-Name-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Name-input"
                        type="text"   size="sm"
                        v-model="editServiceTypeForm.Name"
                        placeholder="Enter name">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Description-group"
                        label="Description"
                        label-for="form-Description-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Description-input"
                        type="text"   size="sm"
                        v-model="editServiceTypeForm.Description"
                        placeholder="Enter description">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Cost-group"
                        label="Carrier cost"
                        label-for="form-Cost-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Cost-input"
                        type="text"   size="sm"
                        v-model="editServiceTypeForm.Cost"
                        placeholder="Enter carrier cost">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
           <b-form-group id="form-Carrier-group"
            label="Charge category"
                      label-for="form-InclusiveText-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
            <div >
                <ChargeCategoryDropDown v-bind:selected_chargecategory="editServiceTypeForm.ChargeCategoryId" @chargecategory-changed="chargecategoryChange"></ChargeCategoryDropDown>
            <br>
        </div>
        </b-form-group>
        </b-col>

        </b-form-row>

        <b-form-row>
            <b-col>
         <p></p>
          </b-col>
          </b-form-row>
          <div class="container">
          <div class="row" v-if="isAdmin" >
              <div class="col text-center">
              <b-button-group>
            <b-button type="submit" variant="primary">Submit</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import ChargeCategoryDropDown from "../components/ChargeCategoryDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        selectedChargeCategory:"",
        addServiceTypeForm: {
          Name: '' ,
          Description: '' ,
          Cost: ''
        },
        editServiceTypeForm: {
          STId: null,
          Name: null ,
          Description: null,
          Cost: null
        },
        apiServiceTypes: null,
        executed: false,
        message: 'initial alert',
        currentSort:'Name',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:true,
        currentPage:1,
        filter:'',
        ShowAccount:true,
      };
    },

    computed:{
    filteredServiceTypes() {
      console.log('filtered servicetype');
        return this.apiServiceTypes.filter(c => {

          if(this.filter == '') return true;
        //   if (c.name == null) c.Name = '';
        //   if (c.chargecategory.Name == null) c.chargecategory.Name = '';
          return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.chargecategory.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
      filteredChargeCategorys() {
      console.log('filtered chargecategory');
        return this.apiChargeCategorys.filter(c => {
          if(this.filter == '') return true;
          return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                 c.ChargeType.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
                 c.SagePart.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        })
      },
    SortedapiServiceTypes:function() {
    //   console.log('sort servicetype');
    //   console.log('sort account');
      if (this.apiServiceTypes == null) return null;
      // console.log ('in sort account....');
      // console.log(this.currentSort)
      return this.filteredServiceTypes.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;

        if(this.currentSort === 'chargecategory.Name'){
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
        else{
          if (a[this.currentSort] === null) return 1;
          if (b[this.currentSort] === null) return -1;
          if(a[this.currentSort].toString().toLowerCase() < b[this.currentSort].toString().toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toString().toLowerCase() > b[this.currentSort].toString().toLowerCase()) return 1 * modifier;
        }
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      chargecategoryChange: function (e) {
        this.selectedChargeCategory = e;
        if(e == ''){
            this.selectedChargeCategory = null;
        }
       },


      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredServiceTypes.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List servicetypes
      // *******************************************
      async callApi(lablex) {

        console.log(lablex)
        var url;
        document.body.style.cursor = 'wait';
        if (typeof(this.$route.query.CBId) != "undefined"){
            console.log('selected ID in ServiceType is:');
            console.log(this.$route.query.CBId)
            url = process.env.VUE_APP_API_BASE_URL + '/api/v1/servicetypes?q={"filters":[{"name":"CBId","op":"eq","val":"' + this.$route.query.CBId + '"}]}';
          //   console.log(url)
            this.ShowAccount = false
            this.selected_servicetype = this.$route.query.CBId
        }
        else{
            url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/servicetypes`
        }
        try {
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiServiceTypes = data;
          console.log(data);
          if (this.apiServiceTypes.length < this.pageSize){
            this.showPaging = false
          }
          this.executed = true;
        } catch (e) {
          this.apiServiceTypes = `Error: the server responded with '${e.response}'`;
        }
      },

      // *******************************************
      // Delete servicetype
      // *******************************************
      async removeServiceType(servicetypeid) {

      //   console.log("in removeServiceType before")
        console.log(servicetypeid)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/servicetypes/${servicetypeid}`).then(() => {
            this.message = 'servicetype removed!';
            this.showMessage = true;
            console.log("in removeServiceType after")
            this.callApi("coming from delete");

          }).catch((err) => {
            console.log("in removeServiceType error")
            console.error(err);
            this.callApi();

          });

          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiServiceTypes = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteServiceType(servicetype) {
        console.log(servicetype.STId)
        if(confirm("Are you sure you want to delete this servicetype?")){
          this.removeServiceType(servicetype.STId);
        }
      },

      // Add servicetype method to post to API
      async addServiceType(payload) {

      //   const id_token = claims.__raw;
        console.log('in add servicetype');
        console.log('payload is');
        console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/servicetypes`, payload).then(() => {
            this.message = 'servicetype added!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated servicetype. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add servicetype. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiServiceTypes = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addServiceTypeForm.Name =  null;
          this.addServiceTypeForm.Notes = null;
      },

      // *******************************************
      // Add servicetype on submit
      // *******************************************
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addServiceTypeModal.hide();
        const payload = {
          Name: this.addServiceTypeForm.Name,
          Description: this.addServiceTypeForm.Description,
          Cost: this.addServiceTypeForm.Cost,
          ChargeCategoryId: this.selectedChargeCategory
        };
        this.addServiceType(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addServiceTypeModal.hide();
        this.initForm();
      },
    //
      editServiceType(servicetype) {
        console.log('editServiceType');
        console.log(servicetype);
        console.log(servicetype.CBId);
        // this.callChargebandApi();
        this.editServiceTypeForm = servicetype;
        this.selectedChargeband = servicetype.CBId
        this.callApi()
      },

      // *******************************************
      // Update servicetype on submit
      // *******************************************
      onSubmitUpdate(evt) {
        console.log('in onSubmitUpdate');
        console.log('CBId');
        evt.preventDefault();
        this.$refs.editServiceTypeModal.hide();
        const payload = {
          Name: this.editServiceTypeForm.Name,
          Description: this.editServiceTypeForm.Description,
          Cost: this.editServiceTypeForm.Cost,
          ChargeCategoryId: this.selectedChargeCategory
        };
        this.updateServiceType(payload, this.editServiceTypeForm.STId);
        this.callApi()
      },

      async updateServiceType(payload, servicetypeid) {

        console.log('in update servicetype');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/servicetypes/${servicetypeid}`, payload).then(() => {
            this.message = 'servicetype updated!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated servicetype. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add servicetype. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi();

          });

          // this.apiServiceTypes = data;
          // this.executed = true;
          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiServiceTypes = `Error: the server responded with '${e.response}'`;
        }
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editServiceTypeModal.hide();
        this.initForm();
        this.getServiceTypes(); // why?
      },
    },
    created() {
      console.log('created')
    //   this.callChargebandApi();
      this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      ChargeCategoryDropDown: ChargeCategoryDropDown,
    },
  };
  </script>
