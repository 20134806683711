<template>

  <div>
    <p><u><strong>Account Bundles List</strong></u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.accountbundle-modal>Insert  Account Bundle</button>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('account.Name')" >Account</th>
              <th class="th-lg" scope="col" @click="sort('bundle.BundleName')" >Bundle</th>
              <th class="th-lg" scope="col" @click="sort('bundle.BundleType')" >Type</th>
              <th scope="col" @click="sort('ModifiedOn')">Date modified</th>
              <th scope="col" @click="sort('ModifiedBy')">Modified by</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(accountbundle, index) in SortedapiAccountBundle" :key="index">
              <td>{{ accountbundle.account.Name }}</td>
              <td>{{ accountbundle.bundle.BundleName }}</td>
              <td>{{ accountbundle.bundle.BundleType }}</td>
              <td>{{ accountbundle.ModifiedOn }}</td>
              <td>{{ accountbundle.ModifiedBy }}</td>
              <td>
                <div class="btn-group"  role="group">
                  <p>  </p>
                  <button v-if="isAdmin" v-on:click="onDeleteAccountBundle(accountbundle)"  type="button"
                          class="btn btn-danger btn-sm">
                      Delete
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div><p align="center">
          <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button>
          </p></div>
      </div>
    </div>


    <!-- ADD modal -->
    <b-modal ref="addAccountBundleModal"
            id="accountbundle-modal"
            title="Insert a new account bundle"
            hide-footer size="m" >
      <b-form @submit="onSubmit" @reset="onReset" class="w-200">
      <b-form-row>
          <b-col>
           <b-form-group id="form-Account-group"
            label="Account"
                      label-for="form-Account-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div >
                <AccountDropdownVue  @account-changed="accountChange"></AccountDropdownVue>
        </div>
        </b-form-group>
        </b-col>
      </b-form-row>
       <b-form-row>
          <b-col>
           <b-form-group id="form-Bundle-group"
            label="Bundle"
                      label-for="form-Bundle-input" label-cols="4" label-cols-lg="3" label-size="sm" >
            <div >
                <BundleDropDown @callbundle-changed="callbundleChange"></BundleDropDown>
        </div>
        </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";
import CarrierDropDownVue from "../components/CarrierDropDown.vue";
import BundleDropDown from "../components/BundleDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      editcomponentKey:0,
      selected_bundle:"",
      addAccountBundleForm: {
        BundleName: '' ,
        BundleType: '' ,
        BundleCost: '' ,
        InclusiveMinutes: '' ,
        Direction: '' ,
        CarrierId: '' ,
        GroupBundle: ''
      },

      apiAccountBundle: null,
      executed: false,
      message: 'initial alert',
      currentSort:'ABId',
      currentSortDir:'asc',
      pageSize:60,
      currentPage:1,
      filter:'',
    };
  },

  computed:{
  filteredAccountBundles() {
    console.log('filtered accountbundle');
      return this.apiAccountBundle.filter(c => {
        if(this.filter == '') return true;
        if (c.bundle.BundleName == null) c.bundle.BundleName = '';
        if (c.account.Name == null) c.account.Name = '';
        return c.bundle.BundleName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
               c.bundle.BundleType.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },

  SortedapiAccountBundle:function() {
    // console.log('sort chargeband');
    // console.log('sort account');
    if (this.apiAccountBundle == null) return null;
    // console.log ('in sort account....');
    // console.log(this.currentSort)
    return this.filteredAccountBundles.slice().sort((a,b) => {
      let modifier = 1;
      var subfields = ['account.Name','bundle.BundleName','bundle.BundleType']
      if(this.currentSortDir === 'desc') modifier = -1;

      if (subfields.includes(this.currentSort)) {
          var prop = this.currentSort.split('.');
          var len = prop.length;
            var i = 0;
            while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
            if (a < b) {
                return -1 * modifier;
            } else if (a > b) {
                return 1 * modifier;
            } else {
                return 0;
            }
        }
      else{
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      }
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    accountChange: function (e) {
        this.selectedAccount = e;
    },
    carrierChange: function (e) {
        this.selectedCarrier = e;
    },
    callbundleChange: function (e) {
        console.log('XXXXXXXXXXXXXXXXXXXXXX in bundlechanged XXXXXXXXXXXXXXXXXXXXXX');
        console.log(e);
        this.selectedCallBundle = e;
    },
    forceRerender:function() {
      this.editcomponentKey += 1
    },
    accountbundleChange: function (e) {
        this.selectedAccount = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredAccountBundles.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    //////////////////////////////////////////////
    // LIST ACCOUNT BUNDLE METHODS
    //////////////////////////////////////////////
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the data')
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountbundles`);
        console.log('got account bundle data')
         console.log(data)
        this.apiAccountBundle = data;
        this.executed = true;
      } catch (e) {
        this.apiAccountBundle = `Error: the server responded with '${e.response}'`;
      }
    },

    //////////////////////////////////////////////
    // DELETE CALL BUNDLE METHODS
    //////////////////////////////////////////////
    async removeAccountBundle(accountbundleid) {

      console.log("in removeAccountBundle before")
      console.log(accountbundleid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountbundles/${accountbundleid}`).then(() => {
          this.message = 'accountbundle removed!';
          this.showMessage = true;
          console.log("in removeAccountBundle after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccountBundle error")
          console.error(err);
          this.callApi();

        });

        // this.apiAccountBundle = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccountBundle = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteAccountBundle(accountbundle) {
      console.log(accountbundle.ABId)
      if(confirm("Are you sure you want to delete the account bundle?")){
        this.removeAccountBundle(accountbundle.ABId);
      }
    },

    //////////////////////////////////////////////
    // ADD CALL BUNDLE METHODS
    //////////////////////////////////////////////

    // Add accountbundle method to post to API
    async addAccountBundle(payload) {

    //   const id_token = claims.__raw;
      console.log('in add accountbundle');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accountbundles`, payload).then(() => {
          this.message = 'accountbundle added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated accountbundle. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add accountbundle. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apiAccountBundle = data;
        // this.executed = true;
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccountBundle = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addAccountBundleForm.GroupBundle =  false;
    },
    // Add account bundle on submit
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addAccountBundleModal.hide();
      const payload = {
        AccountId: this.selectedAccount,
        BundleId: this.selectedCallBundle
      };
      this.addAccountBundle(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addAccountBundleModal.hide();
      this.initForm();
    },


  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
    CarrierDropDownVue: CarrierDropDownVue,
    BundleDropDown: BundleDropDown,
  },
};
</script>
<style>
.table .btn-group {
	white-space: nowrap;
}
.table .btn-group .btn {
	display: inline-block;
	float: none;
}
.table .btn-group .btn + .btn {
	margin-left: -5px;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: normal;
  text-align: center;
  font-size: 14px;
}
td {
  font-size: 12px;
}
.btn-custom {
    width: 50px !important;
    height: 17px;
    font-size: 9px;
    font-weight: normal;
    text-align: center;
    background-color: red;
    text-emphasis-color: white;
    color: white;
}

</style>
