<template>

    <div>
      <p ><u><strong>Deleted Accounts</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
            <b-form @submit="onSubmit" >

               <b-form-group
                id="fieldset-horizontal"
                label-cols-sm="2"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="5"
                label="From Date"
                label-for="input-horizontal"
                >
                <b-form-input id="form-fromdate-input"
                        type="date"   size="sm"
                        v-model="FromDate"
                        placeholder="Enter from date">
                </b-form-input>
                </b-form-group>
                <b-form-group
                id="fieldset-horizontal"
                label-cols-sm="2"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="5"
                label="To Date"
                label-for="input-horizontal"
                >
                <b-form-input id="form-todate-input"
                        type="date"   size="sm"
                        v-model="ToDate"
                        placeholder="Enter to date">
                </b-form-input>
                </b-form-group>


            <b-button type="submit" variant="primary">Find</b-button>
           </b-form>

          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th class="th-sm" scope="col" @click="sort('AccountNumber')">Account No</th>
                <th class="th-lg" scope="col" @click="sort('Name')"  algin="left">Account Name</th>
                <th scope="col" >Deleted on</th>
                <th scope="col">User</th>
                <th scope="col">Action</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(accounthistory, index) in SortedapiAccounts" :key="index">
                <td>{{ accounthistory.account.AccountNumber }}</td>
                <td>{{ accounthistory.account.Name }}</td>
                <td>{{ accounthistory.modifiedon }}</td>
                <td>{{ accounthistory.modifiedby }}</td>
                <td>{{ accounthistory.method }}</td>
                <td align="center"><a :href="'/accounthistory?account_id=' + accounthistory.account.account_id + '&deleted=true'" target="_blank">History</a></td>
                <td>
                  <div class="btn-group"  role="group">
                      <button
                              type="button"
                              class="btn btn-warning btn-sm"
                              label="view"
                              v-b-modal.account-update-modal
                              @click="editAccount(accounthistory)">
                              View
                      </button>
  
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
            v-model="currentPage"
            :total-rows="filteredAccounts.length"
            align="center"
            :per-page="pageSize"
            aria-controls="apirecords"
            ></b-pagination>
            </p></div>
        </div>
      </div>
  
      <!-- EDIT modal -->
      <b-modal ref="editAccountModal"
              id="account-update-modal"
              title="Account History"
              hide-footer size="xl" >
        <b-form  @reset="onResetUpdate" class="w-200">
        <b-form-row>
          <b-col>
              <b-form-group id="form-customerno-group"
                        label="Account Number"
                        label-for="form-customerno-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-customerno-input"
                            type="text"   size="sm"
                            v-model="editAccountForm.AccountNumber"
                            readonly
                            placeholder="Enter account number">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-SageAccount-group"
                        label="Sage Account"
                        label-for="form-SageAccount-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-SageAccount-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.SageAccount"
                        readonly
                        placeholder="Enter Sage Account">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Account Name"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editAccountForm.Name"
                              readonly
                              placeholder="Enter account name" w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-NetXaccount_id-group"
                        label="NetX Account ID"
                        label-for="form-NetXaccount_id-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-NetXaccount_id-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.NetXaccount_id"
                        readonly
                        placeholder="Enter NetX Account ID">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Address1-group"
                        label="Address1"
                        label-for="form-Address1-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Address1-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.Address1"
                        readonly
                        placeholder="Enter Address1">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
             <b-form-group id="form-Carrier-group"
              label="Industry"
                        label-for="form-InclusiveText-input" label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <div >
                  <IndustryDropDown :disable-option="disableOption" v-bind:selected_industrytype="editAccountForm.IndustryTypeId" @industrytype-changed="industrytypeChange"></IndustryDropDown>
          </div>
          </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Address2-group"
                        label="Address2"
                        label-for="form-Address2-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-Address2-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.Address2"
                        readonly
                        placeholder="Enter Address2">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-SaleAgent-group"
                        label="Account Manager"
                        label-for="form-SaleAgent-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-SaleAgent-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.SaleAgent"
                        readonly
                        placeholder="Enter SaleAgent">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-City-group"
                        label="City"
                        label-for="form-City-input" label-cols="6" label-cols-sm="3"  label-size="sm">
              <b-form-input id="form-City-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.City"
                        readonly
                        placeholder="Enter City"
                        >
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-ContactName-group"
                        label="Contact"
                        label-for="form-ContactName-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-ContactName-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.ContactName"
                        readonly
                        placeholder="Enter ContactName">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
               <b-form-group id="form-County-group"
                        label="County"
                        label-for="form-County-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-County-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.County"
                        readonly
                        placeholder="Enter County">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-Email-group"
                        label="Report Email"
                        label-for="form-Email-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Email-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.Email"
                        readonly>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
               <b-form-group id="form-Country-group"
                        label="Country"
                        label-for="form-Country-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-Country-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.Country"
                        readonly
                        placeholder="Enter Country">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-Notes-group"
                        label="Invoice Email"
                        label-for="form-Notes-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Notes-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.InvoiceEmail"
                        readonly>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
               <b-form-group id="form-PostCode-group"
                        label="Post Code"
                        label-for="form-PostCode-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PostCode-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.PostCode"
                        readonly
                        placeholder="Enter Post Code">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-NatMinCharge-group"
                        label="National Min"
                        label-for="form-NatMinCharge-input" label-cols="4" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-NatMinCharge-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.NatMinCharge"
                        readonly
                        placeholder="national minimum charge">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
               <b-form-group id="form-BTN-group"
                        label="PO Number"
                        label-for="form-BTN-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-BTN-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.BTN"
                        readonly
                        placeholder="Enter po number">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-NgnMinCharge-group"
                        label="NGN minimum"
                        label-for="form-NgnMinCharge-input" label-cols="4" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-NgnMinCharge-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.NgnMinCharge"
                        readonly
                        placeholder="ngn minimum charge">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
               <b-form-group id="form-PaymentTerm-group"
                        label="Payment Terms"
                        label-for="form-PaymentTerm-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PaymentTerm-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.PaymentTerm"
                        readonly
                        placeholder="Enter PaymentTerm">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
               <b-form-group id="form-InterMinCharge-group"
                        label="International Min"
                        label-for="form-InterMinCharge-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-InterMinCharge-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.InterMinCharge"
                        readonly
                        placeholder="international minimum charge">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Notes-group"
                        label="Notes"
                        label-for="form-Notes-input" label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Notes-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.Notes"
                        readonly
                        placeholder="Enter Notes">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-clo></b-clo>
        </b-form-row>
      <b-form-row>
          <b-col>
          <b-form-checkbox
              id="MaintenaceOnly"
              v-model="editAccountForm.MaintenaceOnly"
              name="MaintenaceOnly"
              disabled='true'
              >
              Maintainance Only
          </b-form-checkbox>
          </b-col>
          <b-col>
             <b-form-checkbox
              id="DirectDebit"
              v-model="editAccountForm.DirectDebit"
              name="DirectDebit"
              disabled='true'
              >
              Direct Debit
          </b-form-checkbox>
          </b-col>
          <b-col>
             <b-form-checkbox
              id="Taxable"
              v-model="editAccountForm.Taxable"
              name="Taxable"
              disabled='true'
              >
              Taxable
          </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox
              id="ExportCalls"
              v-model="editAccountForm.ExportCalls"
              name="ExportCalls"
              disabled='true'
              >
              Export Calls
          </b-form-checkbox>
          </b-col>
          <b-col>
             <b-form-checkbox
              id="Roundup"
              v-model="editAccountForm.Roundup"
              name="Roundup"
              disabled='true'
              >
              Round Up
          </b-form-checkbox>
          </b-col>
  
        </b-form-row>
        <br>
  
  
        <b-form-row>
          <b-col>
              <b-form-group id="form-LastBillDate-group"
                        label="Date Last Transferred"
                        label-for="form-LastBillDate-input" label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input readonly id="form-LastBillDate-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.LastBillDate">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-ModifiedOn-group"
                        label="Modified on"
                        label-for="form-ModifiedOn-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input readonly id="form-ModifiedOn-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.modifiedon">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
         <b-form-row>
          <b-col>
              <b-form-group id="form-ModifiedBy-group"
                        label="User"
                        label-for="form-ModifiedBy-input" label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input readonly id="form-ModifiedBy-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.modifiedby">
              </b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
              <b-form-group id="form-LastBillDate-group"
                        label="Action"
                        label-for="form-LastBillDate-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input readonly id="form-LastBillDate-input"
                        type="text"   size="sm"
                        v-model="editAccountForm.action">
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
          <div class="container">
          <div class="row" >
              <div class="col text-center">
              <b-button-group>
                <b-button type="reset" variant="danger">Close</b-button>
          </b-button-group>
              </div>
          </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </template>
  
  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import IndustryDropDown from "../components/IndustryDropDownReadOnly.vue"
  
  import {isAdmin, editButtonText} from '@/auth/AuthService'
  export default {
    Name: "Api",
    data() {
      return {
        isAdmin: isAdmin(),
        editButtonText: editButtonText(),
        FromDate : null,
        ToDate : null,
        disableOption: true,
        selectedIndustryType:"",
        buttonName : '',
        editAccountForm: {
          account_id : '',
          Name: '' ,
          AccountNumber: '' ,
          Address1: '' ,
          Address2: '' ,
          PostCode: '' ,
          City: '' ,
          County: '' ,
          Country: '' ,
          SaleAgent: '' ,
          PaymentTerm: '',
          ContactName: '',
          SageAccount: '',
          NetXaccount_id: '' ,
          Email: '' ,
          InvoiceEmail: '',
          MaintenaceOnly: '',
          BTN: '' ,
          PhoneUsers: 0,
          Taxable: '',
          Roundup: '',
          DirectDebit: '',
          BonusEligible: '',
          FaxToEmailCost: '',
          AudioConfPct: '',
          VideoConfPct: '',
          NatMinCharge: '',
          InterMinCharge: '',
          NgnMinCharge: '',
          CreatedOn:'',
          CreatedBy:'',
          ModifiedOn:'',
          ModifiedBy:'',
          ExportCalls: '',
          industry:{},
          modifiedby :null,
          modifiedon : null,
          action : null
        },
        apiAccounts: [],
        apiHistoryAccount: null,
        apiHistoryIndustry: null,
        executed: false,
        message: 'initial alert',
        currentSort:'modifiedon',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:false,
        currentPage:1,
        filter:'',
        account_id:"shahabtest",
  
      };
    },
  
    computed:{
    filteredAccounts() {
      return this.apiAccounts;
      // console.log('filtered account');
        // return this.apiAccounts.filter(c => {
        //   if(this.filter == '') return true;
        //   if (c.SageAccount == null) c.SageAccount = '';
        //   if (c.AccountNumber == null) c.AccountNumber = '';
        //   return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
        //          c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
        //          c.SageAccount.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        // })
      },
    SortedapiAccounts:function() {
      console.log('sort account');
      if (this.apiAccounts == null) return null;
      console.log ('in sort account....');
      console.log(this.currentSort)
      return this.filteredAccounts.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
  
        if (this.currentSort === 'outbounds') {
          if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        }
        else if(this.currentSort === 'costcentres'){
          if(a[this.currentSort].length < b[this.currentSort].length) return -1 * modifier;
          if(a[this.currentSort].length > b[this.currentSort].length) return 1 * modifier;
        }
        else{
          if (a[this.currentSort] === null) return 1;
          if (b[this.currentSort] === null) return -1;
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        }
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      industrytypeChange: function (e) {
          this.selectedIndustryType = e;
          if(e == ''){
              this.selectedIndustryType = null;
          }
         },
    
        onSubmit(evt) {
        console.log('on submit');
        this.apiHistories = null;
        this.showresult = false;
        var todate = null

            if (this.ToDate != null){
            todate = this.ToDate + ' 23:59:59';
            }

            if (this.ToDate != null & this.FromDate > this.ToDate){
                evt.preventDefault();
                alert("To date can not be before the from date")
            }
            else{
                this.callApi( this.FromDate, todate);
                evt.preventDefault();
            }  
        },
    //   nextPage:function() {
    //     if((this.currentPage*this.pageSize) < this.filteredAccounts.length) this.currentPage++;
    //   },
    //   prevPage:function() {
    //     if(this.currentPage > 1) this.currentPage--;
    //   },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
  
      // *******************************************
      // List account history
      // *******************************************
      async callApi(fromdate, todate) {

        console.log('getting the data')
        // build the filters for the url
        var filters =[];
        var fromfilter = {"name":"modifiedon","op":">=","val":fromdate}
        var tofilter = {"name":"modifiedon","op":"<","val":todate}

        if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
            filters.push(fromfilter)
        }
        if (typeof(todate) != "undefined" && todate != null && todate != "") {
            filters.push(tofilter)
        }


        let queryfilters = JSON.stringify(filters);

        this.showPaging = false

        try {
          document.body.style.cursor = "wait";
          var url;
          
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/account/deleted?q={"filters":' + queryfilters + '}' ;
       
          // const { data } = await this.$http.get("https://dev03.btlcloud.net/api/v1/accounts", {
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiAccounts = data;
          console.log('got the account data from api')
          if (this.apiAccounts.length > this.pageSize){
            this.showPaging = true
          }
          this.executed = true;
        } catch (e) {
          this.apiAccounts = `Error: the server responded with '${e.response}'`;
        }
      },
  
     
      // *******************************************
      // get industry details
      // *******************************************
      async getIndustryApi(industrytype) {
  
  
        console.log('getting the industry data')
        console.log(industrytype)
        try {
          document.body.style.cursor = "wait";
          var url;
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/industrytypes/' + industrytype
          console.log(url)
          // const { data } = await this.$http.get("https://dev03.btlcloud.net/api/v1/accounts", {
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiHistoryIndustry = data;
          this.editAccountForm.industry = data
          console.log('got the account data from api')
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apiHistoryIndustry = `Error: the server responded with '${e.response}'`;
        }
      },
  
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addAccountModal.hide();
        this.initForm();
      },
  
      editAccount(history) {
        console.log('editAccount');
        console.log(history.account);
        // console.log(account.account_id);
        console.log(history.account.IndustryTypeId)
        this.editAccountForm = history.account;
        this.editAccountForm.modifiedby = history.modifiedby
        this.editAccountForm.modifiedon = history.modifiedon
        this.editAccountForm.action = history.method
  
        if(history.account.IndustryTypeId){
          this.getIndustryApi(history.account.IndustryTypeId)
        }
  
        // this.callApi()
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editAccountModal.hide();
        this.initForm();
        this.getAccounts(); // why?
      },
    },
    created() {
      console.log('created')
      this.disableOption = true;
      this.executed = true;
    //   this.getAccountApi();
    //   this.callApi();
  
    },
    components: {
      alert: Alert,
      listfilter:Filter,
      IndustryDropDown: IndustryDropDown
    },
  };
  </script>
  