import Vue from 'vue';
import Vuex from 'vuex';

// eslint-disable-next-line import/no-cycle
import { getAuthenticationContext } from '../auth/AuthService';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    authentication: null,
    searchQuery: '',
    selectedAccountId: localStorage.getItem('selected_account'),
  },
  getters: {
    searchQuery: (state) => state.searchQuery || '',
    authentication: (state) => state.authentication,
  },
  mutations: {
    setAuthentication: (state, authentication) => {
      state.authentication = authentication || {};
    },
    setSearchQuery: (state, searchQuery) => {
      state.searchQuery = searchQuery;
    },
  },
  actions: {
    handleLogin: ({ commit }, authenticationContext) => {
      commit('setAuthentication', authenticationContext);
    },
    loadAuthentication: ({ state, dispatch }) => {
    console.log('LoadAuthentication');
      if (!state.authentication || !Object.values(state.authentication).length) { // means we're logged in but the page is reloaded and store emptied
        const authContext = getAuthenticationContext();
        if (authContext) {
          dispatch('handleLogin', authContext);
        }
      }
    },
  },
});

export default store;
