<template>

    <div>
      <p ><u><strong>Priced Calls List</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >

        <b-form-group
        id="fieldset-horizontal"
        label-cols-sm="2"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="5"
        label="Account"
        label-for="input-horizontal"
        >
        <div >
            <CallAccountDropDownVue required @account-changed="accountChange"></CallAccountDropDownVue>
                </div>
        </b-form-group>

        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="4"
            label="Carrier"
            label-for="input-horizontal"
            >
            <div>
                        <CarrierNameDropDownVue @carrier-changed="carrierChange"></CarrierNameDropDownVue>
                    </div>
            </b-form-group>

        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="Call type"
            label-for="input-horizontal"
            >
            <div class="dropdown">
                <select @change="onChangeCallType($event)">
                  <option value="">Select call type</option>
                  <option
                    v-for="(type, index) in calltypes"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
                <!-- <p v-if="SelectedPhoneType">Selected type - {{ this.SelectedPhoneType }}</p> -->
              </div>
            </b-form-group>

        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="Direction"
            label-for="input-horizontal"
            >
            <div class="dropdown">
                <select @change="onChangeDirection($event)">
                  <option value="">Select call direction</option>
                  <option
                    v-for="(type, index) in directions"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </b-form-group>
        <b-button type="submit" variant="primary">Find</b-button>
  </b-form>

         <br><br>
         <table v-show="showresult" >
            <tr>
                <td width="900"> <p style="color:blue;font-size:14px;" v-if="showresult"> Number of records found for {{ Account.Name }}  {{ Account.AccountNumber}}: {{ apiPricedCalls.length }}</p></td>
            </tr>
            <tr v-if="apiPricedCalls.length > 0">
                <td><button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>
        <br><br>

         <!-- <p v-if="showresult"> Number of records found: {{ apiPricedCalls.length }}</p> -->
          <!-- <input type="search" v-model="filter"> -->
          <table class="table table-striped w-auto" v-show="showresult" >
            <thead>
              <tr>
                <th scope="col" @click="sort('Source')">Source</th>
                <th scope="col" @click="sort('Dialled')">Dialled</th>
                <th scope="col" @click="sort('Destination')">Destination</th>
                <th scope="col" @click="sort('CallType')">Call type</th>
                <th scope="col" @click="sort('CallDate')">Date</th>
                <th scope="col" @click="sort('Duration')">Duration</th>
                <th scope="col" @click="sort('CallCost')">Call cost</th>
                <th scope="col" @click="sort('Carrier')">Carrier</th>
                <th scope="col" @click="sort('CarrierCost')">Carrier cost</th>
                <th scope="col" @click="sort('Direction')">Direction</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(suspendedcall, index) in SortedapiPricedCalls" :key="index">
                <td>{{ suspendedcall.Source }}</td>
                <td>{{ suspendedcall.Dialled }}</td>
                <td>{{ suspendedcall.Destination }}</td>
                <td>{{ suspendedcall.CallType }}</td>
                <td>{{ suspendedcall.CallDate }}</td>
                <td>{{ suspendedcall.Duration }}</td>
                <td>{{ suspendedcall.CallCost }}</td>
                <td>{{ suspendedcall.Carrier }}</td>
                <td>{{ suspendedcall.CarrierCost }}</td>
                <td>{{ suspendedcall.Direction }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="apiPricedCalls.length > 0"><p align="center">
              <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredPricedCalls.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import * as XLSX from 'xlsx'
  import CallAccountDropDownVue from "../components/CallAccountDropDown.vue";
  import CarrierNameDropDownVue from "../components/CarrierNameDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        calltypes: [{ "id":"INTERNATIONAL", "value":'International      '}, {"id":"NONGEO","value":'NonGeo'}, {"id":"NATIONAL","value":'National'}],
        directions: [{ "id":"O", "value":'Outgoing      '}, {"id":"I","value":'Incoming'}],
        addOrderForm: {
          Name: '' ,
          BillingName: '' ,
          Address1: null ,
          Address2: null ,
          PostCode: null ,
          City: null ,
          Country: null ,
          ContactName: null,
          ContactNumber: null,
          FaxNumber: null
        },
        editOrderForm: {
          OrderId: null,
          Name: null ,
          BillingName: null ,
          Address1: null ,
          Address2: null ,
          PostCode: null ,
          City: null ,
          Country: null ,
          ContactName: null,
          ContactNumber: null,
          FaxNumber: null ,
          suspendedcalldetails: null
        },
        apiPricedCalls: [],
        executed: false,
        message: 'initial alert',
        currentSort:'CallDate',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        Account: null
      };
    },

    computed:{
    filteredPricedCalls() {
      console.log('filtered suspendedcall');
        return this.apiPricedCalls
        // .filter(c => {
        //   if(this.filter == '') return true;
        //   if (c.Carrier == null) c.Carrier = '';
        //   if (c.Source == null) c.Source = '';
        //   if (c.Destination == null) c.Destination = '';
        //   if (c.CallDate == null) c.CallDate = '';
        //   return c.Carrier.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
        //          c.Source.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
        //          c.Destination.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
        //          c.CallDate.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
        // })
      },
    SortedapiPricedCalls:function() {
      console.log('sort suspendedcall');
      return this.filteredPricedCalls.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
    onChangeCallType(event) {
            this.SelectedCallType= event.target.value
            },

    onChangeDirection(event) {
        this.SelectedDirection= event.target.value
        },
    accountChange: function (e) {
        this.apiPricedCalls = null;
        this.showresult = false;
        console.log('£££££££££££ in change account £££££££')
        console.log(this.showresult)
        this.selectedAccount = e;
        },

    carrierChange: function (e) {
        this.selectedCarrier = e;
        },

    onSubmit(evt) {
        console.log('on submit');
        this.apiPricedCalls = null;
        this.showresult = false;
        // this.Account = null
        console.log(this.selectedAccount)
        if (!this.selectedAccount){ // is null or zero
            alert("Please select an account");
            evt.preventDefault();
                    return false;
            }
        this.callApi(this.selectedAccount, this.selectedCarrier, this.SelectedCallType, this.SelectedDirection);
        // this.showresult = true;
        evt.preventDefault();
        },

    download : function() {
        const data = XLSX.utils.json_to_sheet(this.apiPricedCalls)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var filename = this.Account.Name + ' - ' + this.Account.AccountNumber + '.xlsx';
        console.log(filename)
        XLSX.writeFile(wb,filename)
        },

    async getAccount(accountid) {

      console.log('getting the account data')
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/${accountid}`);

        this.Account = data;
        console.log('got the selected account data from api')
        console.log(this.Account.Name)
        // this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredPricedCalls.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List suspendedcalls
      // *******************************************
    async callApi(account, carrier, calltype, direction) {

        console.log(account)
        console.log('getting the data')
        // build the filters for the url
        var url;
        var filters =[];
        var accountfilter = {"name":"AccountId","op":"eq","val":account}
        var carrierfilter = {"name":"Carrier","op":"eq","val":carrier};
        var calltypefilter = {"name":"CallType","op":"eq","val":calltype};
        var directionfilter = {"name":"Direction","op":"eq","val":direction};

        filters.push(accountfilter)
        document.body.style.cursor = 'wait';

        if (typeof(carrier) != "undefined" && carrier != null && carrier != "") {
            filters.push(carrierfilter)
        }
        if (typeof(calltype) != "undefined" && calltype != null  && calltype != "") {
            filters.push(calltypefilter)
        }
        if (typeof(direction) != "undefined" &&  direction != null  && calltype != "") {
            filters.push(directionfilter)
        }
        let queryfilters = JSON.stringify(filters);

        console.log(queryfilters)
        document.body.style.cursor = 'wait';
        try {
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/pricing/pricedcalls?q={"filters":' + queryfilters + '}';
          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apiPricedCalls = data;
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiPricedCalls = `Error: the server responded with '${e.response}'`;
        }
        try {
            const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/${account}`);
          this.Account = data;
          this.loading = false
          console.log("got the selected account ############################")
          this.executed = true;
          console.log("the carrierapi data is..........")
          console.log(this.Account.Name)
        } catch (e) {
          this.apipricing = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },


    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      CallAccountDropDownVue: CallAccountDropDownVue,
      CarrierNameDropDownVue: CarrierNameDropDownVue,
      listfilter:Filter
    },
  };
  </script>
