<template>

    <div>

      <p ><u><strong>Archived Calls</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >


        <b-form-group
        id="fieldset-horizontal"
        label-cols-sm="2"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="5"
        label="Account"
        label-for="input-horizontal"
        >
        <div >
              <AccountDropdownVue  @account-changed="accountChange"></AccountDropdownVue></div>
        </b-form-group>

        <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="Finalpost date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-fromdate-input"
                      type="date"   size="sm"
                      v-model="PostDate"
                      placeholder="Enter from date">
            </b-form-input>
            </b-form-group>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="From date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-fromdate-input"
                      type="date"   size="sm"
                      v-model="FromDate"
                      placeholder="Enter from date">
            </b-form-input>
            </b-form-group>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="To date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-todate-input"
                      type="date"   size="sm"
                      v-model="ToDate"
                      placeholder="Enter to date">
            </b-form-input>
            </b-form-group>


        <b-button type="submit" variant="primary">Find</b-button>
  </b-form>

        <br><br>

         <!-- <p v-if="showresult"> Number of records found: {{ apiArchivedCalls.length }}</p> -->
         <table v-show="showresult" >
            <!-- <tr>
                <td width="900"> <p style="color:blue;font-size:14px;" v-if="showresult"> Number of records found for {{ Account.Name }}  {{ Account.AccountNumber}}: {{ apiArchivedCalls.length }}</p></td>
            </tr> -->
            <tr v-if="apiArchivedCalls.length > 0">
                <td><button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-on:click="download">Dowload Results</button></td>
            </tr>
        </table>

         <!-- <p v-if="showresult"> Number of records found: {{ apiArchivedCalls.length }}</p> -->
          <!-- <input type="search" v-model="filter"> -->
          <table class="table table-striped w-auto" v-show="showresult" >
            <thead>
              <tr>
                <th scope="col" @click="sort('Source')">Source</th>
                <th scope="col" @click="sort('Dialled')">Dialled</th>
                <th scope="col" @click="sort('Destination')">Destination</th>
                <th scope="col" @click="sort('CallType')">Call type</th>
                <th scope="col" @click="sort('CallDate')">Date</th>
                <th scope="col" @click="sort('Duration')">Duration</th>
                <th scope="col" @click="sort('CallCost')">Call cost</th>
                <th scope="col" @click="sort('Carrier')">Carrier</th>
                <th scope="col" @click="sort('CarrierCost')">Carrier cost</th>
                <th scope="col" @click="sort('Direction')">Direction</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(suspendedcall, index) in SortedapiArchivedCalls" :key="index">
                <td>{{ suspendedcall.Source }}</td>
                <td>{{ suspendedcall.Dialled }}</td>
                <td>{{ suspendedcall.Destination }}</td>
                <td>{{ suspendedcall.CallType }}</td>
                <td>{{ suspendedcall.CallDate }}</td>
                <td>{{ suspendedcall.Duration }}</td>
                <td>{{ suspendedcall.CallCost }}</td>
                <td>{{ suspendedcall.Carrier }}</td>
                <td>{{ suspendedcall.CarrierCost }}</td>
                <td>{{ suspendedcall.Direction }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="apiArchivedCalls.length > 0"><p align="center">
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import datepicker from 'vue-date-picker';
  import AccountDropdownVue from "../components/AccountDropdown.vue";
  import * as XLSX from 'xlsx'


  export default {

    Name: "Api",
    data() {
      return {
        FromDate : null,
        ToDate : null,
        PostDate: null,
        apiArchivedCalls: [],
        executed: false,
        message: 'initial alert',
        currentSort:'CallDate',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        Account: null
      };
    },

    computed:{
    filteredArchivedCalls() {
      console.log('filtered history');
      return this.apiArchivedCalls
    //   return this.apiArchivedCalls.filter(c => {
    //     if(this.filter == '') return true;
    //     // if (c.entityname == null) c.entityname = '';
    //     return (c.entityname && c.entityname.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0
    //   })
    },
    SortedapiArchivedCalls:function() {
      console.log('sort history');
      console.log(this.currentSort);
      return this.filteredArchivedCalls.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] === null) return 1;
        if (a[this.currentSort] === "undefined") return 1;
        if (b[this.currentSort] === null) return -1;
        if (b[this.currentSort] === "undefined") return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },

    methods: {
    accountChange: function (e) {
        this.selectedAccount= e;
        if(e == ''){
            this.selectedAccount = null;
        }
       },
    onSubmit(evt) {
        console.log('on submit');
        this.apiArchivedCalls = null;
        this.showresult = false;
        if (!this.selectedAccount){ // is null or zero
            alert("Please select an account");
            evt.preventDefault();
                    return false;
            }
        if (!this.PostDate){ // is null or zero
            if (!this.FromDate || !this.ToDate){ // is null or zero
                alert("Please select either post date or from and to dates");
                evt.preventDefault();
                        return false;
            }

            }
        this.callApi(this.selectedAccount, this.PostDate, this.FromDate, this.ToDate);
        // this.showresult = true;
        evt.preventDefault();
        },

    download : function() {
        var filename = null;
        const data = XLSX.utils.json_to_sheet(this.apiArchivedCalls)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        var moment = require('moment');
        var datestr = moment(new Date()).format('DD-MM-YYYY');
        if (!this.PostDate){ // is null or zero
            if (this.FromDate  &&  this.ToDate){ // is null or zero
                filename = this.Account.Name + ' - ' + this.Account.AccountNumber +  ' ' +  this.FromDate + ' to ' + this.ToDate + '.xlsx';
            }
            else{
                if (!this.FromDate  &&  this.ToDate){ // is null or zero
                    filename = this.Account.Name + ' - ' + this.Account.AccountNumber +  ' todate  ' + this.ToDate + '.xlsx';
                }
                else{
                    filename = this.Account.Name + ' - ' + this.Account.AccountNumber +  ' fromdate  ' + this.FromDate + '.xlsx';
                }
            }


            }
        else{
             filename = this.Account.Name + ' - ' + this.Account.AccountNumber +  ' archived calls ' + datestr + '.xlsx';
        }
        console.log(filename)
        XLSX.writeFile(wb,filename)
        },
    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredArchivedCalls.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List historys
      // *******************************************
    async callApi(account, postdate, fromdate, todate) {

        console.log(account)
        console.log('getting the data')
        // build the filters for the url
        var url;
        var filters =[];
        var accountfilter = {"name":"AccountId","op":"eq","val":account}
        var postdatefilter = {"name":"FinalPostDate","op":"eq","val":postdate}
        var fromfilter = {"name":"CallDate","op":">","val":fromdate}
        var tofilter = {"name":"CallDate","op":"<","val":todate}


        if (typeof(account) != "undefined" && account != null && account != "") {
            filters.push(accountfilter)
        }
        if (typeof(postdate) != "undefined" && postdate != null && postdate != "") {
            filters.push(postdatefilter)
        }
        if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
            filters.push(fromfilter)
        }
        if (typeof(todate) != "undefined" && todate != null && todate != "") {
            filters.push(tofilter)
        }


        let queryfilters = JSON.stringify(filters);

        console.log(queryfilters)

        try {
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/pricedcalls?q={"filters":' + queryfilters + '}';
          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apiArchivedCalls = data;
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiArchivedCalls = `Error: the server responded with '${e.response}'`;
        }
        try {
            const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/${account}`);
          this.Account = data;
          this.loading = false
          console.log("got the selected account ############################")
          this.executed = true;
          console.log("the carrierapi data is..........")
          console.log(this.Account.Name)
        } catch (e) {
          this.apipricing = `Error: the server responded with '${e.response}'`;
        }
      },

    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
      datepicker
    },
  };
  </script>
