<template>
  <div>
    <p><u><strong>CDR Load</strong></u> </p>
    <br>
    <div class="result-block-container">

      <div :class="['result-block', executed ? 'show' : '']">
        <button type="button" class="btn btn-success btn-sm" v-b-modal.cdrload-modal>Insert a CDR Load</button>
        <br><br>
        <!-- <input type="search" v-model="filter"> -->
        <table class="table table-striped w-auto" id="apirecords">
          <thead >
            <tr>
              <th class="th-lg" scope="col" @click="sort('Carrier')" >Carrier</th>
              <th scope="col" @click="sort('FileName')">File</th>
              <th scope="col" @click="sort('StartedOn')">Started on</th>
              <th scope="col" @click="sort('Finished')">Finished</th>
              <th scope="col" @click="sort('FinishedOn')">Finished on</th>
              <th scope="col" @click="sort('Message')">Status</th>
              <th scope="col" @click="sort('CDRCount')">CDR count</th>
              <th scope="col" @click="sort('LoadedCount')">Loaded count</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cdrload, index) in SortedapiCDRLoads" :key="index">
              <td>{{ cdrload.Carrier }}</td>
              <td>{{ cdrload.FileName }}</td>
              <td>{{ cdrload.StartedOn }}</td>
              <td>{{ cdrload.Finished }}</td>
              <td>{{ cdrload.FinishedOn }}</td>
              <td>{{ cdrload.Message }}</td>
              <td>{{ cdrload.CDRCount }}</td>
              <td>{{ cdrload.LoadedCount }}</td>
              <td>
                <div v-if="cdrload.FinishedOn || cdrload.Message==='failed'">
                  <button v-if="isAdmin"
                          type="button"
                          class="btn btn-danger btn-sm"
                          @click="onDeleteLoad(cdrload)">
                      Delete
                  </button>
                </div>
                <div v-else>

                </div>

              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <button type="button" class="btn btn-danger btn-sm" @click="onResetLoad()">Reset CDR loads</button>
          </div>
      </div>
    </div>

    <!-- ADD modal -->
    <b-modal ref="addcdrloadModal"
            id="cdrload-modal"
            title="Insert a CDR load"
            hide-footer size="xs" >
      <b-form @submit="onSubmit" @reset="onReset" class="w-70">
      <b-form-row>
          <b-col>
            <div >
                <CarrierDropDownVue @carrier-changed="carrierChange"></CarrierDropDownVue>
            <br>
        </div>
        </b-col>
        </b-form-row>
        <b-form-row>
        <b-col>
            <b-form-file id="form-filename-input"
                          type="file"   size="sm"
                          v-model="addcdrloadForm.FileName"
                          placeholder="Select a CDR file">
            </b-form-file>
        </b-col>
      </b-form-row>

      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import CarrierDropDownVue from "../components/CarrierDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      timer: "",
      selectedCarrier:"",
      addcdrloadForm: {
        Carrier: '' ,
        CusBillingName: '' ,
        FileName: ''

      },
      apicdrloads: [],
      executed: false,
      message: 'initial alert',
      currentSort:'Carrier',
      currentSortDir:'asc',
      pageSize:20,
      currentPage:1,
      filter:'',
    };
  },

  computed:{
  filteredcdrloads() {
    console.log('filtered cdrload');
      return this.apicdrloads
      // return this.apicdrloads.filter(c => {
      //   if(this.filter == '') return true;
        // return c.Carrier.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
        //        c.BillingName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
        //        c.FileName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      // })
    },
  SortedapiCDRLoads:function() {
    console.log('sort cdrload');
    if(Array.isArray(this.apicdrloads)){
      return this.filteredcdrloads.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    }
  },
  },
  methods: {
    carrierChange: function (e) {
        this.selectedCarrier = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredcdrloads.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // Delete a cdr load
    // *******************************************
    async removeLoad(loadid) {

      console.log("in removeLoad before")
      console.log(loadid)
      document.body.style.cursor = 'wait';
      try {
        document.body.style.cursor = 'wait';
        const { data } =  await this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/cdrloads/${loadid}`).then(() => {
          this.message = 'load removed!';
          this.showMessage = true;
          console.log("in removeCLoad after")
          this.callApi("coming from delete AAA");

        }).catch((err) => {
          console.log("in removeLoad error")
          console.error(err);
          this.callApi();

        });

        console.log('delete data is B:')
        console.log(data)
        document.body.style.cursor = 'auto';
        this.$forceUpdate();
        this.callApi("coming from delete BBB")
      } catch (e) {
        this.apiCarriers = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteLoad(cdrload) {
      console.log(cdrload.load_id)
      if(confirm("Are you sure you want to delete this cdrload?")){
        document.body.style.cursor = 'wait';
        this.removeLoad(cdrload.load_id);
      }
    },

    // *******************************************
    // List cdrloads
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the data')
      try {
        document.body.style.cursor = 'wait';
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/cdrloads`);
        document.body.style.cursor = 'auto';
        this.apicdrloads = data;
        this.executed = true;
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
    // Add cdrload method to post to API
    async addcdrload(payload) {

      let formData = new FormData();
      console.log( payload.FileName);
      formData.append('Carrier', payload.Carrier);
      formData.append('BillingName', payload.BillingName);
      formData.append('file', payload.FileName);
      try {
        document.body.style.cursor = 'wait';
        const { data } =  await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/cdrloads`, formData).then(() => {
          this.message = 'cdrload added!';
          this.showMessage = true;
          console.log('Here we call update and calApi then');
          document.body.style.cursor = 'auto';
          // this.$forceUpdate();
          // this.$router.go()
          console.log("in add load after")
          this.callApi("coming add  AAA");
          // this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          console.log("in error")
          this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        document.body.style.cursor = 'auto';
        console.log("in add load BBB")
        this.callApi("coming add  BBB");
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addcdrloadForm.Carrier =  '';
        this.addcdrloadForm.BillingName =  '';
        this.addcdrloadForm.FileName =  '';

    },
    // *******************************************
    // Add cdrload on submit
    // *******************************************
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addcdrloadModal.hide();
      console.log('MaintenaceOnly');

      const payload = {
        FileName: this.addcdrloadForm.FileName ,
        Carrier: this.selectedCarrier,
        BillingName: this.addcdrloadForm.Address1,
      };
      document.body.style.cursor = 'wait';
      this.addcdrload(payload);
      //this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addcdrloadModal.hide();
      this.initForm();
    },
  //
    onResetLoad() {
      console.log('onresetload')
      if(confirm("Are you sure you want to reset all CDR loads?")){
        this.resetLoad();
      }

    },
    // reset load
    // *******************************************
    async resetLoad() {

      console.log("in reset load before")
      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/cdrloads/reset`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          console.log("in removeAccount after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          this.callApi();

        });

        // this.apiAccounts = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        document.body.style.cursor = 'auto';
        this.callApi()
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },

  },
  created() {
    console.log('created')
    this.callApi();
    this.timer = setInterval(this.callApi, 20000);

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    CarrierDropDownVue: CarrierDropDownVue,
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
