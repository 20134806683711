<template>
  <div class="cascading-dropdown">
    <div class="dropdown">
      <select style="width: 100%" @change="onChangeMobileBundle($event)" v-model="selected_mobilebundle">
        <option value="">Select a bundle</option>
        <option
          v-for="(mobilebundle, index) in listMobileBundle"
          :value="mobilebundle.BundleId"
          :key="index"
        >
          {{ mobilebundle.BundleName }}
        </option>
      </select>
    </div>
    <!-- <p v-if="SelectedMobileBundle">Selected MobileBundle - {{ this.SelectedMobileBundle }}</p> -->
  </div>
</template>

<script>
export default {
  name: "App",
  props:{
    group_exclude:{
      type: Boolean,
      default: false},
    selected_mobilebundle:{
      type: String,
      default: ""},
    },
  data() {
    return {
      listMobileBundle: [],
      SelectedMobileBundle: "",
      authToken: "",
    };
  },
  methods: {
    onChangeMobileBundle(event) {
      this.SelectedMobileBundle = event.target.value
      console.log(event)
      this.$emit('mobilebundle-changed',this.SelectedMobileBundle)
    },

    async callApi() {
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/mobilebundles`);

        // console.log('API DATA XXXXXXXXXXXXXXXXXXXXXXXXXXXX')
        // console.log(data)
        this.listMobileBundle = data;
        this.executed = true;
      } catch (e) {
        this.listMobileBundle = `Error: the server responded with '${e.response}'`;
      }
    },
  },
  created() {
    this.callApi();
    console.log('the selected mobilebundle in dorpdown is:');
    console.log(this.selected_mobilebundle);
  }
};
</script>
