<template>
  <div>
    <alert :message="message"></alert>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <!-- <h6 class="muted">Result</h6> -->
        <!-- <highlightjs autodetect :code="JSON.stringify(apiNationals, null, 2)" /> -->
        <!-- <hr><br><br> -->
        <button type="button" class="btn btn-success btn-sm" v-b-modal.book-modal>Insert National Rate</button>
        <br><br>
        <p>
          <button @click="prevPage">Previous</button>
          <button @click="nextPage">Next</button>

        </p>
        <input type="search" v-model="filter">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col" @click="sort('AccountId')">Account</th>
              <th scope="col">Day rate</th>
              <th scope="col" >Eve rate</th>
              <th scope="col" >Wend day rate</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(national, index) in SortedApiServices" :key="index">
              <td>{{ national.AccountId }}</td>
              <td>{{ national.SellDayRate }}</td>
              <td>{{ national.SellEveRate }}</td>
              <td>{{ national.SellWendRate }}</td>
              <td>
                <div class="btn-group"  role="group">
                  <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-b-modal.book-update-modal
                          @click="editBook(service)">
                      Update
                  </button>
                  <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          @click="onDeleteBook(service)">
                      Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>

    <b-modal ref="addNationalModal"
            id="book-modal"
            title="Insert a new service"
            hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <div ><AccountDropdownVue @account-changed="accountChange"></AccountDropdownVue> </div>
      <b-form-group id="form-title-group"
                    label="sell day rate:"
                    label-for="form-title-input">
          <b-form-input id="form-title-input"
                        type="text"
                        v-model="addNationalForm.SellDayRate"
                        required
                        placeholder="Sell day Rate">
          </b-form-input>
        </b-form-group>
        <b-form-group id="form-author-group"
                      label="sell day rate:"
                      label-for="form-author-input">
            <b-form-input id="form-author-input"
                          type="text"
                          v-model="addNationalForm.BuyDayRate"
                          required
                          placeholder="Buy day rate">
            </b-form-input>
          </b-form-group>

        <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </b-form>
    </b-modal>
    <!--
    <b-modal ref="editBookModal"
            id="book-update-modal"
            title="Update"
            hide-footer>
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-100">
      <b-form-group id="form-title-edit-group"
                    label="Name:"
                    label-for="form-title-edit-input">
          <b-form-input id="form-title-edit-input"
                        type="text"
                        v-model="editForm.Name"
                        required
                        placeholder="Enter Name">
          </b-form-input>
        </b-form-group>
        <b-form-group id="form-author-edit-group"
                      label="Description:"
                      label-for="form-author-edit-input">
            <b-form-input id="form-author-edit-input"
                          type="text"
                          v-model="editForm.Description"
                          required
                          placeholder="Enter Description">
            </b-form-input>
          </b-form-group>
        <b-button-group class="mr-1">
          <b-button class="mr-1" type="submit" variant="primary">Update</b-button>
          <b-button  class="mr-1" type="reset" variant="danger">Cancel</b-button>
        </b-button-group>
      </b-form>
    </b-modal> -->
  <!-- debug: sort={{currentSort}}, dir={{currentSortDir}} -->

  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      addNationalForm: {
        SellDayRate: '',
        CostDayRate: 0,
        CostConFee: 0,
        SellConFee: 0,
      },
      editForm: {
        id: '',
        Name: '',
        Description: '',
      },
      apiNationals: null,
      executed: false,
      message: 'initial alert',
      currentSort:'Name',
      currentSortDir:'asc',
      pageSize:3,
      currentPage:1,
      filter:'',
      selectedAccount:"",
    };
  },
  watch: {
    filter() {
      console.log('reset to p1 due to filter');
      this.currentPage = 1;
    },
  },
  computed:{
  filteredNationals() {
    console.log('filtered National');
      return this.apiNationals.filter(c => {
        if(this.filter == '') return true;
        console.log(c.TenantName);
        return c.AccountId.indexOf(this.filter) >= 0
      })
    },
  SortedApiServices:function() {
    console.log('sort national');
    return this.filteredNationals.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
      if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredNationals.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    accountChange: function (e) {
        this.selectedAccount = e;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },
    async callApi() {
      console.log('getting the national data')
      try {
        const { data } = await this.$http.get("https://r2dev.btlcloud.net/api/v4/tenants/btlcloud/nationals");
        console.log(data)
        this.apiNationals = data;
        this.executed = true;
      } catch (e) {
        this.apiNationals = `Error: the server responded with '${e.response}'`;
      }
    },
    async removeBook(bookID) {
      console.log(bookID)
      try {
        const { data } = await this.$http.delete(`https://r2dev.btlcloud.net/api/v4/tenants/btlcloud/services/${bookID}`).then(() => {
          this.message = 'service removed!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          console.error(err);
          this.callApi();

        });

        this.apiNationals = data;
        this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiNationals = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteBook(book) {
      console.log(book.service_id)
      this.removeBook(book.service_id);
    },

    async addNational(payload) {
      console.log('in add national');
      console.log('national payload is');
      console.log(payload);
      console.log("url is:");
      console.log(`https://r2dev.btlcloud.net/api/v4/tenants/btlcloud//accounts/` + this.selectedAccount + `/nationals`)
      try {
        const { data } = await this.$http.post(`https://r2dev.btlcloud.net/api/v4/tenants/btlcloud/accounts/` + this.selectedAccount + `/nationals`, payload).then(() => {
          this.message = 'service added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          console.error(err);
          this.callApi();

        });

        this.apiNationals = data;
        this.executed = true;
        this.callApi()
      } catch (e) {
        this.apiNationals = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
      this.addNationalForm.Name = '';
      this.addNationalForm.Description = '';
      this.editForm.service_id = '';
      this.editForm.Name = '';
      this.editForm.Description = '';

    },
    onSubmit(evt) {
      console.log('on submit national' );
      evt.preventDefault();
      this.$refs.addNationalModal.hide();
      const payload = {
        SellDayRate: this.addNationalForm.SellDayRate,
        CostDayRate:0.03,
        CostConFee: 0,
        SellConFee: 0.005
      };
      console.log(payload);
      this.addNational(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addNationalModal.hide();
      this.initForm();
    },
  //
    editBook(book) {
      console.log('editBook');
      console.log(book);
      console.log(book.service_id);
      this.editForm = book;
    },
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('service_id');
      evt.preventDefault();
      this.$refs.editBookModal.hide();
      const payload = {
        Name: this.editForm.Name,
        Description: this.editForm.Description,
      };
      this.updateBook(payload, this.editForm.service_id);
    },

    async updateBook(payload, bookID) {
      console.log('in update book');
      console.log(bookID);
      try {
        const { data } = await this.$http.put(`https://r2dev.btlcloud.net/api/v4/tenants/btlcloud/services/${bookID}`, payload).then(() => {
          this.message = 'service updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          console.error(err);
          this.callApi();

        });

        this.apiNationals = data;
        this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiNationals = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editBookModal.hide();
      this.initForm();
      this.getBooks(); // why?
    },
  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    AccountDropdownVue: AccountDropdownVue,
  },
};
</script>
