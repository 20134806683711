<template>
  <div>
    <p><u><strong>Recurring Charge Inventory </strong></u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onFind" style="width: 100%">
          <b-form-row>
            <!-- <b-col text-align="top">
              <b-form-group id="form-recurringCharge-group" label="Product" label-for="form-InclusiveText-input"
                label-cols="3" label-cols-lg="3" label-size="m" size="lg" align="top">
                <div>
                  <ProductDropDown @product-changed="productChange"></ProductDropDown>
                </div>
              </b-form-group>
            </b-col>
            <b-col> <b-button type="submit" variant="primary">Find</b-button></b-col> -->
            <b-col>
              <b-form-group id="fieldset-horizontal" label-cols-sm="2" label-cols-lg="5" content-cols-sm
                content-cols-lg="7" label="Charge Number" label-for="input-horizontal">
                <b-form-input id="form-ccn-input" type="text" size="sm" v-model="ChargeNumber"
                  placeholder="Enter charge number">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col> <b-button type="submit" variant="primary">Find</b-button></b-col>

          </b-form-row>

        </b-form>

        <br><br>
        <div class="result-block-container" width="1200">
          <div :class="['result-block', executed ? 'show' : '']">
            <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.recurringcharge-modal>Insert
              Recurring Charge</button>&nbsp;&nbsp;&nbsp;
            <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.recurring-load-modal>Load
              charges</button>&nbsp;&nbsp;&nbsp;
            <a :href="'deletedrecurringcharges'" target="_blank">
              <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm">Deleted</button>
            </a>
            <br><br>
            <!-- <input type="search" v-model="filter">   -->
            <!-- Account No (add)
            Account Name (add)
            Cost Centre No (add)
            Cost Centre Name (add)
            Outbound
            Label (add)
            Charge Number
            Product
            Frequency
            Quantity
            Unit Price
            Total Price
            Term Start
            Term End -->
            <table class="table table-striped" id="apirecords">
              <thead>
                <tr>
                  <th scope="col" @click="sort('account.AccountNumber')">Account No</th>
                  <th scope="col" @click="sort('account.Name')">Account Name</th>
                  <th scope="col" @click="sort('outbound.costcentre.CostCentreNumber')">Cost Centre No</th>
                  <th scope="col" @click="sort('outbound.costcentre.Name')">Cost Centre Name</th>
                  <th class="th-lg" scope="col" @click="sort('outbound.PhoneNumber')">Outbound</th>
                  <th class="th-lg" scope="col" @click="sort('outbound.Label')">Label</th>
                  <th scope="col" @click="sort('ChargeNumber')">Charge Number</th>
                  <th class="th-lg" scope="col" @click="sort('product.Name')">Product</th>
                  <th scope="col" @click="sort('BillFrequency')">Frequency</th>
                  <th scope="col" @click="sort('Quantity')">Quantity</th>
                  <th scope="col" @click="sort('SellUnitPrice')">Unit Price</th>
                  <th scope="col" @click="sort('SellTotalPrice')">Total Price</th>
                  <th scope="col" @click="sort('TermStartDate')">Term Start</th>
                  <th scope="col" @click="sort('TermEndDate')">Term End</th>
                  <!-- <th scope="col">ISA</th>
                  <th scope="col" @click="sort('LastBillDate')">Date First Billed</th>
                  <th scope="col" @click="sort('LastBillDate')">Bill Run First Billed</th>
                  <th scope="col" @click="sort('LastBillDate')">Date Last Billed</th>
                  <th scope="col">Bill Run Last Billed</th>
                  <th scope="col">Bill Run Next Billed</th>
                  <th scope="col" @click="sort('LastBillRun')">Final Bill Run</th> -->
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(recurringcharge, index) in SortedapiRecurringCharge" :key="index">
                  <td>{{ recurringcharge.account.AccountNumber }}</td>
                  <td>{{ recurringcharge.account.Name }}</td>
                  <td v-if="recurringcharge.outbound.costcentre">{{ recurringcharge.outbound.costcentre.CostCentreNumber }}</td>
                  <td v-else></td>
                  <td v-if="recurringcharge.outbound.costcentre">{{ recurringcharge.outbound.costcentre.Name }}</td>
                  <td v-else></td>
                  <td v-if="recurringcharge.cli">{{ recurringcharge.outbound.PhoneNumber }}</td>
                  <td v-else></td>
                  <td v-if="recurringcharge.cli">{{ recurringcharge.outbound.Label }}</td>
                  <td v-else></td>
                  <td>{{ recurringcharge.ChargeNumber }}</td>
                  <td>{{ recurringcharge.product.Name }}</td>
                  <td>{{ recurringcharge.BillFrequency }}</td>
                  <td>{{ recurringcharge.Quantity }}</td>
                  <td>{{ recurringcharge.SellUnitPrice}}</td>
                  <td>{{ recurringcharge.SellTotalPrice}}</td>
                  <td>{{ recurringcharge.TermStartDate }}</td>
                  <td>{{ recurringcharge.TermEndDate }}</td>
                  <!-- <td>{{ recurringcharge.ISA }}</td> -->
                  <!-- <td>{{ recurringcharge.DateFirstBilled }}</td>
                  <td>{{ recurringcharge.BillRunFirstBilled }}</td>
                  <td>{{ recurringcharge.LastBillDate }}</td>
                  <td>{{ recurringcharge.BillRunCharged }}</td>
                  <td>{{ recurringcharge.BillRunNextCharged }}</td>
                  <td>{{ recurringcharge.LastBillRun }}</td> -->
                  <td align="center"><a :href="'/recurringchargehistory?recurringcharge_id=' + recurringcharge.RCId"
                      target="_blank">History</a></td>
                  <td>
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-warning btn-sm" v-b-modal.recurringcharge-update-modal
                        @click="editRecurringCharge(recurringcharge)">
                        {{editButtonText}}
                      </button>
                      <p> </p>
                      <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm"
                        @click="onDeleteRecurringCharge(recurringcharge)">
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-show="showPaging">
              <p align="center">
                <b-pagination v-model="currentPage" align="center" :total-rows="filteredRecurringCharge.length"
                  :per-page="pageSize" aria-controls="apirecords"></b-pagination>
                <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- LOAD modal -->
      <b-modal ref="loadRecurringModal" id="recurring-load-modal" title="Load recurring charges" hide-footer size="xs">
        <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
          <b-form-row>
            <b-col>
              <p>Select a comma seperated file (.txt).</p>
              <p> <strong> Update will fail if outbound doesn’t match existing</strong></p>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadRecurringForm.FileName" required
                placeholder="Select recurring charge file">
              </b-form-file>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <p></p>
            </b-col>
          </b-form-row>
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <b-button-group>
                  <b-button type="submit" variant="primary">Submit</b-button>
                  <b-button type="reset" variant="danger">Reset</b-button>
                  <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
                </b-button-group>
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>

      <!-- add modal -->
      <b-modal ref="addRecurringChargeModal" id="recurringcharge-modal" title="Insert a recurring charge" hide-footer
        size="m">
        <b-form @submit="onSubmit" @reset="onReset" class="w-600">
          <b-form-row>
            <b-col>
              <b-form-group id="form-recurringCharge-group" label="Account" label-for="form-InclusiveText-input"
                label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
                <div align="left">
                  <AccountDropdownVue v-bind:selected_account="selectedAccount " @account-changed="accountChange">
                  </AccountDropdownVue>
                </div>
              </b-form-group>
            </b-col>

          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-CLI-group" label="Outbound" label-for="form-BCLI-input" label-cols="4"
                label-cols-lg="3" label-size="sm" size="xs">
                <div class="dropdown" align="left">
                  <select v-model="SelectedCLI" @change="onChangeCLI($event)" required>
                    <option value="">Select CLI</option>
                    <option v-for="(type, index) in accountoutbounds" :value="type.outbound_id" :key="index">
                      {{ type.PhoneNumber }}
                    </option>
                  </select>
                  <!-- <p v-if="SelectedBillFrequency">Selected type - {{ this.SelectedBillFrequency }}</p> -->
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-recurringCharge-group" label="Product" label-for="form-InclusiveText-input"
                label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
                <div align="left">
                  <!-- <ServiceTypeDropDown   @servicetype-changed="servicetypeChange"></ServiceTypeDropDown></div> -->
                  <ProductDropDown @product-changed="productChange"></ProductDropDown>
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-CustomName-group" label="Customised Name" label-for="form-CustomName-input"
                label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-Name-input" type="text" size="sm" v-model="addRecurringChargeForm.CustomName"
                  placeholder="Enter Customised Name">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-UseCustomName-group" label="Use Customised Name"
                label-for="form-UseCustomName-input" label-cols="4" label-cols-lg="4" label-size="sm" size="sm">
                <b-form-checkbox id="UseCustomName" v-model="addRecurringChargeForm.UseCustomName" name="UseCustomName">
                </b-form-checkbox>
              </b-form-group>

            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-BillFrequency-group" label="Bill Frequency" label-for="form-BillFrequency-input"
                label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
                <div class="dropdown" align="left" required>
                  <select @change="onChangeBillFrequency($event)">
                    <option value="">Select bill frequency</option>
                    <option v-for="(type, index) in billfrequencies" :value="type.id" :key="index">
                      {{ type.value }}
                    </option>
                  </select>
                  <!-- <p v-if="SelectedBillFrequency">Selected type - {{ this.SelectedBillFrequency }}</p> -->
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-Quantity-group" label="Quantity" label-for="form-Quantity-input" label-cols="2"
                label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-Quantity-input" type="text" size="sm" v-model="addRecurringChargeForm.Quantity"
                  placeholder="Enter quantity">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-CostUnitPrice-group" label="Cost Unit Price" label-for="form-CostUnitPrice-input"
                label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-CostUnitPrice-input" type="text" size="sm"
                  v-model="addRecurringChargeForm.CostUnitPrice" placeholder="Enter cost unit price">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-SellUnitPrice-group" label="Sell Unit Price" label-for="form-SellUnitPrice-input"
                label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-SellUnitPrice-input" type="text" size="sm"
                  v-model="addRecurringChargeForm.SellUnitPrice" placeholder="Enter sell unit price">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group id="form-TermStartDate-group" label="Term Start Date" label-for="form-TermStartDate-input"
                label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-TermStartDate-input" type="text" size="sm"
                  v-model="addRecurringChargeForm.TermStartDate"
                  placeholder="Enter term star in this format dd/mm/yyyy">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-TermEndDate-group" label="Term End Date" label-for="form-TermEndDate-input"
                label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-TermEndDate-input" type="text" size="sm"
                  v-model="addRecurringChargeForm.TermEndDate" placeholder="Enter term end in this format dd/mm/yyyy">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group id="form-ISA-group" label="ISA" label-for="form-ISA-input" label-cols="2" label-cols-lg="3"
                label-size="sm" size="sm">
                <b-form-input id="form-Name-input" type="text" size="sm" v-model="addRecurringChargeForm.ISA"
                  placeholder="Enter ISA">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-Notes-group" label="Notes" label-for="form-Notes-input" label-cols="2"
                label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-Name-input" type="text" size="sm" v-model="addRecurringChargeForm.Notes"
                  placeholder="Enter notes">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-AutoDelete-group" label="Final Bill Run" label-for="form-AutoDelete-input"
                label-cols="1" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input id="form-AutoDelete-input" type="text" size="sm"
                  v-model="addRecurringChargeForm.LastBillRun" placeholder="Enter final bill run">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="form-AutoDeletel-group" label="" label-for="form-AutoDeletel-input" label-cols="1"
                label-cols-lg="6" label-size="sm" size="sm">
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <p></p>
            </b-col>
          </b-form-row>
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <b-button-group>
                  <b-button type="submit" variant="primary">Submit</b-button>
                  <b-button type="reset" variant="danger">Reset</b-button>
                </b-button-group>
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>

      <!-- EDIT modal -->
      <b-modal ref="editRecurringChargeModal" id="recurringcharge-update-modal" title="Update recurring charge"
        hide-footer size="lg">
        <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
          <b-form-row hidden>
            <b-col>
              <b-form-group id="form-recurringCharge-group" label="Account" label-for="form-InclusiveText-input"
                label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
                <div align="left">
                  <AccountDropdownVue v-bind:selected_account="editRecurringChargeForm.AccountId"
                    @account-changed="accountChange"></AccountDropdownVue>
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-recurringCharge" label="Charge Number" label-for="form-name-input" label-cols="4"
                label-cols-sm="3" label-size="sm">
                <b-form-input id="form-tname-input" type="text" size="sm" v-model="editRecurringChargeForm.ChargeNumber"
                  required readonly w-20>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-CLI-group" label="Outbound" label-for="form-BCLI-input" label-cols="4"
                label-cols-lg="3" label-size="sm" size="xs">
                <div class="dropdown" align="left">
                  <select v-model="editRecurringChargeForm.OutboundId" @change="onChangeCLI($event)" required>
                    <option value="">Select outbound</option>
                    <option v-for="(type, index) in accountoutbounds" :value="type.outbound_id" :key="index">
                      {{ type.PhoneNumber }}
                    </option>
                  </select>
                  <!-- <p v-if="SelectedBillFrequency">Selected type - {{ this.SelectedBillFrequency }}</p> -->
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-recurringCharge-group" label="Product" label-for="form-InclusiveText-input"
                label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
                <div align="left">
                  <!-- <ServiceTypeDropDown v-bind:selected_servicetype="editRecurringChargeForm.ServiceTypeId" @servicetype-changed="servicetypeChange"></ServiceTypeDropDown></div> -->
                  <ProductDropDown v-bind:selected_product="editRecurringChargeForm.ProductId"
                    @product-changed="productChange"></ProductDropDown>
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-CustomName-group" label="Customised Name" label-for="form-CustomName-input"
                label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-Name-input" type="text" size="sm" v-model="editRecurringChargeForm.CustomName"
                  placeholder="Enter Customised Name">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-UseCustomName-group" label="Use Customised Name"
                label-for="form-UseCustomName-input" label-cols="4" label-cols-lg="4" label-size="sm" size="sm">
                <b-form-checkbox id="UseCustomName" v-model="editRecurringChargeForm.UseCustomName"
                  name="UseCustomName">
                </b-form-checkbox>
              </b-form-group>

            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-BillFrequency-group" label="Bill Frequency" label-for="form-BillFrequency-input"
                label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
                <div class="dropdown" align="left">
                  <select v-model="editRecurringChargeForm.BillFrequency" @change="onChangeBillFrequency($event)"
                    required>
                    <option value="">Select bill frequency</option>
                    <option v-for="(type, index) in billfrequencies" :value="type.id" :key="index">
                      {{ type.value }}
                    </option>
                  </select>
                  <!-- <p v-if="SelectedBillFrequency">Selected type - {{ this.SelectedBillFrequency }}</p> -->
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-Quantity-group" label="Quantity" label-for="form-Quantity-input" label-cols="2"
                label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-Quantity-input" type="text" size="sm" v-model="editRecurringChargeForm.Quantity"
                  placeholder="Enter quantity">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-CostUnitPrice-group" label="Cost Unit Price" label-for="form-CostUnitPrice-input"
                label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input id="form-CostUnitPrice-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.CostUnitPrice" placeholder="Enter cost unit price">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="form-CostTotalPrice-group" label="Cost Total Price"
                label-for="form-CostTotalPrice-input" label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input readonly id="form-CostTotalPrice-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.CostTotalPrice" placeholder="Enter cost unit price">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-SellUnitPrice-group" label="Sell Unit Price" label-for="form-SellUnitPrice-input"
                label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input id="form-SellUnitPrice-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.SellUnitPrice" placeholder="Enter sell unit price">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="form-SellTotalPrice-group" label="Sell Total Price"
                label-for="form-SellTotalPrice-input" label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input readonly id="form-SellTotalPrice-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.SellTotalPrice" placeholder="Enter sell unit price">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group id="form-TermStartDate-group" label="Term Start Date" label-for="form-TermStartDate-input"
                label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input id="form-TermStartDate-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.TermStartDate"
                  placeholder="Enter term star in this format dd/mm/yyyy">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="form-TermEndDate-group" label="Term End Date" label-for="form-TermEndDate-input"
                label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input id="form-TermEndDate-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.TermEndDate" placeholder="Enter term end in this format dd/mm/yyyy">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col>
              <b-form-group id="form-ISA-group" label="ISA" label-for="form-ISA-input" label-cols="2" label-cols-lg="3"
                label-size="sm" size="sm">
                <b-form-input id="form-Name-input" type="text" size="sm" v-model="editRecurringChargeForm.ISA"
                  placeholder="Enter ISA">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-Notes-group" label="Notes" label-for="form-Notes-input" label-cols="2"
                label-cols-lg="3" label-size="sm" size="sm">
                <b-form-input id="form-Name-input" type="text" size="sm" v-model="editRecurringChargeForm.Notes"
                  placeholder="Enter notes">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-TermStartDate-group" label="Date First Billed" label-for="form-TermStartDate-input"
                label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input readonly id="form-TermStartDate-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.DateFirstBilled">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="form-TermEndDate-group" label="Bill Run First Billed" label-for="form-TermEndDate-input"
                label-cols="2" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input readonly id="form-TermEndDate-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.BillRunFirstBilled">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-CreatedOn-group" label="Date Last Billed" label-for="form-CreatedOn-input"
                label-cols="2" label-cols-lg="6" label-size="sm" size="xs">
                <b-form-input readonly id="form-CreatedOn-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.LastBillDate">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="form-ModifiedOn-group" label="Bill Run Last Billed" label-for="form-ModifiedOn-input"
                label-cols="2" label-cols-lg="6" label-size="sm" size="xs">
                <b-form-input readonly id="form-ModifiedOn-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.BillRunCharged">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-ModifiedOn-group" label="Bill Run Next Billed" label-for="form-ModifiedOn-input"
                label-cols="2" label-cols-lg="6" label-size="sm" size="xs">
                <b-form-input readonly id="form-ModifiedOn-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.BillRunNextCharged">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="form-AutoDeletel-group" label="" label-for="form-AutoDeletel-input" label-cols="1"
                label-cols-lg="6" label-size="sm" size="sm">
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group id="form-AutoDelete-group" label="Final Bill Run" label-for="form-AutoDelete-input"
                label-cols="1" label-cols-lg="6" label-size="sm" size="sm">
                <b-form-input id="form-AutoDelete-input" type="text" size="sm"
                  v-model="editRecurringChargeForm.LastBillRun" placeholder="Enter final bill run">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="form-AutoDeletel-group" label="" label-for="form-AutoDeletel-input" label-cols="1"
                label-cols-lg="6" label-size="sm" size="sm">
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <p></p>
            </b-col>
          </b-form-row>
          <div class="container">
            <div class="row" v-if="isAdmin">
              <div class="col text-center">
                <b-button-group>
                  <b-button type="submit" variant="primary">Submit</b-button>
                  <b-button type="reset" variant="danger">Reset</b-button>
                </b-button-group>
              </div>
            </div>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import ChargeCategoryDropDown from "../components/ChargeCategoryDropDown.vue";
  import AccountDropdownVue from "../components/AccountDropdown.vue";
  import ServiceTypeDropDown from  "../components/ServiceTypeDropDown.vue";
  import ProductDropDown from "../components/ProductDropDown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
        billfrequencies: [{ "id":"M", "value":'Monthly      '}, {"id":"Q","value":'Quartly'}, {"id":"A","value":'Annually'}],
        accountoutbounds : null,
        SelectedBillFrequency: '',
        selectedChargeCategory:"",
        SelectedCLI : "",
        loadRecurringForm: {
        FileName: ''
        },
        addRecurringChargeForm: {
            ProductId : null,
            AccountId : null,
            OutboundId : null,
            CustomName : null,
            UseCustomName : null,
            Notes : null,
            BillFrequency: null,
            Quantity : null,
            SellUnitPrice : null,
            SellTotalPrice : null,
            CostUnitPrice : null,
            CostTotalPrice : null,
            TermStartDate : null,
            TermEndDate : null,
            LastBillRun: null,
            ISA : null
        },
        editRecurringChargeForm: {
            RCId  : null,
            ProductId : null,
            AccountId : null,
            OutboundId : null,
            CustomName : null,
            UseCustomName : null,
            Notes : null,
            BillFrequency: null,
            Quantity : null,
            SellUnitPrice : null,
            SellTotalPrice : null,
            CostUnitPrice : null,
            CostTotalPrice : null,
            TermStartDate : null,
            TermEndDate : null,
            LastBillDate: null,
            NextBillDate: null,
            CreatedOn : null,
            ModifiedOn : null,
            ModifiedBy : null,
            LastBillRun: null,
            ISA : null,
            DateFirstBilled:null,
            BillRunFirstBilled: null,
            ChargeNumber: null
        },
        apiRecurringCharge: [],
        apiRecurringAccount:null,
        apiRecurringCLI:null,
        executed: false,
        message: 'initial alert',
        currentSort:'Notes',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:false,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selectedAccount : null,
        selected_account: null,
      selected_product: null,
      ChargeNumber: null,
      };
    },

    computed:{
      filteredCarriers() {
    console.log('filtered carrier');
      return this.apiCarriers.filter(c => {
        if(this.filter == '') return true;
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.BillingName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
              //  (c.ContactName && c.ContactName.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0
      })
    },

    filteredRecurringCharge() {
      console.log('filtered recurringcharge');
        return this.apiRecurringCharge.filter(c => {

          if(this.filter == '') return true;
        //   if (c.name == null) c.Name = '';
          if (c.servicetype.Name == null) c.servicetype.Name = '';
          if (c.account.Name == null) c.account.Name = '';
          return c.servicetype.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
                 c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
                 (c.ChargeNumber && c.ChargeNumber.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0
        })
      },

    SortedapiRecurringCharge:function() {
    //   console.log('sort recurringcharge');
    //   console.log('sort account');
      if (this.apiRecurringCharge == null) return null;
      // console.log ('in sort account....');
      // console.log(this.currentSort)
      return this.filteredRecurringCharge.slice().sort((a,b) => {
        let modifier = 1;
        var subfields = ['account.Name','servicetype.Name']
        var numfields = ['Quantity','SellUnitPrice']
        if(this.currentSortDir === 'desc') modifier = -1;

        if (subfields.includes(this.currentSort)) {
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
        }
        else if(numfields.includes(this.currentSort)){
            if(a[this.currentSort].length < b[this.currentSort].length) return -1 * modifier;
            if(a[this.currentSort].length > b[this.currentSort].length) return 1 * modifier;
        }
        else{
          if (a[this.currentSort] === null) return 1;
          if (b[this.currentSort] === null) return -1;
          if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
          if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        }
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {
      download: function() {
        console.log("in download")
        var data = "ChargeNumber,Outbound,Product,CustomisedName,BillFrequency,Quantity,CostUnitPrice,SellUnitPrice,TermStartDate,TermEndDate,ISA,Notes,FinalBillRun,Action"
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', "UploadRecurringCharges.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
      onChangeBillFrequency(event) {
      this.SelectedBillFrequency= event.target.value
      },
      onChangeCLI(event) {
      this.SelectedCLI= event.target.value
      },
      productChange: function (e) {
        this.selectedProduct = e;
        if(e == ''){
            this.selectedProduct = null;
        }
       },
      // servicetypeChange: function (e) {
      //   this.selectedServiceType = e;
      //   if(e == ''){
      //       this.selectedServiceType = null;
      //   }
      //  },
       accountChange: function (e) {
        // this.accountoutbounds = [{ "id":"M", "value":'Monthly      '}, {"id":"Q","value":'Quartly'}, {"id":"A","value":'Annually'}],

        this.selectedAccount= e;
        this.getAccountCLI(this.selectedAccount);
        if(e == ''){
            this.selectedAccount = null;
        }
       },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredRecurringCharge.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },
      // *******************************************
      // List account cli
      // *******************************************
      async getAccountCLI(accountid) {
        console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH")

        console.log(this.selectedAccount)
        var url;
        var urlstr;
        document.body.style.cursor = 'wait';
        urlstr = '/api/v1/accounts/' + accountid + '/outbounds'
        console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH")
        console.log(urlstr)
        url = process.env.VUE_APP_API_BASE_URL + urlstr;
        try {
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          console.log("CCCCCCCCCCCC111111111111")
          console.log('got the account CLIs from api getAccountCLI')
          console.log(url)
          this.accountoutbounds = data;
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.accountoutbounds = `Error: the server responded with '${e.response}'`;
        }
      },


      // *******************************************
      // List recurringcharges
      // *******************************************
      async callApi(ccn) {

        // console.log(lablex)
        var url;
        document.body.style.cursor = 'wait';
        var searchlike = '%25' + ccn + '%25';
        if (ccn !== null){
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/recurringcharges?q={"filters":[{"name":"ChargeNumber","op":"like","val":"' + searchlike + '"}]}';
          //   console.log(url)
            // this.ShowAccount = false
            // this.selected_recurringcharge = this.$route.query.CBId
            // console.log("AAAAAAAAAAAAAAAAA")
            // this.selected_account = this.$route.query.account_id
            // this.getAccountApi(this.$route.query.account_id);
            // console.log("BBBBBBBBBBBBBBBBBB")
            // this.selectedAccount =  this.selected_account;
            // this.getAccountCLI(this.selectedAccount);
            console.log("CCCCCCCCCCCCCCCCCC")
        }
        else{
            url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/recurringcharges`
        }
        try {
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiRecurringCharge = data;
          console.log("DDDDDDDDDDDDDDDDDDDD")
          console.log(" recurring data is:")
          console.log(data)
          this.showresult = true
          if (this.apiRecurringCharge.length >this.pageSize){
            this.showPaging = true
          }
          this.executed = true;
        } catch (e) {
          this.apiRecurringCharge = `Error: the server responded with '${e.response}'`;
        }
      },

    // *******************************************
    // get account details
    // *******************************************
    async getAccountApi(accountid) {

      console.log(accountid)
      console.log('getting the account data')
      try {
        document.body.style.cursor = "wait";
        var url;
        url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/accounts/' + accountid
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiRecurringAccount = data;
        console.log('got the account data from api getAccountApi')
        console.log(data)
        this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // get CLI details
    // *******************************************
    async getCLIApi(cliid) {

      console.log(cliid)
      console.log('getting the account data')
      try {
        document.body.style.cursor = "wait";
        var url;
        url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/outbounds/' + cliid
        const { data } = await this.$http.get(url);
        document.body.style.cursor = 'auto';
        this.apiRecurringCLI = data;
        console.log('got the cli data from api')
        console.log(data)
        console.log("GET CLI DETAILS")
        console.log(data)
        this.getAccountApi(this.apiRecurringCLI.AccountId)
        this.getAccountCLI(this.apiRecurringCLI.AccountId);
        this.SelectedCLI = cliid;
        this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // Load on submit
    // *******************************************
    onLoadSubmit(evt) {
      console.log('on load submit');
      evt.preventDefault();
      this.$refs.loadRecurringModal.hide();
      const payload = {
        FileName: this.loadRecurringForm.FileName
      };
      this.loadrecurrings(payload);
      //this.initForm();
    },
    onLoadReset(evt) {
    evt.preventDefault();
    this.$refs.loadRecurringModal.hide();
    this.initForm();
    },
    // Add cdrload method to post to API
    async loadrecurrings(payload) {

      let formData = new FormData();
      console.log( 'in load outbounds');
      console.log( payload.file);
      formData.append('file', payload.FileName);
      var url;
      
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/recurringcharges/load`
      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'cost centre loaded!';
          this.showMessage = true;
          document.body.style.cursor = 'auto';
          // this.callApi();
        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          document.body.style.cursor = 'auto';
          // this.callApi();

        });
        // this.apicdrloads = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
      // *******************************************
      // Delete recurringcharge
      // *******************************************
      async removeRecurringCharge(recurringchargeid) {

      //   console.log("in removeRecurringCharge before")
        console.log(recurringchargeid)
        try {
          const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/recurringcharges/${recurringchargeid}`).then(() => {
            this.message = 'recurringcharge removed!';
            this.showMessage = true;
            console.log("in removeRecurringCharge after")
            this.callApi("coming from delete");

          }).catch((err) => {
            console.log("in removeRecurringCharge error")
            console.error(err);
            this.callApi();

          });

          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiRecurringCharge = `Error: the server responded with '${e.response}'`;
        }
      },
      onDeleteRecurringCharge(recurringcharge) {
        console.log(recurringcharge.RCId)
        if(confirm("Are you sure you want to delete this recurringcharge?")){
          this.removeRecurringCharge(recurringcharge.RCId);
        }
      },

      // Add recurringcharge method to post to API
      async addRecurringCharge(payload) {

      //   const id_token = claims.__raw;
        console.log('in add recurringcharge');
        console.log('payload is');
        console.log(payload);
        try {
          const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/recurringcharges`, payload).then(() => {
            this.message = 'recurringcharge added!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated recurringcharge. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add recurringcharge. status code:  " + err.response.status)
              }
            console.error(err);
            this.callApi();

          });
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiRecurringCharge = `Error: the server responded with '${e.response}'`;
        }
      },
      initForm() {
        console.log('init form');
          this.addRecurringChargeForm.CustomName =  null;
          this.addRecurringChargeForm.Notes = null;
          this.addRecurringChargeForm.BillFrequency =  null;
          this.addRecurringChargeForm.Quantity = null;
          this.addRecurringChargeForm.SellTotalPrice =  null;
          this.addRecurringChargeForm.CostUnitPrice = null;
          this.addRecurringChargeForm.TermStartDate =  null;
          this.addRecurringChargeForm.TermEndDate = null;
          this.addRecurringChargeForm.ISA = null;
      },

      // *******************************************
      // Add recurringcharge on submit
      // *******************************************
      onSubmit(evt) {
        console.log('on submit');
        evt.preventDefault();
        this.$refs.addRecurringChargeModal.hide();
        if(this.addRecurringChargeForm.UseCustomName== "") this.addRecurringChargeForm.UseCustomName= false;
        const payload = {
            OutboundId : this.SelectedCLI,
            // ServiceTypeId : this.selectedServiceType,
            ProductId : this.selectedProduct,
            AccountId : this.selectedAccount,
            CustomName : this.addRecurringChargeForm.CustomName,
            UseCustomName : this.addRecurringChargeForm.UseCustomName,
            Notes : this.addRecurringChargeForm.Notes,
            BillFrequency: this.SelectedBillFrequency,
            Quantity : this.addRecurringChargeForm.Quantity,
            SellUnitPrice : this.addRecurringChargeForm.SellUnitPrice,
            SellTotalPrice : this.addRecurringChargeForm.SellTotalPrice,
            CostUnitPrice : this.addRecurringChargeForm.CostUnitPrice,
            TermStartDate : this.addRecurringChargeForm.TermStartDate,
            TermEndDate : this.addRecurringChargeForm.TermEndDate,
            LastBillRun: this.addRecurringChargeForm.LastBillRun,
            ISA: this.addRecurringChargeForm.ISA

        };
        console.log(payload)
        this.addRecurringCharge(payload);
        this.initForm();
      },
      onReset(evt) {
        evt.preventDefault();
        this.$refs.addRecurringChargeModal.hide();
        this.initForm();
      },
    //
      editRecurringCharge(recurringcharge) {
        console.log('editRecurringCharge');
        console.log(recurringcharge);
        console.log(recurringcharge.CBId);
        this.selectedAccount = recurringcharge.AccountId
        this.getAccountCLI( recurringcharge.AccountId)
        this.SelectedCLI = recurringcharge.OutboundId
        // this.selectedServiceType = recurringcharge.ServiceTypeId
        this.selectedProduct = recurringcharge.ProductId
        this.editRecurringChargeForm = recurringcharge;
        this.selectedChargeband = recurringcharge.CBId
        this.SelectedBillFrequency = recurringcharge.BillFrequency
        // this.callApi()
      },

      // *******************************************
      // Update recurringcharge on submit
      // *******************************************
      onSubmitUpdate(evt) {
        console.log('in onSubmitUpdate');
        console.log('CBId');
        evt.preventDefault();
        this.$refs.editRecurringChargeModal.hide();
        if(this.editRecurringChargeForm.UseCustomName== "") this.editRecurringChargeForm.UseCustomName= false;
        if (!this.SelectedCLI){ // is null or zero
            alert("Update error:  Please select a CLI and try again");
            evt.preventDefault();
                    return false;
            }
        if (!this.SelectedBillFrequency){ // is null or zero
            alert("Update error: Please select a billing frequency and try again");
            evt.preventDefault();
                    return false;
            }
        const payload = {
            OutboundId : this.SelectedCLI,
            BillFrequency: this.SelectedBillFrequency,
            ProductId : this.selectedProduct,
            AccountId : this.selectedAccount,
            CustomName : this.editRecurringChargeForm.CustomName,
            UseCustomName : this.editRecurringChargeForm.UseCustomName,
            Notes : this.editRecurringChargeForm.Notes,
            Quantity : this.editRecurringChargeForm.Quantity,
            SellUnitPrice : this.editRecurringChargeForm.SellUnitPrice,
            SellTotalPrice : this.editRecurringChargeForm.SellTotalPrice,
            CostUnitPrice : this.editRecurringChargeForm.CostUnitPrice,
            TermStartDate : this.editRecurringChargeForm.TermStartDate,
            TermEndDate : this.editRecurringChargeForm.TermEndDate,
            LastBillRun: this.editRecurringChargeForm.LastBillRun,
            ISA: this.editRecurringChargeForm.ISA
        };
        this.updateRecurringCharge(payload, this.editRecurringChargeForm.RCId);
        this.callApi()
      },

      async updateRecurringCharge(payload, recurringchargeid) {

        console.log('in update recurringcharge');
        console.log(payload);
        try {
          const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/recurringcharges/${recurringchargeid}`, payload).then(() => {
            this.message = 'recurringcharge updated!';
            this.showMessage = true;
            this.callApi();

          }).catch((err) => {
            if(err.response.status == 409) {
              alert("Duplicated recurringcharge. status code:  " + err.response.status)
              }
            if(err.response.status != 409) {
              alert("Problem with add recurringcharge. status code:  " + err.response.status)
              }

            console.error(err);
            this.callApi();

          });

          console.log('delete data is:')
          console.log(data)
          this.callApi()
        } catch (e) {
          this.apiRecurringCharge = `Error: the server responded with '${e.response}'`;
        }
      },
      onResetUpdate(evt) {
        evt.preventDefault();
        this.$refs.editRecurringChargeModal.hide();
        this.initForm();
        this.getRecurringCharge(); // why?
      },
      // *******************************************
      // search Recurring by account
      // *******************************************
      onFind(evt) {
        console.log('on Find');
        this.apiCLIs = null;
        this.selectedAccount = null;
        this.SelectedCLI = null;
        this.showresult = false;
        this.showPaging = false
        console.log(this.selectedProduct)
        // this.ChargeNumber = null
        // this.callApi(this.selectedProduct);
        this.callApi(this.ChargeNumber);
        // this.showresult = true;
        evt.preventDefault();
        },
    },
    created() {
      console.log('created')
    //   this.callChargebandApi();
      // this.callApi();
      this.executed = true;

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      AccountDropdownVue: AccountDropdownVue,
      ServiceTypeDropDown: ServiceTypeDropDown,
      ChargeCategoryDropDown: ChargeCategoryDropDown,
      ProductDropDown: ProductDropDown
      // AccountCLIDropdownVue: AccountCLIDropdownVue,
    },
  };
  </script>
