<template>
  <div>
    <p v-if="showfilter"><u><strong>Deleted Inbounds</strong></u></p>
    <p v-else><u><strong>Deleted Inbounds ({{ accountname }} {{ accountnumber }})</strong></u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <b-form v-if="showfilter" @submit="onSubmit">
          <b-form-group id="fieldset-horizontal" label-cols-sm="2" label-cols-lg="2" content-cols-sm content-cols-lg="5"
            label="From Date" label-for="input-horizontal">
            <b-form-input id="form-fromdate-input" type="date" size="sm" v-model="FromDate"
              placeholder="Enter from date">
            </b-form-input>
          </b-form-group>
          <b-form-group id="fieldset-horizontal" label-cols-sm="2" label-cols-lg="2" content-cols-sm content-cols-lg="5"
            label="To Date" label-for="input-horizontal">
            <b-form-input id="form-todate-input" type="date" size="sm" v-model="ToDate" placeholder="Enter to date">
            </b-form-input>
          </b-form-group>


          <b-button type="submit" variant="primary">Find</b-button>
        </b-form>
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-sm" scope="col">Account No</th>
              <th class="th-sm" scope="col">Account Name</th>
              <th class="th-sm" scope="col">Cost Centre No</th>
              <th class="th-sm" scope="col">Cost Centre Name</th>
              <th class="th-lg" scope="col">Inbound</th>
              <th class="th-lg" scope="col" @click="sort('Label')">Label</th>
              <th class="th-sm" scope="col">Day Nat</th>
              <th class="th-sm" scope="col">Eve Nat</th>
              <th class="th-sm" scope="col">W/E Nat</th>
              <th class="th-sm" scope="col">Day Mob</th>
              <th class="th-sm" scope="col">Eve Mob</th>
              <th class="th-sm" scope="col">W/E Mob</th>
              <th class="th-sm" scope="col">Con Fee</th>
              <th scope="col">Modified on</th>
              <th scope="col">User</th>
              <th scope="col">Action</th>
              <th></th>
              <th>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(inboundhistory, index) in SortedapiInbounds" :key="index">

              <td>{{ inboundhistory.inbound.account.AccountNumber }}</td>
              <td>{{ inboundhistory.inbound.account.Name }}</td>
              <td v-if="inboundhistory.inbound.costcentre">{{ inboundhistory.inbound.costcentre.CostCentreNumber }}</td>
              <td v-else></td>
              <td v-if="inboundhistory.inbound.costcentre">{{ inboundhistory.inbound.costcentre.Name }}</td>
              <td v-else></td>
              <td>{{ inboundhistory.inbound.PhoneNumber }}</td>
              <td>{{ inboundhistory.inbound.Lable }}</td>
              <td align="center">{{ inboundhistory.inbound.DayRate }}</td>
              <td align="center">{{ inboundhistory.inbound.EveRate }}</td>
              <td align="center">{{ inboundhistory.inbound.WendRate }}</td>
              <td align="center">{{ inboundhistory.inbound.DayRateMobile }}</td>
              <td align="center">{{ inboundhistory.inbound.EveRateMobile }}</td>
              <td align="center">{{ inboundhistory.inbound.WendRateMobile }}</td>
              <td align="center">{{ inboundhistory.inbound.ConnFee }}</td>
              <td>{{ inboundhistory.modifiedon }}</td>
              <td>{{ inboundhistory.modifiedby }}</td>
              <td>{{ inboundhistory.method }}</td>
              <td align="center"><a
                  :href="'/inboundhistory?inbound_id=' + inboundhistory.inbound.inbound_id + '&inbound_phonenumber=' + inboundhistory.inbound.PhoneNumber"
                  target="_blank">History</a></td>
              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" label="view" v-b-modal.inbound-update-modal
                    @click="editInbound(inboundhistory)">
                    View
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging">
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredInbounds.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
                  <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>

    <!-- EDIT modal -->
    <b-modal ref="editInboundModal" id="inbound-update-modal" title="Inbound billing reference history" hide-footer
      size="lg">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Account No" label-for="form-PhoneNumber-input"
              label-cols="3" label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="sm"
                v-model="editInboundForm.account.AccountNumber" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col></b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Account Name" label-for="form-PhoneNumber-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="m" v-model="editInboundForm.account.Name"
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Cost Centre" label-for="form-PhoneNumber-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="sm" v-model="costcentre" readonly>
              </b-form-input>
            </b-form-group>
            <!-- <b-form-group id="form-CostCentre-group" label="Cost Centre" label-for="form-CostCentre-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <div class="dropdown">
                <select disabled='true' style="width: 100%" v-model="editInboundForm.CostCentreId"
                  @change="onChangeCostCentre($event)">
                  <option value="">Select cost center</option>
                  <option v-for="(costcentre, index) in CostCentres" :value="costcentre.costcentre_id" :key="index">
                    {{ costcentre.CostCentreNumber + ' - ' + costcentre.Name }}
                  </option>
                </select>
              </div>
            </b-form-group> -->
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PhoneNumber-group" label="Inbound" label-for="form-PhoneNumber-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-PhoneNumber-input" type="text" size="sm" v-model="editInboundForm.PhoneNumber"
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Label-group" label="Label" label-for="form-Label-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="sm">
              <b-form-input id="form-Label-input" type="text" size="sm" v-model="editInboundForm.Label" placeholder=""
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>

        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group id="form-DayRate-group" label="Day Nat" label-for="form-DayRate-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-DayRate-input" type="text" size="sm" v-model="editInboundForm.DayRate" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-EveRate-group" label="Eve Nat" label-for="form-EveRate-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-EveRate-input" type="text" size="sm" v-model="editInboundForm.EveRate" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-WendRate-group" label="W/E Nat" label-for="form-WendRate-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-WendRate-input" type="text" size="sm" v-model="editInboundForm.WendRate" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-DayRateMobile-group" label="Day Mob" label-for="form-DayRateMobile-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-DayRateMobile-input" type="text" size="sm" v-model="editInboundForm.DayRateMobile"
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-EveRateMobile-group" label="Eve Mob" label-for="form-EveRateMobile-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-EveRateMobile-input" type="text" size="sm" v-model="editInboundForm.EveRateMobile"
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group id="form-WendRateMobile-group" label="W/E Mob" label-for="form-WendRateMobile-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-WendRateMobile-input" type="text" size="sm"
                v-model="editInboundForm.WendRateMobile" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ConnFee-group" label="Con Fee" label-for="form-ConnFee-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-ConnFee-input" type="text" size="sm" v-model="editInboundForm.ConnFee" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Note-input" label-size="sm" size="xs">
              <b-form-textarea id="textarea" readonly v-model="editInboundForm.Notes" placeholder="" rows="3"
                max-rows="6"></b-form-textarea>

            </b-form-group>
          </b-col>
          <!-- <b-col>
            </b-col> -->
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-modified-group" label="Modified on" label-for="form-modified-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-modified-input" type="text" size="sm" v-model="editInboundForm.modifiedon"
                readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ConnFee-group" label="User" label-for="form-ConnFee-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-user-input" type="text" size="sm" v-model="editInboundForm.modifiedby" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-ConnFee-group" label="Action" label-for="form-ConnFee-input" label-cols="2"
              label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input id="form-action-input" type="text" size="sm" v-model="editInboundForm.action" readonly>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="reset" variant="danger">Close</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
    
      <script>
      import Alert from '../components/Alert.vue';
      import Filter from '../components/Filter.vue';
      import AccountDropdownVue from "../components/AccountDropdown.vue";
    
    import {isAdmin, editButtonText} from '@/auth/AuthService'
    export default {
      Name: "Api",
      data() {
        return {
          isAdmin: isAdmin(),
          editButtonText: editButtonText(),
          FromDate : null,
          ToDate : null,
            SearchPhoneNumber: null,
            SelectedCostCentre: null,
            editInboundForm: {
              InboundId: null,
              PhoneNumber: null ,
              DayRate: null ,
              EveRate: null ,
              WendRate: null ,
              DayRateMobile: null ,
              EveRateMobile: null,
              WendRateMobile: null,
              CostCentreId: null,
              ConnFee : null,
              account:{},
              Account:null,
              modifiedby :null,
              modifiedon : null,
              action : null
            },
            apiInbounds: [],
            apiHistoryInbound:null,
            executed: false,
            message: 'initial alert',
            currentSort:'modifiedon',
            currentSortDir:'asc',
            pageSize:20,
            showPaging:false,
            currentPage:1,
            filter:'',
            ShowAccount:true,
            selected_account: null,
            CostCentres: null,
            showfilter : true,
            account_id : null,
            accountname:null,
          accountnumber: null,
          costcentre: null
          };
        },
    
        computed:{
        filteredInbounds() {
          console.log('filtered inbound');
            return this.apiInbounds.filter(c => {
              if(this.filter == '') return true;
              return c.PhoneNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
                     c.account.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
            })
          },
        SortedapiInbounds:function() {
          console.log('sort inbound');
          if (this.apiInbounds != null){
            return this.filteredInbounds.slice().sort((a,b) => {
              let modifier = 1;
              var numfields = ['DayRate','EveRate','WendRate','DayRateMobile', 'EveRateMobile', 'WendRateMobile']
              if(this.currentSortDir === 'desc') modifier = -1;
              if (numfields.includes(this.currentSort)) {
              if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
              if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            }
            else if(this.currentSort === 'account.Name'){
                var prop = this.currentSort.split('.');
                var len = prop.length;
                  var i = 0;
                  while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
                  if (a < b) {
                      return -1 * modifier;
                  } else if (a > b) {
                      return 1 * modifier;
                  } else {
                      return 0;
                  }
            }
            else{
              if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
              if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
              return 0;
            }
            }).filter((row, index) => {
              let start = (this.currentPage-1)*this.pageSize;
              let end = this.currentPage*this.pageSize;
              if(index >= start && index < end) return true;
            });
          }
              else{ return null}
        },
        },
        methods: {
          onChangePhoneType(event) {
            this.SelectedPhoneType= event.target.value
    
            console.log(event.target.value)
    
    
            // this.$emit('account-changed',this.SelectedAccount)
          },
          onChangeCostCentre(event) {
            this.SelectedCostCentre= event.target.value
    
            console.log(event.target.value)
    
    
            // this.$emit('account-changed',this.SelectedAccount)
          },
          accountChange: function (e) {
              this.selectedAccount = e;
          },
          onSubmit(evt) {
            console.log('on submit');
            this.apiHistories = null;
            this.showresult = false;
            if (this.FromDate > this.ToDate){
            evt.preventDefault();
            alert("To date can not be before the from date")
            }
            else if(this.FromDate === this.ToDate){
            var date = new Date(this.ToDate);        
            /* Add a day to to date*/
            date.setDate(date.getDate() + 1);
            /* to date =  to date + 1*/
            this.callApi(this.FromDate, date.toLocaleDateString("en-CA"));
            evt.preventDefault();
            }
            else{
                this.callApi( this.FromDate, this.ToDate);
                evt.preventDefault();
            }  
        },
          nextPage:function() {
            if((this.currentPage*this.pageSize) < this.filteredInbounds.length) this.currentPage++;
          },
          prevPage:function() {
            if(this.currentPage > 1) this.currentPage--;
          },
          sort:function(s) {
          //if s == current sort, reverse
          if(s === this.currentSort) {
            this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
          }
          this.currentSort = s;
          },
          // *******************************************
          // List costcentres
          // *******************************************
          async callCostCentreApi(lablex) {
    
            console.log(lablex)
          //   console.log('getting the costcentre list')
          //   console.log(this.$route.query.account_id)
          //   console.log('getting the costcentre list')
            var url;
            if (typeof(this.$route.query.account_id) != "undefined"){
              //   console.log('selected account in CostCentre is AAAAAAAAAA:');
                console.log(this.$route.query.account_id)
                url = process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.$route.query.account_id + '"}]}';
                console.log(url)
            }
            else{
              //   console.log('in where the account cc are selected BBBBBBBBBBB')
                console.log(this.editInbound.AccountId)
                console.log(this.editInboundForm.AccountId)
                url = process.env.VUE_APP_API_BASE_URL + '/api/v1/costcentres?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.editInboundForm.AccountId + '"}]}';
            }
            try {
              const { data } = await this.$http.get(url);
    
              this.CostCentres = data;
              if (this.apiCostCentres.length > this.pageSize){
                this.showPaging = true
              }
              console.log(this.CostCentres)
    
              this.executed = true;
            } catch (e) {
              this.apiCostCentres = `Error: the server responded with '${e.response}'`;
            }
          },
    
          // *******************************************
          // get Inbound details
          // *******************************************
          async getInboundApi(inboundid) {
    
            console.log(inboundid)
            console.log('getting the account data')
            try {
              document.body.style.cursor = "wait";
              var url;
              url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/inbounds/' + inboundid
              const { data } = await this.$http.get(url);
              document.body.style.cursor = 'auto';
              this.apiHistoryInbound = data;
              console.log('got the cli data from api')
              console.log("GET INBOUND DETAILS")
              console.log(data)
              this.executed = true;
            } catch (e) {
              this.apiAccounts = `Error: the server responded with '${e.response}'`;
            }
          },
          // *******************************************
          // List inbounds
          // *******************************************
          async callApi(fromdate, todate) {

            console.log('getting the data')
            // build the filters for the url
            var filters =[];
            var fromfilter = {"name":"modifiedon","op":">=","val":fromdate}
            var tofilter = {"name":"modifiedon","op":"<","val":todate}

            if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
                filters.push(fromfilter)
            }
            if (typeof(todate) != "undefined" && todate != null && todate != "") {
                filters.push(tofilter)
            }


            let queryfilters = JSON.stringify(filters);

            this.showPaging = false

            try {
              document.body.style.cursor = 'wait';
              var url;
              url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/inbound/deleted?q={"filters":' + queryfilters + '}' ;
              const { data } = await this.$http.get(url);
              document.body.style.cursor = 'auto';
              this.apiInbounds = data;
              console.log("XXXXXXXXXXXXXXXXXXXX DATA XXXXXXXXXXXXXXXXXXXXXXXXX")
              console.log(data)
    
              this.executed = true;
              if (this.apiInbounds.length > this.pageSize){
                this.showPaging = true
              }
              else{
                this.showPaging = false
              }
              this.executed = true;
            } catch (e) {
              this.apiInbounds = `Error: the server responded with '${e.response}'`;
            }
          },
          
           // *******************************************
          // List inbounds
          // *******************************************
          async callAccountDeletedApi() {

              this.showPaging = false

              try {
                document.body.style.cursor = 'wait';
                var url;
                url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/inbound/account/' + this.account_id ;
                const { data } = await this.$http.get(url);
                document.body.style.cursor = 'auto';
                this.apiInbounds = data;
                console.log("XXXXXXXXXXXXXXXXXXXX DATA XXXXXXXXXXXXXXXXXXXXXXXXX")
                console.log(data)

                this.executed = true;
                if (this.apiInbounds.length > this.pageSize){
                  this.showPaging = true
                }
                else{
                  this.showPaging = false
                }
                this.executed = true;
              } catch (e) {
                this.apiInbounds = `Error: the server responded with '${e.response}'`;
              }
              },
          editInbound(history) {
            console.log('editInbound');
            this.editInboundForm = history.inbound;
            // this.callCostCentreApi(history.inbound.AccountId);
            this.costcentre = null;
            if (history.inbound.costcentre) {
              if (history.inbound.costcentre.CostCentreNumber) {
                this.costcentre = history.inbound.costcentre.CostCentreNumber + ' - ' + history.inbound.costcentre.Name;
              }
              else {
                this.costcentre = history.inbound.costcentre.Name;
              }
            }
            this.editInboundForm.modifiedby = history.modifiedby
            this.editInboundForm.modifiedon = history.modifiedon
            this.editInboundForm.action = history.method
            console.log(this.editInboundForm.action);
            // this.callCostCentreApi();
          //   console.log(inbound.inbound_id);
    
            this.editInboundForm.Account = this.editInboundForm.account.Name + ' - ' +  this.editInboundForm.account.AccountNumber
    
          //   this.callApi()
          },
    
          // *******************************************
          // Update inbound on submit
          // *******************************************
          onSubmitUpdate(evt) {
          //   console.log('in onSubmitUpdatexxxxxxxxxxxxx');
          //   console.log('selectedAccount is');
          //   console.log(this.selectedAccount);
          //   console.log('inbound_id');
            evt.preventDefault();
            this.$refs.editInboundModal.hide();
            const payload = {
              PhoneNumber: this.editInboundForm.PhoneNumber,
              DayRate: this.editInboundForm.DayRate,
              EveRate: this.editInboundForm.EveRate,
              WendRate: this.editInboundForm.WendRate,
              DayRateMobile: this.editInboundForm.DayRateMobile,
              EveRateMobile: this.editInboundForm.EveRateMobile,
              WendRateMobile: this.editInboundForm.WendRateMobile,
              AccountId: this.selectedAccount,
              CostCentreId: this.SelectedCostCentre,
              ConnFee: this.editInboundForm.ConnFee
            };
            console.log(payload)
            this.updateInbound(payload, this.editInboundForm.inbound_id);
            this.callApi(this.SearchPhoneNumber);
          },
    
          async updateInbound(payload, inboundid) {
    
            console.log('in update inbound');
            console.log(payload);
            try {
              const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/inbounds/${inboundid}`, payload).then(() => {
                this.message = 'inbound updated!';
                this.showMessage = true;
                this.callApi(this.SearchPhoneNumber);
    
              }).catch((err) => {
                if(err.response.status == 409) {
                  alert("Duplicated inbound. status code:  " + err.response.status)
                  }
                if(err.response.status != 409) {
                  alert("Problem with add inbound. status code:  " + err.response.status)
                  }
    
                console.error(err);
                this.callApi(this.SearchPhoneNumber);
    
              });
    
              // this.apiInbounds = data;
              // this.executed = true;
              console.log('delete data is:')
              console.log(data)
              this.callApi(this.SearchPhoneNumber);
            } catch (e) {
              this.apiInbounds = `Error: the server responded with '${e.response}'`;
            }
          },
          onResetUpdate(evt) {
            evt.preventDefault();
            this.$refs.editInboundModal.hide();
            this.initForm();
            this.getInbounds(); // why?
          },
        },
    
        created() {
          console.log('created')
          if (typeof(this.$route.query.account_id) != "undefined"){
            this.account_id = this.$route.query.account_id;
            this.accountname = this.$route.query.accountname;
            this.accountnumber = this.$route.query.accountnumber;
            this.showfilter = false;
            this.callAccountDeletedApi()
          }
          // this.callCostCentreApi();
          this.executed = true;
        //   this.callApi();
    
    
        },
        components: {
          alert: Alert,
          listfilter:Filter,
          AccountDropdownVue: AccountDropdownVue,
        },
      };
      </script>
    