<template>

  <div>
    <p ><u><strong>NetX Group Accounts</strong></u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button  v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.account-modal>Insert NetX Group</button>&nbsp;&nbsp;&nbsp; 
          <a :href="'deletednetxgroups'" target="_blank">
          <button v-if="isAdmin"  type="button"  class="btn btn-danger btn-sm">Deleted</button>
          </a>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('Name')" >Account Name</th>
              <th class="th-lg" scope="col" @click="sort('AccountCount')" >Accounts</th>
              <!-- <th scope="col" @click="sort('CreatedOn')">Date created</th>
              <th scope="col" @click="sort('CreatedBy')">Created by</th> -->
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(netxgroup, index) in SortedapiNetxGroup" :key="index">
              <td>{{ netxgroup.Name }}</td>
              <td>{{ netxgroup.accounts.length }}</td>
              <td align="center"><a :href="'/netxgrouphistory?netxgroup_id=' + netxgroup.netxgroup_id" target="_blank">History</a></td>
              <!-- <td>{{ netxgroup.CreatedOn }}</td>
              <td>{{ netxgroup.CreatedBy }}</td> -->
              <td>
                <div class="btn-group"  role="group">
                  <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-b-modal.netxgroup-update-modal
                          @click="editNetxGroup(netxgroup)">
                      View
                  </button>
                  <p>  </p>
                  <button  v-if="isAdmin" v-on:click="onDeleteAccount(netxgroup)"  type="button"
                          class="btn btn-danger btn-sm">
                          <!-- @click="onDeleteAccount(netxgroup)"> -->
                      Delete
                  </button>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div><p align="center">
          <b-pagination
          v-model="currentPage"
          :total-rows="filteredNetxGroup.length"
          :per-page="pageSize"
          aria-controls="apirecords"
          ></b-pagination>
          <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p></div>
      </div>
    </div>


  <!-- ADD member modal -->
    <b-modal ref="addAccountMemberModal"
            id="member-modal"
            title="Insert account to group"
            hide-footer size="auto" >
      <b-form @submit="onMemberSubmit" @reset="onResetMember" class="w-300">
      <b-form-row>
        <b-col>
            <div >
                <center> <AccountDropdownVue @account-changed="accountChange"></AccountDropdownVue></center>
            <br>
            <!-- <p> selected account = {{this.selectedAccount}}</p> -->
        </div>
        </b-col>
      </b-form-row>
      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
    <!-- ADD modal -->
    <b-modal ref="addNetXGroupModal"
            id="account-modal"
            title="Insert a new NetX group"
            hide-footer size="lg" >
      <b-form @submit="onSubmit" @reset="onReset" class="w-200">
      <b-form-row>
        <b-col>
            <b-form-group id="form-name-group"
                    label="Account Name"
                    label-for="form-name-input" label-cols="1" label-cols-sm="2" label-size="sm">
            <b-form-input id="form-tname-input"
                            type="text"   size="sm"
                            v-model="addNetxGroupForm.Name"
                            required
                            placeholder="Enter Name" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
           <b-col>
            <b-form-group id="form-Notes-group"
                      label="Notes:"
                      label-for="form-Notes-input" label-cols="1" label-cols-lg="2" label-size="sm" size="xs">
            <b-form-input id="form-Notes-input"
                      type="text"   size="sm"
                      v-model="addNetxGroupForm.Notes"
                      required
                      placeholder="Enter Notes">
            </b-form-input>
            </b-form-group>
        </b-col>

      </b-form-row>

      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal :key="editcomponentKey" ref="editNetxGroupModal"
            id="netxgroup-update-modal"
            title="Update NetX Group"
            hide-footer size="lg" >
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
      <b-form-row>
        <b-col>
            <b-form-group id="form-name-group"
                    label="Account Name"
                    label-for="form-name-input" label-cols="1" label-cols-sm="2" label-size="sm">
            <b-form-input id="form-tname-input"
                            type="text"   size="sm"
                            v-model="editNetxGroupForm.Name"
                            required
                            placeholder="Enter Name" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-notes-group"
                    label="Notes"
                    label-for="form-notes-input" label-cols="1" label-cols-sm="2" label-size="sm">
            <b-form-input id="form-tnotes-input"
                            type="text"   size="sm"
                            v-model="editNetxGroupForm.Notes"
                            required
                            placeholder="Enter Notes" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
      <b-form-row>
          <p>Group Members</p>
      </b-form-row>
      <b-form-row>
          <b-col cols sm="2"  lg="auto">
          <table class="table table-small w-auto" >
          <thead>
            <tr>
              <th class="th-xs"  scope="col" >Name</th>
               <th class="th-xs"   scope="col" >AccountNumber</th>
               <!-- <th class="th-xs"   scope="col" >Added on</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in editNetxGroupForm.accounts" :key="index">
              <td>{{ account.Name }}</td>
              <td>{{ account.AccountNumber }}</td>
              <!-- <td>{{ account.AddedOn }}</td> -->
              <td>
                  <button  v-if="isAdmin"
                          type="button"
                          class="btn-custom"
                          title="Remove member"
                          @click="onDeleteAccountMember(account.account_id)">
                      Remove
                  </button>
              </td>
            </tr>
          </tbody>
        </table>
        </b-col>
        <b-col>
            <button  v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.member-modal>Insert member</button>
        </b-col>
      </b-form-row>


        <div class="container">
        <div class="row"  v-if="isAdmin" >
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      editcomponentKey:0,
      selectedAccount:"",
      addNetxGroupForm: {
        Name: '' ,
        Notes: ''
      },
      editNetxGroupForm: {
        accountgroup_id : '',
        Name: '' ,
        Address1: '' ,
        Notes: '',
        CreatedOn:'',
        CreatedBy:'',
        ModifiedOn:'',
        ModifiedBy:'',
        Accounts:[],
        accounts:[]
      },
      apiNetxGroup: null,
      executed: false,
      message: 'initial alert',
      currentSort:'Name',
      currentSortDir:'asc',
      pageSize:20,
      currentPage:1,
      filter:'',
    };
  },

  computed:{
    filteredNetxGroup() {
    console.log('filtered account');
    console.log(this.filter);
      return this.apiNetxGroup.filter(c => {
        if(this.filter == '') return true;
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },

  SortedapiNetxGroup:function() {
    console.log('sort netx group');
    if (this.apiNetxGroup != null){
    return this.filteredNetxGroup.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
    }
    else{ return null}
  },
  },
  methods: {
    forceRerender:function() {
      this.editcomponentKey += 1
    },
    accountChange: function (e) {
        this.selectedAccount = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredNetxGroup.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    //////////////////////////////////////////////
    // LIST ACCOUNT GROUP METHODS
    //////////////////////////////////////////////
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the data')
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/netxgroups`);
        console.log(data)
        console.log('got netx group data')
        this.apiNetxGroup = data;
        this.executed = true;
      } catch (e) {
        console.log(e)
        this.apiNetxGroup = `Error: the server responded with '${e.response}'`;
      }
    },

    //////////////////////////////////////////////
    // DELETE ACCOUNT GROUP METHODS
    //////////////////////////////////////////////
    async removeAccount(accountid) {

      console.log("in removeAccount before")
      console.log(accountid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/netxgroups/${accountid}`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          console.log("in removeAccount after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          this.callApi();

        });

        // this.apiNetxGroup = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiNetxGroup = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteAccount(netxgroup) {
      console.log(netxgroup.accountgroup_id)
      if(confirm("Are you sure you want to delete the netx group?")){
        this.removeAccount(netxgroup.netxgroup_id);
      }
    },

    //////////////////////////////////////////////
    // DELETE MEMBER  METHODS
    //////////////////////////////////////////////
    async removeNetxGroupMember(netxgroupid,memberid) {

      console.log("in remove netxgroup member before")
      console.log(memberid)
      console.log(netxgroupid)
    //   const  payload = {};
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/netxgroups/${netxgroupid}/accounts/${memberid}`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          console.log("in removeAccount after")
        //   this.forceRerender();
          this.$refs.editNetxGroupModal.hide();
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          this.callApi();

        });

        // this.apiNetxGroup = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiNetxGroup = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteAccountMember(memberid) {
      console.log(memberid)
      this.removeNetxGroupMember(this.editNetxGroupForm.netxgroup_id, memberid);
    },

    //////////////////////////////////////////////
    // ADD MEMBER METHODS
    //////////////////////////////////////////////
    onMemberSubmit(evt) {
      console.log('on member submit');
      evt.preventDefault();
      this.$refs.addAccountMemberModal.hide();

      const memberid = this.selectedAccount;
      console.log('memberid is');
      console.log(memberid);
      console.log('groupid is');
      console.log(this.editNetxGroupForm.accountgroup_id);

      this.addMemberAccount(this.editNetxGroupForm.netxgroup_id,memberid);
      this.initForm();
    },
    onResetMember(evt) {
      evt.preventDefault();
      this.$refs.addAccountMemberModal.hide();
      this.initForm();
    },
    // Add account member method to post to API
    async addMemberAccount(netxgroupid,memberid) {

    //   const id_token = claims.__raw;
      console.log('in add account');
      console.log('payload is');
      console.log(memberid);
      const  payload = {};
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/netxgroups/${netxgroupid}/accounts/${memberid}`, payload).then(() => {
          this.message = 'account added!';
          this.showMessage = true;
          this.$refs.editNetxGroupModal.hide();
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated netxgroup. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add member to netxgroup. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apiNetxGroup = data;
        // this.executed = true;
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiNetxGroup = `Error: the server responded with '${e.response}'`;
      }
    },

    //////////////////////////////////////////////
    // ADD ACCOUNT GROUP METHODS
    //////////////////////////////////////////////

    // Add account method to post to API
    async addNetXGroup(payload) {

    //   const id_token = claims.__raw;
      console.log('in add netx group');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/netxgroups`, payload).then(() => {
          this.message = 'netx group added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated netxgroup. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add netxgroup. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiNetxGroup = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addNetxGroupForm.Name =  '';
        this.addNetxGroupForm.Notes =  '';
    },
    // Add account on submit
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addNetXGroupModal.hide();

      const payload = {
        Name: this.addNetxGroupForm.Name,
        Notes: this.addNetxGroupForm.Notes,
      };
      this.addNetXGroup(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addNetXGroupModal.hide();
      this.initForm();
    },
    //////////////////////////////////////////////
    // UPDATE ACCOUNT GROUP METHODS
    //////////////////////////////////////////////
    editNetxGroup(netxgroup) {
      console.log('editNetxGroup');
      console.log(netxgroup);
      console.log(netxgroup.netxgroup_id);
      this.editNetxGroupForm = netxgroup;
      this.callApi()
    },

    // Update account on submit
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('accountgroup_id');
      evt.preventDefault();
      this.$refs.editNetxGroupModal.hide();


      const payload = {
        Name: this.editNetxGroupForm.Name,
        Notes: this.editNetxGroupForm.Notes
      };
      this.updateNetXGroup(payload, this.editNetxGroupForm.netxgroup_id);
      this.callApi()
    },

    async updateNetXGroup(payload, netxgroupid) {

      console.log('in update netx group');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/netxgroups/${netxgroupid}`, payload).then(() => {
          this.message = 'netx group updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated netxgroup. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add netxgroup. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiNetxGroup = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiNetxGroup = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editNetxGroupModal.hide();
      this.initForm();
      this.getAccounts(); // why?
    },
  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
  },
};
</script>
<style>
.table .btn-group {
	white-space: nowrap;
}
.table .btn-group .btn {
	display: inline-block;
	float: none;
}
.table .btn-group .btn + .btn {
	margin-left: -5px;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: normal;
  text-align: center;
  font-size: 14px;
}
td {
  font-size: 12px;
}
.btn-custom {
    width: 50px !important;
    height: 17px;
    font-size: 9px;
    font-weight: normal;
    text-align: center;
    background-color: red;
    text-emphasis-color: white;
    color: white;
}

</style>
