<template>

  <div>
    <p ><u><strong>Gamma Mobile Markup List</strong> </u></p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button  v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.gammamarkup-modal>Insert Markup</button>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-sm" scope="col" @click="sort('AccountName')">Account </th>
              <th class="th-sm" scope="col" @click="sort('MarkupPercentage')">Markup percentage </th>
              <th scope="col" @click="sort('ModifiedOn')">Date modified</th>
              <th scope="col" @click="sort('ModifiedBy')">Modified by</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(gammamarkup, index) in SortedapiGammaMarkups" :key="index">
              <td>{{ gammamarkup.AccountName }}</td>
              <td align="center">{{ gammamarkup.MarkupPercentage }}</td>
              <td>{{ gammamarkup.ModifiedOn }}</td>
              <td>{{ gammamarkup.ModifiedBy }}</td>
              <td>
                <div class="btn-group"  role="group">
                  <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-b-modal.gammamarkup-update-modal
                          @click="editGammaMarkup(gammamarkup)">
                          {{editButtonText}}
                  </button>
                  <p>  </p>
                  <button  v-if="isAdmin"
                          type="button"
                          class="btn btn-danger btn-sm"
                          @click="onDeleteGammaMarkup(gammamarkup)">
                      Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging"><p align="center" >
          <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button>
          </p></div>
      </div>
    </div>

  <!-- add modal -->
     <!-- add modal -->
    <b-modal ref="addMobileBundleModal"
            id="gammamarkup-modal"
            title="Insert a gamma markup"
            hide-footer size="sm" >
      <b-form @submit="onSubmit" @reset="onReset" class="w-600">
      <b-form-row>
         <b-col>
            <div align="left">
              <AccountDropdownVue v-bind:selected_account="addGammaMarkupForm.AccountId" @account-changed="accountChange"></AccountDropdownVue></div>
            <br>
        </b-col>

      </b-form-row>
         <b-form-row>
        <b-col>
            <b-form-group id="form-MarkupPercentage-group"
                      label="Markup %"
                      label-for="form-MarkupPercentage-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-MarkupPercentage-input"
                      type="text"   size="sm"
                      v-model="addGammaMarkupForm.MarkupPercentage"
                      placeholder="Enter markup percentage">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>

  <!-- EDIT modal -->
    <b-modal ref="editGammaMarkupModal"
            id="gammamarkup-update-modal"
            title="Update gamma markup"
            hide-footer size="sm" >
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-600">
            <b-form-row>
         <b-col>
            <div align="left">
              <AccountDropdownVue v-bind:selected_account="editGammaMarkupForm.AccountId" @account-changed="accountChange"></AccountDropdownVue></div>
            <br>
        </b-col>

      </b-form-row>
         <b-form-row>
        <b-col>
            <b-form-group id="form-MarkupPercentage-group"
                      label="Markup %"
                      label-for="form-MarkupPercentage-input" label-cols="4" label-cols-lg="4" label-size="sm" size="xs">
            <b-form-input id="form-MarkupPercentage-input"
                      type="text"   size="sm"
                      v-model="editGammaMarkupForm.MarkupPercentage"
                      placeholder="Enter markup percentage">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row"  v-if="isAdmin">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import AccountDropdownVue from "../components/AccountDropdown.vue";

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),

      addGammaMarkupForm: {
        MarkupPercentage: ''
      },
      editGammaMarkupForm: {
        GMId: null,
        MarkupPercentage: null ,
        AccountId: null
      },
      apiGammaMarkups: null,
      executed: false,
      message: 'initial alert',
      currentSort:'MarkupPercentage',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:true,
      currentPage:1,
      filter:'',
      ShowAccount:true,
      selected_account: null
    };
  },

  computed:{
  filteredGammaMarkups() {
    // console.log('filtered gammamarkup');
      return this.apiGammaMarkups.filter(c => {
        if(this.filter == '') return true;
        if (c.Name == null) c.Name = '';
        if (c.AccountName == null) c.AccountName = '';
        if (c.Carrier == null) c.Carrier = '';
        if (c.Description == null) c.Description = '';
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
               c.Description.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiGammaMarkups:function() {
    // console.log('sort gammamarkup');
    // console.log('sort account');
    if (this.apiGammaMarkups == null) return null;
    // console.log ('in sort account....');
    // console.log(this.currentSort)
    return this.filteredGammaMarkups.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;

      if (this.currentSort === 'SellDayRate' || 'SellEveRate' ||'SellWendRate' || 'SellConFee' || 'CodeCount') {
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
      }
      else{
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      }
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    onChangePhoneType(event) {
      this.SelectedPhoneType= event.target.value

    //   console.log(event.target.value)


      // this.$emit('account-changed',this.SelectedAccount)
    },
    accountChange: function (e) {
        this.selectedAccount = e;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredGammaMarkups.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List gammamarkups
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
    //   console.log('getting the gammamarkup list')
    //   console.log(this.$route.query.account_id)
    //   console.log('getting the gammamarkup list')
      var url;
      if (typeof(this.$route.query.account_id) != "undefined"){
        //   console.log('selected account in GammaMarkup is:');
        //   console.log(this.$route.query.account_id)
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/gammamarkups?q={"filters":[{"name":"AccountId","op":"eq","val":"' + this.$route.query.account_id + '"}]}';
        //   console.log(url)
          this.ShowAccount = false
          this.selected_account = this.$route.query.account_id
        //   console.log('selected account in GammaMarkup is:');
        //   console.log(this.selected_account);
      }
      else{
          url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/gammamarkups`
      }
      try {
        const { data } = await this.$http.get(url);

        this.apiGammaMarkups = data;
        if (this.apiGammaMarkups.length < this.pageSize){
          this.showPaging = false
        }
        // console.log(this.apiGammaMarkups )
        // console.log(this.apiGammaMarkups.length)
        this.executed = true;
      } catch (e) {
        this.apiGammaMarkups = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // Delete gammamarkup
    // *******************************************
    async removeGammaMarkup(gammamarkupid) {

      console.log("in removeGammaMarkup before")
      console.log(gammamarkupid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}api/v1/gammamarkups/${gammamarkupid}`).then(() => {
          this.message = 'gammamarkup removed!';
          this.showMessage = true;
          console.log("in removeGammaMarkup after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeGammaMarkup error")
          console.error(err);
          this.callApi();

        });

        // console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiGammaMarkups = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteGammaMarkup(gammamarkup) {
    //   console.log('in onDeleteGammaMarkup')
    //   console.log(gammamarkup)
    //   console.log(gammamarkup.GMId)
      if(confirm("Are you sure you want to delete this gamma markup?")){
        this.removeGammaMarkup(gammamarkup.GMId);
      }
    },

    // Add gammamarkup method to post to API
    async addGammaMarkup(payload) {

    //   const id_token = claims.__raw;
    //   console.log('in add gammamarkup');
    //   console.log('payload is');
    //   console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/gammamarkups`, payload).then(() => {
          this.message = 'gammamarkup added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated gammamarkup. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add gammamarkup. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiGammaMarkups = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
    //   console.log('init form');
        this.addGammaMarkupForm.MarkupPercentage =  null;
        // this.selectedAccount = null;
    },

    // *******************************************
    // Add gammamarkup on submit
    // *******************************************
    onSubmit(evt) {
    //   console.log('on submit');
      evt.preventDefault();
    //   console.log(this.selectedAccount);

      this.$refs.addGammaMarkupModal.hide();
      var accountid;
      if ((this.selected_account) != null){
        accountid = this.selected_account
      }
      else{
        accountid = this.selectedAccount
      }
      const payload = {
        MarkupPercentage: this.addGammaMarkupForm.MarkupPercentage,
        AccountId: accountid
      };
      this.addGammaMarkup(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addGammaMarkupModal.hide();
      this.initForm();
    },
  //
    editGammaMarkup(gammamarkup) {
    //   console.log('editGammaMarkup');
    //   console.log(gammamarkup);
    //   console.log(gammamarkup.GMId);
      this.editGammaMarkupForm = gammamarkup;
      this.callApi()
    },

    // *******************************************
    // Update gammamarkup on submit
    // *******************************************
    onSubmitUpdate(evt) {
    //   console.log('in onSubmitUpdate');
    //   console.log('CBId');
      evt.preventDefault();
      this.$refs.editGammaMarkupModal.hide();
    //   var accountid;
      console.log("in onSubmitUpdate");
      console.log(this.selectedAccount);
    //   if ((this.selected_account) != null){
    //     accountid = this.selected_account
    //   }
    //   else{
    //     accountid = this.selectedAccount
    //   }
      const payload = {
        MarkupPercentage: this.editGammaMarkupForm.MarkupPercentage,
        AccountId: this.selectedAccount
      };
      console.log(payload);
      this.updateGammaMarkup(payload, this.editGammaMarkupForm.GMId);
      this.callApi()
    },

    async updateGammaMarkup(payload, gammamarkupid) {

    //   console.log('in update gammamarkup');
    //   console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/gammamarkups/${gammamarkupid}`, payload).then(() => {
          this.message = 'gammamarkup updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated gammamarkup. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add gammamarkup. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiGammaMarkups = data;
        // this.executed = true;
        // console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiGammaMarkups = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editGammaMarkupModal.hide();
      this.initForm();
      this.getGammaMarkups(); // why?
    },
  },
  created() {
    // console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    AccountDropdownVue: AccountDropdownVue,
  },
};
</script>
