<template>

  <div>
    <p ><u><strong>Carriers</strong></u> </p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.carrier-modal>Insert Carrier</button>
        <br><br>
        <input type="search" v-model="filter">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col" @click="sort('Name')" >Name</th>
              <!-- <th class="th-sm" scope="col" @click="sort('BillingName')">Billing name</th> -->
              <th scope="col" @click="sort('ContactName')">Contact</th>
              <th scope="col" @click="sort('ContactEmail')">Contact Email</th>
              <th scope="col" @click="sort('URL')">URL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(carrier, index) in SortedapiCarriers" :key="index">
              <td>{{ carrier.Name }}</td>
              <td>{{ carrier.ContactName }}</td>
              <td>{{ carrier.ContactEmail }}</td>
              <td>{{ carrier.URL }}</td>
              <td>
                <div class="btn-group"  role="group">
                  <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-b-modal.carrier-update-modal
                          @click="editCarrier(carrier)">
                      {{editButtonText}}
                  </button>
                  <p>  </p>
                  <button v-if="isAdmin"
                          type="button"
                          class="btn btn-danger btn-sm"
                          @click="onDeleteCarrier(carrier)">
                      Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div><p align="center">
          <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredCarriers.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
          <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p></div>
      </div>
    </div>
    <!-- add modal -->
    <b-modal ref="addCarrierModal"
            id="carrier-modal"
            title="Insert a new carrier"
            hide-footer size="m" >
      <b-form @submit="onSubmit" @reset="onReset" class="w-200">
      <b-form-row>
        <b-col>
            <b-form-group id="form-name-group"
                    label="Name"
                    label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
            <b-form-input id="form-tname-input"
                            type="text"   size="sm"
                            v-model="addCarrierForm.Name"
                            required
                            placeholder="Enter Name" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-SageCarrier-group"
                      label="Contact"
                      label-for="form-SageCarrier-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-SageCarrier-input"
                      type="text"   size="sm"
                      v-model="addCarrierForm.ContactName"
                      placeholder="Enter contact">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-ContactName-group"
                      label="Contact Email"
                      label-for="form-ContactName-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-ContactName-input"
                      type="text"   size="sm"
                      v-model="addCarrierForm.ContactEmail"
                      placeholder="Enter contact email">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
       <b-form-row>
        <b-col>
            <b-form-group id="form-City-group"
                      label="URL"
                      label-for="form-City-input" label-cols="6" label-cols-sm="3"  label-size="sm">
            <b-form-input id="form-City-input"
                      type="text"   size="sm"
                      v-model="addCarrierForm.URL"
                      placeholder="Enter url"
                      >
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal ref="editCarrierModal"
            id="carrier-update-modal"
            title="Update carrier"
            hide-footer size="m" >
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
        <b-form-row>
        <b-col>
            <b-form-group id="form-name-group"
                    label="Name"
                    label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
            <b-form-input id="form-tname-input"
                            type="text"   size="sm"
                            v-model="editCarrierForm.Name"
                            required
                            placeholder="Enter Name" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-SageCarrier-group"
                      label="Contact"
                      label-for="form-SageCarrier-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input id="form-SageCarrier-input"
                      type="text"   size="sm"
                      v-model="editCarrierForm.ContactName"
                      placeholder="Enter contact">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
            <b-form-group id="form-ContactName-group"
                      label="Contact Email"
                      label-for="form-ContactName-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input id="form-ContactName-input"
                      type="text"   size="sm"
                      v-model="editCarrierForm.ContactEmail"
                      placeholder="Enter contact email">
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>
       <b-form-row>
        <b-col>
            <b-form-group id="form-City-group"
                      label="URL"
                      label-for="form-City-input" label-cols="6" label-cols-sm="3"  label-size="sm">
            <b-form-input id="form-City-input"
                      type="text"   size="sm"
                      v-model="editCarrierForm.URL"
                      placeholder="Enter url"
                      >
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center" v-if="isAdmin">
            <b-button-group>
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      // env_url: process.env.ENV_API_URL,
      addCarrierForm: {
        Name: '' ,
        BillingName: '' ,
        ContactName: null,
        ContactEmail: null,
        URL: null
      },
      editCarrierForm: {
        CarrierId: null,
        Name: '' ,
        BillingName: '' ,
        ContactName: null,
        ContactEmail: null,
        URL: null
      },
      apiCarriers: null,
      executed: false,
      message: 'initial alert',
      currentSort:'Name',
      currentSortDir:'asc',
      pageSize:20,
      currentPage:1,
      filter:'',
    };
  },

  computed:{
  filteredCarriers() {
    console.log('filtered carrier');
      return this.apiCarriers.filter(c => {
        if(this.filter == '') return true;
        // if (c.Name == null) c.Name = '';
        // if (c.ContactName == null) c.ContactName = '';
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               (c.ContactName && c.ContactName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) ||
               (c.ContactEmail && c.ContactEmail.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)||
               (c.URL && c.URL.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
      })
    },
  

  SortedapiCarriers:function() {
    console.log('sort carrier');
    return this.filteredCarriers.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if (a[this.currentSort] === null) return 1;
      if (b[this.currentSort] === null) return -1;
      if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredCarriers.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List carriers
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('XXXXXXXXXXXXXXXXXXXXgetting the dataXXXXXXXXXXXXXXXXXX')
      try {
        console.log(process.env.VUE_APP_API_BASE_URL)
        console.log(process.env.VUE_APP_TEST)
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/carriers`);

        this.apiCarriers = data;
        this.executed = true;
      } catch (e) {
        this.apiCarriers = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // Delete carrier
    // *******************************************
    async removeCarrier(carrierid) {

      console.log("in removeCarrier before")
      console.log(carrierid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/carriers/${carrierid}`).then(() => {
          this.message = 'carrier removed!';
          this.showMessage = true;
          console.log("in removeCarrier after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeCarrier error")
          console.error(err);
          this.callApi();

        });

        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCarriers = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteCarrier(carrier) {
      console.log(carrier.CarrierId)
      if(confirm("Are you sure you want to delete this carrier?")){
        this.removeCarrier(carrier.CarrierId);
      }
    },

    // Add carrier method to post to API
    async addCarrier(payload) {

    //   const id_token = claims.__raw;
      console.log('in add carrier');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/carriers`, payload).then(() => {
          this.message = 'carrier added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated carrier. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add carrier. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCarriers = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addCarrierForm.Name =  null;
        this.addCarrierForm.ContactName =null;
        this.addCarrierForm.ContactEmail =null;
        this.addCarrierForm.URL =null;
    },
    // *******************************************
    // Add carrier on submit
    // *******************************************
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addCarrierModal.hide();
      console.log('MaintenaceOnly');
      const payload = {
        Name: this.addCarrierForm.Name,
        ContactName:this.addCarrierForm.ContactName,
        ContactEmail:this.addCarrierForm.ContactEmail,
        URL:this.addCarrierForm.URL
      };
      this.addCarrier(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addCarrierModal.hide();
      this.initForm();
    },
  //
    editCarrier(carrier) {
      console.log('editCarrier');
      console.log(carrier);
      console.log(carrier.carrier_id);
      this.editCarrierForm = carrier;
      this.callApi()
    },

    // *******************************************
    // Update carrier on submit
    // *******************************************
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('carrier_id');
      evt.preventDefault();
      this.$refs.editCarrierModal.hide();
      const payload = {
        Name: this.editCarrierForm.Name,
        ContactName:this.editCarrierForm.ContactName,
        ContactEmail:this.editCarrierForm.ContactEmail,
        URL:this.editCarrierForm.URL
      };
      this.updateCarrier(payload, this.editCarrierForm.CarrierId);
      this.callApi()
    },

    async updateCarrier(payload, carrierid) {

      console.log('in update carrier');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/carriers/${carrierid}`, payload).then(() => {
          this.message = 'carrier updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated carrier. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add carrier. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiCarriers = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiCarriers = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editCarrierModal.hide();
      this.initForm();
      this.getCarriers(); // why?
    },
  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter
  },
};
</script>
