<template>

    <div>

      <p ><u><strong>Deleted Entities</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" >


        <b-form-group
        id="fieldset-horizontal"
        label-cols-sm="2"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="5"
        label="Entity Type"
        label-for="input-horizontal"
        >
        <div class="dropdown">
                <select @change="onChangeEntity($event)">
                  <option value="">Select record type</option>
                  <option
                    v-for="(type, index) in entitytype"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
        </b-form-group>

        <!-- <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="Method"
            label-for="input-horizontal"
            >
            <div class="dropdown">
                <select @change="onChangeMethod($event)">
                  <option value="">Select method</option>
                  <option
                    v-for="(type, index) in methods"
                    :value="type.id"
                    :key="index"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </b-form-group> -->
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="From Date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-fromdate-input"
                      type="date"   size="sm"
                      v-model="FromDate"
                      placeholder="Enter from date">
            </b-form-input>
            </b-form-group>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="To Date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-todate-input"
                      type="date"   size="sm"
                      v-model="ToDate"
                      placeholder="Enter to date">
            </b-form-input>
            </b-form-group>


        <b-button type="submit" variant="primary">Find</b-button>
  </b-form>

        <br><br>

         <!-- <p v-if="showresult"> Number of records found: {{ apiHistories.length }}</p> -->
          <label v-if="showresult" for="filter">Name filter  &ensp; </label><input v-if="showresult" type="search" v-model="filter">
          <table class="table table-striped w-auto" v-show="showresult" >
            <thead>
              <tr>
                <th scope="col" @click="sort('entitytype')">Type</th>
                <th scope="col" @click="sort('entityname')">Name</th>
                <th scope="col" @click="sort('modifiedon')">Deleted Date</th>
                <th scope="col" >User</th>
                <th></th>

              </tr>
            </thead>
            <tbody>
              <tr v-for="(history, index) in SortedapiHistories" :key="index">
                <div v-if="history.entitytype=='CLI'">
                  <td>Outbound</td>
                </div>
                <div v-else>
                  <td>{{ history.entitytype }}</td>
                </div>   
                <td>{{ history.entityname }}</td>
                <td>{{ history.modifiedon }}</td>
                <td>{{ history.modifiedby }}</td>
                <td>
                <div class="btn-group"  role="group">
                  <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-b-modal.history-update-modal
                          @click="editHistory(history)">
                      View
                  </button>
                  <p>  </p>
                </div>
              </td>
              </tr>
            </tbody>
          </table>
          <div v-if="apiHistories.length > pageSize"><p align="center">
            <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button>
            </p></div>
        </div>
      </div>
        <!-- view modal -->
        <b-modal ref="editHistoryModal"
            id="history-update-modal"
            :title="editHistoryForm.comment"
            hide-footer size="l" >
      <b-form  @reset="onResetUpdate" class="w-600">
      <b-form-row>
        <b-col>
            <b-form-group id="form-name-group"
                    label="Type"
                    label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
            <b-form-input  readonly id="form-tname-input"
                            type="text"   size="sm"
                            v-model="editHistoryForm.entitytype"
                            required
                            placeholder="Enter Name" w-20>
            </b-form-input>
            </b-form-group>
        </b-col>
        <!-- <b-col>
            <b-form-group id="form-customerno-group"
                      label="Name"
                      label-for="form-customerno-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input  readonly id="form-customerno-input"
                          type="text"   size="sm"
                          v-model="editHistoryForm.entityname"

                          >
            </b-form-input>
          </b-form-group>
        </b-col> -->
      </b-form-row>
      <b-form-row>
       <b-col>
            <b-form-group id="form-customerno-group"
                      label="Name"
                      label-for="form-customerno-input" label-cols="4" label-cols-lg="3" label-size="sm">
            <b-form-input  readonly id="form-customerno-input"
                          type="text"   size="sm"
                          v-model="editHistoryForm.entityname"

                          >
            </b-form-input>
          </b-form-group>
        </b-col>
        <!-- <b-col>
            <b-form-group id="form-SageCarrier-group"
                      label="Date"
                      label-for="form-SageCarrier-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
            <b-form-input  readonly id="form-SageCarrier-input"
                      type="text"   size="sm"
                      v-model="editHistoryForm.modifiedon"
                      >
            </b-form-input>
            </b-form-group>
        </b-col> -->
      </b-form-row>
      <b-form-row>
      </b-form-row>
       <b-form-row>
        <b-col>
            <b-form-group id="form-City-group"
                      label="Record Details"
                      label-for="form-City-input" label-cols="6" label-cols-sm="3"  label-size="sm">
                      <b-form-textarea readonly
                      id="textarea"
                      v-model="editHistoryForm.before"
                      rows="3"
                      max-rows="20"
                    ><pre>{{ JSON.stringify(editHistoryForm.before, null, 2) }}</pre></b-form-textarea>
            </b-form-group>
        </b-col>
        <!-- <b-col>
            <b-form-group id="form-SaleAgent-group"
                      label="After"
                      label-for="form-SaleAgent-input" label-cols="4" label-cols-lg="3" label-size="sm">
                      <b-form-textarea readonly
                      id="textarea"
                      v-model="editHistoryForm.after"
                      rows="3"
                      max-rows="20"
                    ></b-form-textarea>

            </b-form-group>
        </b-col> -->

      </b-form-row>
      <b-form-row>
          <b-col>
       <p></p>
        </b-col>
        </b-form-row>
        <div class="container">
        <div class="row">
            <div class="col text-center" v-if="isAdmin">
            <b-button-group>
          <b-button type="reset" variant="danger">Close</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  import datepicker from 'vue-date-picker'

  export default {

    Name: "Api",
    data() {
      return {
        methods: [{ "id":"ADD", "value":'Add'}, {"id":"UPDATE","value":'Update'}, {"id":"DELETE","value":'Delete'}],
        entitytype: [{ "id":"Account", "value":'Account'},
                    { "id":"AccountGroup", "value":'Group Account'},
                    { "id":"netXGroup", "value":'NetX Group Account'},
                    { "id":"CLI", "value":'Outbound'},
                    // { "id":"Carrier", "value":'Carrier'},
                    { "id":"Inbound", "value":'Inbound'},
                    { "id":"CostCentre", "value":'Cost Centre'},
                    // { "id":"CallBundle", "value":'Call bundle'},
                    // { "id":"AccountBundle", "value":'Account call bundle'},
                    // { "id":"SMSContract", "value":'SMS contract'},
                    // { "id":"AccountSMSContract", "value":'Account SMS contract'},
                    // { "id":"ChargeCategory", "value":'Charge category'},
                    // { "id":"ServiceType", "value":'Service type'},
                    { "id":"RecurringCharge", "value":'Recurring Charge'},
                    { "id":"Product", "value":'Product'},
                    // { "id":"AccountChargeBand", "value":'Account chargeband rate'},
                    // { "id":"Country", "value":'Country rate'},
                    // { "id":"AccountCountry", "value":'Account country rate'}
                    ],
        directions: [{ "id":"O", "value":'Outgoing      '}, {"id":"I","value":'Incoming'}],
        FromDate : null,
        ToDate : null,
        editHistoryForm: {
          OrderId: null,
          entityid: null ,
          entityname: null ,
          before: null ,
          after: null ,
          modifiedon: null ,
          modifiedby: null ,
          comment: null ,
          entitytype: null,
          method: null
        },
        apiHistories: [],
        executed: false,
        message: 'initial alert',
        currentSort:'modifiedon',
        currentSortDir:'desc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        Account: null
      };
    },

    computed:{
    filteredHistories() {
      console.log('filtered history');
      return this.apiHistories.filter(c => {
        if(this.filter == '') return true;
        // if (c.entityname == null) c.entityname = '';
        return (c.entityname && c.entityname.toLowerCase().indexOf(this.filter.toLowerCase())) >= 0
      })
    },
    SortedapiHistories:function() {
      console.log('sort history');
      console.log(this.currentSort);
      return this.filteredHistories.slice().sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] === null) return 1;
        if (a[this.currentSort] === "undefined") return 1;
        if (b[this.currentSort] === null) return -1;
        if (b[this.currentSort] === "undefined") return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    },
    methods: {

    onChangeCallType(event) {
            this.SelectedCallType= event.target.value
            },

    onChangeMethod(event) {
        console.log(' in change methiod');
        console.log(event.target.value);
        this.SelectedMethod= event.target.value;
        },

    onChangeEntity(event) {
        console.log(' in change entity');
        console.log(event.target.value);
        this.SelectedEntity= event.target.value;
        },

    //
    editHistory(history) {
      console.log('editCaedithistoryrrier');
      console.log(history);
      this.editHistoryForm = history;
      if (this.editHistoryForm.entitytype == 'CLI'){
        this.editHistoryForm.entitytype = 'Outbound'
      }
      if (this.editHistoryForm.before == null){
        this.editHistoryForm.before = this.editHistoryForm.after
      }
      // this.callApi()
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editHistoryModal.hide();
    },
    onSubmit(evt) {
        console.log('on submit');
        if (!this.SelectedEntity){ // is null or zero
            alert("Please select an entity type");
            evt.preventDefault();
                    return false;
            }
        this.apiHistories = null;
        this.showresult = false;
        this.callApi(this.SelectedEntity, 'DELETE', this.FromDate, this.ToDate);
        // this.showresult = true;
        evt.preventDefault();
        },


    nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredHistories.length) this.currentPage++;
      },

    prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },

    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // List historys
      // *******************************************
    async callApi(entity, method, fromdate, todate) {

        console.log(entity)
        console.log('getting the data')
        // build the filters for the url
        var url;
        var filters =[];
        var entityfilter = {"name":"entitytype","op":"eq","val":entity}
        var methodfilter = {"name":"method","op":"eq","val":method}
        var fromfilter = {"name":"modifiedon","op":">","val":fromdate}
        var tofilter = {"name":"modifiedon","op":"<","val":todate}


        if (typeof(entity) != "undefined" && entity != null && entity != "") {
            filters.push(entityfilter)
        }
        if (typeof(method) != "undefined" && method != null && method != "") {
            filters.push(methodfilter)
        }
        if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
            filters.push(fromfilter)
        }
        if (typeof(todate) != "undefined" && todate != null && todate != "") {
            filters.push(tofilter)
        }


        let queryfilters = JSON.stringify(filters);

        console.log(queryfilters)

        document.body.style.cursor = 'wait';

        try {
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/histories?q={"filters":' + queryfilters + '}';
          console.log(url)
          const { data } = await this.$http.get(url);
          console.log(data);
          this.apiHistories = data;
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiHistories = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    },

    created() {
      console.log('created')
      this.executed = true;
    //   this.callApi();

    },
    components: {
      alert: Alert,
      listfilter:Filter,
      datepicker
    },
  };
  </script>
