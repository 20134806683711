<template>

    <div>
      <p><u><strong>Deleted Products</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
            <b-form @submit="onSubmit" >
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="From Date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-fromdate-input"
                    type="date"   size="sm"
                    v-model="FromDate"
                    placeholder="Enter from date">
            </b-form-input>
            </b-form-group>
            <b-form-group
            id="fieldset-horizontal"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="To Date"
            label-for="input-horizontal"
            >
            <b-form-input id="form-todate-input"
                    type="date"   size="sm"
                    v-model="ToDate"
                    placeholder="Enter to date">
            </b-form-input>
            </b-form-group>


            <b-button type="submit" variant="primary">Find</b-button>
            </b-form>
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th scope="col" >Product Number</th>
                <th class="th-lg" scope="col" >Product Name</th>
                <th class="th-sm" scope="col" >Product Type</th>
                <th scope="col" >Category</th>
                <th scope="col"  >Carrier</th>
                <th scope="col" >Part Number</th>
                <th scope="col"  >Unit Cost</th>
                <th scope="col" >Modified on</th>
                <th scope="col">User</th>
                <th scope="col">Action</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(prodcuthistory, index) in SortedapiProducts" :key="index">
                <td>{{ prodcuthistory.product.ProductNumber }}</td>
                <td>{{ prodcuthistory.product.Name}}</td>
                <td>{{ prodcuthistory.product.ChargeType}}</td>
                <td v-if="prodcuthistory.product.category">{{ prodcuthistory.product.category.Name }}</td>
                <td v-else></td>
                <td v-if="prodcuthistory.product.carrier">{{ prodcuthistory.product.carrier.Name }}</td>
                <td v-else></td>
                <td>{{ prodcuthistory.product.PartNumber}}</td>
                <td>{{ prodcuthistory.product.CostUnitPrice}}</td>
                <td>{{ prodcuthistory.modifiedon }}</td>
                <td>{{ prodcuthistory.modifiedby }}</td>
                <td>{{ prodcuthistory.method }}</td>
                <td align="center"><a :href="'/producthistory?product_id=' + prodcuthistory.product.ProductId + '&productnumber=' + prodcuthistory.product.PartNumber" target="_blank">History</a></td>
                <td>
                    <div class="btn-group"  role="group">
                      <button
                              type="button"
                              class="btn btn-warning btn-sm"
                              label="view"
                              v-b-modal.product-update-modal
                              @click="editProduct(prodcuthistory)">
                              View
                              <!-- {{editButtonText}} -->
                      </button>
  
                    </div>
                  </td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="filteredProducts.length"
            :per-page="pageSize"
            aria-controls="apirecords"
            ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
      <!-- EDIT modal -->
      <b-modal ref="editProductModal"
                id="product-update-modal"
                title="Product history"
                hide-footer size="m" >
          <b-form  @reset="onResetUpdate" class="w-600">
            <b-form-row>
          <b-col>
              <b-form-group id="form-productnumbergroup"
                      label="Product Number"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.ProductNumber"
                              readonly
                               w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Product Name"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.Name"
                              readonly w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Product Type"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.ChargeType"
                              readonly w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Category"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.category"
                              readonly
                               w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Carrier"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.carrier.Name"
                              readonly
                              w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-PartNumber-group"
                        label="Part Number"
                        label-for="form-PartNumber-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PartNumber-input"
                        type="text"   size="sm"
                        v-model="editProductForm.PartNumber"
                        readonly>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-costprice-group"
                        label="Unit Cost"
                        label-for="form-costprice-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-costprice-input"
                        type="text"   size="sm"
                        v-model="editProductForm.CostUnitPrice"
                        readonly>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
         <b-form-row>
          <b-col>
              <b-form-group id="form-Description-group"
                        label="Description"
                        label-for="form-Description-input" label-cols="6" label-cols-sm="3"  label-size="sm">
              <b-form-input id="form-Description-input"
                        type="text"   size="sm"
                        v-model="editProductForm.Description"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Notes-group"
                        label="Modified on"
                        label-for="form-Note-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
                        <b-form-input id="form-Label-input"
                          type="text"   size="sm"
                          v-model="editProductForm.modifiedon"
                          readonly
                          >
                </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Notes-group"
                        label="User"
                        label-for="form-Note-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
                        <b-form-input id="form-Label-input"
                          type="text"   size="sm"
                          v-model="editProductForm.modifiedby"
                          readonly
                          >
                </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Notes-group"
                        label="Action"
                        label-for="form-Note-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
                        <b-form-input id="form-Label-input"
                          type="text"   size="sm"
                          v-model="editProductForm.action"
                          readonly
                          >
                </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
          <b-form-row>
              <b-col>
           <p></p>
            </b-col>
            </b-form-row>
            <div class="container">
            <div class="row"  >
                <div class="col text-center">
                <b-button-group>
                  <b-button type="reset" variant="danger">Close</b-button>
            </b-button-group>
                </div>
            </div>
            </div>
          </b-form>
        </b-modal>
    </div>
  </template>
  
  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  
  import {isAdmin, editButtonText} from '@/auth/AuthService'
  export default {
    Name: "Api",
    data() {
      return {
        isAdmin: isAdmin(),
        editButtonText: editButtonText(),
        FromDate : null,
        ToDate : null,
        phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
        SelectedPhoneType: '',
        SelectedCostCentre: null,
        apiProducts: [],
        apiHistoryProduct:null,
        executed: false,
        message: 'initial alert',
        currentSort:'modifiedon',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:false,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selected_account: null,
        ShowBundle:false,
        selected_mobilebundle: null,
        CostCentres: null,
        editProductForm: {
            ProductCatalogueId: null,
            Name: null ,
            ChargeType: null ,
            ProductNumber: null ,
            PartNumber: null ,
            Description: null ,
            CarrierId: null ,
            CategoryId: null ,
            CostUnitPrice: null,
            modifiedby :null,
            modifiedon : null,
            action : null,
            category: null,
            // category:{Name:null,
            //           CategoryId:null},
            carrier:{Name:null,
                      CarrierId:null}
          }
      };
    },
  
    computed:{
    filteredProducts() {
      return this.apiProducts
      },
    SortedapiProducts:function() {
      //  console.log('sort cli');
      if (this.apiProducts != null){
        return this.filteredProducts.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(this.currentSort === 'account.Name'){
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
          else{
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
          else{ return null}
    },
    },
    methods: {
       mobilebundleChange: function (e) {
            this.selectedMobileBundle = e;
        },
      onSubmit(evt) {
            console.log('on submit');
            this.apiHistories = null;
            this.showresult = false;
            var todate = null

            if (this.ToDate != null){
            todate = this.ToDate + ' 23:59:59';
            }

            if (this.ToDate != null & this.FromDate > this.ToDate){
                evt.preventDefault();
                alert("To date can not be before the from date")
            }
            else{
                this.callApi( this.FromDate, todate);
                evt.preventDefault();
            }  
        },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredProducts.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // get Product details
      // *******************************************
      async getProductApi(productid) {
  
        console.log(productid)
        console.log('getting the account data')
        try {
          document.body.style.cursor = "wait";
          var url;
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/products/' + productid
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiHistoryProduct = data;
          console.log('got the cli data from api')
          console.log("GET Product DETAILS")
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apiAccounts = `Error: the server responded with '${e.response}'`;
        }
      },
      // *******************************************
      // List product history
      // *******************************************
      async callApi(fromdate, todate) {

        console.log('getting the data')
        // build the filters for the url
        var filters =[];
        var fromfilter = {"name":"modifiedon","op":">=","val":fromdate}
        var tofilter = {"name":"modifiedon","op":"<","val":todate}

        if (typeof(fromdate) != "undefined" && fromdate != null && fromdate != "") {
            filters.push(fromfilter)
        }
        if (typeof(todate) != "undefined" && todate != null && todate != "") {
            filters.push(tofilter)
        }
        let queryfilters = JSON.stringify(filters);

        this.showPaging = false
  
        try {
          document.body.style.cursor = 'wait';
          var url;
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/product/deleted?q={"filters":' + queryfilters + '}' ;
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiProducts = data;
          console.log(data)
          if (this.apiProducts.length > this.pageSize){
            this.showPaging = true
          }
          this.executed = true;
        } catch (e) {
          this.apiProducts = `Error: the server responded with '${e.response}'`;
        }
      },
      editProduct(history) {
  
          // console.log(cli)
          this.editProductForm = history.product;
          this.editProductForm.modifiedby = history.modifiedby
          this.editProductForm.modifiedon = history.modifiedon
          this.editProductForm.action = history.method
          if (!history.product.category){
            this.editProductForm.category = ''
          }
          else{
            this.editProductForm.category = history.product.category.Name
          }
          // if (!history.product.carrier){
          //   this.editProductForm.carrier.Name = ''
          // }
          console.log(" EDIT cli xxxxxxxxxxxx")
          console.log(history)
          console.log(this.editProductForm.history)
          this.SelectedPhoneType = history.product.PhoneType
        },
        onResetUpdate(evt) {
          evt.preventDefault();
          this.$refs.editProductModal.hide();
          // this.initForm();
          // this.getProducts(); // why?
        },
  
    },
  
    created() {
    //   this.callApi();
        this.executed = true;
  
  
    },
    components: {
      alert: Alert,
      listfilter:Filter,
    },
  };
  </script>
  
  