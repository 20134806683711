<template>

  <div>
    <p ><u><strong>Orders</strong></u> </p>
    <br>
    <div class="result-block-container">
      <div :class="['result-block', executed ? 'show' : '']">
        <div v-if="isAdmin">
        <div v-if="this.apibills.StageNumber > 21" >

          <div v-if="this.apibills.StageNumber > 22">
            <!-- <div class="button-container">
              <button style="margin-left :600px" type="button" class="btn btn-success btn-sm" v-on:click="onStartTransfer()" >Transfer Orders</button>
              <button style="margin-left :600px" type="button" class="btn btn-success btn-sm" v-on:click="onStartExport()" >Export Orders</button>
          </div> -->
            <button style="margin-left :600px" type="button" class="btn btn-success btn-sm" v-on:click="onStartTransfer()" >Transfer Orders</button>
            <!-- <button style="margin-left :600px" type="button" class="btn btn-success btn-sm" v-on:click="onStartExport()" >Export Orders</button>
             -->
          </div>
          <div v-else>
            <button  type="button" class="btn btn-success btn-sm" @click="onStartBills()">Generate Orders</button>
            <button style="margin-left :600px" type="button" class="btn btn-success btn-sm" v-on:click="onStartTransfer()" >Transfer Orders</button>
            
          </div>

        </div>
        <div v-else>
          <button  type="button" class="btn btn-success btn-sm" @click="onStartBills()">Generate Orders</button>
        </div>
      </div>
        <br>
          <p  v-if="this.apibills.Name==='ORDER GENERATION COMPLETED'" style="color:blue;font-size:16px;text-align:left;"> {{ this.apibills.Name }} -- Finished on: {{ this.apibills.FinishedOn }} </p>
          <p  v-else-if="this.apibills.Name==='ORDER TRANSFER COMPLETED'" style="color:blue;font-size:16px;text-align:left;"> {{ this.apibills.Name }} -- Finished on: {{ this.apibills.FinishedOn }} </p>
          <p  v-else-if="this.apibills.Name" style="color:blue;font-size:16px;text-align:left;"> {{ this.apibills.Name }} -- Started on: {{ this.apibills.StartedOn }}</p>
          <p  v-else style="color:blue;font-size:16px;text-align:left;"> </p>
        <br>

        <input type="search" v-model="filter">

        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr >
              <th scope="col" @click="sort('AccountNumber')">Account No</th>
              <th scope="col" @click="sort('AccountName')">Account Name</th>
              <th class="th-sm" scope="col" @click="sort('SageAccount')">Sage Account</th>
              <th class="th-lg" scope="col" @click="sort('BillNumber')" >BriBill Order Number</th>
              <th scope="col" @click="sort('OrderCost')">BriBill Order Value</th>
              <th scope="col" @click="sort('CreateSageOrder')">Transfer</th>
              <!-- <th scope="col" @click="sort('Transferred')">Transferred</th> -->
              <th scope="col" @click="sort('OrderCost')">Transfer Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in SortedapiOrders" :key="index" >
              <td v-if="order.AccountName" >{{ order.AccountNumber }}</td>
              <td v-else>{{ order.GroupNo }}</td>
              <td v-if="order.AccountName">{{ order.AccountName }}</td>
              <td v-else>{{ order.GroupName }}</td>
              <td >{{ order.SageAccount }}</td>
              <td> {{ order.BillNumber }}</td>
              <td >{{ order.OrderCost }}</td>
              <td >{{ order.CreateSageOrder }}</td>
              <!-- <td >{{ order.Transferred }}</td> -->
              <td >{{ order.TransferredOn }}</td>
              <td>
                <div class="btn-group"  role="group">
                  <button
                          type="button"
                          class="btn btn-warning btn-sm"
                          v-b-modal.order-update-modal
                          @click="editOrder(order)">
                      Order lines
                  </button>
                  <p>  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-show="showPaging"><p align="center">
          <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="filteredOrders.length"
              :per-page="pageSize"
              aria-controls="apirecords"
              ></b-pagination>
          <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p></div>
      </div>
    </div>

    <!-- EDIT modal -->
    <b-modal ref="editOrderModal"
            id="order-update-modal"
            hide-footer size="xl" >
      <b-form @reset="onResetUpdate" class="w-1200">
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-lg" scope="col"  >Bill no.</th>
              <th class="th-sm" scope="col" >Sage Ref</th>
              <th class="th-sm" scope="col" >Part no.</th>
              <th class="th-sm" scope="col" >Category</th>
              <th scope="col" >Quantity</th>
              <th scope="col" >Unit cost</th>
              <th scope="col" >Line total</th>
              <th scope="col" >Transferred</th>
              <th scope="col" >Date transferred</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(orderdetail, index) in editOrderForm.orderdetails" :key="index">
              <td>{{ orderdetail.BillNumber }}</td>
              <td>{{ orderdetail.SageAccount }}</td>
              <td>{{ orderdetail.SagePart }}</td>
              <td>{{ orderdetail.Category }}</td>
              <td>{{ orderdetail.Quantity }}</td>
              <td>{{ orderdetail.UnitCost }}</td>
              <td>{{ orderdetail.LineTotal }}</td>
              <td>{{ orderdetail.Transferred }}</td>
              <td>{{ orderdetail.DateTransferred }}</td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="container">
        <div class="row">
            <div class="col text-center">
            <b-button-group>
          <b-button type="reset" variant="danger">Close</b-button>
        </b-button-group>
            </div>
        </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      timer1: "",
      timer2: "",
      editOrderForm: {
        OrderId: null,
        BillingName: null ,
        orderdetails: null
      },
      apiOrders: [],
      apibills:{},
      executed: false,
      message: 'initial alert',
      currentSort:'BillNumber',
      currentSortDir:'asc',
      pageSize:20,
      showPaging:false,
      currentPage:1,
      filter:'',
      GenerateMsg:''
    };
  },

  computed:{

  filteredOrders() {
    console.log('filtered order');
      return this.apiOrders.filter(c => {
        if(this.filter == '') return true;
        if (c.AccountNumber == null) c.AccountNumber = '';
        if (c.GroupNo == null) c.GroupNo = '';
        if (c.AccountName == null) c.AccountName = '';
        if (c.GroupName == null) c.GroupName = '';
        if (c.SageAccount == null) c.SageAccount = '';
        if (c.BillNumber == null) c.BillNumber = '';
        return c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.GroupNo.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.AccountName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.GroupName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.SageAccount.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
              c.BillNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiOrders:function() {
    console.log('sort order');
    return this.filteredOrders.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;
      if (a[this.currentSort] === null) return 1;
      if (b[this.currentSort] === null) return -1;
      if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
      if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredOrders.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List orders
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the data')
      try {
        document.body.style.cursor = 'wait';
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/orders`);
        document.body.style.cursor = 'auto';
        console.log(data);
        this.apiOrders = data;
        this.executed = true;
        if (this.apiOrders.length > this.pageSize){
            this.showPaging = true
          }
          else{
            this.showPaging = false
          }
      } catch (e) {
        this.apiOrders = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // List bill logs
    // *******************************************
    async callBillLogApi(lablex) {

      console.log(lablex)
      console.log('***********************getting the data')

      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/bills/billlogs`);

        this.apibills = data;
        console.log("in bill log.................");
        console.log(data);
        this.executed = true;
        // stop the autorefresh
        // if (this.apibills.Name==='ORDER GENERATION COMPLETED'){
        //   console.log("inside stop auto refresh")
        //   this.cancelAutoUpdate();
        // }
      } catch (e) {
        this.apibills = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // Add billlog on submit
    // *******************************************
    // Add billlog method to post to API
    async addbilllog() {

      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/bills`, null).then(() => {
          this.message = 'bills started!';
          this.showMessage = true;
          this.callBillLogApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated billlog. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add billlog. status code:  " + err.response.status)
            }
          console.error(err);
          this.callBillLogApi();

        });

        // this.apibills = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apibills = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // Add transferlog on submit
    // *******************************************
    // Add transferlog method to post to API
    async addTransferLog() {

      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/orders/transfers`, null).then(() => {
          this.message = 'transfer started!';
          this.showMessage = true;
          this.callBillLogApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated billlog. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add billlog. status code:  " + err.response.status)
            }
          console.error(err);
          this.callBillLogApi();

        });

        // this.apibills = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apibills = `Error: the server responded with '${e.response}'`;
      }
    },
    // *******************************************
    // start export download
    // *******************************************
    async startOrderExport() {

        console.log('***********************download*****************************')

        try {
          document.body.style.cursor = "wait";
          const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/orders/export`);
          document.body.style.cursor = 'auto';

          this.executed = true;
          var fileURL = window.URL.createObjectURL(new Blob([data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          // if (invoicename == 'all'){
          //   invoicename = 'invoices.zip';
          // }
          fileLink.setAttribute('download', "BillrunOrders.xlsx");
          document.body.appendChild(fileLink);

          fileLink.click();

        } catch (e) {
          this.apiInvoices = `Error: the server responded with '${e.response}'`;
        }
      },
    // async startOrderExport() {
    //   try {
    //     const { data } =  this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/orders/export`, null).then(() => {
    //       this.message = 'export started!';
    //       this.showMessage = true;
    //       this.callBillLogApi();

    //     }).catch((err) => {
    //       if(err.response.status == 409) {
    //         alert("Duplicated billlog. status code:  " + err.response.status)
    //         }
    //       if(err.response.status != 409) {
    //         alert("Problem with add billlog. status code:  " + err.response.status)
    //         }
    //       console.error(err);
    //       this.callBillLogApi();

    //     });

    //     // this.apibills = data;
    //     // this.executed = true;
    //     console.log(data)
    //     //this.callApi()
    //   } catch (e) {
    //     this.apibills = `Error: the server responded with '${e.response}'`;
    //   }
    // },
    // *******************************************
    // Start transfer process
    // *******************************************
    onStartTransfer() {
      console.log('on start transfer');
      this.addTransferLog();
    },
    // *******************************************
    // Start export process
    // *******************************************
    onStartExport() {
      console.log('on start export');
      this.startOrderExport();
    },
    // *******************************************
    // Add billlog on submit
    // *******************************************
    onStartBills() {
      console.log('on start bills');
      this.apiOrders = [];
      this.addbilllog();
      //this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addOrderModal.hide();
      this.initForm();
    },
  //
    editOrder(order) {
      console.log('editOrder');
      console.log(order);
      console.log(order.orderdetails);
      this.editOrderForm = order;
      this.callApi()
    },

    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editOrderModal.hide();
      this.initForm();
      this.getOrders(); // why?
    },
    cancelAutoUpdate() {
      console.log("cancleautoupdate~~~~~~~~~~~~~~~~~~~~~~~~")
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      console.log("cancleautoupdate~~~~~~~~~~~~~~~~~~~~~~~~")
    },
  },
  created() {
    console.log('created')
    this.callApi();
    this.callBillLogApi();
    this.timer1 = setInterval(this.callApi, 20000);
    this.timer2 = setInterval(this.callBillLogApi, 20000);

  },
  components: {
    alert: Alert,
    listfilter:Filter
  },
  beforeDestroy() {
    console.log("order before destroy ~~~~~~~~~~~~~~~~~~~~~~~~~~~")
    this.cancelAutoUpdate();
  },

};
</script>
<style>
.button-container {
  display: flex;
}
</style>