<template>

    <div>
      <p v-if="deleted"><u><strong>Deleted Product History ({{ this.productnumber}})</strong></u></p>
      <p v-else><u><strong>Product History ({{ this.productnumber}})</strong></u></p>
      <br>
      <div class="result-block-container">
        <div :class="['result-block', executed ? 'show' : '']">
          <table class="table table-striped w-auto" id="apirecords">
            <thead>
              <tr>
                <th scope="col" >Product Number</th>
                <th class="th-lg" scope="col" >Product Name</th>
                <th class="th-sm" scope="col" >Product Type</th>
                <th scope="col" >Category</th>
                <th scope="col"  >Carrier</th>
                <th scope="col" >Part Number</th>
                <th scope="col"  >Unit Cost</th>
                <th scope="col" >Modified on</th>
                <th scope="col">User</th>
                <th scope="col">Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(prodcuthistory, index) in SortedapiProducts" :key="index">
                <td>{{ prodcuthistory.product.ProductNumber }}</td>
                <td>{{ prodcuthistory.product.Name}}</td>
                <td>{{ prodcuthistory.product.ChargeType}}</td>
                <td v-if="prodcuthistory.product.category">{{ prodcuthistory.product.category.Name }}</td>
                <td v-else></td>
                <td v-if="prodcuthistory.product.carrier">{{ prodcuthistory.product.carrier.Name }}</td>
                <td v-else></td>
                <td>{{ prodcuthistory.product.PartNumber}}</td>
                <td>{{ prodcuthistory.product.CostUnitPrice}}</td>
                <td>{{ prodcuthistory.modifiedon }}</td>
                <td>{{ prodcuthistory.modifiedby }}</td>
                <td>{{ prodcuthistory.method }}</td>
                <!-- <td>{{ prodcuthistory.product.Name}}</td>
                <td>{{ prodcuthistory.product.Name}}</td>
                <td v-if="prodcuthistory.product.costcentre">{{ prodcuthistory.product.costcentre.Name }}</td>
                <td v-else></td>
                <td>{{ prodcuthistory.product.PhoneNumber }}</td>
                <td>{{ prodcuthistory.product.Label }}</td>
                <td>{{ prodcuthistory.product.PhoneType }}</td>
                <td>{{ prodcuthistory.modifiedon }}</td>
                <td>{{ prodcuthistory.modifiedby }}</td>
                <td>{{ prodcuthistory.method }}</td> -->
                <td>
                    <div class="btn-group"  role="group">
                      <button
                              type="button"
                              class="btn btn-warning btn-sm"
                              label="view"
                              v-b-modal.product-update-modal
                              @click="editProduct(prodcuthistory)">
                              View
                              <!-- {{editButtonText}} -->
                      </button>
  
                    </div>
                  </td>
              </tr>
            </tbody>
          </table>
          <div v-show="showPaging"><p align="center" >
            <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="filteredProducts.length"
            :per-page="pageSize"
            aria-controls="apirecords"
            ></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
              <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
            </p></div>
        </div>
      </div>
      <!-- EDIT modal -->
      <b-modal ref="editProductModal"
                id="product-update-modal"
                title="Product history"
                hide-footer size="m" >
          <b-form  @reset="onResetUpdate" class="w-600">
            <b-form-row>
          <b-col>
              <b-form-group id="form-productnumbergroup"
                      label="Product Number"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.ProductNumber"
                              readonly
                               w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Product Name"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.Name"
                              readonly w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Product Type"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.ChargeType"
                              readonly w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Category"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.category"
                              readonly
                               w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-name-group"
                      label="Carrier"
                      label-for="form-name-input" label-cols="4" label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input"
                              type="text"   size="sm"
                              v-model="editProductForm.carrier.Name"
                              readonly
                              w-20>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-PartNumber-group"
                        label="Part Number"
                        label-for="form-PartNumber-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PartNumber-input"
                        type="text"   size="sm"
                        v-model="editProductForm.PartNumber"
                        readonly>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-costprice-group"
                        label="Unit Cost"
                        label-for="form-costprice-input" label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-costprice-input"
                        type="text"   size="sm"
                        v-model="editProductForm.CostUnitPrice"
                        readonly>
              </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
         <b-form-row>
          <b-col>
              <b-form-group id="form-Description-group"
                        label="Description"
                        label-for="form-Description-input" label-cols="6" label-cols-sm="3"  label-size="sm">
              <b-form-input id="form-Description-input"
                        type="text"   size="sm"
                        v-model="editProductForm.Description"
                        readonly
                        >
              </b-form-input>
              </b-form-group>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Notes-group"
                        label="Modified on"
                        label-for="form-Note-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
                        <b-form-input id="form-Label-input"
                          type="text"   size="sm"
                          v-model="editProductForm.modifiedon"
                          readonly
                          >
                </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Notes-group"
                        label="User"
                        label-for="form-Note-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
                        <b-form-input id="form-Label-input"
                          type="text"   size="sm"
                          v-model="editProductForm.modifiedby"
                          readonly
                          >
                </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
              <b-form-group id="form-Notes-group"
                        label="Action"
                        label-for="form-Note-input" label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
                        <b-form-input id="form-Label-input"
                          type="text"   size="sm"
                          v-model="editProductForm.action"
                          readonly
                          >
                </b-form-input>
              </b-form-group>
          </b-col>
        </b-form-row>
          <b-form-row>
              <b-col>
           <p></p>
            </b-col>
            </b-form-row>
            <div class="container">
            <div class="row"  >
                <div class="col text-center">
                <b-button-group>
                  <b-button type="reset" variant="danger">Close</b-button>
            </b-button-group>
                </div>
            </div>
            </div>
          </b-form>
        </b-modal>
    </div>
  </template>
  
  <script>
  import Alert from '../components/Alert.vue';
  import Filter from '../components/Filter.vue';
  
  import {isAdmin, editButtonText} from '@/auth/AuthService'
  export default {
    Name: "Api",
    data() {
      return {
        isAdmin: isAdmin(),
        editButtonText: editButtonText(),
        phonetypes: [{ "id":"L", "value":'Landline      '}, {"id":"M","value":'Mobile'}],
        SelectedPhoneType: '',
        SelectedCostCentre: null,
        apiProducts: null,
        apiHistoryProduct:null,
        executed: false,
        message: 'initial alert',
        currentSort:'modifiedon',
        currentSortDir:'asc',
        pageSize:20,
        showPaging:true,
        currentPage:1,
        filter:'',
        ShowAccount:true,
        selected_account: null,
        ShowBundle:false,
        selected_mobilebundle: null,
        CostCentres: null,
        editProductForm: {
            ProductCatalogueId: null,
            Name: null ,
            ChargeType: null ,
            ProductNumber: null ,
            PartNumber: null ,
            Description: null ,
            CarrierId: null ,
            CategoryId: null ,
            CostUnitPrice: null,
            modifiedby :null,
            modifiedon : null,
            action : null,
            category: null,
            // category:{Name:null,
            //           CategoryId:null},
            carrier:{Name:null,
            CarrierId:null}
          },
          productnumber: null,
          deleted:false
      };
    },
  
    computed:{
    filteredProducts() {
      return this.apiProducts
      },
    SortedapiProducts:function() {
      //  console.log('sort cli');
      if (this.apiProducts != null){
        return this.filteredProducts.slice().sort((a,b) => {
          let modifier = 1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(this.currentSort === 'account.Name'){
            var prop = this.currentSort.split('.');
            var len = prop.length;
              var i = 0;
              while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
              if (a < b) {
                  return -1 * modifier;
              } else if (a > b) {
                  return 1 * modifier;
              } else {
                  return 0;
              }
          }
          else{
            if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
            if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
            return 0;
          }
        }).filter((row, index) => {
          let start = (this.currentPage-1)*this.pageSize;
          let end = this.currentPage*this.pageSize;
          if(index >= start && index < end) return true;
        });
      }
          else{ return null}
    },
    },
    methods: {
       mobilebundleChange: function (e) {
            this.selectedMobileBundle = e;
        },
      nextPage:function() {
        if((this.currentPage*this.pageSize) < this.filteredProducts.length) this.currentPage++;
      },
      prevPage:function() {
        if(this.currentPage > 1) this.currentPage--;
      },
      sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
      },

      // *******************************************
      // get Product details
      // *******************************************
      async getProductApi(productid) {
  
        console.log(productid)
        console.log('getting the account data')
        try {
          document.body.style.cursor = "wait";
          var url;
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/products/' + productid
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiHistoryProduct = data;
          this.productnumber = this.apiHistoryProduct.PartNumber;
          console.log('got the cli data from api')
          console.log("GET Product DETAILS")
          console.log(data)
          this.executed = true;
        } catch (e) {
          this.apiAccounts = `Error: the server responded with '${e.response}'`;
        }
      },
      // *******************************************
      // List product history
      // *******************************************
      async callApi(lablex) {
  
         console.log(lablex)
        //  console.log('getting the product list')
        //  console.log(this.$route.query.account_id)
        //  console.log('getting the product list')
        var url;
        if (typeof(this.$route.query.product_id) != "undefined"){
          url =  process.env.VUE_APP_API_BASE_URL + '/api/v1/histories/entitytype/product/' + this.$route.query.product_id
          console.log("Product HISTORY")
          console.log(url)
          this.ShowAccount = false
          this.selected_account = this.$route.query.account_id
          // if deleted 
          if (typeof(this.$route.query.productnumber) != "undefined"){
            this.productnumber = this.$route.query.productnumber;
            this.deleted = true;
          }
          else{
            this.getProductApi(this.$route.query.product_id)
          }
          
         
        }
        else{
            url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/products`
        }
        try {
          document.body.style.cursor = 'wait';
          const { data } = await this.$http.get(url);
          document.body.style.cursor = 'auto';
          this.apiProducts = data;
          console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")
          console.log(data)
          if (this.apiProducts.length < this.pageSize){
            this.showPaging = false
          }
          //  console.log(this.apiProducts )
          //  console.log(this.apiProducts.length)
          this.executed = true;
        } catch (e) {
          this.apiProducts = `Error: the server responded with '${e.response}'`;
        }
      },
      editProduct(history) {
  
          // console.log(cli)
          this.editProductForm = history.product;
          this.editProductForm.modifiedby = history.modifiedby
          this.editProductForm.modifiedon = history.modifiedon
          this.editProductForm.action = history.method
          if (!history.product.category){
            this.editProductForm.category = ''
          }
          else{
            this.editProductForm.category = history.product.category.Name
          }
          // if (!history.product.carrier){
          //   this.editProductForm.carrier.Name = ''
          // }
          console.log(" EDIT cli xxxxxxxxxxxx")
          console.log(history)
          console.log(this.editProductForm.history)
          this.SelectedPhoneType = history.product.PhoneType
        },
        onResetUpdate(evt) {
          evt.preventDefault();
          this.$refs.editProductModal.hide();
          // this.initForm();
          // this.getProducts(); // why?
        },
  
    },
  
    created() {
      this.callApi();
  
  
    },
    components: {
      alert: Alert,
      listfilter:Filter,
    },
  };
  </script>
  
  